import { useEffect, useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { createSelector } from 'reselect'

import { getFetchingSuppressedVariableOptions } from 'store/DatasetSelectors'

const areSuppressedOptionsLoading = createSelector(
  getFetchingSuppressedVariableOptions,
  fetchingVars => Object.values(fetchingVars).some(isFetching => isFetching)
)

/** Only show the variable loading state if it's taking a while. Looks weird when it's very brief... */
export default function useVariableLoading() {
  const isFetchingVariable = useSelector(areSuppressedOptionsLoading, shallowEqual)
  const [debouncedValue, setDebouncedValue] = useState(false)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(isFetchingVariable)
    }, 500)

    return () => {
      clearTimeout(handler)
    }
  }, [isFetchingVariable])

  return isFetchingVariable === false ? false : debouncedValue
}
