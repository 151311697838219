import React, { useState, useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import cs from 'classnames'
import { Button, SearchInput, LoadingSpinner, useDebounce } from '@knowledgehound/laika'

import { fetchVariableWithSuppressedOptions, setVariableSearchQuery } from 'store/DatasetActions'
import { getClientBase } from 'store/configurationDuck'
import {
  getIsEmbedded,
  getSuppressedVariablesWithErrors,
  getVariablesWithSearch,
  getHandleTrack,
} from 'store/DatasetSelectors'

import VariableSection from './VariableSection'
import VariableOptionsSection from './VariableOptionsSection'
import useVariableLoading from './useVariableLoading'

import * as animations from '../SidebarAnimations.module.scss'
import * as styles from './AddVariableSlider.module.scss'

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  /** Callback triggered when user decides not to add or replace a variable */
  handleCancel: PropTypes.func.isRequired,
  chartType: PropTypes.string.isRequired,
  /** List of variables that belong to this study */
  variables: PropTypes.arrayOf(PropTypes.object),
  /** Analysis axis to which the staged variable will be added (or replace another variable) */
  axis: PropTypes.string.isRequired,
  addedVariables: PropTypes.arrayOf(
    PropTypes.shape({
      variableNk: PropTypes.string.isRequired,
    })
  ),
  /** Callback triggered when user adds a variable to the current analysis */
  onVariableSubmit: PropTypes.func.isRequired,
  /** Callback triggered when user selected a variable from list */
  onClickVariable: PropTypes.func.isRequired,
  /** Variable to be replaced if user adds a variable */
  replacingVariable: PropTypes.shape({
    variableNk: PropTypes.string.isRequired,
  }),
  totalVariableLimit: PropTypes.number.isRequired,
  /** Variable response options to be hidden from the user **/
  optionExclusions: PropTypes.arrayOf(PropTypes.string),
}

function AddVariableSlider({
  addedVariables,
  isOpen,
  axis,
  replacingVariable,
  onVariableSubmit,
  totalVariableLimit,
  chartType,
  handleCancel,
  onClickVariable,
  optionExclusions,
}) {
  const intl = useIntl()
  const history = useHistory()
  const clientBase = useSelector(getClientBase, shallowEqual)
  const isEmbedded = useSelector(getIsEmbedded, shallowEqual)
  const variablesWithErrors = useSelector(getSuppressedVariablesWithErrors, shallowEqual)
  const [selectedVariable, setSelectedVariable] = useState(null)
  const [disabledClick, setDisabledClick] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [gatedOpenEndVariable, setGatedOpenEndVariable] = useState(null)
  const debouncedSearchQuery = useDebounce(searchQuery, 50)
  const anyVariableLoading = useVariableLoading()
  const dispatch = useDispatch()
  const searchVariables = useSelector(getVariablesWithSearch, shallowEqual)
  const handleTrack = useSelector(getHandleTrack, shallowEqual)

  useEffect(() => {
    if (debouncedSearchQuery) {
      dispatch(setVariableSearchQuery(debouncedSearchQuery))
      handleTrack('Add Variables Filtered', { debouncedSearchQuery })
    } else {
      dispatch(setVariableSearchQuery(''))
    }
  }, [debouncedSearchQuery, dispatch, handleTrack])

  const handleClickVariable = useCallback(
    async variable => {
      if (disabledClick) return
      setDisabledClick(true)

      if (variable.question_type === 'Open Ended') {
        setGatedOpenEndVariable(variable)
      } else {
        const varRes = await dispatch(
          fetchVariableWithSuppressedOptions({
            variableNk: variable.nk,
            replacingVariable,
            axis,
          })
        )
        onClickVariable(varRes)
        setSelectedVariable(varRes)
      }
      setDisabledClick(false)
    },
    [disabledClick, onClickVariable, replacingVariable, axis, dispatch]
  )

  const {
    suggestedVariables,
    gridVariables,
    intervalVariables,
    openEndedVariables,
    otherVariables,
    numberOfVariables,
  } = useMemo(() => {
    let occurrences = 0
    if (replacingVariable) {
      addedVariables.forEach(v => {
        if (v.variableNk === replacingVariable.variableNk) {
          occurrences += 1
        }
      })
    }

    const suggestedVariables = []
    const intervalVariables = []
    const gridVariables = []
    const openEndedVariables = []
    const otherVariables = []

    // classify
    searchVariables.forEach(variable => {
      // strip out existing questions from the list
      if (addedVariables.some(av => variable.nk === av.variableNk)) {
        return
      }
      if (
        !replacingVariable &&
        (addedVariables.length === totalVariableLimit ||
          (addedVariables.length === totalVariableLimit - 1 && variable.is_grid))
      ) {
        return
      }
      // the user cannot replace a normal question with a grid when already at capacity
      if (
        replacingVariable &&
        addedVariables.length === totalVariableLimit &&
        occurrences === 1 &&
        variable.is_grid
      ) {
        return
      }
      if (chartType === 'line' && variable.represents === 'interval') {
        intervalVariables.push(variable)
      } else if (variable.question_type === 'Open Ended') {
        openEndedVariables.push(variable)
      } else if (variable.suggested_xtab) {
        suggestedVariables.push(variable)
      } else if (variable.is_grid) {
        gridVariables.push(variable)
      } else {
        otherVariables.push(variable)
      }
    })

    return {
      intervalVariables,
      suggestedVariables,
      gridVariables,
      openEndedVariables,
      otherVariables,
      numberOfVariables:
        suggestedVariables.length +
        gridVariables.length +
        intervalVariables.length +
        openEndedVariables.length +
        otherVariables.length,
    }
  }, [addedVariables, chartType, replacingVariable, searchVariables, totalVariableLimit])

  return (
    <div
      className={`${styles.createVariableMenuContainer} ${
        isOpen ? animations.slideIn : animations.slideOut
      }`}
    >
      <div
        className={cs(styles.topContent, {
          [styles.variableSelected]: selectedVariable,
          [styles.variableNotSelected]: !selectedVariable,
        })}
      >
        <div className={styles.controls}>
          {selectedVariable ? (
            <Button
              size="small"
              variant="text"
              onClick={() => {
                setSearchQuery('')
                setSelectedVariable(null)
              }}
            >
              &#171; Back to List
            </Button>
          ) : (
            <span className={styles.header}>Variable list</span>
          )}
          <Button onClick={handleCancel} variant="text" size="small" icon="close">
            Close
          </Button>
        </div>
        {!selectedVariable && (
          <div className={styles.searchContainer}>
            <SearchInput
              value={searchQuery}
              name="add-variables-search"
              id="add-variables-search"
              placeholder="Search list"
              onChange={event => setSearchQuery(event.currentTarget.value)}
            />
          </div>
        )}
        {!selectedVariable && numberOfVariables > 0 && (
          <div className={styles.variableResults}>
            <VariableSection
              headerText="Suggested"
              variableList={suggestedVariables}
              handleClickVariable={handleClickVariable}
            />
            <VariableSection
              headerText="Time Variables"
              variableList={intervalVariables}
              handleClickVariable={handleClickVariable}
            />
            <VariableSection
              headerText="Grid Variables"
              variableList={gridVariables}
              handleClickVariable={handleClickVariable}
            />
            <VariableSection
              headerText="Variables"
              variableList={otherVariables}
              handleClickVariable={handleClickVariable}
            />
            <VariableSection
              headerText="Open-ended Variables"
              variableList={openEndedVariables}
              handleClickVariable={handleClickVariable}
            />
          </div>
        )}
        {!selectedVariable && numberOfVariables === 0 && (
          <div className={styles.noVariables}>No matching variables found.</div>
        )}
        {selectedVariable && !anyVariableLoading && (
          <VariableOptionsSection
            optionExclusions={optionExclusions}
            selectedVariable={selectedVariable}
            handleCancel={() => setSelectedVariable(null)}
            onVariableSubmit={(variableNk, selected) => {
              onVariableSubmit(variableNk, axis, selected)
            }}
          />
        )}
        {!selectedVariable && (
          <div className={styles.actionBar}>
            <Button onClick={handleCancel} variant="secondary" size="large">
              Cancel
            </Button>
          </div>
        )}
      </div>
      {anyVariableLoading && (
        <div className={styles.stateOverlay}>
          <LoadingSpinner text="" />
        </div>
      )}
      {Boolean(gatedOpenEndVariable) && (
        <div className={styles.stateOverlay}>
          <div className={styles.content}>
            <p className={styles.title}>
              {intl.formatMessage({
                id: `analysis.openEndGate.title.${isEmbedded ? 'embedded' : 'notEmbedded'}`,
              })}
            </p>
            <p className={styles.body}>
              {intl.formatMessage({
                id: `analysis.openEndGate.body.${isEmbedded ? 'embedded' : 'notEmbedded'}`,
              })}
            </p>
            <div className={styles.controls}>
              <Button onClick={() => setGatedOpenEndVariable(null)} variant="secondary">
                {intl.formatMessage({ id: 'analysis.openEndGate.cancel' })}
              </Button>
              <Button
                onClick={() => {
                  const param = gatedOpenEndVariable.nk_uri
                  if (isEmbedded) {
                    window.location.assign(
                      `https://${clientBase}.knowledgehound.co/open-analysis/${param}`
                    )
                  } else {
                    history.push(`/open-analysis/${param}`)
                  }
                }}
                variant="primary"
              >
                {intl.formatMessage({
                  id: `analysis.openEndGate.cta`,
                })}
              </Button>
            </div>
          </div>
        </div>
      )}
      {Boolean(selectedVariable) && variablesWithErrors[selectedVariable?.variableNk] && (
        <div className={styles.stateOverlay}>
          <div className={styles.content}>
            <span className={styles.title}>Oops, something went wrong</span>
            <span className={styles.body}>
              Please refresh your browser or try again in a few minutes.
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

AddVariableSlider.propTypes = propTypes

export default AddVariableSlider
