import classnames from 'classnames'
import React, { useState, useEffect, useRef } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { useSelector, shallowEqual } from 'react-redux'
import { throttle } from 'lodash'
import search from 'simple-text-search'
import { SearchInput } from '@knowledgehound/laika'

import { filterNet as filterNetDuck } from 'store'

import * as styles from './AddFilter.module.scss'

function VariableFilterSelection({ datasetVariables, intl, handleClickVariable }) {
  const selectedVariable = useSelector(filterNetDuck.getSelectedVariable, shallowEqual)
  const [filteredQuestions, setFilteredQuestions] = useState([])
  const [questionFilter, setQuestionFilter] = useState('')

  const filterQuestionsBySearch = useRef(
    throttle(questionFilter => {
      let availableFilters = datasetVariables
      const get = search(availableFilters, 'question_text')
      setFilteredQuestions(get(questionFilter))
    }, 200)
  )

  useEffect(() => {
    filterQuestionsBySearch.current(questionFilter)
  }, [questionFilter, filterQuestionsBySearch])

  const handleChangeSearch = evt => {
    const {
      currentTarget: { value },
    } = evt
    setQuestionFilter(value)
  }

  const createListWithGrids = qList => {
    let availableFilters = [] // type DatasetQuestionT
    qList.forEach(question => {
      if (question.is_grid) {
        if (question.fundamental_question_type.includes('Numeric')) {
          availableFilters.push({
            ...question,
            question_text: `${question.question_text} (options)`,
            resourceType: 'breakouts',
          })
        } else {
          availableFilters = [
            ...availableFilters,
            {
              ...question,
              resourceType: 'options',
              question_text: `${question.question_text} (responses)`,
            },
            {
              ...question,
              resourceType: 'breakouts',
              question_text: `${question.question_text} (options)`,
            },
          ]
        }
      } else {
        availableFilters.push({ ...question, resourceType: 'options' })
      }
    })

    return availableFilters
  }

  const availableFilters = createListWithGrids(datasetVariables) // type modified DatasetQuestionT
  let suggestedQuestions, otherQuestions

  if (availableFilters && availableFilters.length > 0) {
    suggestedQuestions = availableFilters.filter(q => {
      return q.suggested_xtab
    })
    otherQuestions = availableFilters.filter(q => {
      return !q.suggested_xtab && q.main_var_list && q.is_quantitative
    })
  }

  const renderVariableItem = q => {
    return (
      <li
        key={`${q.nk}-${q.resourceType}`}
        className={classnames(styles.variableItem, {
          [styles.selected]: selectedVariable && selectedVariable.variableNk === q.nk,
        })}
        onClick={() => handleClickVariable(q)}
      >
        {q.question_text}
      </li>
    )
  }

  return (
    <React.Fragment>
      <h4 className={styles.columnHeader}>
        <FormattedMessage id="analysis.selectQuestion" />
      </h4>
      <div className={styles.searchInputContainer}>
        <SearchInput
          autoFocus={!selectedVariable}
          size="large"
          name="filters-search"
          id="filters-search"
          value={questionFilter}
          placeholder={intl.formatMessage({ id: 'analysis.filterQuestions' })}
          onChange={handleChangeSearch}
        />
      </div>
      {questionFilter.length > 0 ? (
        <div className={styles.variables}>
          <section className={styles.variable}>
            <ul className={styles.variableList}>
              {createListWithGrids(filteredQuestions).map(q => renderVariableItem(q))}
            </ul>
          </section>
        </div>
      ) : (
        <div className={styles.variables}>
          {suggestedQuestions && suggestedQuestions.length > 0 && (
            <section className={styles.variable}>
              <p className={styles.variableHeader}>Suggested</p>
              <ul className={styles.variableList}>
                {suggestedQuestions.map(q => renderVariableItem(q))}
              </ul>
            </section>
          )}
          {otherQuestions && otherQuestions.length > 0 && (
            <section className={styles.variable}>
              <p className={styles.variableHeader}>Questions</p>
              <ul className={styles.variableList}>
                {otherQuestions.map(q => renderVariableItem(q))}
              </ul>
            </section>
          )}
        </div>
      )}
    </React.Fragment>
  )
}

export default injectIntl(VariableFilterSelection)
