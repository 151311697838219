import classnames from 'classnames'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as Sentry from '@sentry/react'
import { Button } from '@knowledgehound/laika'

import { actions, filterNet as filterNetDuck } from 'store'
import { getHandleTrack } from '../store/DatasetSelectors'
import FilterMethod from '../AddFilter/FilterMethod'
import CategoricalNets from './CategoricalNets'
import NumericNets from './NumericNets'
import NetName from './NetName'
import * as sidebarAnimations from '../AnalysisSidebar/SidebarAnimations.module.scss'
import * as styles from './CreateNets.module.scss'

import type { Dispatch } from 'redux'

type PropsT = {
  isOpen: boolean,
  email: string,
  selectedVariable: VariableT,
  selectedOptions: Array<Object>,
  filters: Array<FilterT>,
  dispatch: Dispatch<*>,
  onCreateNets: Function,
  clickCancel: Function,
  handleTrack: Function,
  onCreateAnother?: Function,
  inheritPosition?: boolean,
  disableDataFetch?: boolean,
}

type StateT = {
  initialState: boolean,
}

class CreateNets extends Component<PropsT, StateT> {
  state = {
    initialState: true, // this is so slide-out animation doesnt trigger on open
  }

  static getDerivedStateFromProps(props, state) {
    if (props.isOpen && state.initialState) {
      return {
        initialState: false,
      }
    }
    return null
  }

  handleClickCreate = async () => {
    const {
      dispatch,
      onCreateNets,
      handleTrack,
      email,
      selectedOptions,
      selectedVariable,
      selectedMethod,
      newNetName,
      loading,
      disableDataFetch,
    } = this.props

    if (loading) return

    try {
      const res = await dispatch(filterNetDuck.createNetThunk(false))
      if (res) {
        onCreateNets &&
          onCreateNets(res, selectedVariable.variableNk, selectedVariable.resourceType)
        dispatch(filterNetDuck.resetFilterNetThunk(disableDataFetch))
        if (!disableDataFetch) {
          dispatch(actions.clearAnalysisData(true))
          dispatch(actions.fetchAnalysisData())
        }
      }
    } catch (e) {
      Sentry.captureException(
        new Error('Analysis2: Error when fetching analysis Data', { cause: e })
      )
    }

    const trackProps = {
      question_id: selectedVariable.variableNk,
      question_text: selectedVariable.questionName,
      user: email,
      netName: newNetName,
      netMethod: selectedMethod,
      nettedResponses: selectedOptions,
    }
    handleTrack('Question Response Net Created', trackProps)
  }

  createAnother = async () => {
    const {
      dispatch,
      onCreateAnother,
      handleTrack,
      email,
      selectedOptions,
      selectedVariable,
      selectedMethod,
      newNetName,
      loading,
    } = this.props

    if (loading) return

    try {
      const res = await dispatch(filterNetDuck.createNetThunk(false, true))
      onCreateAnother &&
        onCreateAnother(res, selectedVariable.variableNk, selectedVariable.resourceType)
    } catch (e) {
      Sentry.captureException(new Error('Analysis2: Error when creating net', { cause: e }))
    }

    const trackProps = {
      question_id: selectedVariable.variableNk,
      question_text: selectedVariable.questionName,
      user: email,
      netName: newNetName,
      netMethod: selectedMethod,
      nettedResponses: selectedOptions,
    }
    handleTrack('Question Response Net Created', trackProps)
  }

  handleChangeCheckbox = value => {
    const { selectedOptions, selectedVariable, dispatch } = this.props
    if (!value) {
      if (selectedOptions.length === 0) {
        const options = selectedVariable.options ?? []
        dispatch(filterNetDuck.setSelectedOptions(options))
      } else {
        dispatch(filterNetDuck.setSelectedOptions([]))
      }
      return
    }

    let currentSelectedOptions = [...selectedOptions]

    if (currentSelectedOptions.find(o => o.id === value.id)) {
      dispatch(
        filterNetDuck.setSelectedOptions(currentSelectedOptions.filter(o => o.id !== value.id))
      )
    } else {
      dispatch(filterNetDuck.setSelectedOptions([...currentSelectedOptions, value]))
    }
  }

  handleNameChange = (value: string) => {
    this.props.dispatch(filterNetDuck.setCreateNetName(value))
  }

  render() {
    const { clickCancel, selectedVariable, filters, dispatch, isOpen, inheritPosition } = this.props

    if (this.state.initialState || !selectedVariable) {
      return null
    }

    const isOptions = selectedVariable.resourceType === 'options'

    const isNumeric = selectedVariable.fundamentalQuestionType.includes('Numeric') && isOptions

    return (
      <div
        className={classnames(`${styles.responseNetMenuContainer}`, {
          [sidebarAnimations.slideIn]: isOpen,
          [sidebarAnimations.slideOut]: !isOpen,
          [styles.inheritPosition]: inheritPosition,
        })}
      >
        <div className={styles.createNetHeader}>
          <div className={styles.headerText}>Create a Net</div>
          <Button onClick={clickCancel} variant="text" size="small" icon="close">
            Close
          </Button>
        </div>
        {isOptions && (
          <FilterMethod
            displayMethod
            headerNumber="1"
            handleChangeFilterMethod={(event, newMethod) => {
              dispatch(filterNetDuck.setMethodThunk(newMethod, []))
              if (!isNumeric) {
                dispatch(filterNetDuck.setCreateNetName(''))
              }
            }}
            sectionStyle={styles.methodSection}
          />
        )}
        {isNumeric ? (
          <NumericNets
            handleChangeCheckbox={this.handleChangeCheckbox}
            handleNameChange={this.handleNameChange}
            filters={filters}
          />
        ) : (
          <CategoricalNets
            handleChangeCheckbox={this.handleChangeCheckbox}
            headerNumber={isOptions ? '2' : '1'}
          />
        )}
        <NetName
          headerNumber={isOptions ? '3' : '2'}
          handleClickCreate={this.handleClickCreate}
          createAnother={this.createAnother}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  selectedVariable: filterNetDuck.getSelectedVariable(state),
  selectedOptions: filterNetDuck.getSelectedOptions(state),
  selectedMethod: filterNetDuck.getSelectedMethod(state),
  newNetName: filterNetDuck.getCreateNetName(state),
  loading: filterNetDuck.getCreateNetLoading(state),
  handleTrack: getHandleTrack(state),
})

export default connect(mapStateToProps)(CreateNets)
