import React, { useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { createSelector } from 'reselect'
import { Checkbox, Icon, Modal, NumberInput, TextInput } from '@knowledgehound/laika'

import { CUSTOM_WEIGHT_MAX_STAGED_VARIABLES } from 'store/constants'
import {
  fetchVariableForCustomWeight,
  unstageVariable,
  setCustomWeightName,
  editCustomWeightSelection,
  createCustomWeight,
} from 'store/customWeightActions'
import {
  getCustomWeightEligibleVariables,
  getCustomWeightName,
  getStagedVariablesForCustomWeights,
  getSelectionsForCustomWeights,
  getCustomWeightValidity,
  getIsCreatingCustomWeight,
  getErrorCreatingCustomWeight,
} from 'store/DatasetSelectors'
import * as styles from './CustomWeightModal.module.scss'

const getStagedVariablesMap = createSelector(
  getStagedVariablesForCustomWeights,
  stagedVariables => ({
    atMaxStagedVariables: stagedVariables.length >= CUSTOM_WEIGHT_MAX_STAGED_VARIABLES,
    stagedVariables: stagedVariables.reduce((acc, id) => {
      acc[id] = true
      return acc
    }, {}),
  })
)

const getWeightsTotal = createSelector(getSelectionsForCustomWeights, options =>
  Number(
    options
      .reduce((sum, opt) => {
        const value = Number(opt.value)
        return sum + (Number.isNaN(value) ? 0 : value)
      }, 0)
      .toFixed(1)
  )
)

export default function CustomWeightModal({ isOpen, onClose }) {
  const dispatch = useDispatch()
  const variables = useSelector(getCustomWeightEligibleVariables, shallowEqual)
  const { stagedVariables, atMaxStagedVariables } = useSelector(getStagedVariablesMap, shallowEqual)
  const optionsSelections = useSelector(getSelectionsForCustomWeights, shallowEqual)
  const name = useSelector(getCustomWeightName, shallowEqual)
  const total = useSelector(getWeightsTotal, shallowEqual)
  const validation = useSelector(getCustomWeightValidity, shallowEqual)
  const isCreating = useSelector(getIsCreatingCustomWeight, shallowEqual)
  const errorCreating = useSelector(getErrorCreatingCustomWeight, shallowEqual)
  const submitHandler = useCallback(async () => {
    dispatch(createCustomWeight(onClose))
  }, [dispatch, onClose])

  return (
    <React.Fragment>
      <Modal
        title="Create a custom weight"
        isOpen={isOpen}
        onClose={onClose}
        onSubmit={submitHandler}
        submitLoading={isCreating}
        submitDisabled={!validation.isValid || isCreating}
      >
        <div className={styles.layout}>
          <div className={styles.variablesSection}>
            <h2>1. Select up to 2 variables</h2>
            <div className={styles.variables}>
              {variables.map(variable => (
                <Checkbox
                  key={variable.nk}
                  labelText={variable.question_text}
                  labelTextWrap
                  onChange={() => {
                    if (!stagedVariables[variable.nk]) {
                      dispatch(fetchVariableForCustomWeight(variable.nk))
                    } else {
                      dispatch(unstageVariable(variable.nk))
                    }
                  }}
                  checked={Boolean(stagedVariables[variable.nk])}
                  disabled={!stagedVariables[variable.nk] ? atMaxStagedVariables : false}
                />
              ))}
            </div>
          </div>
          <div className={styles.optionsContainer}>
            <h2>2. Define targets</h2>
            <div className={styles.options}>
              {optionsSelections.map(opt => (
                <React.Fragment key={opt.id}>
                  <label htmlFor={opt.id}>{opt.label}</label>
                  <div className={styles.inputGroup}>
                    <NumberInput
                      id={opt.id}
                      min={0}
                      max={100}
                      step={0.1}
                      size="small"
                      onChange={event =>
                        dispatch(editCustomWeightSelection(opt.id, event.target.value))
                      }
                      value={opt.value}
                    />
                    <span>%</span>
                  </div>
                </React.Fragment>
              ))}
            </div>
            {optionsSelections.length > 1 ? (
              <div className={styles.total}>
                <span>Total (Must = 100)</span>
                <span className={total === 100 ? styles.valid : styles.invalid}>{total}%</span>
              </div>
            ) : (
              <span className={styles.emptyMessage}>
                Start by selecting a variable from the list.
              </span>
            )}
          </div>
          <div className={styles.name}>
            <h2>3. Name weight variable</h2>
            <TextInput
              maxLength={100}
              onChange={event => dispatch(setCustomWeightName(event.target.value))}
              value={name}
            />
            {errorCreating && (
              <div className={styles.errorMessage}>
                <Icon icon="error" color="scarlet" size="medium" />
                <span>{errorCreating}</span>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}
