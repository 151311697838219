import React, { memo, useCallback, useMemo, useRef } from 'react'
import classnames from 'classnames'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { uniqWith, isEqual } from 'lodash'
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid'
import { Tooltip } from '@progress/kendo-react-tooltip'
import { Icon } from '@knowledgehound/laika'

import {
  getChartSortState,
  isFetchingData,
  getChartNumberType,
  getXAxisVariables,
  getYAxisVariables,
  shouldHideLowBase,
  getShowUnweightedBase,
} from 'store/DatasetSelectors'
import { setSortAction } from 'store/DatasetActions'
import { COLUMN, ROW } from 'store/constants'
import ChartLoading from '../ChartLoading'
import ChartError from '../ChartError'
import {
  columnNumToColumnId,
  formatDisplayType,
  getColSpan,
  getCellSortDirection,
} from 'tabularizeData'
import { getGridData, getGridHeaders } from './selectors'
import KendoRowHeaderCell from './KendoRowHeaderCell'

import './KendoChart.scss'

function KendoChart() {
  const intl = useIntl()
  const dispatch = useDispatch()
  const gridRef = useRef()
  const sortState: SortStateT = useSelector(getChartSortState, shallowEqual)
  const isFetching = useSelector(isFetchingData, shallowEqual)
  const gridData = useSelector(getGridData, shallowEqual)
  const error = gridData.error
  const numberType = useSelector(getChartNumberType, shallowEqual)
  const xAxisVariables: VariableListT = useSelector(getXAxisVariables, shallowEqual)
  const yAxisVariables: VariableListT = useSelector(getYAxisVariables, shallowEqual)
  const hideLowBase: boolean = useSelector(shouldHideLowBase, shallowEqual)
  const showUnweightedBase = useSelector(getShowUnweightedBase, shallowEqual)
  const { colHeaders, rowHeaders, rowHeaderCount, colHeaderCount } = useSelector(
    getGridHeaders,
    shallowEqual
  )

  const getNextSortDirection = useCallback(
    (axis: AxisT, cellSortVariables: Array<SortVariableKeyT>) => {
      const { direction, sortVariables } = sortState[axis] || {}
      if (!direction || !isEqual(sortVariables, cellSortVariables)) return 'descending'
      if (direction === 'descending') return 'ascending'
      return ''
    },
    [sortState]
  )

  const cellRenderer = useMemo(() => {
    return function (cell: Object, props: Object) {
      if (!props || !props.field || !props.dataItem) {
        return cell
      }

      const colId = props.field.indexOf('column-') > -1 ? props.field.split('-')[1] : null
      if (colId && props.dataItem.isBaseRow) {
        const data = props.dataItem[props.field]
        return (
          <td
            className={classnames(cell.props.className, 'is-base-row', {
              'low-base': data.isLowBase,
            })}
          >
            <div className="base-row-cell">
              {data.isLowBase && (
                <span className="low-base-icon">
                  <Icon icon="lowBase" size="small" color="inherit" />
                </span>
              )}
              <span>{formatDisplayType(data, numberType)}</span>
            </div>
          </td>
        )
      }

      // add spans for row headers
      const headerFields = rowHeaders[0].map(rh => rh.field)
      if (headerFields.includes(props.field) && props.field !== 'rowIds') {
        return (
          <KendoRowHeaderCell
            cell={cell}
            className={props.className}
            columnIndex={props.columnIndex}
            dataItem={props.dataItem}
            field={props.field}
            gridRef={gridRef}
            style={props.style}
          />
        )
      }

      // Row Sorting code, only if there is more than 1 col to sort
      const bottomVarOptionCount = yAxisVariables.length
        ? yAxisVariables[yAxisVariables.length - 1].selectedOptions.filter(o => o.selected).length
        : 1
      if (props.field === 'rowIds') {
        const isSortable = bottomVarOptionCount > 1 && !props.dataItem.isBaseRow

        return (
          <td
            className={classnames(cell.props.className, 'row-id', {
              sortable: isSortable,
              [`${props.dataItem.sorted} sorted`]:
                props.dataItem.sorted && !props.dataItem.isBaseRow,
            })}
            style={{ ...props.style, borderRightWidth: '2px' }}
            onClick={e => {
              e && e.stopPropagation()
              if (isSortable) {
                const direction = getNextSortDirection(ROW, props.dataItem.sortVariables)
                const reduxSort = {
                  direction,
                  sortVariables: direction ? props.dataItem.sortVariables : [],
                }
                if (props.dataItem.sortVariables) dispatch(setSortAction(ROW, reduxSort))
              }
            }}
          >
            <span>{cell.props.children}</span>
          </td>
        )
      }

      const data = props.dataItem[props.field]
      const baseValue =
        showUnweightedBase && (data.unweighted_base ?? false) ? data.unweighted_base : data.base
      const showCellBase = Boolean(baseValue !== data.groupBase)
      const hiddenByLowBase = data.isLowBase && hideLowBase
      const n = Math.round(baseValue).toLocaleString()

      return (
        <td
          className={classnames(`${cell.props.className} data-cell`, {
            'base-cell': showCellBase,
            'low-base': data.isLowBase,
          })}
        >
          <div className="data-value">{formatDisplayType(data, numberType, hideLowBase)}</div>
          {showCellBase && !hiddenByLowBase && !data.meanRow && (
            <span
              className="response-base"
              title={intl.formatMessage({ id: 'analysis.differentBaseCell' }, data)}
            >
              {`N=${n}`}
            </span>
          )}
          {data.statTesting && !hiddenByLowBase && (
            <div className="stat-sig-ids">
              {data.statTesting.map(colId => (
                <span key={colId}>{colId}</span>
              ))}
            </div>
          )}
        </td>
      )
    }
  }, [
    dispatch,
    intl,
    getNextSortDirection,
    numberType,
    rowHeaders,
    yAxisVariables,
    hideLowBase,
    showUnweightedBase,
  ])

  const headers = useMemo(() => {
    if (error) return []
    const generateEmptyHeaderCells = (): Array<Object> => {
      const generateEmptyHeaderData = (idx, field, colHeaderCount) => {
        let width = '50px'
        if (field !== 'rowIds') {
          const longestLabelLength = Math.max(...rowHeaders.map(row => row[idx].label.length))
          // 7px is the average character width of Source Sans (12px @ 600 weight)
          width = Math.min(200, Math.max(10, longestLabelLength) * 7)
        }

        return {
          nestLevel: colHeaderCount > 0 ? colHeaderCount : 2,
          columnProps: {
            key: `row-label-${idx + 1}-header`,
            title: ' ',
            width: `${width}px`,
            minResizableWidth: field === 'rowIds' ? 30 : width,
            locked: true,
            sortable: false,
            headerClassName: `empty-header-cell ${field === 'rowIds' ? 'row-id' : ''}`,
          },
          objToNest: {
            title: ' ',
            width: `${width}px`,
            minResizableWidth: field === 'rowIds' ? 30 : width,
            locked: true,
            headerClassName: `empty-header-cell ${field === 'rowIds' ? 'row-id' : ''}`,
          },
          onLastIter: (obj: Object) => {
            obj.locked = true
            obj.sortable = colHeaderCount === 0
            obj.field = field
          },
        }
      }

      const nestChildren = (
        origObj: Object,
        nestLevel: number,
        onLastIter?: Function
      ): Array<Object> => {
        if (nestLevel === 0) {
          return []
        }

        let nested = { ...origObj }
        let pointer = nested

        for (let i = 0; i < nestLevel - 1; i++) {
          const currChild = { ...origObj }
          pointer.children = [currChild]
          pointer = currChild
        }

        // pointer is referencing last child object after the loop
        if (typeof onLastIter === 'function') {
          onLastIter(pointer)
        }
        return [nested]
      }

      const emptyHeadersData = []
      if (xAxisVariables.length) {
        for (let i = 0; i < rowHeaderCount; i++) {
          const currCol = colHeaders[0][i]
          emptyHeadersData.push(generateEmptyHeaderData(i, currCol.field, colHeaderCount - 1))
        }
      } else {
        emptyHeadersData.push(generateEmptyHeaderData(0, 'Total', colHeaderCount - 1))
        emptyHeadersData.push(generateEmptyHeaderData(1, 'rowIds', colHeaderCount - 1))
      }

      const constructedEmptyHeaders: Array<Object> = emptyHeadersData.map((dataItem: Object) => {
        const nestedChild = nestChildren(
          dataItem.objToNest,
          dataItem.nestLevel,
          dataItem.onLastIter
        )
        if (yAxisVariables.length === 1 || yAxisVariables.length === 0) {
          dataItem.onLastIter(dataItem.columnProps)
        }

        return <Column {...dataItem.columnProps} children={nestedChild} />
      })

      if (constructedEmptyHeaders.length && yAxisVariables.length && constructedEmptyHeaders[0]) {
        // label for column id in the bottommost child in the leftmost empty header
        let ptr = constructedEmptyHeaders[0].props
        while (ptr.children) {
          // note empty headers cannot have multiple children -
          // if they ever do, need to change this algo
          ptr = ptr.children[0]
          if (!ptr.children) {
            // bottom level
            ptr.headerCell = () => <span className="row-container align-center" />
          }
        }
      }
      return constructedEmptyHeaders
    }

    const colIdsCell = (label, cellSortVariables) => {
      const { direction, sortVariables } = sortState[COLUMN]
      const nextSortDirection = getNextSortDirection(COLUMN, cellSortVariables)
      const cellSortDirection = getCellSortDirection(direction, sortVariables, cellSortVariables)
      const reduxSort = {
        direction: nextSortDirection,
        sortVariables: nextSortDirection ? cellSortVariables : [],
      }
      return (
        <span
          className={`k-link ${cellSortDirection ? `sorted ${cellSortDirection}` : 'sortable'}`}
          onClick={() => {
            if (cellSortVariables) {
              dispatch(setSortAction(COLUMN, reduxSort))
            }
          }}
        >
          {label}
        </span>
      )
    }

    const CustomHeaderCell = ({ title, field }) => (
      <span className="k-link" title={title}>
        {title || field + ' '}
      </span>
    )

    const generateHeaderCells = () => {
      const nestAxisChildren = (headerRows, parentSortVars) => {
        if (headerRows.length === 0) return
        const currRow = headerRows[0]
        const filteredOptions = uniqWith(currRow, (a, b) => a.id === b.id)

        // bottom level (aka last row before col ids)
        if (headerRows.length === 2) {
          return filteredOptions.map((opt, i) => {
            const colIdCell = headerRows[1][i]
            return {
              title: `${opt.label}`,
              sortVariables: [...parentSortVars, opt.sortId],
              headerCell: CustomHeaderCell,
              // children represents the col ids
              children: [
                {
                  title: colIdCell.label,
                  field: colIdCell.field,
                  sortVariables: [...parentSortVars, opt.sortId],
                  width: '90px',
                  headerCell: () => colIdsCell(colIdCell.label, [...parentSortVars, opt.sortId]),
                },
              ],
            }
          })
        }

        const getChildren = (opt, headerRows, currRow, prevSpan) => {
          const currSpan = getColSpan(opt.id, currRow)
          const children = nestAxisChildren(
            headerRows.slice(1).map(row => row.slice(prevSpan, prevSpan + currSpan)),
            [...parentSortVars, opt.sortId]
          )
          return { children, currSpan }
        }

        // top level does not need an object nest, simply return array of children
        if (headerRows.length === colHeaderCount) {
          let prevSpan = 0
          return filteredOptions.map((opt, i) => {
            const { children, currSpan } = getChildren(opt, headerRows, currRow, prevSpan)
            prevSpan += currSpan
            return children
          })
        }

        let prevSpan = 0
        return filteredOptions.map((opt, i) => {
          const { children, currSpan } = getChildren(opt, headerRows, currRow, prevSpan)
          prevSpan += currSpan
          return {
            title: `${opt.label}`,
            sortVariables: [...parentSortVars, opt.sortId],
            headerCell: CustomHeaderCell,
            children,
          }
        })
      }

      const headerCells = []
      const notEmptyHeaders = colHeaders.map(header => header.slice(rowHeaderCount))
      const topRow = notEmptyHeaders[0]
      const nestedChildrenArr = nestAxisChildren(notEmptyHeaders, []) ?? []

      const filteredOptions = uniqWith(topRow, (a, b) => a.id === b.id)

      if (nestedChildrenArr.length !== filteredOptions.length) {
        throw new Error(
          'Error while nesting children, number of children did not match number of columns'
        )
      }

      for (let i = 0; i < filteredOptions.length; i++) {
        const currOpt = filteredOptions[i]
        const topLevelProps: Object = {
          key: `${currOpt.id.optionId}${currOpt.id.optionType}-header`,
          title: currOpt.label,
          headerCell: CustomHeaderCell,
        }

        let currChildren = nestedChildrenArr[i]

        // no nesting case
        if (yAxisVariables.length === 1) {
          currChildren = [
            {
              title: `${columnNumToColumnId(i + 1)}`,
              field: `column-${i}`,
              width: '90px',
              sortVariables: [currOpt.sortId],
              headerCell: () => colIdsCell(`${columnNumToColumnId(i + 1)}`, [currOpt.sortId]),
            },
          ]
        }

        headerCells.push(<Column {...topLevelProps} children={currChildren} />)
      }
      return headerCells
    }

    if (yAxisVariables.length === 0) {
      const topLevelProps = {
        key: 'Total-header',
        title: 'Total',
        field: 'column-0',
        width: '90px',
      }
      // children === col id
      const children = [
        {
          title: 'a',
          field: 'column-0',
          sortVariables: [],
          width: '90px',
          headerCell: () => colIdsCell('a', []),
        },
      ]
      return [...generateEmptyHeaderCells(), <Column {...topLevelProps} children={children} />]
    }
    return [...generateEmptyHeaderCells(), ...generateHeaderCells()]
  }, [
    error,
    rowHeaders,
    colHeaderCount,
    colHeaders,
    dispatch,
    getNextSortDirection,
    rowHeaderCount,
    sortState,
    xAxisVariables.length,
    yAxisVariables.length,
  ])

  if (error) {
    const acceptedErrorKeys = {
      noVariableOptionsSelected: true,
      deletedOption: true,
    }
    const messageKey = acceptedErrorKeys[error.message] ? error.message : 'unknown'
    const messageContent = error.variableLabel
      ? { variableLabel: error.variableLabel }
      : { variableLabel: 'a Variable' }

    return <ChartError messageKey={messageKey} messageContent={messageContent} />
  }

  if (isFetching) return <ChartLoading />

  return (
    <Tooltip openDelay={200} position="top" anchorElement="target">
      <Grid
        ref={ref => (gridRef.current = ref)}
        className={xAxisVariables.length < 2 ? 'no-nest' : 'row-nest'}
        style={{ height: '100%', width: '100%' }}
        data={gridData}
        cellRender={cellRenderer}
        resizable
      >
        {headers}
      </Grid>
    </Tooltip>
  )
}

export default memo(KendoChart)
