class AnalysisSegmentUtil {
  constructor() {
    this.instance = null
    this.sourceType = null
    this.debugMode = false
    this.userId = null
    this.anonymousId = null
  }

  set({ segment, sourceType, anonymousId, debug = false }) {
    this.instance = segment
    this.sourceType = sourceType
    this.anonymousId = anonymousId
    this.debugMode = !!debug
  }

  get() {
    return this.instance
  }

  clear() {
    this.instance = null
    this.sourceType = null
    this.debugMode = false
    this.userId = null
    this.anonymousId = null
  }

  // See https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#identify for info on web args
  // See https://segment.com/docs/connections/sources/catalog/libraries/server/node/#identify for info on node args
  identify(userId, traits, options, callback) {
    if (!this.debugMode) {
      if (this.instance === undefined || this.instance === null) {
        return
      }

      if (this.sourceType === 'node') {
        const identifyFields = {
          traits,
          ...(options?.integrations && {
            integrations: options.integrations,
          }),
        }

        if (userId) {
          this.userId = userId
          identifyFields.userId = userId
        }

        if (this.anonymousId) {
          identifyFields.anonymousId = this.anonymousId
        }

        this.instance.identify(identifyFields, callback)

        // node lib doesn't do auto-aliasing like client js
        if (userId && this.anonymousId) {
          this.alias(userId, this.anonymousId)
        }
      } else {
        this.instance.identify(userId, traits, options, callback)
      }
    } else {
      console.debug('Segment - Identify:', userId, ', Traits: ', traits, ', Options: ', options)

      if (callback) {
        callback()
      }
    }
  }

  // See https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#group for info on web args
  // See https://segment.com/docs/connections/sources/catalog/libraries/server/node/#group for info on node args
  group(groupId, traits, options, callback) {
    if (!this.debugMode) {
      if (this.instance === undefined || this.instance === null) {
        return
      }

      if (this.sourceType === 'node') {
        const groupFields = {
          groupId,
          traits,
          ...(options?.integrations && {
            integrations: options.integrations,
          }),
        }

        if (this.userId) {
          groupFields.userId = this.userId
        } else {
          groupFields.anonymousId = this.anonymousId
        }

        this.instance.group(groupFields, callback)
      } else {
        this.instance.group(groupId, traits, options, callback)
      }
    } else {
      console.debug('Segment - Group:', groupId, ', Traits: ', traits, ', Options: ', options)

      if (callback) {
        callback()
      }
    }
  }

  // See https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#alias for info on web args
  // See https://segment.com/docs/connections/sources/catalog/libraries/server/node/#alias for info on node args
  alias(userId, previousId, options, callback) {
    if (!this.debugMode) {
      if (this.instance === undefined || this.instance === null) {
        return
      }

      if (this.sourceType === 'node') {
        const aliasFields = {
          userId,
          previousId,
          ...(options?.integrations && {
            integrations: options.integrations,
          }),
        }

        this.instance.alias(aliasFields, callback)
      } else {
        this.instance.alias(userId, previousId, options, callback)
      }
    } else {
      console.debug(
        'Segment - Alias:',
        userId,
        ', Previous Id: ',
        previousId,
        ', Options: ',
        options
      )

      if (callback) {
        callback()
      }
    }
  }

  // See https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#page for info on web args
  // See https://segment.com/docs/connections/sources/catalog/libraries/server/node/#page for info on node args
  // Note: if you pass only one string to page it is assumed to be name. You must include a name to send a category.
  page(category, name, properties, options, callback) {
    const hasName = name && typeof name === 'string'

    if (!this.debugMode) {
      if (this.instance === undefined || this.instance === null) {
        return
      }

      if (this.sourceType === 'node') {
        const categoryVal = category && hasName ? category : undefined
        const nameVal = category && hasName ? name : category && !hasName ? category : undefined
        const pageFields = {
          properties,
          ...(categoryVal && {
            category: categoryVal,
          }),
          ...(nameVal && {
            name: nameVal,
          }),
          ...(options?.integrations && {
            integrations: options.integrations,
          }),
        }

        if (this.userId) {
          pageFields.userId = this.userId
        } else {
          pageFields.anonymousId = this.anonymousId
        }

        this.instance.page(pageFields, callback)
      } else {
        this.instance.page(category, name, properties, options, callback)
      }
    } else {
      console.debug('Segment - Page:', {
        category: category && hasName ? category : undefined,
        name: category && hasName ? name : category && !hasName ? category : undefined,
        properties,
        options,
      })

      if (callback) {
        callback()
      }
    }
  }

  // See https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track for info on web args
  // See https://segment.com/docs/connections/sources/catalog/libraries/server/node/#track for info on node args
  track(event, properties, options, callback) {
    if (!this.debugMode) {
      if (this.instance === undefined || this.instance === null) {
        return
      }

      if (this.sourceType === 'node') {
        const trackFields = {
          event,
          properties,
          ...(options?.integrations && {
            integrations: options.integrations,
          }),
        }

        if (this.userId) {
          trackFields.userId = this.userId
        } else {
          trackFields.anonymousId = this.anonymousId
        }

        this.instance.track(trackFields, callback)
      } else {
        this.instance.track(event, properties, options, callback)
      }
    } else {
      console.debug('Segment - Track:', event, ', Properties: ', properties, ', Options: ', options)

      if (callback) {
        callback()
      }
    }
  }
}

const AnalysisSegment = new AnalysisSegmentUtil()

export default AnalysisSegment
