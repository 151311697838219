// Pug uses this, make sure if you update this file you bump up
// the analysis package version and update Pug

import fastCartesianProduct from 'fast-cartesian-product'
import { isEqual, isEmpty } from 'lodash'
import * as Sentry from '@sentry/react'

import {
  getOptionText,
  buildCellId,
  determineBaseVariable,
  getOptionFromVar,
  getVariable,
} from '../Analysis2Utils'

type AnalysisDataFieldT = {
  base: number,
  frequency: number,
  baseRow: boolean,
  statTesting?: Array<number>,
  meanRow: boolean,
}
export type HeaderCellT = {
  label: string,
  field: string,
  baseRow?: boolean,
  id?: SortVariableKeyT,
}
type LabelT = {
  [string]: string,
}
type GroupsT = {
  [string]: number,
}

export class ManagedTabularizeDataError extends Error {}

export const formatDisplayType = (
  analysisDataField: AnalysisDataFieldT,
  numberType: string,
  hideLowBase: boolean
) => {
  const { base, frequency } = analysisDataField

  if (analysisDataField.baseRow) {
    return Array.isArray(base)
      ? base.map(b => Math.round(b).toLocaleString()).join(' - ')
      : Math.round(base).toLocaleString()
  }

  if (base === 0 || (hideLowBase && analysisDataField.isLowBase)) {
    return '―'
  }
  if (analysisDataField.meanRow) {
    return frequency.toFixed(1)
  }
  if (numberType === 'percentage') {
    return ((100 * frequency) / base).toFixed(1) + '%'
  } else if (numberType !== 'numeric') {
    // NOTE(alex): numeric will be the default fallthrough for now in case the number type
    // has not been defined yet. More display types can be added as need arises.
    Sentry.captureMessage('tabularizeData: Unsupported display type ' + numberType)
  }
  return Math.round(frequency).toLocaleString()
}

// converted python code from this stack overflow:
// https://stackoverflow.com/questions/23861680/convert-spreadsheet-number-to-column-letter
// takes in the column index and converts it to column id - `1` would become `a`, '27' would be 'aa'
// this algorithm scales, in theory, infinitely
export const columnNumToColumnId = (num: number) => {
  let colId = ''
  let remainder

  while (num > 0) {
    remainder = (num - 1) % 26
    colId = String.fromCharCode(65 + remainder) + colId
    num = ((num - remainder) / 26) | 0
  }

  if (!colId) {
    Sentry.captureMessage(`could not convert ${num} to alphabetical representation`)
    return num.toString()
  }

  return colId.toLocaleLowerCase() || ''
}

export const constructGridLabels = (
  axisVariables: Array<AxisVariableT>,
  variables: VariableListT
) => {
  let emptyAxisV = null
  const axisIds = axisVariables
    .map(axisV => {
      const filteredoptions = axisV.selectedOptions.filter(opt => opt.selected)
      if (!filteredoptions.length) emptyAxisV = axisV
      return filteredoptions.map(opt => ({ [axisV.id]: buildCellId(axisV, opt) }))
    })
    .filter(fOpts => fOpts.length)

  if (!axisIds.length) {
    const emptyVar = emptyAxisV
      ? getVariable(variables, emptyAxisV.variableNk, emptyAxisV.resourceType)
      : null
    // DEV NOTE: cause is likely a net that was deleted being the only option selected
    const noVarErr = new ManagedTabularizeDataError('noVariableOptionsSelected', {
      cause: emptyVar
        ? emptyVar.questionName
        : 'tabularizeData was given an axis Variable with no options selected',
    })
    noVarErr.variableLabel = emptyVar && emptyVar.label ? emptyVar.label : null
    throw noVarErr
  }

  return fastCartesianProduct(axisIds).map(idList =>
    idList.reduce((idObj, id) => ({ ...idObj, ...id }))
  )
}

const cleanAxisVariables = (axisVars, variables, baseId, chartType) => {
  axisVars.forEach(axisVar => {
    const currVar = getVariable(variables, axisVar.variableNk, axisVar.resourceType)
    // there is a chance axisVars has outdated information, such as a deleted net.
    // we use variables as source of truth and throw an error if we find discrepancy
    for (let i = axisVar.selectedOptions.length - 1; i >= 0; i--) {
      const currOpt = axisVar.selectedOptions[i]
      if (!getOptionFromVar(currVar, currOpt)) {
        const deletedOptError = new ManagedTabularizeDataError('deletedOption')
        deletedOptError.variableLabel = currVar && currVar.label ? currVar.label : null
        throw deletedOptError
      }
    }
  })

  if (!baseId) return axisVars

  return axisVars.map(av => {
    if (
      chartType === 'spreadsheet' ||
      av.id !== baseId ||
      !av.selectedOptions.some(o => o.selected && o.id === '__mean__')
    ) {
      return av
    }

    return {
      ...av,
      selectedOptions: av.selectedOptions.map(o => {
        return o.id === '__mean__' ? o : { ...o, selected: false }
      }),
    }
  })
}

const getOptIdFromCellId = (cellId: string) => cellId.split(':')[2]

const getOptTypeFromCellId = (cellId: string, varType: string) => {
  const typeMap = {
    N: 'net',
    C: 'calculated',
  }
  const typeKey = cellId.split(':')[1]
  // varType: options => option OR breakouts => breakout
  return typeKey === 'N' || typeKey === 'C' ? typeMap[typeKey] : varType.slice(0, -1)
}

const getCellData = (
  yId: LabelT,
  xId: LabelT,
  data: AnalysisDataDataT,
  groups: GroupsT,
  isBaseRow: boolean,
  lowBaseThreshold: number,
  useUnweightedBase: boolean,
  statsIds?: Array<string> = []
) => {
  const cellIds = [...Object.values(yId), ...Object.values(xId)].sort()

  if (isBaseRow) {
    const base = groups[cellIds.join(',')] ? groups[cellIds.join(',')] : 0
    return {
      base,
      frequency: 0,
      baseRow: isBaseRow,
      isLowBase: base <= lowBaseThreshold,
    }
  }

  const meanRow = cellIds.join(',').indexOf('__mean__') > -1

  let cellData = data[cellIds.join(',')] || null

  if (!cellData) {
    cellData = {
      base: 0,
      unweighted_base: 0,
      frequency: 0,
    }
  }

  const { statTesting, statTestingIndexes } = (cellData.statTesting || []).reduce(
    (acc, cell) => {
      const idx = statsIds.indexOf(cell)
      if (idx > -1) {
        acc.statTestingIndexes = acc.statTestingIndexes.concat(idx)
        acc.statTesting = acc.statTesting.concat(columnNumToColumnId(idx + 1))
      }
      return acc
    },
    { statTesting: [], statTestingIndexes: [] }
  )

  const isLowBase = (() => {
    const base = useUnweightedBase ? cellData.unweighted_base : cellData.base
    return base !== 0 && base <= lowBaseThreshold
  })()

  return {
    ...cellData,
    statTesting: statTesting.sort(),
    statTestingIndexes: statTestingIndexes.sort(),
    baseRow: isBaseRow,
    meanRow,
    isLowBase,
  }
}

const getCellValue = (
  cellData: AnalysisDataFieldT,
  numberType: NumberTypeT,
  direction: string,
  sorting: string
) => {
  const { base, frequency } = cellData
  if (cellData.baseRow) {
    return base
  }
  if (cellData.meanRow && sorting === 'COLUMN') {
    return direction === 'ascending' ? -Infinity : Infinity
  }
  if (base === 0) return -Infinity
  if (numberType === 'percentage') {
    return (100 * frequency) / base
  } else return frequency
}

const sortGroup = (
  group: Array<LabelT>,
  sortBy: LabelT,
  data: AnalysisDataDataT,
  direction: string,
  numberType: string,
  sorting: string,
  lowBaseThreshold: number,
  useUnweightedBase: boolean
) => {
  const groupWithData = group.map(cell => ({
    ...cell,
    value: getCellValue(
      getCellData(cell, sortBy, data, {}, false, lowBaseThreshold, useUnweightedBase),
      numberType,
      direction,
      sorting
    ),
  }))
  groupWithData.sort((g1, g2) => {
    if (direction === 'ascending') {
      return g1.value - g2.value
    }
    return g2.value - g1.value
  })
  groupWithData.forEach(g => delete g.value)
  return groupWithData
}

const applySort = (
  gridLabelsToSort: Array<LabelT>,
  labelsToSortBy: Array<LabelT>,
  appliedSort: SortKeyT,
  data: AnalysisDataDataT,
  groupSize: number,
  numberType: string,
  sorting: string,
  lowBaseThreshold: number,
  baseId?: string,
  useUnweightedBase: boolean
) => {
  if (!appliedSort || !appliedSort.direction || groupSize === 0) return gridLabelsToSort

  const meanSort = appliedSort.sortVariables.some(v => v.optionId === '__mean__')

  let sortBy = {}
  if (appliedSort.sortVariables.length) {
    const sortVariables = appliedSort.sortVariables.reduce((sortObj, v) => {
      const optTypeKey = v.optionType === 'net' ? 'N' : v.optionType === 'calculated' ? 'C' : 'O'
      sortObj[v.varId] = `${optTypeKey}:${v.optionId}`
      return sortObj
    }, {})
    sortBy = labelsToSortBy.find(labels => {
      let match = true
      Object.keys(labels).forEach(key => {
        if (!labels[key].includes(sortVariables[key])) match = false
      })
      return match
    })
    if (!sortBy) return gridLabelsToSort
  }

  let sortedLabels = []
  for (let i = 0; i < gridLabelsToSort.length; i = i + groupSize) {
    const group = gridLabelsToSort.slice(i, groupSize + i)
    sortedLabels = [
      ...sortedLabels,
      ...sortGroup(
        group,
        sortBy,
        data,
        appliedSort.direction,
        meanSort ? 'numeric' : numberType,
        sorting,
        lowBaseThreshold,
        useUnweightedBase
      ),
    ]
  }
  return sortedLabels
}

const getGroupSize = (axisVars: Array<AxisVariableT>) => {
  if (!axisVars.length) return 1
  return axisVars[axisVars.length - 1].selectedOptions.filter(o => o.selected).length
}

const addBaseRows = (rows: Array<LabelT>, groupSize: number, lastVar: AxisVariableT) => {
  if (groupSize === 0) return rows
  if (lastVar) {
    let updatedRows = []
    for (let i = 0; i < rows.length; i += groupSize) {
      const baseRow = { ...rows[i] }
      delete baseRow[lastVar.id]
      updatedRows = [...updatedRows, baseRow, ...rows.slice(i, groupSize + i)]
    }
    return updatedRows
  } else {
    return [{ Total: 'BASE' }, ...rows]
  }
}

const generateData = (
  xLabels: Array<LabelT>,
  yLabels: Array<LabelT>,
  analysisData: AnalysisDataT,
  xVarCount: number,
  yVarCount: number,
  base_size: number,
  baseRowsEnabled: boolean,
  isInverted: boolean,
  lowBaseThreshold: number,
  useUnweightedBase: boolean
) => {
  if (!yVarCount) {
    let currBase
    const statsIds = xLabels.reduce(
      (acc, label) => acc.concat(Object.values(label).sort().join(',')),
      []
    )
    return xLabels.map(row => {
      const isBaseRow = baseRowsEnabled && Object.keys(row).length < xVarCount
      if (isBaseRow && xVarCount === 1) {
        // in this case the BE does not return any groups
        // base data, use the base_size field
        currBase = base_size
        return [
          {
            frequency: 0,
            base: base_size,
            isLowBase: base_size <= lowBaseThreshold,
            baseRow: true,
            groupBase: base_size,
            statTesting: [],
          },
        ]
      }
      const cellData = getCellData(
        row,
        {},
        analysisData.data,
        useUnweightedBase ? analysisData.unweighted_groups : analysisData.groups,
        isBaseRow,
        lowBaseThreshold,
        useUnweightedBase,
        statsIds
      )
      if (isBaseRow) currBase = cellData.base
      return [{ ...cellData, groupBase: isBaseRow ? cellData.base : currBase }]
    })
  }

  if (!xVarCount) {
    const baseRow = []
    const totRow = []
    const statsIds = yLabels.reduce(
      (acc, label) => acc.concat(Object.values(label).sort().join(',')),
      []
    )
    yLabels.forEach(col => {
      const cellData = getCellData(
        {},
        col,
        analysisData.data,
        useUnweightedBase ? analysisData.unweighted_groups : analysisData.groups,
        false,
        lowBaseThreshold,
        useUnweightedBase,
        statsIds
      )
      if (baseRowsEnabled)
        baseRow.push({
          ...cellData,
          baseRow: true,
          base: useUnweightedBase ? cellData.unweighted_base : cellData.base,
          groupBase: useUnweightedBase ? cellData.unweighted_base : cellData.base,
          isLowBase: base_size <= lowBaseThreshold,
        })
      totRow.push({
        ...cellData,
        groupBase: useUnweightedBase ? cellData.unweighted_base : cellData.base,
      })
    })
    return baseRowsEnabled ? [baseRow, totRow] : [totRow]
  }

  const statsTable = isInverted
    ? yLabels.map(col =>
        xLabels.map(l => [...Object.values(l), ...Object.values(col)].sort().join(','))
      )
    : xLabels.map(row =>
        yLabels.map(l => [...Object.values(l), ...Object.values(row)].sort().join(','))
      )

  let currBaseRow = []
  return xLabels.map((row, rowIndex) => {
    const isBaseRow = baseRowsEnabled && Object.keys(row).length < xVarCount
    const rowData = yLabels.map((col, colIndex) => {
      const cellData = getCellData(
        row,
        col,
        analysisData.data,
        useUnweightedBase ? analysisData.unweighted_groups : analysisData.groups,
        isBaseRow,
        lowBaseThreshold,
        useUnweightedBase,
        isInverted ? statsTable[colIndex] : statsTable[rowIndex]
      )
      return isBaseRow
        ? { ...cellData, groupBase: cellData.base }
        : { ...cellData, groupBase: currBaseRow[colIndex] }
    })
    if (isBaseRow) {
      currBaseRow = rowData.map(cell => cell.base)
    }
    return rowData
  })
}

/**
 * Apply "hide low base" and "null suppression" filters to table data.
 *
 * @param {Array<Array<Object>>} tableData - Tabularized analysis data
 * @param {boolean} isInverted - True when generating data for an inverted chart, i.e. stacked or line
 * @param {boolean} hideLowBase - True when low base cells should be removed from chart
 * @param {boolean} suppressNullValues - True when null cells should be removed from chart
 * @param {boolean} useUnweightedBase - True when we should display unweighted base values
 */
const filterTableData = ({
  tableData,
  isInverted,
  hideLowBase,
  suppressNullValues,
  useUnweightedBase,
}) => {
  const baseKey = useUnweightedBase ? 'unweighted_base' : 'base'
  const lowBaseColumnLookupTable = tableData.reduce(
    (acc, rowData) =>
      acc.map(
        (columnIsLowBase, idx) =>
          columnIsLowBase && (rowData[idx].isLowBase || rowData[idx][baseKey] === 0)
      ),
    new Array(tableData[0].length).fill(true)
  )

  const nullColumnLookupTable = tableData.reduce(
    (acc, rowData) =>
      acc.map(
        (columnIsNull, idx) =>
          columnIsNull && rowData[idx].frequency === 0 && rowData[idx][baseKey] === 0
      ),
    new Array(tableData[0].length).fill(true)
  )

  const nullRowLookupTable = tableData.map((rowData, idx) => {
    if (rowData[0].baseRow) {
      const nextBaseRow = tableData.findIndex(
        (nextRow, nextIdx) => nextIdx > idx && nextRow[0].baseRow
      )
      const nextGroup = tableData.slice(idx + 1, nextBaseRow === -1 ? undefined : nextBaseRow)
      return nextGroup.every(row => row.every(cell => cell.frequency === 0 && cell[baseKey] === 0))
    }
    return rowData.every(cell => cell.frequency === 0 && cell[baseKey] === 0)
  })

  const statIdLookupTable = { count: 0 }
  for (let i = 0, len = isInverted ? tableData.length : tableData[0].length; i < len; i++) {
    if (
      (hideLowBase && lowBaseColumnLookupTable[i]) ||
      (suppressNullValues && (isInverted ? nullRowLookupTable[i] : nullColumnLookupTable[i]))
    ) {
      statIdLookupTable[i] = null
    } else {
      statIdLookupTable[i] = statIdLookupTable.count++
    }
  }

  const filteredTableData =
    !hideLowBase && !suppressNullValues
      ? tableData
      : tableData
          .map(row =>
            row.reduce((columnAcc, cell, idx) => {
              if (
                (hideLowBase && lowBaseColumnLookupTable[idx]) ||
                (suppressNullValues && nullColumnLookupTable[idx])
              ) {
                return columnAcc
              }

              cell.statTestingIndexes = (cell.statTestingIndexes ?? [])
                .reduce((acc, statIdx) => {
                  if (statIdLookupTable[statIdx] === null) return acc
                  return acc.concat(statIdLookupTable[statIdx])
                }, [])
                .sort()
              cell.statTesting = cell.statTestingIndexes.map(i => columnNumToColumnId(i + 1)).sort()

              return columnAcc.concat(cell)
            }, [])
          )
          .filter(
            (_, idx) => !suppressNullValues || !nullRowLookupTable || !nullRowLookupTable[idx]
          )

  return {
    filteredTableData,
    lowBaseColumnLookupTable,
    isEverythingLowBase: !lowBaseColumnLookupTable.some(col => !col),
    isEverythingSuppressed:
      !nullColumnLookupTable.some(col => !col) || !nullRowLookupTable.some(row => !row),
    nullColumnLookupTable,
    nullRowLookupTable,
  }
}

const generateColHeaders = ({
  yLabels,
  variables,
  yAxisVars,
  xAxisVars,
  hideLowBase,
  suppressNullValues,
  lowBaseColumnLookupTable,
  nullColumnLookupTable,
}) => {
  const emptyHeaderCells = []
  for (let i = 0; i < (xAxisVars.length || 1); i++) {
    emptyHeaderCells.push({
      field: xAxisVars.length ? xAxisVars[i].id : 'Total',
      label: '',
    })
  }
  emptyHeaderCells.push({ field: 'rowIds', label: '' })
  if (!yAxisVars.length)
    return [
      [...emptyHeaderCells, { field: 'column-0', label: 'Total' }],
      [...emptyHeaderCells, { field: 'column-0', label: 'a' }],
    ]

  const filteredYLabels = yLabels.filter(
    (_, idx) =>
      !(hideLowBase && lowBaseColumnLookupTable[idx]) &&
      (!suppressNullValues || !nullColumnLookupTable || !nullColumnLookupTable[idx])
  )

  const colHeaders = []
  yAxisVars.forEach(({ id: varId }, varIdx) => {
    const foundVar = variables.find(variable => variable.id === varId)
    const header = filteredYLabels.reduce((acc, label, colIndex) => {
      const optId = getOptIdFromCellId(label[varId])
      if (!foundVar) return acc.concat({ label: optId, field: `column-${colIndex}` })
      const optType = getOptTypeFromCellId(label[varId], foundVar.resourceType)
      const parentId = varIdx
        ? `${colHeaders[varIdx - 1][colIndex + emptyHeaderCells.length].id}|`
        : ''
      return acc.concat({
        label: getOptionText(foundVar, { id: optId, type: optType }),
        allGroupsLowBase: lowBaseColumnLookupTable[colIndex],
        field: `column-${colIndex}`,
        id: `${parentId}${varId}:${optId}:${optType}`,
        sortId: {
          optionId: optId,
          optionType: optType,
          varId,
        },
      })
    }, [])
    colHeaders.push([...emptyHeaderCells, ...header])
  })

  // add col ids
  const colIds = Array(colHeaders[0].length - emptyHeaderCells.length)
    .fill(0)
    .map((_, i) => ({
      label: columnNumToColumnId(i + 1),
      field: `column-${i}`,
      id: columnNumToColumnId(i + 1),
    }))
  colHeaders.push([...emptyHeaderCells, ...colIds])
  return colHeaders
}

const generateRowHeaders = ({
  xLabels,
  variables,
  xAxisVars,
  baseRowsEnabled,
  suppressNullValues,
  nullRowLookupTable,
}) => {
  if (!xAxisVars.length) {
    // no row var case
    return baseRowsEnabled
      ? [
          [
            { field: 'Total', label: 'Base', baseRow: true },
            { field: 'rowIds', label: '1', baseRow: true },
          ],
          [
            { field: 'Total', label: 'Total', baseRow: false },
            { field: 'rowIds', label: '2', baseRow: false },
          ],
        ]
      : [
          [
            { field: 'Total', label: 'Total', baseRow: false },
            { field: 'rowIds', label: '1', baseRow: false },
          ],
        ]
  }

  const filteredXLabels = xLabels.filter(
    (_, idx) => !suppressNullValues || !nullRowLookupTable || !nullRowLookupTable[idx]
  )

  const rowHeaders = filteredXLabels.map((label, rowIdx) => {
    return xAxisVars
      .map(xVar => {
        const mapId = label[xVar.id]
        if (mapId) {
          const foundVar = variables.find(variable => variable.id === xVar.id)
          const optId = getOptIdFromCellId(label[xVar.id])
          if (!foundVar) return { label: optId, field: xVar.id }
          const optType = getOptTypeFromCellId(label[xVar.id], foundVar.resourceType)
          return {
            label: getOptionText(foundVar, { id: optId, type: optType }),
            field: xVar.id,
            id: {
              optionId: optId,
              optionType: optType,
              varId: xVar.id,
            },
          }
        } else return { field: xVar.id, label: 'Base', baseRow: true }
      })
      .concat([{ field: 'rowIds', label: (rowIdx + 1).toString() }])
  })

  return rowHeaders
}

// Pug uses this, make sure if you update this method you bump up
// the analysis package version and update Pug
export const generateTableData = (
  variables: VariableListT,
  xAxisVariables: Array<AxisVariableT>,
  yAxisVariables: Array<AxisVariableT>,
  analysisData: AnalysisDataT,
  lowBaseThreshold: number,
  {
    chartType,
    numberType,
    sortState,
    hideLowBase,
    suppressNullValues,
    showUnweightedBase,
  }: ViewSettingsT
) => {
  if (isEmpty(analysisData.data)) {
    return {
      tableData: [],
      isEverythingLowBase: false,
      isEverythingSuppressed: false,
      colHeaders: [],
      rowHeaders: [],
    }
  }

  const baseRowsEnabled = chartType === 'spreadsheet'
  const isInverted = ['stackedBar', 'stackedColumn', 'line'].includes(chartType)
  let baseId = analysisData && analysisData.baseQuestion && analysisData.baseQuestion.id
  if (!baseId) {
    const baseVar = determineBaseVariable(xAxisVariables, yAxisVariables, chartType)
    if (!baseVar) return {}
    baseId = baseVar.id
  }

  const cleanedXAxis = cleanAxisVariables(xAxisVariables, variables, baseId, chartType)
  const cleanedYAxis = cleanAxisVariables(yAxisVariables, variables, baseId, chartType)

  const yVarCount = cleanedYAxis.length
  const xVarCount = cleanedXAxis.length
  // construct flat grid data labels for data only, no headers
  // yGridLabels = [col1Code, col2Code, col3Code...]
  // xGridLabels = [row1Code, row2Code row3Code...]
  const yGridLabels = yVarCount ? constructGridLabels(cleanedYAxis, variables) : [{ Total: '' }]
  const xGridLabels = xVarCount ? constructGridLabels(cleanedXAxis, variables) : [{ Total: '' }]
  const rowGroupSize = getGroupSize(cleanedXAxis)
  const colGroupSize = getGroupSize(cleanedYAxis)

  const useUnweightedBase = Boolean(
    showUnweightedBase && (analysisData.unweighted_base_size ?? false)
  )

  // apply any sorting
  let sortedXLabels = applySort(
    xGridLabels,
    yGridLabels,
    sortState.COLUMN,
    analysisData.data,
    rowGroupSize,
    numberType,
    'COLUMN',
    lowBaseThreshold,
    baseId,
    useUnweightedBase
  )
  const sortedYLabels = applySort(
    yGridLabels,
    xGridLabels,
    sortState.ROW,
    analysisData.data,
    colGroupSize,
    numberType,
    'ROW',
    lowBaseThreshold,
    baseId,
    useUnweightedBase
  )

  // add base rows
  if (baseRowsEnabled) {
    // Flow doesn't understand that the value field is added and removed here
    //$FlowFixMe
    sortedXLabels = addBaseRows(
      sortedXLabels,
      rowGroupSize,
      xVarCount ? cleanedXAxis[xVarCount - 1] : 0
    )
  }

  // build table with all data
  const tableData = generateData(
    sortedXLabels,
    sortedYLabels,
    analysisData,
    xVarCount,
    yVarCount,
    useUnweightedBase ? analysisData.unweighted_base_size : analysisData.base_size,
    baseRowsEnabled,
    isInverted,
    lowBaseThreshold,
    useUnweightedBase
  )

  // filter data by low base or nulls if requested
  const {
    filteredTableData,
    lowBaseColumnLookupTable,
    isEverythingLowBase,
    isEverythingSuppressed,
    nullColumnLookupTable,
    nullRowLookupTable,
  } = filterTableData({
    tableData,
    isInverted,
    hideLowBase,
    suppressNullValues,
    useUnweightedBase,
  })

  const colHeaders = generateColHeaders({
    yLabels: sortedYLabels,
    variables,
    yAxisVars: cleanedYAxis,
    xAxisVars: cleanedXAxis,
    hideLowBase,
    suppressNullValues,
    lowBaseColumnLookupTable,
    nullColumnLookupTable,
  })

  const rowHeaders = generateRowHeaders({
    xLabels: sortedXLabels,
    variables,
    xAxisVars: cleanedXAxis,
    baseRowsEnabled,
    suppressNullValues,
    nullRowLookupTable,
  })

  return {
    tableData: filteredTableData,
    isEverythingLowBase,
    isEverythingSuppressed,
    colHeaders,
    rowHeaders,
  }
}

export const getColSpan = (optId, colHeader: Array<HeaderCellT>) => {
  let span = 1
  colHeader.forEach((header, idx) => {
    if (idx === colHeader.length - 1) return span
    if (header.id === optId && header.id === colHeader[idx + 1].id) {
      span++
    }
  })
  return span
}

export const getRowSpan = (rowHeaders: Array<Array<HeaderCellT>>, colIdx: number) => {
  let span = 1
  for (let i = 0; i < rowHeaders.length - 1; i++) {
    if (isEqual(rowHeaders[i][colIdx].id, rowHeaders[i + 1][colIdx].id)) {
      span++
    } else {
      break
    }
  }
  return span
}

export const getCellSortDirection = (
  direction: AxisT,
  sortStateVariables: Array<SortVariableKeyT>,
  cellSortVariables: Array<SortVariableKeyT>
): AxisT | '' => {
  if (!direction || !isEqual(cellSortVariables, sortStateVariables)) return ''
  return direction
}
