import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Button, Icon } from '@knowledgehound/laika'

import * as styles from './AccessRestricted.module.scss'

const propTypes = {
  intl: PropTypes.shape({ formatMessage: PropTypes.func }),
  isEmbedded: PropTypes.bool,
}

const AccessRestricted = ({ intl, isEmbedded }) => (
  <div className={styles.accessRestricted}>
    <section className={styles.content}>
      <Icon icon="locked" size="extraLarge" color="scarlet" />
      <h1>{intl.formatMessage({ id: 'accessRestricted.title' })}</h1>
      <p>{intl.formatMessage({ id: 'accessRestricted.content' })}</p>
      {!isEmbedded && (
        <div className={styles.buttons}>
          <Button size="large" variant="secondary" onClick={() => window.Intercom('show')}>
            {intl.formatMessage({ id: 'accessRestricted.chat' })}
          </Button>
          <Link to="/home">
            <Button size="large" variant="primary" onClick={() => {}}>
              {intl.formatMessage({ id: 'accessRestricted.homepage' })}
            </Button>
          </Link>
        </div>
      )}
    </section>
  </div>
)

AccessRestricted.propTypes = propTypes

export default injectIntl(AccessRestricted)
