import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@knowledgehound/laika'

import * as styles from './SwapTranspose.module.scss'

const propTypes = {
  disabled: PropTypes.bool,
  chartType: PropTypes.string,
  handleSwapRowsColumns: PropTypes.func,
}

const SwapTranspose = ({ disabled, chartType, handleSwapRowsColumns }) => (
  <div className={styles.swapContainer}>
    <Button
      icon="swap"
      variant="secondary"
      disabled={disabled}
      onClick={event => {
        event && event.stopPropagation()
        handleSwapRowsColumns()
      }}
    >
      {chartType === 'spreadsheet' ? 'Swap rows/columns' : 'Swap/Transpose'}
    </Button>
  </div>
)

SwapTranspose.propTypes = propTypes

export default SwapTranspose
