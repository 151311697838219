import { isEmpty } from 'lodash'
import { stringify } from 'query-string'

import { handleResponse } from '@knowledgehound/data/fetchUtils'
import { getPublicStudy } from '@knowledgehound/studies/StudyRequests'

import { fetchPublic } from 'util/api'
import { Auth } from 'util/jwt'
import { encodeNaturalKey } from 'util/naturalKeyUtils'

const COLLIE_URL = process.env.REACT_APP_COLLIE_URL || ''

const datasetDefaultOpts = {
  richQuestionMetadata: false,
}

/**
 * Grab dataset information.
 * JWT override is to be used by other packages that consume this function, as
 * when this is imported it loses context of process.env vars and does not have
 * Auth set.
 *
 * @param {string} studyId - ID of the requested study, i.e. FRE0123
 * @param {boolean} [jwt=''] - JWT for authentication
 * @param {Object} [options] - Additional configuration
 * @param {boolean} [options.richQuestionMetadata=false] - Include question response/breakout text
 * @returns {Promise<Object>} Dataset
 */
export function getCollieDataset(studyId, jwt = '', options = datasetDefaultOpts) {
  const params = options.richQuestionMetadata ? '?rich_question_metadata=1' : ''
  return fetchPublic(`${COLLIE_URL}/datasets/${studyId}/${params}`, {}, true, jwt).then(
    handleResponse
  )
}

/**
 * Grab study and dataset information.
 *
 * @param {string} studyId - ID of the requested study, i.e. FRE0123
 * @param {Object} [options] - Additional configuration
 * @param {boolean} [options.richQuestionMetadata=false] - Include question response/breakout text
 * @returns {Promise<Object>} Dataset, default filters, and dataset
 */
export async function getDatasetData(studyId, options = datasetDefaultOpts) {
  const greyhoundPromise = getPublicStudy(studyId, Auth.currentBearer)
  const colliePromise = getCollieDataset(studyId, '', options)
  const [greyhoundStudy, allDatasets] = await Promise.all([greyhoundPromise, colliePromise])
  const dataset = allDatasets.results[0]
  if (dataset && greyhoundStudy && greyhoundStudy.permissions && greyhoundStudy.permissions.view) {
    dataset.studyName = greyhoundStudy.study_name
    dataset.studySample = greyhoundStudy.study_sample
    dataset.studyDate = greyhoundStudy.study_date
    dataset.lowBaseThreshold = greyhoundStudy.low_base_threshold ?? 100
    const paths =
      greyhoundStudy.value_paths &&
      greyhoundStudy.value_paths.find(path => path.name === 'Supplier').paths
    dataset.suppliers = paths ? paths.map(path => path[0]) : []
  }
  let defaultFilters = []
  if (dataset && dataset.default_filters) {
    const filters = dataset.default_filters
    defaultFilters = JSON.parse(filters)
  }
  return { dataset, defaultFilters, allDatasets }
}

// Pug uses this, make sure if you update this method you bump up
// the analysis package version and update Pug
export const createOptionVar = (newVar: AnalysisQuestionT, nets: Array<ResponseNetT>) => {
  const responseArr =
    newVar.response_metadata && newVar.response_metadata.length > 0
      ? newVar.response_metadata
      : newVar.responses

  return {
    id: `${newVar.question_name}options`,
    questionName: newVar.question_name,
    question_type: newVar.question_type,
    fundamentalQuestionType: newVar.fundamental_question_type,
    variableNk: newVar.nk,
    label: newVar.question_text,
    // nets call response should only have responses, no breakouts for options type variables
    // or, if it doesn't have the breakouts field because of some migration glitch, default to options
    nets: nets
      .filter(net => net.type !== 'breakout')
      .map(net => ({
        id: net.pk.toString(),
        type: 'net',
        label: net.name,
        responses: net.responses || [],
        responseRules: net.response_rules || null,
        email: net.email,
      })),
    options: responseArr.map(response => {
      // response ID is not available for numerics
      return {
        id: response.hasOwnProperty('response_id')
          ? //$FlowFixMe
            response.response_id.toString()
          : response.toString(),
        type: 'option',
        label: response.hasOwnProperty('response_text')
          ? //$FlowFixMe
            response.response_text
          : response.toString(),
      }
    }),
    calculated: newVar.means_eligible
      ? [
          {
            id: '__mean__',
            type: 'calculated',
            label: 'Mean',
          },
          {
            id: '__total__',
            type: 'calculated',
            label: 'Total',
          },
        ]
      : [
          {
            id: '__total__',
            type: 'calculated',
            label: 'Total',
          },
        ],
    resourceType: 'options',
    supported_filter_methods: newVar.supported_filter_methods,
    isGrid: newVar.is_grid,
    represents: newVar.represents,
    meansEligible: newVar.means_eligible,
  }
}

// Pug uses this, make sure if you update this method you bump up
// the analysis package version and update Pug
export const createBreakoutVar = (newVar: AnalysisQuestionT, nets: Array<ResponseNetT>) => {
  return {
    id: `${newVar.question_name}breakouts`,
    questionName: `${newVar.question_name}`,
    question_type: newVar.question_type,
    fundamentalQuestionType: newVar.fundamental_question_type,
    variableNk: newVar.nk,
    label: newVar.question_text,
    nets: nets
      .filter(net => net.type === 'breakout')
      .map(net => {
        const breakoutNames = net.breakouts.reduce((names, breakout) => {
          const foundBreakout = newVar.grid_breakouts.find(b => b.id === breakout)
          if (foundBreakout) {
            return [...names, foundBreakout.breakout_name]
          }
          return names
        }, [])
        return {
          id: net.pk.toString(),
          type: 'net',
          label: net.name,
          responses: breakoutNames,
          email: net.email,
        }
      }),
    options: newVar.grid_breakouts.map(breakout => {
      return {
        id: `${breakout.id}`,
        type: 'breakout',
        label: breakout.breakout_name,
      }
    }),
    calculated: [
      {
        id: '__total__',
        type: 'calculated',
        label: 'Total',
      },
    ],
    resourceType: 'breakouts',
    supported_filter_methods: newVar.supported_filter_methods.filter(m => m.includes('$')),
    isGrid: newVar.is_grid,
    represents: newVar.represents,
    meansEligible: false,
  }
}

// jwt override is to be used by other packages that consume this function, as
// when this is imported it loses context of process.env vars and does not have Auth set
export function getQuestion(naturalKey: string, params: Object = {}, jwt: string = ''): any {
  return fetchPublic(
    `${COLLIE_URL}/v2/questions/${encodeNaturalKey(naturalKey)}/${
      params && !isEmpty(params) ? '?' + stringify(params) : ''
    }`,
    {},
    true,
    jwt
  ).then(handleResponse)
}

// jwt override is to be used by other packages that consume this function, as
// when this is imported it loses context of process.env vars and does not have Auth set
export function getQuestionNets(variableNk: string, jwt: string = ''): any {
  const baseurl = `${COLLIE_URL}/v2/questions`
  return fetchPublic(`${baseurl}/${encodeNaturalKey(variableNk)}/nets/`, {}, true, jwt).then(
    handleResponse
  )
}

export async function getVariablesWithNets(variableNk: string, params: Object = {}, jwt) {
  const varPromise = getQuestion(variableNk, params, jwt)
  const netPromise = getQuestionNets(variableNk, jwt)
  const [newVar, netResponse] = await Promise.all([varPromise, netPromise])
  const nets = netResponse.results || []

  const newOptionVariable: VariableT = createOptionVar(newVar, nets)

  if (newVar.is_grid && newVar.grid_breakouts) {
    const newBreakoutVariable: VariableT = createBreakoutVar(newVar, nets)
    return [newOptionVariable, newBreakoutVariable]
  }

  return [newOptionVariable]
}

export const generateAxisVars = (
  newVars: Array<VariableT>,
  axis: AxisT,
  selectedNets: Array<string> = [],
  options: Array<string> = [],
  breakouts: Array<string> = [],
  calculated: Array<string> = [],
  calculatedBreakout: Array<string> = []
) => {
  const hasSelected =
    selectedNets.length > 0 ||
    options.length > 0 ||
    breakouts.length > 0 ||
    calculated.length > 0 ||
    calculatedBreakout.length > 0

  return newVars.map((varToAdd, i) => {
    const nets = varToAdd.nets.map(net => ({
      id: net.id,
      type: 'net',
      selected: selectedNets.includes(net.id.toString()), // nets start deselected
    }))

    const calcsToInclude = varToAdd.resourceType === 'breakouts' ? calculatedBreakout : calculated

    const calcs = varToAdd.calculated.map(calc => ({
      id: calc.id,
      type: 'calculated',
      selected: calcsToInclude.includes(calc.id.toString()),
    }))

    // Only select 3 variables if no selected list provided (set as true)
    const selectedOptions = varToAdd.options.map((option: OptionT, index: number) => {
      const selectedList = option.type === 'breakout' ? breakouts : options

      return {
        id: option.id,
        type: option.type,
        selected: hasSelected ? selectedList.includes(option.id.toString()) : index < 3,
      }
    })
    return {
      id: varToAdd.id,
      variableNk: varToAdd.variableNk,
      resourceType: varToAdd.resourceType,
      // nets always display before options so we just stick them in front for ordering purposes
      selectedOptions: [...calcs, ...nets, ...selectedOptions],
      axis,
    }
  })
}

// jwt override is to be used by other packages that consume this function, as
// when this is imported it loses context of process.env vars and does not have Auth set
export async function getAnalysisData(
  request: DatasetRequestBodyT,
  studyId: string,
  datasetNk: string,
  jwt: string = ''
): any {
  const response = await fetchPublic(
    encodeURI(`${COLLIE_URL}/datasets/${studyId}/${datasetNk}/analysis-data/`),
    {
      method: 'POST',
      body: JSON.stringify(request),
    },
    true,
    jwt
  )
  return handleResponse(response)
}
