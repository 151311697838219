import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import cs from 'classnames'
import { Button, ToggleableSelectionOptions, Tooltip } from '@knowledgehound/laika'
import { some } from 'lodash'

import {
  getHandleTrack,
  getSuppressedVariableOptions,
  getSuppressedVariablesWithErrors,
  shouldSuppressNulls,
} from 'store/DatasetSelectors'
import { fetchVariableWithSuppressedOptions } from '../../store/DatasetActions'
import { getNetExplanation } from '../../Analysis2Utils'
import NullSuppressionAccordion from '../../NullSuppressionAccordion'
import * as styles from './VariableOptionsSection.module.scss'
import { getFetchingSuppressedVariableOptions } from 'store/DatasetSelectors'

const initSvoState = {
  responses: {
    option: {},
    net: {},
    calculated: {},
  },
  breakouts: {
    breakout: {},
    net: {},
    calculated: {},
  },
}

const svoReducer = (state, action) => {
  const variableKey = action.isBreakout ? 'breakouts' : 'responses'

  if (action.emptySelection !== undefined) {
    return {
      ...state,
      [variableKey]: {
        ...state[variableKey],
        [action.options[0].type]: action.options.reduce((acc, opt) => {
          acc[opt.optionId] = action.emptySelection
          return acc
        }, {}),
      },
    }
  }

  const { type, id } = action.option

  return {
    ...state,
    [variableKey]: {
      ...state[variableKey],
      [type]: {
        ...state[variableKey][type],
        [id]: !state[variableKey][type][id],
      },
    },
  }
}

const convertOption = (svoDispatch, handleTrack, selections, option, isBreakout) => {
  const isSelected = Boolean(selections[option.type][option.id])

  return {
    value: '',
    text: option.label,
    type: option.type,
    optionId: option.id,
    id: `${option.id}${isBreakout ? '-breakout' : ''}`,
    isAvailable: true,
    isSelected,
    canDelete: false,
    info: option.type === 'net' ? getNetExplanation(option) : null,
    onClick: opt => {
      handleTrack('Selected Add Variable Option Checkbox', {
        isSelected: !isSelected,
        option: option.id,
        optionText: option.label,
        isNet: option.type === 'net',
      })
      svoDispatch({ option, isBreakout })
    },
  }
}

const propTypes = {
  handleCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  onVariableSubmit: PropTypes.func.isRequired,
  optionExclusions: PropTypes.arrayOf(PropTypes.string),
  selectedVariable: PropTypes.arrayOf(
    PropTypes.shape({
      resourceType: PropTypes.string.isRequired,
      isGrid: PropTypes.bool,
      label: PropTypes.string,
    })
  ).isRequired,
}

function VariableOptionsSection({
  loading = false,
  optionExclusions = [],
  selectedVariable,
  handleCancel,
  onVariableSubmit,
}) {
  const variableNameElRef = useRef()
  const [nameOverflowing, setNameOverflowing] = useState(false)
  const handleTrack = useSelector(getHandleTrack, shallowEqual)
  const suppressedMap = useSelector(getSuppressedVariableOptions, shallowEqual)
  const variableErrorMap = useSelector(getSuppressedVariablesWithErrors, shallowEqual)
  const suppressNulls = useSelector(shouldSuppressNulls, shallowEqual)
  const [initialNullSuppressionValue, setInitialNullSuppressionValue] = useState(suppressNulls)
  const isFetchingSuppressedVarOpts = useSelector(
    getFetchingSuppressedVariableOptions,
    shallowEqual
  )
  const [selections, svoDispatch] = useReducer(svoReducer, initSvoState)
  const dispatch = useDispatch()
  const responsesVariable = selectedVariable.find(({ resourceType: rt }) =>
    ['options', 'calculated'].includes(rt)
  )

  const breakoutsVariable = selectedVariable.find(el => el.resourceType === 'breakouts')
  const isGrid = selectedVariable.some(el => el.isGrid)

  useEffect(() => {
    if (initialNullSuppressionValue === false && suppressNulls === true) {
      setInitialNullSuppressionValue(true)
      const variableNk = responsesVariable.variableNk
      dispatch(fetchVariableWithSuppressedOptions({ variableNk }))
    }
  }, [suppressNulls, responsesVariable.variableNk, dispatch, initialNullSuppressionValue])

  const { responseOptions, responseNets, responseCalcs, suppressedOptions } = useMemo(
    () => ({
      responseNets: responsesVariable.nets.reduce((nets, net) => {
        if (!optionExclusions.includes(net.id)) {
          return nets.concat(
            convertOption(svoDispatch, handleTrack, selections.responses, net, false)
          )
        }
        return nets
      }, []),
      responseCalcs: responsesVariable.calculated.reduce((calcs, calc) => {
        if (!optionExclusions.includes(calc.id)) {
          return calcs.concat(
            convertOption(svoDispatch, handleTrack, selections.responses, calc, false)
          )
        }
        return calcs
      }, []),
      ...responsesVariable.options.reduce(
        (opts, opt) => {
          if (!optionExclusions.includes(opt.id)) {
            if (
              suppressNulls &&
              !isFetchingSuppressedVarOpts[responsesVariable.variableNk] &&
              !variableErrorMap[responsesVariable.variableNk] &&
              suppressedMap[responsesVariable.variableNk]?.[opt.id]
            ) {
              return {
                ...opts,
                suppressedOptions: opts.suppressedOptions.concat(
                  convertOption(svoDispatch, handleTrack, selections.responses, opt, false)
                ),
              }
            }
            return {
              ...opts,
              responseOptions: opts.responseOptions.concat(
                convertOption(svoDispatch, handleTrack, selections.responses, opt, false)
              ),
            }
          }
          return opts
        },
        { responseOptions: [], suppressedOptions: [] }
      ),
    }),
    [
      responsesVariable,
      selections.responses,
      handleTrack,
      optionExclusions,
      suppressNulls,
      suppressedMap,
      variableErrorMap,
      isFetchingSuppressedVarOpts,
    ]
  )

  const { breakoutOptions, breakoutNets, breakoutCalcs, suppressedBreakoutOptions } = useMemo(
    () => ({
      breakoutNets: (breakoutsVariable?.nets ?? []).map(net =>
        convertOption(svoDispatch, handleTrack, selections.breakouts, net, true)
      ),
      breakoutCalcs: (breakoutsVariable?.calculated ?? []).map(calc =>
        convertOption(svoDispatch, handleTrack, selections.breakouts, calc, true)
      ),
      ...(breakoutsVariable?.options ?? []).reduce(
        (opts, opt) => {
          if (
            suppressNulls &&
            !isFetchingSuppressedVarOpts[responsesVariable.variableNk] &&
            !variableErrorMap[breakoutsVariable.variableNk] &&
            suppressedMap[breakoutsVariable.variableNk]?.[opt.id]
          ) {
            return {
              ...opts,
              suppressedBreakoutOptions: opts.suppressedBreakoutOptions.concat(
                convertOption(svoDispatch, handleTrack, selections.breakouts, opt, true)
              ),
            }
          } else {
            return {
              ...opts,
              breakoutOptions: opts.breakoutOptions.concat(
                convertOption(svoDispatch, handleTrack, selections.breakouts, opt, true)
              ),
            }
          }
        },
        { breakoutOptions: [], suppressedBreakoutOptions: [] }
      ),
    }),
    [
      breakoutsVariable,
      selections.breakouts,
      handleTrack,
      suppressNulls,
      suppressedMap,
      variableErrorMap,
      isFetchingSuppressedVarOpts,
      responsesVariable.variableNk,
    ]
  )

  const hasNoOptionsSelected = useMemo(() => {
    const {
      responses: { option: ro, calculated: rc, net: rn },
      breakouts: { breakout: bo, calculated: bc, net: bn },
    } = selections

    return (
      !(some(ro, Boolean) || some(rc, Boolean) || some(rn, Boolean)) ||
      (Boolean(breakoutsVariable) && !(some(bo, Boolean) || some(bc, Boolean) || some(bn, Boolean)))
    )
  }, [selections, breakoutsVariable])

  const selectedVarTitle =
    responsesVariable && responsesVariable.label ? responsesVariable.label : ''

  useEffect(() => {
    const el = variableNameElRef.current
    if (el.scrollHeight > el.clientHeight) {
      setNameOverflowing(true)
    } else {
      setNameOverflowing(false)
    }
  }, [variableNameElRef, setNameOverflowing])

  return (
    <React.Fragment>
      <div
        id="slider-selected-vars"
        className={cs(styles.selectedVariableContainer, {
          [styles.variableIsGrid]: isGrid,
          [styles.variableNotGrid]: !isGrid,
        })}
      >
        <Tooltip content={selectedVarTitle} placement="bottom">
          {({ hostRef, openTooltip, closeTooltip, attributes }) => (
            <span
              ref={ref => {
                variableNameElRef.current = ref
                hostRef(ref)
              }}
              onMouseEnter={nameOverflowing ? openTooltip : undefined}
              onMouseLeave={closeTooltip}
              className={styles.selectedVariable}
              {...attributes}
            >
              {selectedVarTitle}
            </span>
          )}
        </Tooltip>
        {isGrid && (
          <React.Fragment>
            <div className={styles.listHeader}>Options</div>
            <div className={styles.variableBreakouts}>
              <div>
                <ToggleableSelectionOptions options={breakoutCalcs} loading={loading} />
                <ToggleableSelectionOptions options={breakoutNets} loading={loading} />
                <hr className={styles.divider} />
                <ToggleableSelectionOptions
                  options={breakoutOptions}
                  loading={loading}
                  onToggleAll={emptySelection =>
                    svoDispatch({
                      emptySelection,
                      isBreakout: true,
                      options: breakoutOptions,
                    })
                  }
                />
                {!Boolean(breakoutOptions.length) && Boolean(suppressedOptions.length) && (
                  <p className={styles.allSuppressed}>
                    <FormattedMessage id="analysis.nullSuppression.allOptionsSuppressed.breakouts" />
                  </p>
                )}
                {suppressNulls && Boolean(suppressedOptions.length) && (
                  <NullSuppressionAccordion
                    isBreakout
                    hasError={Boolean(variableErrorMap[breakoutsVariable.variableNk])}
                  >
                    <ToggleableSelectionOptions
                      options={suppressedBreakoutOptions}
                      loading={loading}
                    />
                  </NullSuppressionAccordion>
                )}
              </div>
            </div>
          </React.Fragment>
        )}
        {isGrid && <div className={styles.listHeader}>Responses</div>}
        <div className={styles.variableOptions}>
          <div>
            <ToggleableSelectionOptions options={responseCalcs} loading={loading} />
            <ToggleableSelectionOptions options={responseNets} loading={loading} />
            <hr className={styles.divider} />
            <ToggleableSelectionOptions
              options={responseOptions}
              loading={loading}
              onToggleAll={emptySelection =>
                svoDispatch({ emptySelection, options: responseOptions })
              }
            />
            {!Boolean(responseOptions.length) && Boolean(suppressedOptions.length) && (
              <p className={styles.allSuppressed}>
                <FormattedMessage id="analysis.nullSuppression.allOptionsSuppressed.responses" />
              </p>
            )}
            {suppressNulls && Boolean(suppressedOptions.length) && (
              <NullSuppressionAccordion
                hasError={Boolean(variableErrorMap[responsesVariable.variableNk])}
              >
                <ToggleableSelectionOptions options={suppressedOptions} loading={loading} />
              </NullSuppressionAccordion>
            )}
          </div>
        </div>
      </div>
      <div className={styles.actionBar}>
        <Button disabled={loading} onClick={handleCancel} variant="secondary" size="large">
          Cancel
        </Button>
        <Button
          disabled={hasNoOptionsSelected}
          onClick={e => {
            const selectedNets = [
              ...Object.entries(selections.responses.net),
              ...(breakoutsVariable ? Object.entries(selections.breakouts.net) : []),
            ]

            const selected = {
              options: Object.entries(selections.responses.option).reduce(
                (acc, [id, selected]) => (selected ? acc.concat(id) : acc),
                []
              ),
              breakouts: Object.entries(selections.breakouts.breakout).reduce(
                (acc, [id, selected]) => (selected ? acc.concat(id) : acc),
                []
              ),
              calculated: Object.entries(selections.responses.calculated).reduce(
                (acc, [id, selected]) => (selected ? acc.concat(id) : acc),
                []
              ),
              calculatedBreakouts: Object.entries(selections.breakouts.calculated).reduce(
                (acc, [id, selected]) => (selected ? acc.concat(id) : acc),
                []
              ),
              nets: selectedNets.reduce(
                (acc, [id, selected]) => (selected ? acc.concat(id) : acc),
                []
              ),
            }

            onVariableSubmit(selectedVariable[0].variableNk, selected)
          }}
          variant="primary"
          size="large"
        >
          {hasNoOptionsSelected ? 'Select At Least One' : 'Add Variable'}
        </Button>
      </div>
    </React.Fragment>
  )
}

VariableOptionsSection.propTypes = propTypes

export default VariableOptionsSection
