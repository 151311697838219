import React from 'react'
import { LoadingSpinner } from '@knowledgehound/laika'

import * as styles from './ChartLoading.module.scss'

const ChartLoading = () => (
  <section className={styles.loadingWrapper}>
    <LoadingSpinner text="One Moment..." />
  </section>
)

export default ChartLoading
