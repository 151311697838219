import React from 'react'
import { injectIntl } from 'react-intl'
import { useSelector, shallowEqual } from 'react-redux'

import { filterNet as filterNetDuck } from 'store'
import NetsFilters from './NetsFilters'
import CategoricalFilters from './CategoricalFilters'

import * as styles from './AddFilter.module.scss'

function FilterResponseOptions({
  isChecked,
  netModalOpen,
  handleChangeResponseOption,
  handleTrack,
  handleToggleAllQuestionResponses,
}) {
  const selectedVariable = useSelector(filterNetDuck.getSelectedVariable, shallowEqual)
  const selectedGrid = useSelector(filterNetDuck.getSelectedGrid, shallowEqual)
  const selectedGridBreakout = useSelector(filterNetDuck.getSelectedBreakout, shallowEqual)

  return (selectedVariable && !selectedGrid) || (selectedVariable && selectedGridBreakout) ? (
    <div
      className={`${styles.variables} ${netModalOpen ? styles.netOptions : styles.filterOptions}`}
    >
      {!netModalOpen && (
        <NetsFilters
          isChecked={isChecked}
          handleChangeResponseOption={handleChangeResponseOption}
          handleTrack={handleTrack}
        />
      )}
      <CategoricalFilters
        selectedVariable={selectedVariable}
        selectedGridBreakout={selectedGridBreakout}
        isChecked={isChecked}
        handleChangeResponseOption={handleChangeResponseOption}
        handleToggleAllQuestionResponses={handleToggleAllQuestionResponses}
      />
    </div>
  ) : (
    <div className={`${styles.placeholder} ${styles.fullHeight}`} />
  )
}

export default injectIntl(FilterResponseOptions)
