import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { useSelector, shallowEqual } from 'react-redux'
import { Checkbox } from '@knowledgehound/laika'

import { getSelectedOptions, getSelectedVariable } from 'store/FilterNetDuck'

import * as styles from './CategoricalNets.module.scss'

const propTypes = {
  handleChangeCheckbox: PropTypes.func.isRequired,
  headerNumber: PropTypes.oneOf(['1', '2', '3', '4']),
}

function CategoricalNets({ handleChangeCheckbox, labelTextClassName, headerNumber = '2' }) {
  const variable = useSelector(getSelectedVariable, shallowEqual)
  const selectedOptions = useSelector(getSelectedOptions, shallowEqual)
  const selectionLength = selectedOptions.length
  const options = (variable && variable.options) || []

  return (
    <div className={styles.nets}>
      <p className={styles.header}>
        <FormattedMessage id="analysis.selectedResponseOptions" values={{ headerNumber }} />
      </p>
      <ul className={styles.options}>
        <li className={styles.option}>
          <Checkbox
            labelText={selectionLength === 0 ? 'Select All' : 'Deselect All'}
            labelType="linkSmall"
            value={selectionLength === 0 ? 'select-all-nets' : 'deselect-all-nets'}
            checked={selectionLength === options.length}
            indeterminate={selectionLength > 0 && selectionLength < options.length}
            onChange={() => handleChangeCheckbox()}
          />
        </li>
        {options &&
          options.map(o => (
            <li className={styles.option} key={`${o.id}-${o.type}`}>
              <Checkbox
                labelText={o.label}
                value={o.label}
                tooltip={o.label}
                checked={selectedOptions.some(so => so.id === o.id)}
                onChange={() => handleChangeCheckbox(o)}
              />
            </li>
          ))}
      </ul>
    </div>
  )
}

CategoricalNets.propTypes = propTypes

export default CategoricalNets
