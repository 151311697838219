import React from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { isEmpty, unescape } from 'lodash'
import * as laikaColors from '@knowledgehound/laika/scss/_colors.module.scss'

import { columnNumToColumnId } from 'tabularizeData'
import WithHtml from './WithHtml'
import * as styles from './AnalysisChartUtils.module.scss'

export const PALETTE = [
  '059FD8',
  '333F45',
  '13C075',
  'EF872F',
  '8645D6',
  '9BA3A8',
  'D7C927',
  '13ADD4',
  '4239BB',
  'DC3387',
]

// highcharts bug https://github.com/highcharts/highcharts/issues/14909
// https://www.highcharts.com/forum/viewtopic.php?p=163085#p163085
const HIGHCHARTS_MAX_WIDTH = 100000

const isPercent = (numberType: NumberTypeT) => numberType === 'percentage'
export const isChartStacked = chartType => ['stackedBar', 'stackedColumn'].includes(chartType)
export const isChartInverted = chartType =>
  ['stackedBar', 'stackedColumn', 'line'].includes(chartType)

export const calculateChartDimensions = (
  minDim: number,
  minBarWidth: number,
  minValAxisWidth: number,
  tableData: Array<Array<Object>>
) => {
  const xValCount = tableData.length
  const yValCount = tableData[0].length

  return Math.max(
    // Min overall chart width
    minDim,
    // each bar will be the minimum allowable width (usually for many bars)
    xValCount * yValCount * minBarWidth,
    // each grouping of bars will be the minimum allowable (usually for 1 or 2 bars)
    // necessary so the x axis labels don't look too crazy
    xValCount * minValAxisWidth
  )
}

const calculateChartHeight = (
  minHeight: number,
  chartType: ChartTypeT,
  tableData: Array<Array<Object>>,
  showLabels: boolean
) => {
  // these min and max values have just been found by trial and error
  const minBarWidth = showLabels ? 18 : 10
  const minValAxisWidth = showLabels ? 30 : 20

  if (chartType === 'bar') {
    return calculateChartDimensions(minHeight, minBarWidth, minValAxisWidth, tableData)
  } else if (chartType === 'stackedBar') {
    return calculateChartDimensions(minHeight, 0, minValAxisWidth, tableData)
  }
  return minHeight
}

const calculateChartWidth = (
  minWidth: number,
  chartType: ChartTypeT,
  tableData: Array<Array<Object>>,
  showLabels: boolean
) => {
  // these min and max values have just been found by trial and error
  const minValAxisWidth = 100
  const minBarWidth = showLabels ? 40 : 10

  if (chartType === 'column') {
    return calculateChartDimensions(minWidth, minBarWidth, minValAxisWidth, tableData)
  } else if (chartType === 'line' || chartType === 'stackedColumn') {
    return calculateChartDimensions(minWidth, 0, minValAxisWidth, tableData)
  }
  return minWidth
}

const baseSizeDisplayString = (baseSize: number) => {
  return `N=${Math.round(baseSize).toLocaleString()}`
}

export const resultValueDisplay = (val: number, numberType: NumberTypeT, meanMode: boolean) => {
  return isPercent(numberType) || meanMode ? val.toFixed(1) : Math.round(val).toLocaleString()
}

export const buildYAxis = (
  numberType: NumberTypeT,
  chartType: ChartTypeT,
  series: Array<Object>,
  isFullWidth: boolean,
  meanMode: boolean,
  baseText: string
) => {
  // this is so stacked charts are always out of 100 or
  // max for pick many in percent view per customer requests
  const stackPercent = isPercent(numberType) && !meanMode && isChartStacked(chartType)
  const maxAxisValues = stackPercent
    ? series
        .map(s => s.data)
        .reduce((maxVals, category) => {
          if (!maxVals.length) {
            return category.map(c => c.y)
          } else {
            category.forEach((val, vIdx) => {
              maxVals[vIdx] += val.y
            })
            return maxVals
          }
        }, [])
    : null

  const text = meanMode
    ? baseText || 'Count of Respondents'
    : isPercent(numberType)
    ? 'Percent of Respondents'
    : 'Count of Respondents'

  return {
    title: {
      enabled: isFullWidth,
      text,
    },
    labels: {
      enabled: isFullWidth,
      format: '{value}',
      style: {
        fontSize: '12px',
        color: laikaColors.charcoal,
        fill: laikaColors.charcoal,
      },
    },
    max:
      stackPercent && maxAxisValues
        ? Math.round(Math.max(...maxAxisValues) * 100) / 100
        : undefined,
  }
}

export const buildXAxis = (
  rowHeaders: Array<Array<Object>>,
  showIds: boolean,
  isFullWidth: boolean
) => {
  const categories = rowHeaders
    .filter(r => !r[0].baseRow)
    .map(row =>
      row
        .slice(0, -1)
        .map(header => header.label)
        .join(' - ')
    )

  return {
    categories,
    minPadding: 0,
    maxPadding: 0,
    tickWidth: 1,
    tickLength: 20,
    showEmpty: false,
    labels: {
      enabled: isFullWidth,
      step: 1,
      useHTML: false,
      formatter: function () {
        // if there's 8 columns or more, truncate long single word labels
        if (categories.length > 7 && this.value.length > 12 && this.value.indexOf(' ') === -1) {
          this.value = this.value.substring(0, 10) + '...'
        }
        const escapedValue = unescape(this.value)
        if (showIds) return `(${columnNumToColumnId(this.pos + 1)}) ${escapedValue}`
        return escapedValue
      },
      style: {
        fontSize: '12px',
        color: laikaColors.charcoal,
        fill: laikaColors.charcoal,
      },
    },
  }
}

const buildPlotOptions = (
  chartType: ChartTypeT,
  numberType: NumberTypeT,
  showLabels: boolean,
  meanMode: boolean,
  interactive: boolean = true
) => {
  const isStacked = isChartStacked(chartType)
  const series: Object = {
    animation: true,
    animationLimit: 1000, // allow intro animation up to threshold
    stacking: isStacked ? 'normal' : null,
    turboThreshold: 0, // disable turbo, we need POJOs for tooltip information
    boostThreshold: 0, // TODO: consider boost for line charts, useless otherwise
    borderRadius: isStacked ? 0 : 4,
    connectNulls: false,
    pointPadding: 0.05,
    groupPadding: 0.03, // constant spacing between groups of series
    minPointLength: 2, // show something for the 0 values
    enableMouseTracking: interactive,
    allowPointSelect: false,
    colorByPoint: isStacked && meanMode,
    symbolHeight: 14,
    symbolWidth: 14,
    symbolRadius: 7,
    events: {
      legendItemClick: () => false,
    },
    states: {
      hover: {
        enabled: interactive,
      },
    },
    dataLabels: {
      formatter: showLabels
        ? function () {
            const yValue = resultValueDisplay(this.y, numberType, meanMode)
            let format = `<b>${yValue}${isPercent(numberType) && !meanMode ? '%' : ``}</b>`
            if (this.point.statTesting && this.point.statTesting.length) {
              const breakType = chartType === 'column' || isStacked ? '<br/>' : ' '
              format += `${breakType}(${this.point.statTesting
                .map(id => `<b>${id}</b>`)
                .join(', ')})`
            }
            return format
          }
        : () => {},
      enabled: showLabels,
      padding: 3,
      inside: isStacked,
      useHTML: true,
      style: {
        fontSize: '12px',
        fontWeight: 'normal',
        textOutline: isStacked ? 'none' : '1px contrast',
        textAlign: 'center',
      },
      allowOverlap: isStacked ? false : true,
      align: chartType === 'bar' ? 'left' : 'center',
      crop: false,
      overflow: isStacked ? 'justify' : 'allow',
    },
  }
  return { series }
}

const createTooltip = (numberType: NumberTypeT, isFullWidth: boolean, meanMode: boolean) => ({
  enabled: isFullWidth,
  padding: 5,
  backgroundColor: laikaColors.white,
  useHTML: true,
  valueDecimals: isPercent(numberType) || meanMode ? 1 : 0,
  valueSuffix: isPercent(numberType) && !meanMode ? '%' : '',
  borderWidth: 0,
  borderRadius: 3,
  shadow: {
    color: laikaColors.charcoal,
    offsetX: 0,
    offsetY: 1,
    opacity: 0.04,
    width: 8,
  },
  outside: true,
  style: {
    fontSize: '12px',
    color: laikaColors.charcoal,
    zIndex: 100,
  },
  formatter() {
    const { point, x } = this
    const yValue = resultValueDisplay(point.y, numberType, meanMode)
    let formatted = `<div>`
    formatted += `<span><b>${x}</b></span>`
    formatted += `<span><span style="font-size: 16px;color: ${point.borderColor}">\u25CF </span>${
      point.series.name
    }: ${yValue} ${isPercent(numberType) && !meanMode ? '%' : ''}</span>`
    if (point.isLowBase) {
      formatted += `<span><span style="color: ${laikaColors.scarlet}"> ${baseSizeDisplayString(
        point.base
      )} Low base</span></span>`
    }
    if (!point.isLowBase) {
      formatted += `<span>${baseSizeDisplayString(point.base)}</span>`
    }
    if (point.statTesting && point.statTesting.length) {
      formatted += `<span>`
      formatted += `Significant compared to: ${point.statTesting
        .map(id => `<b>${id}</b>`)
        .join(', ')}`
      formatted += `</span>`
    }
    formatted += '</div>'
    return formatted
  },
})

export const getPointData = (isPercent: boolean, cellData: Object, hideLowBase: boolean) => {
  if (cellData.base === 0) return null
  if (hideLowBase && cellData.isLowBase) return null
  return isPercent && !cellData.meanRow
    ? (cellData.frequency / cellData.base) * 100
    : cellData.frequency
}

export const getSeriesData = (
  chartType: ChartTypeT,
  tableData: Array<Array<Object>>,
  colIdx: number,
  isPercent: boolean,
  hideLowBase: boolean,
  colors: Array<string>
): Array<Object> => {
  const isStacked = isChartStacked(chartType)

  return tableData
    .filter(r => !r[0].baseRow)
    .map(row => {
      const cellData = row[colIdx]
      if (!cellData) return {}
      const borderColor = colors[colIdx % colors.length]
      let color = borderColor
      if (cellData.isLowBase) {
        color =
          chartType === 'line'
            ? '#fff'
            : {
                pattern: {
                  path: {
                    // Repeating diagonal stripes
                    d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
                    stroke: color,
                    strokeWidth: 2,
                  },
                  opacity: 0.7,
                  width: 10,
                  height: 10,
                },
              }
      }
      return {
        y: getPointData(isPercent, cellData, hideLowBase),
        base: cellData.base,
        statTesting: cellData.statTesting,
        isLowBase: cellData.isLowBase,
        borderColor,
        borderWidth: cellData.isLowBase ? 2 : 0,
        marker:
          chartType === 'line' && cellData.isLowBase
            ? {
                radius: 3,
                lineColor: borderColor,
                lineWidth: 2,
              }
            : undefined,
        dataLabels: {
          color: cellData.isLowBase ? laikaColors.scarlet : undefined,
          backgroundColor: cellData.isLowBase && isStacked ? '#fff' : undefined,
          borderRadius: 3,
        },
        color,
      }
    })
}

export const getOrderedSeries = (
  chartType: ChartTypeT,
  tableData: Array<Array<Object>>,
  colHeaders: Array<Array<Object>>,
  rowHeaderCount: number,
  numberType: NumberTypeT,
  hideLowBase: boolean,
  colors: Array<string>
) => {
  // don't care about col id row
  const series = colHeaders.slice(0, -1).reduce((comboHeaders, row, variableIdx) => {
    const dataRow = row.slice(rowHeaderCount)
    if (dataRow[0].label === 'Total' && !dataRow[0].id) {
      return [{ label: 'Total', id: '__total__' }]
    }
    if (variableIdx === 0) {
      return dataRow.map(cellData => ({
        label: cellData.label,
        id: cellData.id,
        allGroupsLowBase: cellData.allGroupsLowBase,
      }))
    }
    dataRow.forEach((cellData, optIdx) => {
      comboHeaders[optIdx] = {
        label: `${comboHeaders[optIdx].label} - ${cellData.label}`,
        id: `${comboHeaders[optIdx].id}-${cellData.id}`,
        allGroupsLowBase: cellData.allGroupsLowBase,
      }
    })
    return comboHeaders
  }, [])

  return series.map((yValue, i) => {
    const yKey = yValue.id // guaranteed to be unique
    const name = yValue.label
    const formattedName = renderToStaticMarkup(
      <WithHtml content={name} className={styles.responseBox} />
    )
    const allGroupsLowBase = yValue.allGroupsLowBase
    const seriesData = getSeriesData(
      chartType,
      tableData,
      i,
      isPercent(numberType),
      hideLowBase,
      colors
    )
    return {
      id: yKey,
      name: formattedName,
      allGroupsLowBase,
      data: seriesData,
    }
  })
}

export const generateHighChartOptions = (
  chartType: ChartTypeT,
  chartColors: Array<string>,
  numberType: NumberTypeT,
  tableData: Array<Array<Object>>,
  colHeaders: Array<Array<Object>>,
  rowHeaders: Array<Array<Object>>,
  rowHeaderCount: number,
  height: number,
  width: number,
  showLabels: boolean,
  baseText: string,
  fullWidthHeight: number = 560,
  legendHeight: number | null = null,
  statTestingEnabled: boolean = false,
  hideLowBase: boolean = false
) => {
  const meanMode = tableData[0][0] && tableData[0][0].meanRow // reset on each call
  const isFullWidth = width > 400
  const chartHeight = isFullWidth ? fullWidthHeight : 270

  // some charts scroll horizontally, some scroll vertically. they need different heights and widths
  const scrollHorizontally =
    chartType === 'column' || chartType === 'stackedColumn' || chartType === 'line'
  const calculatedHeight = calculateChartHeight(chartHeight, chartType, tableData, showLabels)
  const heightToSet = scrollHorizontally ? height : calculatedHeight

  const calculatedWidth =
    scrollHorizontally && isFullWidth
      ? calculateChartWidth(width, chartType, tableData, showLabels)
      : width

  let highChartsType = chartType
  if (chartType === 'stackedBar') {
    highChartsType = 'bar'
  } else if (chartType === 'stackedColumn') {
    highChartsType = 'column'
  }

  let scrollablePlotArea = undefined
  if (highChartsType === 'bar') {
    scrollablePlotArea = {
      minHeight: Math.min(heightToSet, HIGHCHARTS_MAX_WIDTH),
      opacity: 1,
    }
  } else if (['column', 'line'].includes(highChartsType)) {
    scrollablePlotArea = {
      minWidth: Math.min(calculatedWidth, HIGHCHARTS_MAX_WIDTH),
      scrollPositionX: 0,
      opacity: 1,
    }
  }

  const colors = (isEmpty(chartColors) ? PALETTE : chartColors).map(color => `#${color}`)
  const isInverted = isChartInverted(chartType)

  const plotOptions = buildPlotOptions(chartType, numberType, showLabels, meanMode, isFullWidth)
  const tooltip = createTooltip(numberType, isFullWidth, meanMode)
  const series = getOrderedSeries(
    chartType,
    tableData,
    colHeaders,
    rowHeaderCount,
    numberType,
    hideLowBase,
    colors
  )

  // Must do this AFTER getOrderedSeries for max axis val
  const yAxis = buildYAxis(numberType, chartType, series, isFullWidth, meanMode, baseText)
  const xAxis = buildXAxis(rowHeaders, statTestingEnabled && isInverted, isFullWidth)

  return {
    chart: {
      type: highChartsType,
      animation: false, // do not animate updates (Highcharts is immutable anyway)
      scrollablePlotArea,
      style: {
        fontFamily: 'inherit',
        fontSize: '12px',
        color: laikaColors.charcoal,
      },
    },
    plotOptions,
    legend: {
      enabled: isFullWidth,
      useHTML: true,
      maxHeight: legendHeight || undefined,
      itemHoverStyle: { color: laikaColors.charcoal },
      itemStyle: {
        color: laikaColors.charcoal,
        fontWeight: 400,
        fontSize: '12px',
        cursor: '',
      },
      labelFormatter() {
        if (!statTestingEnabled || isInverted) return this.name
        return `(${columnNumToColumnId(this.index + 1)}) ${this.name}`
      },
    },
    series,
    colors,
    tooltip,
    xAxis,
    yAxis,
    title: { text: null },
    exporting: {
      enabled: false,
      scale: 2,
      width: 1000,
      chartOptions: {
        legend: {
          enabled: true,
        },
      },
    },
    credits: { enabled: false },
  }
}
