import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { useSelector, shallowEqual } from 'react-redux'
import { Icon } from '@knowledgehound/laika'

import { filterNet as filterNetDuck } from 'store'

import * as styles from './AddFilter.module.scss'

function SelectedVariable({ handleClickCloseQuestion, handleSelectBreakout }) {
  const selectedVariable = useSelector(filterNetDuck.getSelectedVariable, shallowEqual)
  const selectedGrid = useSelector(filterNetDuck.getSelectedGrid, shallowEqual)
  const selectedGridBreakout = useSelector(filterNetDuck.getSelectedBreakout, shallowEqual)

  if (!selectedVariable) return null

  if (!selectedGrid) {
    return (
      <section className={styles.selectedVariable}>
        <h4 className={styles.columnHeader}>
          <FormattedMessage id="analysis.selectQuestion" />
        </h4>
        <div key={selectedVariable.variableNk} className={styles.value}>
          {selectedVariable.label}
          <button
            type="button"
            title="remove"
            className={styles.closeButton}
            onClick={handleClickCloseQuestion}
          >
            &#215;
          </button>
        </div>
      </section>
    )
  }

  const gridOption = selectedGrid.find(o => o.resourceType === 'options')
  const gridBreakout = selectedGrid.find(o => o.resourceType === 'breakouts')
  const selected = selectedVariable.resourceType === 'options' ? gridBreakout : gridOption
  const breakoutBy = selectedVariable.resourceType === 'options' ? gridOption : gridBreakout

  if (!selectedGridBreakout) {
    return (
      <section className={styles.variables}>
        <h4 className={styles.optionsHeading} onClick={handleClickCloseQuestion}>
          <span className={styles.arrow}>
            <Icon icon="arrowDown" size="small" color="charcoal" />
          </span>
          <span>{selected.label}</span>
        </h4>
        <section className={styles.variable}>
          <ul className={styles.variableList}>
            {breakoutBy.options.map(o => (
              <li
                key={o.id}
                className={styles.variableItem}
                onClick={() => {
                  handleSelectBreakout(o, selected)
                }}
              >
                {o.label}
              </li>
            ))}
          </ul>
        </section>
      </section>
    )
  }

  return (
    <section className={styles.selectedVariable}>
      <h4 className={styles.columnHeader}>
        <FormattedMessage id="analysis.selectQuestion" />
      </h4>
      <div key={selectedVariable.variableNk} className={styles.value}>
        {selectedVariable.label} — {selectedGridBreakout.label}
        <button
          type="button"
          title="remove"
          className={styles.closeButton}
          onClick={handleClickCloseQuestion}
        >
          &#215;
        </button>
      </div>
    </section>
  )
}

export default injectIntl(SelectedVariable)
