import AnalysisSegment from '../../util/segment'
import { FETCH_USER_CONFIG_SUCCESS } from '../configurationDuck'

export const segmentMiddleware = store => next => action => {
  const { payload, type } = action

  if (type === FETCH_USER_CONFIG_SUCCESS) {
    const { client = '', user = {} } = payload

    const now = new Date()
    const utcMilllisecondsSinceEpoch = now.getTime() + now.getTimezoneOffset() * 60 * 1000
    const utcSecondsSinceEpoch = Math.round(utcMilllisecondsSinceEpoch / 1000)

    AnalysisSegment.group(client, {
      name: client,
      company: client,
    })

    AnalysisSegment.identify(`${client}:${user.email}`, {
      name: `${user.first_name} ${user.last_name}`,
      email: user.email,
      company: client,
      role: user.admin ? 'admin' : user.manager ? 'manager' : 'user',
      created_at: utcSecondsSinceEpoch,
      organization: {
        name: client, // Required for ClientSuccess integration
      },
    })
  }

  return next(action)
}
