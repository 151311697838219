import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { useSelector, shallowEqual } from 'react-redux'

import { filterNet as filterNetDuck } from 'store'
import NumericFilters from '../AddFilter/NumericFilters'
import { isNumericMethod } from '../FilterUtils'
import CategoricalNets from './CategoricalNets'

import * as styles from './NumericNets.module.scss'

const propTypes = {
  handleChangeCheckbox: PropTypes.func.isRequired,
  labelTextClassName: PropTypes.string,
  filters: PropTypes.arrayOf(PropTypes.shape({})), // TODO better typing
}

function NumericNets({ handleChangeCheckbox, labelTextClassName, filters }) {
  const method = useSelector(filterNetDuck.getSelectedMethod, shallowEqual)

  return isNumericMethod(method) ? (
    <div className={styles.columnContainer}>
      <h4 className={styles.columnHeader}>
        <FormattedMessage id="analysis.selectedNumericOptions" values={{ headerNumber: '2' }} />
      </h4>
      <NumericFilters appliedFilters={filters} fromFilterModal={false} />
    </div>
  ) : (
    <CategoricalNets
      handleChangeCheckbox={handleChangeCheckbox}
      labelTextClassName={labelTextClassName}
    />
  )
}

NumericNets.propTypes = propTypes

export default NumericNets
