export const getStudyNumberFromNk = naturalKey =>
  naturalKey.split('/').length ? naturalKey.split('/')[0] : ''

export const getDatasetFromNk = naturalKey =>
  naturalKey.split('/').length > 1 ? naturalKey.split('/')[1] : ''

export const getQnameFromNk = naturalKey =>
  naturalKey.split('/').length >= 3 ? naturalKey.split('/')[2] : ''

export const constructNkFromParts = (studyNumber, datasetName, questionName) =>
  `${studyNumber}/${datasetName}${questionName ? `/${questionName}` : ''}`

export const encodeNaturalKey = key => key.split('/').map(encodeURIComponent).join('/')
