// Flow is deliberately disabled for this file and class because it always
// warns about unsafe setters and getters, v0.64 fixes it https://github.com/facebook/flow/issues/2826
export class Auth {
  constructor(jwt: string) {
    this.jwt = jwt
  }

  get bearer(): string {
    return `Bearer ${this.jwt}`
  }

  jwt: string

  // Accessors to manage the default Auth
  static defaultAuth() {
    return Auth.current
  }
  static get currentJWT() {
    return Auth.defaultAuth().jwt
  }
  static get currentBearer() {
    return Auth.defaultAuth().bearer
  }

  static setDefaultAuthJWT(jwt) {
    Auth.current = new Auth(jwt)
  }
  static current: Auth
}
Auth.current = new Auth('')
