import { Auth } from './jwt'
import { handleResponse } from '@knowledgehound/data/fetchUtils'
// Need so that when Pug uses the exported request functions
// this is read from within those functions, otherwise it is undefined
const ROTTWEILER_URL = process.env.REACT_APP_ROTTWEILER_URL || ''

// jwtOverride is to be used by other services that consume this function, as
// when this is imported it loses context of process.env vars and does not have Auth set
export async function fetchPublic(url, opts, json = true, jwtOverride = null) {
  const authOverride = jwtOverride ? `Bearer ${jwtOverride}` : null
  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization: authOverride || Auth.currentBearer,
      ...(json ? { 'Content-Type': 'application/json' } : {}),
    },
    ...opts,
  })
}

export async function fetchConfiguration(opts, jwt = '') {
  const url = `${ROTTWEILER_URL}/accounts/configuration/`
  try {
    const response = await fetchPublic(url, opts, true, jwt)
    return await handleResponse(response)
  } catch (error) {
    console.error('Configuration', `Couldn't fetch configuration: ${error}`)
    return { error, url }
  }
}
