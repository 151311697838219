import React, { useMemo } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { useIntl } from 'react-intl'
import classnames from 'classnames'

import {
  getChartSummary,
  isFetchingChartSummary,
  hasErrorFetchingChartSummary,
} from 'store/DatasetSelectors'
import SummaryLogo from './SummaryLogo'
import * as styles from './ChartSummary.module.scss'

export default function ChartSummary() {
  const intl = useIntl()
  const isFetching = useSelector(isFetchingChartSummary, shallowEqual)
  const hasError = useSelector(hasErrorFetchingChartSummary, shallowEqual)
  const summary = useSelector(getChartSummary, shallowEqual)
  const content = useMemo(() => {
    if (isFetching) {
      return intl.formatMessage({ id: 'analysis.summaries.fetching' })
    }
    if (hasError) {
      return intl.formatMessage({ id: 'analysis.summaries.error' })
    }
    if (!summary) {
      return intl.formatMessage({ id: 'analysis.summaries.awaitingAD' })
    }
    return summary
  }, [summary, isFetching, hasError, intl])

  return (
    <div className={classnames(styles.summaryBar, { [styles.fetching]: isFetching })}>
      <div className={styles.content}>
        <SummaryLogo />
        <span>
          <span className={styles.summary}>{content}</span>
        </span>
        <span className={styles.footer}>
          <span>Summaries is in Beta.</span> {/* TODO: Restore when ready for Intercom Survey */}
          {/* <button className={styles.cta} onClick={() => {}}> */}
          {/*   Have feedback? */}
          {/* </button> */}
        </span>
      </div>
    </div>
  )
}
