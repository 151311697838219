import { isEmpty } from 'lodash'

import { getQnameFromNk } from '../util/naturalKeyUtils'
import { createBaseFilters, serializeFilterParams, mergeFilterLists } from '../FilterUtils'
import { determineBaseVariable } from '../Analysis2Utils'

// Pug uses this, make sure if you update this method you bump up
// the analysis package version and update Pug
const combineGridOptions = (existingVarReqData, variableRequestData) => {
  const options = [
    'deselected_options',
    'options',
    'deselected_breakouts',
    'breakouts',
    'nets',
    'calculated',
  ]
  options.forEach(optType => {
    if (existingVarReqData[optType]) {
      if (variableRequestData[optType]) {
        variableRequestData[optType] = [
          ...variableRequestData[optType],
          ...existingVarReqData[optType],
        ]
        // remove duplicates
        variableRequestData[optType] = [...new Set(variableRequestData[optType])]
      } else {
        variableRequestData[optType] = [...existingVarReqData[optType]]
      }
    }
  })
  return variableRequestData
}

export const getComboFilters = (xAxisVariables, yAxisVariables, filters) => {
  const autoAdjustBaseFilters = [...xAxisVariables, ...yAxisVariables]
    .map(v => createBaseFilters(v))
    .filter(f => f.filteredOptions.length > 0)
  const { comboFilters } = mergeFilterLists(autoAdjustBaseFilters, filters)
  return comboFilters
}

// Pug uses this, make sure if you update this method you bump up
// the analysis package version and update Pug
export default function setupDataFetchRequest({
  xAxisVariables = [],
  yAxisVariables = [],
  filters = [],
  autoAdjustBaseEnabled = false,
  chartType = 'spreadsheet',
  statTestingRequested = false,
  statsConfidence = [95],
  elevatedKey = '',
  weighting = '',
  showUnweightedBase,
}) {
  const isRowBasedAnalysis = ['line', 'stackedBar', 'stackedColumn'].includes(chartType)

  // for now, we only allow 1 base variable
  const combinedVars = [...xAxisVariables, ...yAxisVariables]
  // takes qName from either last item in nested xAxis or yAxis
  const baseVar = determineBaseVariable(xAxisVariables, yAxisVariables, chartType)

  const requestBody = {
    bases: [getQnameFromNk(baseVar.variableNk), baseVar.resourceType],
    variables: {},
    filters: {},
    stat_groups: {},
    stat_testing_enabled: false,
  }

  if (elevatedKey) requestBody.elevated_key = elevatedKey
  if (weighting) requestBody.weighting = weighting
  if (showUnweightedBase) requestBody.unweighted_bases = true

  const variables = {}
  let hasNoOptionsSelected = false

  combinedVars.forEach(variable => {
    const currQName = getQnameFromNk(variable.variableNk)
    let variableRequestData = {}

    const selected =
      (variable.selectedOptions && variable.selectedOptions.filter(o => o.selected)) || []
    const deselected = variable.deselectedOptions || []
    if (variable.deselectedBreakouts !== undefined) {
      variableRequestData['deselected_breakouts'] = variable.deselectedBreakouts
    }

    selected.forEach(opt => {
      // note that if type does not exist, it must be a blank key/val pair
      const optType = `${opt.type}${opt.type === 'calculated' ? '' : 's'}`

      if (!variableRequestData[optType]) {
        variableRequestData[optType] = []
      }

      // convert to numeric as back end expects it
      let currId = opt.id
      if (opt.type !== 'breakout' && +currId) {
        // checks if it is truthy when cast as numeric
        currId = +currId
      }
      variableRequestData[optType].push(currId)
    })

    deselected.forEach(optId => {
      if (!variableRequestData['deselected_options']) {
        variableRequestData['deselected_options'] = []
      }
      let currId = optId
      if (+currId) {
        // checks if it is truthy when cast as numeric
        currId = +currId
      }
      variableRequestData['deselected_options'].push(currId)
    })

    if (variable.resourceType === 'options' && !selected.length && !deselected.length) {
      if (variable.deselectedOptions !== undefined) {
        variableRequestData['deselected_options'] = []
      } else {
        // No selected variables, and no deselected indications, so this is an error
        hasNoOptionsSelected = true
        return
      }
    }

    const selected_options = variable.selectedOptions
      ? variable.selectedOptions.filter(so => {
          return so.type === 'option'
        })
      : []
    if (
      variable.deselectedOptions &&
      deselected.length + selected_options.length === 0 &&
      variable.resourceType === 'options'
    ) {
      variableRequestData['deselected_options'] = []
    }

    // grids have 2 qNames, merge them instead of replacing
    const existingVarReqData = { ...variables[currQName] }
    if (existingVarReqData) {
      variableRequestData = combineGridOptions(existingVarReqData, { ...variableRequestData })
    }

    variables[currQName] = variableRequestData
  })

  if (hasNoOptionsSelected) {
    return null
  }

  requestBody.variables = variables

  if (autoAdjustBaseEnabled) {
    requestBody.filters = serializeFilterParams(
      getComboFilters(xAxisVariables, yAxisVariables, filters)
    )
    requestBody.filtered_base = true
  } else {
    requestBody.filters = !isEmpty(filters) ? serializeFilterParams(filters) : {}
  }

  if (statTestingRequested) {
    requestBody.stat_testing_enabled = true
    const xKey: string = isRowBasedAnalysis ? 'between' : 'comparisons'
    const yKey: string = isRowBasedAnalysis ? 'comparisons' : 'between'
    requestBody.stat_groups = {
      [xKey]: xAxisVariables.map(v => [getQnameFromNk(v.variableNk), v.resourceType]),
      [yKey]: yAxisVariables.map(v => [getQnameFromNk(v.variableNk), v.resourceType]),
    }
    // this is to avoid the goofiness of float math in JS  Without this
    // 95 confidence would resolve to 0.050000000000000044 instead of .05
    requestBody.pval = Math.round(1000 * (1 - statsConfidence[0] / 100)) / 1000
  }

  return requestBody
}
