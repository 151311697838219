import { getFilterMethodText } from './filterMethods'

/**
 * Translate a list of filter objects into SPSS's filter format.
 */
function serializeFiltersToObject(filters) {
  // Group the filters by question name, and by method under that.
  const byName = {}
  const netMethodText = '-net'
  filters.forEach(filter => {
    const questionName = filter.filter
    byName[questionName] = byName[questionName] || {}
    // doing this to keep the netted values separate from the other values
    const method = filter.nets ? `${filter.method}${netMethodText}` : filter.method
    byName[questionName][method] = byName[questionName][method] || []
    const filterToAdd = { option: filter.option }
    if (filter.breakoutName) {
      filterToAdd.breakout = filter.breakoutName
    }
    if (filter.nets) {
      filterToAdd.nets = filter.nets
    }
    byName[questionName][method].push(filterToAdd)
  })

  // Make a list of conditions that will either be returned or ANDed together.
  const conditions = []

  // Convert the `byName` dictionary into the filter format.
  Object.keys(byName).forEach(questionName => {
    const orMethods = []
    Object.keys(byName[questionName]).forEach(method => {
      const values = byName[questionName][method]
      const methodText = method.replace('$not', '$').replace(netMethodText, '')
      // all responses for that quest-method pair stored in a list
      const methodDict = { [questionName]: { [methodText]: [] } }

      values.forEach(val => {
        const methodDictItem = methodDict[questionName][methodText]
        if (val.breakout) {
          const currBreakoutItem = methodDictItem.find(
            breakObj => breakObj['$breakout_name'] === val.breakout
          )
          if (currBreakoutItem) {
            const breakoutIdx = methodDictItem.indexOf(currBreakoutItem)
            methodDictItem[breakoutIdx]['$response'].push(val.option)
          } else {
            methodDictItem.push({
              $breakout_name: val.breakout,
              $response: [val.option],
            })
          }
        } else {
          methodDictItem.push(val.option)
        }
        if (val.nets) {
          const currNets = methodDict[questionName].$nets || []
          methodDict[questionName].$nets = [...new Set([...currNets, ...val.nets])]
        }
      })
      if (method.startsWith('$not')) {
        conditions.push({ $not: methodDict })
      } else {
        // necessary to nest old and new equivalent methods in
        // an $or, like $eq and $eq
        orMethods.push(methodDict)
      }
    })

    if (orMethods.length === 1) {
      conditions.push(orMethods[0])
    } else if (orMethods.length > 1) {
      conditions.push({ $or: orMethods })
    }
  })
  // If there is more than one top-level condition, we combine them with $and.
  return conditions.length === 1 ? conditions[0] : { $and: conditions }
}

/**
 * takes in an array of filter objects and converts to acceptable query string
 * in the format of a mongo-like query
 */
export function serializeFilters(filters: Array<any>) {
  if (filters && Array.isArray(filters) && filters.length > 0) {
    const conditions = serializeFiltersToObject(filters)
    return conditions ? JSON.stringify(conditions) : ''
  }
  return ''
}

function deserializeFilterOption(option) {
  let filter = Object.keys(option)[0]
  let filterMethod
  let filterMethodValue

  const isNotFilter = filter === `$not`
  if (isNotFilter) {
    option = option[filter]
    filter = Object.keys(option)[0]
  }

  const filterOptions = option[filter]
  const netsValue = filterOptions['$nets']
  filterMethod = Object.keys(filterOptions).filter(key => key !== '$nets')[0]
  filterMethodValue = Array.isArray(filterOptions[filterMethod])
    ? filterOptions[filterMethod]
    : [filterOptions[filterMethod]]

  if (isNotFilter) {
    filterMethod = filterMethod.replace('$', '$not')
  }

  const methodText = getFilterMethodText(filterMethod)

  const buildDeserialized = (option, breakoutName = null) => {
    const filterItem = {
      filter,
      method: filterMethod,
      methodText,
      option,
    }
    if (breakoutName) filterItem.breakoutName = breakoutName
    if (netsValue) filterItem.nets = netsValue
    return filterItem
  }

  const deserializedFilters = []
  filterMethodValue.forEach(val => {
    if (val.$response && val.$breakout_name) {
      if (!Array.isArray(val.$response)) {
        val.$response = [val.$response]
      }
      val.$response.forEach(res => {
        deserializedFilters.push(buildDeserialized(res, val.$breakout_name))
      })
    } else {
      deserializedFilters.push(buildDeserialized(val))
    }
  })

  return deserializedFilters
}

/**
 * takes in filterQuery and turns back into array of filters
 */
export function deserializeFilters(filterQuery) {
  return Object.keys(filterQuery).reduce(function iter(acc, filter) {
    if (!filter) {
      return acc
    }
    if (Array.isArray(filterQuery[filter])) {
      return filterQuery[filter].reduce(iter, acc)
    }
    if (Array.isArray(filter)) {
      let deserializedArray = []
      filter.forEach(item => {
        deserializedArray = deserializedArray.concat(deserializeFilterOption(item))
      })
      return [...acc, ...deserializedArray]
    }
    if (typeof filter === 'object') {
      if (Object.keys(filter)[0] === '$and' || Object.keys(filter)[0] === '$or') {
        return Object.keys(filter)
          .map(k => filter[k])
          .reduce(iter, acc)
      } else {
        return [...acc, ...deserializeFilterOption(filter)]
      }
    }
    if (typeof filter === 'string' && filterQuery[filter]) {
      return acc.concat(deserializeFilterOption(filterQuery))
    }
    // when in doubt, return it!
    return acc
  }, [])
}

/**
 * takes in filterQuery and turns back into array of filters
 */
export function deserializeFilterString(filterString) {
  return deserializeFilters(JSON.parse(filterString))
}
