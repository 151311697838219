import { flatten, isEqual } from 'lodash'
import * as Sentry from '@sentry/react'
import { handleError, handleData } from '@knowledgehound/data/fetchUtils'

import { deserializeFilterString } from 'util/paramsSerializer'
import setupDataFetchRequest from 'util/setupDataFetchRequest'
import {
  convertA1toA2Url,
  getFirstInterval,
  buildCellId,
  getVariable,
  determineBaseVariable,
  determineNewVarLayout,
  transformAnalysisData,
} from '../Analysis2Utils'
import { isChartStacked, isChartInverted } from '../chart/AnalysisChartUtils'
import {
  getDatasetData,
  getVariablesWithNets,
  getAnalysisData,
  getQuestion,
  getQuestionNets,
  createOptionVar,
  createBreakoutVar,
} from './DatasetRequests'
import {
  getHandleTrack,
  getDataset,
  getAnalysisDataFetchRequestBody,
  getStatTestingPollIntervalId,
  getFetchedStatTestingId,
  getFilters,
  getAnalysisData as getAnalysisDataSelector,
  shouldSuppressNulls,
  isStatTestingEnabled,
  getChartType,
  getVariables,
  getXAxisVariables,
  getYAxisVariables,
  getDatasetVariables,
  getChartSortState,
  getA1Husk,
  getStatTestConfidence,
  isAutoAdjustBaseEnabled,
  getElevatedKey,
  getValidWeightShape,
  getCustomWeights,
  getWeightsPollIntervalId,
  isFetchingChartSummary,
  getSerializedChartState,
  getShowUnweightedBase,
} from './DatasetSelectors'
import { getUserPermissions, getHasUnweightedBasePerm } from './configurationDuck'
import { VIS_CHART_VAR_LIMIT, ROW } from './constants'
import generateReduxAction from './GenerateReduxAction'

const COLLIE_URL = process.env.REACT_APP_COLLIE_URL || ''
const DALMATIAN_URL = process.env.REACT_APP_DALMATIAN_API2_URL || ''
const STATS_TESTING_POLL_RATE = 1000
const WEIGHTS_POLL_RATE = 10000

const willOptsBeSelected = (selectedOptions, optionId, optionType) => {
  return selectedOptions.some(o =>
    o.id !== optionId || o.type !== optionType ? o.selected : !o.selected
  )
}

const toggleCalcOff = (selectedOptions, hasNets, optionId, optionType, isSelected) => {
  return selectedOptions.map(o => {
    if (o.id === optionId && o.type === optionType) {
      isSelected = !o.selected
      return {
        ...o,
        selected: isSelected,
      }
    } else if (o.type === 'net') {
      return {
        ...o,
        selected: true,
      }
    } else if (o.type === 'calculated') {
      return {
        ...o,
        selected: false,
      }
    } else {
      return {
        ...o,
        selected: !hasNets,
      }
    }
  })
}

export const toggleSelectionOption = (id, axisVariables, optionId, optionType) => {
  let isSelected
  const updatedVars = axisVariables.map(av => {
    if (av.id === id) {
      let selectedOptions = []
      if (
        optionType === 'calculated' &&
        !willOptsBeSelected(av.selectedOptions, optionId, optionType)
      ) {
        selectedOptions = toggleCalcOff(
          av.selectedOptions,
          Boolean(av.selectedOptions.filter(o => o.type === 'net').length),
          optionId,
          optionType,
          isSelected
        )
      } else {
        selectedOptions = av.selectedOptions.map(o => {
          if (o.id === optionId && o.type === optionType) {
            isSelected = !o.selected
            return {
              ...o,
              selected: isSelected,
            }
          }
          return o
        })
      }
      return {
        ...av,
        selectedOptions: selectedOptions,
      }
    }
    return av
  })
  return { updatedVars, isSelected }
}

export const toggleAllOptions = (id, axisVariables) => {
  // if any option is selected, deselect all, including if all options are selected
  // if no options are selected, select all.
  // does not include nets or calculated options
  let someSelected
  const updatedVars = axisVariables.map(av => {
    if (av.id === id) {
      someSelected = av.selectedOptions.some(
        o => ['option', 'breakout'].includes(o.type) && o.selected
      )
      const newSelectedOptions = av.selectedOptions.map(option => {
        if (['option', 'breakout'].includes(option.type)) {
          if (someSelected) {
            return {
              ...option,
              selected: false,
            }
          } else {
            return {
              ...option,
              selected: true,
            }
          }
        }
        return option
      })
      return {
        ...av,
        selectedOptions: newSelectedOptions,
      }
    }
    return av
  })

  return { updatedVars, someSelected }
}

export const getVariablePosition = (
  currTargetAxis,
  isReplacingVariable,
  replacingVariableNk,
  replacingVariableType,
  xAxisVariables,
  yAxisVariables,
  variables
) => {
  if (isReplacingVariable) {
    const oldVariable = getVariable(variables, replacingVariableNk, replacingVariableType)

    if (oldVariable) {
      const axisVars = currTargetAxis === 'ROW' ? xAxisVariables : yAxisVariables
      const index = axisVars.findIndex(v => v.id === oldVariable.id)
      return index
    }
  }
  return -1
}

export const RESET_DATASET_REDUCER_STATE = 'RESET_DATASET_REDUCER_STATE'
export function resetDatasetReducer(): ActionT {
  return {
    type: RESET_DATASET_REDUCER_STATE,
  }
}

export const RESET_ANALYSIS_CHART = 'RESET_ANALYSIS_CHART'
export function resetAnalysisChart(): ActionT {
  return {
    type: RESET_ANALYSIS_CHART,
  }
}

export const fetchDatasetAction = generateReduxAction('FETCH_DATASET')

/** Fetch dataset for study
 * @param {String} studyId – ID of the study, i.e. FRE0123
 * @returns {Promise<Object>} - Requested dataset
 */
export const fetchDataset = studyId => async dispatch => {
  dispatch(fetchDatasetAction.loading())

  try {
    const data = await getDatasetData(studyId, { richQuestionMetadata: true })
    dispatch(fetchDatasetAction.success(data))
    return data
  } catch (error) {
    dispatch(fetchDatasetAction.error(error))
  }
}

export const fetchWeightsAction = generateReduxAction('FETCH_WEIGHTS')

const __fetchWeightsSingle =
  () =>
  async (dispatch, getState, { fetch }) => {
    dispatch(fetchWeightsAction.loading())
    const { studyId, datasetNk } = getDataset(getState())

    try {
      const response = await fetch(`${COLLIE_URL}/datasets/${studyId}/${datasetNk}/weights/`)
      if (!response.ok) {
        dispatch(fetchWeightsAction.error(response.statusText))
        return
      }

      const data = await response.json()
      dispatch(fetchWeightsAction.success(data))
    } catch (error) {
      dispatch(fetchWeightsAction.error(error))
    }
  }

export const SET_WEIGHT_POLL_INTERVAL_ID = 'SET_WEIGHT_POLL_INTERVAL_ID'
const __setWeightPollIntervalId = intervalId => ({
  type: SET_WEIGHT_POLL_INTERVAL_ID,
  intervalId,
})

export const stopFetchWeightPoll = () => (dispatch, getState) => {
  clearInterval(getWeightsPollIntervalId(getState()))
  dispatch(__setWeightPollIntervalId(null))
}

export const fetchWeights = () => async (dispatch, getState) => {
  await dispatch(__fetchWeightsSingle())

  const notRunning = getWeightsPollIntervalId(getState()) === null
  const incomplete = !getCustomWeights(getState()).every(
    weight => (weight.id && weight.isDataReady) || !weight.isValid
  )

  if (notRunning && incomplete) {
    const intervalId = setInterval(async () => {
      await dispatch(__fetchWeightsSingle())

      const allWeightsReady = getCustomWeights(getState()).every(
        weight => (weight.id && weight.isDataReady) || !weight.isValid
      )

      if (allWeightsReady) {
        dispatch(stopFetchWeightPoll())
      }
    }, WEIGHTS_POLL_RATE)

    dispatch(__setWeightPollIntervalId(intervalId))
  }
}

export const fetchVariableAction = generateReduxAction('FETCH_VARIABLE')

/**
 * @param variableNk {String} - Natural key of the variable
 * @param dispatchLoad {boolean} - Set loading state on visual chart
 * @param params {Object} - Additional opts to send to backend
 */
export const fetchVariable =
  (variableNk, dispatchLoad = true, params = {}) =>
  async (dispatch, getState) => {
    if (dispatchLoad) {
      dispatch(fetchVariableAction.loading())
    }

    // Pull JWT from session so Pug can set auth
    const jwt = getState()?.session?.currentSession?.data?.jwt

    return new Promise((resolve: Function, reject: Function) => {
      getVariablesWithNets(variableNk, params, jwt)
        .then(handleData(resolve, dispatch, fetchVariableAction.success))
        .catch(handleError(reject, dispatch, fetchVariableAction.error))
    })
  }

export const SET_FETCH_VARIABLE_LOADING = 'SET_FETCH_VARIABLE_LOADING'
export function setFetchVariableLoading(isFetching): ActionT {
  return {
    type: SET_FETCH_VARIABLE_LOADING,
    payload: { isFetching },
  }
}

/** Resolve variable from cache, otherwise fetch the variable and store in cache.
 *
 * @param variableNk {String} - Natural key of the variable, ex. FRE0001/StudyName/QuestionName
 * @param dispatchLoad {boolean} - Display analysis loading spinner
 * @return {Object} - Requested variable
 */
export const fetchVariableThunk =
  (variableNk, dispatchLoad = true) =>
  async (dispatch, getState) => {
    dispatchLoad && dispatch(fetchVariableAction.loading())
    const fetchedVariables = getVariables(getState())
    const fetched = fetchedVariables.filter(v => v.variableNk === variableNk)
    if (!fetched.length) return dispatch(fetchVariable(variableNk, false))

    dispatch(setFetchVariableLoading(false))
    return fetched
  }

export const ADD_SUPPRESSED_OPTIONS = 'ADD_SUPPRESSED_OPTIONS'
const __addSuppressedOptions = (variableNk, options, filters, baseVariableId) => ({
  type: ADD_SUPPRESSED_OPTIONS,
  filters,
  variableNk,
  options,
  baseVariableId,
})

export const SET_SUPPRESSED_OPTIONS_FETCHING = 'SET_SUPPRESSED_OPTIONS_FETCHING'
const __setSuppressedOptionsFetching = (variableNk, isFetching = true) => ({
  type: SET_SUPPRESSED_OPTIONS_FETCHING,
  variableNk,
  isFetching,
})

export const SET_SUPPRESSED_OPTIONS_ERROR = 'SET_SUPPRESSED_OPTIONS_ERROR'
const __setSuppressedOptionsError = (variableNk, error) => ({
  type: SET_SUPPRESSED_OPTIONS_ERROR,
  variableNk,
  error,
})

/**
 * Fetch variable data and populate the null suppression cache. Will not
 * refetch variables that have already been cached, and will not refetch
 * null suppression data unless invalidated or stale.
 *
 * @param {Object} options - Thunk arguments
 * @param {String} variableNk - Natural key of the dataset variable
 * @param {Object} [replacingVariable] - If present, variable to be replaced by variableNk
 * @param {String} axis - Axis the variable will be placed into
 * @param {boolean} forceRefresh - Ignore cache and refetch null suppression data
 * @return {Object} Requested analysis variable
 */
export const fetchVariableWithSuppressedOptions =
  ({ variableNk, replacingVariable, axis, forceRefetch }) =>
  async (dispatch, getState) => {
    const filters = getFilters(getState())
    const xAxisVariables = getXAxisVariables(getState())
    const yAxisVariables = getYAxisVariables(getState())
    const fetchedVars = getVariables(getState())
    const chartType = getChartType(getState())
    const elevatedKey = getElevatedKey(getState())
    const { nullSuppressedOpts } = getState().dataset.variables

    dispatch(__setSuppressedOptionsFetching(variableNk))
    const [responsesVar, breakoutsVar] = await dispatch(fetchVariableThunk(variableNk, false))

    const replacingPosition =
      replacingVariable && axis
        ? getVariablePosition(
            axis,
            replacingVariable,
            replacingVariable.variableNk,
            replacingVariable.resourceType,
            xAxisVariables,
            yAxisVariables,
            fetchedVars
          )
        : -1

    const { baseVar: nextBaseVar } = determineNewVarLayout({
      xAxisVariables,
      yAxisVariables,
      axis,
      newVars: breakoutsVar ? [responsesVar, breakoutsVar] : [responsesVar],
      replacingPosition,
      chartType,
    })

    if (
      !forceRefetch &&
      (!shouldSuppressNulls(getState()) ||
        (filters === nullSuppressedOpts.filters[variableNk] &&
          // Might not be a base variable if no variables are loaded
          nullSuppressedOpts.baseVariableId[variableNk] === nextBaseVar?.id &&
          nullSuppressedOpts.variables[variableNk]) ||
        nullSuppressedOpts.isFetching[variableNk])
    ) {
      dispatch(__setSuppressedOptionsFetching(variableNk, false))
      return breakoutsVar !== undefined ? [responsesVar, breakoutsVar] : [responsesVar]
    }

    const responsesChartVar = [
      {
        id: responsesVar.id,
        axis: 'COLUMN',
        variableNk: responsesVar.variableNk,
        resourceType: responsesVar.resourceType,
        selectedOptions: responsesVar.options.map(opt => ({
          id: opt.id,
          type: 'option',
          selected: true,
        })),
      },
    ]

    const breakoutsChartVar = breakoutsVar
      ? [
          {
            id: breakoutsVar.id,
            axis: 'ROW',
            variableNk: breakoutsVar.variableNk,
            resourceType: breakoutsVar.resourceType,
            selectedOptions: breakoutsVar.options.map(opt => ({
              id: opt.id,
              type: 'breakout',
              selected: true,
            })),
          },
        ]
      : []

    let requestX
    let requestY
    if (
      (!xAxisVariables.length && !yAxisVariables.length) ||
      nextBaseVar.variableNk === responsesVar.variableNk
    ) {
      requestX = responsesChartVar
      requestY = breakoutsChartVar
    } else {
      requestX = [
        {
          ...nextBaseVar,
          selectedOptions: nextBaseVar.selectedOptions.map(o => ({
            ...o,
            selected: ['breakout', 'option'].includes(o.type),
          })),
        },
      ]
      requestY = responsesChartVar.concat(breakoutsChartVar)
      const baseVariables = [...xAxisVariables, ...yAxisVariables].filter(
        v => v.variableNk === nextBaseVar.variableNk
      )
      if (baseVariables.length > 1) {
        const otherBaseVar = baseVariables.find(bv => bv.resourceType !== nextBaseVar.resourceType)
        requestY.push({
          ...otherBaseVar,
          selectedOptions: otherBaseVar.selectedOptions.map(o => ({
            ...o,
            selected: ['breakout', 'option'].includes(o.type),
          })),
        })
      }
    }
    const validWeight = getValidWeightShape(getState())
    const showUnweightedBase = getShowUnweightedBase(getState())

    const request = setupDataFetchRequest({
      xAxisVariables: requestX,
      yAxisVariables: requestY,
      filters,
      elevatedKey,
      weighting: validWeight,
      showUnweightedBase,
    })
    const { studyId, datasetNk } = getDataset(getState())

    // Pull JWT from session so Pug can set auth
    const jwt = getState()?.session?.currentSession?.data?.jwt
    try {
      const { data, variables, groups, unweighted_groups } = await getAnalysisData(
        request,
        studyId,
        datasetNk,
        jwt
      )
      const mappedVariables = variables.map(variable => ({
        ...variable,
        questionName: variable.name,
        resourceType: variable.resource_type,
      }))

      const updatedFetchedData = transformAnalysisData(
        mappedVariables,
        data,
        groups,
        unweighted_groups
      )

      let suppressedMapping = {}
      const allVars = [...responsesChartVar, ...breakoutsChartVar]
      const adResultKeys = Object.keys(updatedFetchedData.data).map(k => k.split(','))
      allVars.forEach(axisV => {
        axisV.selectedOptions.forEach(o => {
          const cellId = buildCellId(axisV, o)
          suppressedMapping[o.id] = !adResultKeys.find(keyId => keyId.includes(cellId))
        })
      })

      dispatch(__addSuppressedOptions(variableNk, suppressedMapping, filters, nextBaseVar?.id))
    } catch (error) {
      console.error(error)
      dispatch(__setSuppressedOptionsError(variableNk, error))
      Sentry.captureException(
        new Error('Error creating suppressed option mapping', { cause: error })
      )
    }

    return breakoutsVar !== undefined ? [responsesVar, breakoutsVar] : [responsesVar]
  }

export const refetchAllSuppressedOptions =
  (forceRefetch = false) =>
  async (dispatch, getState) => {
    await Promise.all(
      getXAxisVariables(getState()).map(async ({ variableNk }) =>
        dispatch(fetchVariableWithSuppressedOptions({ variableNk, forceRefetch }))
      )
    )
    await Promise.all(
      getYAxisVariables(getState()).map(async ({ variableNk }) =>
        dispatch(fetchVariableWithSuppressedOptions({ variableNk, forceRefetch }))
      )
    )
  }

export const STAGE_ADD_FOR_AXIS = 'STAGE_ADD_FOR_AXIS'
export const stageAddForAxis = (axis = null) => ({
  type: STAGE_ADD_FOR_AXIS,
  axis,
})

export const GET_ANALYSIS_CHART_SUMMARY_LOADING = 'GET_ANALYSIS_CHART_SUMMARY_LOADING'
const __getAnalysisChartSummaryLoading = () => ({
  type: GET_ANALYSIS_CHART_SUMMARY_LOADING,
})

export const GET_ANALYSIS_CHART_SUMMARY_SUCCESS = 'GET_ANALYSIS_CHART_SUMMARY_SUCCESS'
const __getAnalysisChartSummarySuccess = summary => ({
  type: GET_ANALYSIS_CHART_SUMMARY_SUCCESS,
  summary,
})

export const GET_ANALYSIS_CHART_SUMMARY_ERROR = 'GET_ANALYSIS_CHART_SUMMARY_ERROR'
const __getAnalysisChartSummaryError = error => ({
  type: GET_ANALYSIS_CHART_SUMMARY_ERROR,
  error,
})

export const getAnalysisChartSummary =
  () =>
  async (dispatch, getState, { fetch }) => {
    if (
      isFetchingChartSummary(getState()) ||
      !getUserPermissions(getState()).includes('GPT_ANALYSIS_EXPLANATION')
    ) {
      return
    }

    dispatch(__getAnalysisChartSummaryLoading())

    const state = getSerializedChartState(getState())

    const response = await fetch(`${DALMATIAN_URL}/analysis_gpt/`, {
      method: 'POST',
      jsonBody: {
        content_type: 'linkedanalysis',
        content_id: state.state_id,
        state,
      },
    })

    if (!response.ok) {
      dispatch(__getAnalysisChartSummaryError(response.statusText))
      return
    }

    const data = await response.json()

    if (data.errors.length > 0) {
      dispatch(__getAnalysisChartSummaryError(data.errors))
    } else {
      dispatch(__getAnalysisChartSummarySuccess(data.response))
    }
  }

export const fetchAnalysisDataAction = generateReduxAction('FETCH_ANALYSIS_DATA')
export const fetchAnalysisData = (shouldDispatchLoad: boolean = true): Function => {
  return async (dispatch: Dispatch<*>, getState: Function) => {
    const { loading, success, error } = fetchAnalysisDataAction
    const suppressionOn = shouldSuppressNulls(getState())

    if (shouldDispatchLoad || suppressionOn) dispatch(loading())
    dispatch(clearStatTestingAndStopPoll())

    const statTestingEnabled = isStatTestingEnabled(getState())
    if (statTestingEnabled) {
      dispatch(statTestingPollTick({ loading: true, statsData: {} }))
    }

    if (isAutoAdjustBaseEnabled(getState())) {
      dispatch(refetchAllSuppressedOptions(true))
    }

    const request = getAnalysisDataFetchRequestBody(getState())
    const { studyId, datasetNk } = getDataset(getState())
    try {
      const fetchedData = await getAnalysisData(request, studyId, datasetNk)
      dispatch(success({ fetchedData, base: request.bases }))

      if (fetchedData.error) {
        dispatch(error(fetchedData.error))
      } else if (fetchedData.stats_warning) {
        dispatch(
          setStatTestingError(fetchedData.stats_warning.message_id, fetchedData.stats_warning)
        )
      } else if (statTestingEnabled && !fetchedData.stats_id) {
        // We requested stats but didn't get an ID, so something failed on BE
        dispatch(setStatTestingError('generic'))
      } else {
        dispatch(getAnalysisChartSummary())
        dispatch(startPollForStatTesting(true))
      }
    } catch (e) {
      dispatch(error(e))
      Sentry.captureException(new Error('Failed to download analysis data', { cause: e }))
    }
  }
}

export const SET_WEIGHTING = 'SET_WEIGHTING'
export function setWeight(weight: string): ActionT {
  return {
    type: SET_WEIGHTING,
    payload: { weight },
  }
}

export const setWeighting = (weight: string) => (dispatch, getState) => {
  dispatch(setWeight(weight))
  const handleTrack = getHandleTrack(getState())
  const xAxis = getXAxisVariables(getState())
  const yAxis = getYAxisVariables(getState())
  if (xAxis.length > 0 || yAxis.length > 0) {
    dispatch(fetchAnalysisData())
  }
  handleTrack('Selected Weight Variable', {
    weight,
  })
}

export const CLEAR_ANALYSIS_DATA = 'CLEAR_ANALYSIS_DATA'
export function clearAnalysisData(keepBase: boolean = false): ActionT {
  return {
    type: CLEAR_ANALYSIS_DATA,
    payload: { keepBase },
  }
}

export const ADD_NET_TO_VARIABLE = 'ADD_NET_TO_VARIABLE'
export function addNetToVariable(response, updatedVariable, fromFilterModal): ActionT {
  return {
    type: ADD_NET_TO_VARIABLE,
    payload: { response, updatedVariable, fromFilterModal },
  }
}

export const REMOVE_NET_FROM_VARIABLE = 'REMOVE_NET_FROM_VARIABLE'
export function removeNetFromVariable(variableNaturalKey, variableType, netId): ActionT {
  return {
    type: REMOVE_NET_FROM_VARIABLE,
    payload: { variableNaturalKey, variableType, netId },
  }
}

export const ADD_VARIABLES_TO_AXIS = 'ADD_VARIABLES_TO_AXIS'
export function addVariablesToAxisAction({
  axis,
  newVars,
  selected = { options: [], calculated: [], breakouts: [], nets: [], calculatedBreakout: [] },
  replacingPosition,
  defaultMean,
  setMeanPopUp,
  fromPageLoadWithXtab = false,
}) {
  if (typeof replacingPosition !== 'number' || replacingPosition < 0) {
    replacingPosition = -1
  }
  return {
    type: ADD_VARIABLES_TO_AXIS,
    payload: {
      axis,
      newVars,
      selected,
      replacingPosition,
      defaultMean,
      setMeanPopUp,
      fromPageLoadWithXtab,
    },
  }
}

export const SET_AXIS_VARIABLES = 'SET_AXIS_VARIABLES'
export function setAxisVariables(xAxisVariables, yAxisVariables) {
  return {
    type: SET_AXIS_VARIABLES,
    payload: {
      xAxisVariables,
      yAxisVariables,
    },
  }
}

export const SWAP_AXIS_VARIABLES = 'SWAP_AXIS_VARIABLES'
function __swapAxisVariablesAction(): ActionT {
  return {
    type: SWAP_AXIS_VARIABLES,
  }
}

export const swapAxisVariablesAction = () => dispatch => {
  dispatch(__swapAxisVariablesAction())
  dispatch(refetchAllSuppressedOptions(true))
}

export const REMOVE_VARIABLE_FROM_VAR_LIST = 'REMOVE_VARIABLE_FROM_VAR_LIST'
export function removeVariableFromVarListAction(variableNk: string): ActionT {
  return {
    type: REMOVE_VARIABLE_FROM_VAR_LIST,
    payload: {
      variableNk,
    },
  }
}

export const REMOVE_AXIS_VARIABLE = 'REMOVE_AXIS_VARIABLE'
export function removeAxisVariableAction(variableNk: string): ActionT {
  return {
    type: REMOVE_AXIS_VARIABLE,
    payload: {
      variableNk,
    },
  }
}

export function removeVariableThunk(variableNk: string) {
  return (dispatch, getState) => {
    const chartType = getChartType(getState())
    const currentXAxis = getXAxisVariables(getState())
    const currentYAxis = getYAxisVariables(getState())
    const currentBaseVariable = determineBaseVariable(currentXAxis, currentYAxis, chartType)

    dispatch(removeAxisVariableAction(variableNk))
    dispatch(removeVariableFromVarListAction(variableNk))
    if (currentBaseVariable.variableNk === variableNk) {
      dispatch(refetchAllSuppressedOptions(true))
    }
  }
}

export const TOGGLE_OPTION = 'TOGGLE_OPTION'
export function toggleOptionAction(
  id: string,
  axis: AxisT,
  optionId: string,
  optionType: string,
  updatedVars,
  isSelected
): ActionT {
  return {
    type: TOGGLE_OPTION,
    payload: {
      id,
      axis,
      optionId,
      optionType,
      updatedVars,
      isSelected,
    },
  }
}

export const toggleOptionThunk = (id, axis, optionId, optionType) => (dispatch, getState) => {
  const xAxisVariables = getXAxisVariables(getState())
  const yAxisVariables = getYAxisVariables(getState())
  const { updatedVars, isSelected } = toggleSelectionOption(
    id,
    axis === ROW ? xAxisVariables : yAxisVariables,
    optionId,
    optionType
  )
  dispatch(toggleOptionAction(id, axis, optionId, optionType, updatedVars, isSelected))
}

export const TOGGLE_ALL_OPTIONS = 'TOGGLE_ALL_OPTIONS'
export function toggleAllAction(id: string, axis: AxisT, updatedVars, someSelected): ActionT {
  return {
    type: TOGGLE_ALL_OPTIONS,
    payload: {
      id,
      axis,
      updatedVars,
      someSelected,
    },
  }
}

export const toggleAllOptionsThunk = (id, axis) => (dispatch, getState) => {
  const xAxisVariables = getXAxisVariables(getState())
  const yAxisVariables = getYAxisVariables(getState())
  const { updatedVars, someSelected } = toggleAllOptions(
    id,
    axis === ROW ? xAxisVariables : yAxisVariables
  )
  dispatch(toggleAllAction(id, axis, updatedVars, someSelected))
}

type nkPlusType = string // variable nk + variable type

export const SET_VARIABLE_POSITION = 'SET_VARIABLE_POSITION'
function __setVariablePosition(newXAxis: Array<nkPlusType>, newYAxis: Array<nkPlusType>): ActionT {
  return {
    type: SET_VARIABLE_POSITION,
    payload: {
      newXAxis,
      newYAxis,
    },
  }
}

export const setVariablePosition =
  (xAxisVariables, yAxisVariables) => async (dispatch, getState) => {
    const chartType = getChartType(getState())
    const currentXAxis = getXAxisVariables(getState())
    const currentYAxis = getYAxisVariables(getState())

    const currentBaseVariable = determineBaseVariable(currentXAxis, currentYAxis, chartType)
    const nextBaseVariable = determineBaseVariable(xAxisVariables, yAxisVariables, chartType)

    dispatch(__setVariablePosition(xAxisVariables, yAxisVariables))

    if (currentBaseVariable.id !== nextBaseVariable.id) {
      dispatch(refetchAllSuppressedOptions(true))
    }
  }

export const ADD_FILTER = 'ADD_FILTER'
export function addFilterAction(filterData: VariableFilterDataT): ActionT {
  return {
    type: ADD_FILTER,
    payload: { filterData },
  }
}

export const REMOVE_FILTERS = 'REMOVE_FILTERS'
export function removeFiltersAction(
  filtersToRemove: Array<FilterT>,
  resetFilters: boolean = false,
  clearFromVars: boolean = true
): ActionT {
  return {
    type: REMOVE_FILTERS,
    payload: { filtersToRemove, resetFilters, clearFromVars },
  }
}

export const setDefaultFiltersAction = generateReduxAction('SET_DEFAULT_FILTERS')

// params object will be stringified using queryString library and sent to back end
export const setDefaultFilters = (
  defaultFilters: Array<FilterT>,
  studyId: string,
  datasetName: string
): Function => {
  return (dispatch: Dispatch<*>) => {
    dispatch(setDefaultFiltersAction.loading())

    return new Promise((resolve: Function, reject: Function) => {
      const filterVars = defaultFilters.map(f => `${studyId}/${datasetName}/${f.questionName}`)
      return Promise.all(filterVars.map(v => getVariablesWithNets(v)))
        .then(varData => ({ varData: flatten(varData), defaultFilters }))
        .then(handleData(resolve, dispatch, setDefaultFiltersAction.success))
        .catch(handleError(reject, dispatch, setDefaultFiltersAction.error))
    })
  }
}

export const SET_SORT = 'SET_SORT'
function _setSortAction(sortState: SortStateT) {
  return {
    type: SET_SORT,
    payload: { sortState },
  }
}

export const setSortAction = (axis: AxisT, sortObj: SortKeyT) => (dispatch, getState) => {
  const handleTrack = getHandleTrack(getState())
  const sortState = getChartSortState(getState())

  const updatedState = { ...sortState, [axis]: sortObj }
  if (!isEqual(sortState, updatedState)) {
    dispatch(_setSortAction(updatedState))
    handleTrack('Analysis 2 Sort Toggle', {
      axis,
      sortVariables: sortObj.sortVariables.reduce(
        (a, c) =>
          `${a}, Variable:"${c.varId}" OptionID:"${c.optionId}" OptionType:"${c.optionType}"`,
        ''
      ),
      direction: sortObj.direction,
    })
  }
}

export const SET_CHART_DISPLAY_TYPE = 'SET_CHART_DISPLAY_TYPE'
export function setDisplayTypeAction(displayType: string): ActionT {
  return {
    type: SET_CHART_DISPLAY_TYPE,
    payload: {
      displayType,
    },
  }
}

export const SET_CHART_TYPE = 'SET_CHART_TYPE'
export function setChartTypeAction(chartType: string, interval?: string): ActionT {
  return {
    type: SET_CHART_TYPE,
    payload: {
      chartType,
      interval: interval || '',
    },
  }
}

export const updateChartTypeThunk =
  (nextChartType, onAddIntervalVar) => async (dispatch, getState) => {
    const state = getState()
    const currChartType = getChartType(state)
    const variables = getVariables(state)
    const xAxisVariables = getXAxisVariables(state)
    const yAxisVariables = getYAxisVariables(state)
    const datasetVariables = getDatasetVariables(state)
    const singleAxis = yAxisVariables.length === 0 || xAxisVariables.length === 0
    // download variables if necessary
    let axisIntervalId = ''
    if (nextChartType === 'line') {
      const intervalVar = getFirstInterval(variables)
      const axisInterval = intervalVar
        ? [...xAxisVariables, ...yAxisVariables].find(av => av.id === intervalVar.id)
        : null
      if (!axisInterval) {
        // If no interval question is currently selected, fetch one from the dataset
        const interval = getFirstInterval(datasetVariables)
        if (!interval) {
          dispatch(fetchVariableAction.error('There are no interval questions in this study.'))
          return
        }
        try {
          const intervalVars = await dispatch(fetchVariableThunk(interval.nk, true))
          await dispatch(clearAnalysisData())
          const selected = {
            options: intervalVars[0].options.map(o => o.id),
            nets: [],
            breakouts: [],
            calculated: [],
            calculatedBreakout: [],
          }
          await dispatch(
            addVariablesToAxisAction({ axis: ROW, newVars: intervalVars.slice(0, 1), selected })
          )
          axisIntervalId = intervalVars[0].id
          await dispatch(setChartTypeAction(nextChartType, axisIntervalId))
          dispatch(fetchAnalysisData())
          if (typeof onAddIntervalVar === 'function') onAddIntervalVar(intervalVars[0])
          return
        } catch (e) {
          Sentry.captureException(new Error('Error in handleChangeChartType', { cause: e }))
          dispatch(fetchAnalysisDataAction.error(e))
          return
        }
      } else {
        axisIntervalId = axisInterval.id
      }
    }

    await dispatch(setChartTypeAction(nextChartType, axisIntervalId))

    if (yAxisVariables.length + xAxisVariables.length === 0) return

    if (
      currChartType === 'line' &&
      [...xAxisVariables, ...yAxisVariables].length > VIS_CHART_VAR_LIMIT &&
      nextChartType !== 'spreadsheet'
    ) {
      // we added an interval variable and now the total var count exceeds vis chart
      // the limit, so remove it so we can go back to the other visual chart types
      await dispatch(clearAnalysisData())
      await dispatch(removeVariableThunk(xAxisVariables[0].variableNk))
      if (yAxisVariables.length > 1) {
        const xAxisVarIds = [yAxisVariables[0].id]
        const yAxisVarIds = yAxisVariables.slice(1).map(v => v.id)
        await dispatch(setVariablePosition(xAxisVarIds, yAxisVarIds))
      }
      dispatch(refetchAllSuppressedOptions(true))
      dispatch(fetchAnalysisData())
    } else if (nextChartType === 'line' && isChartStacked(currChartType)) {
      // this could be optimized, but let's just get it all working rn
      dispatch(refetchAllSuppressedOptions(true))
      dispatch(fetchAnalysisData())
    } else if (singleAxis && isChartInverted(currChartType) !== isChartInverted(nextChartType)) {
      dispatch(fetchAnalysisData(false))
      dispatch(startPollForStatTesting())
    }
  }

export const updateChartStateFromUrlAction = generateReduxAction('UPDATE_CHART_STATE_FROM_URL')

/**
 * Populate Redux state based on the supplied state ID from the URL.
 *
 * @param {String} stateId
 */
export const updateChartStateFromUrl =
  stateId =>
  async (dispatch, getState, { fetch }) => {
    const { studyId, datasetNk } = getDataset(getState())

    dispatch(updateChartStateFromUrlAction.loading())

    const response = await fetch(
      encodeURI(`${DALMATIAN_URL}/analysis_states/${studyId}/${datasetNk}/${stateId}/`)
    )

    if (!response.ok) {
      dispatch(updateChartStateFromUrlAction.error(response))
      return
    }

    const stateData = await response.json()
    const {
      state: { filters, xAxisVariables, yAxisVariables },
    } = stateData

    // get the nks of the questions/variables we need to fetch
    const filterVars = filters.map(f => `${studyId}/${datasetNk}/${f.questionName}`)
    const axisVars = [...yAxisVariables, ...xAxisVariables].map(v => v.variableNk)
    const allVars = [...new Set([...filterVars, ...axisVars])]

    // ok...yes this is goofy...but we need to fetch all the questions that make
    // up the axis vars and filters, and map them to variables.  we will be updating
    // both the chartConfig and the Variables redux states
    try {
      const varData = await Promise.all(allVars.map(v => getVariablesWithNets(v)))
      dispatch(updateChartStateFromUrlAction.success({ varData: flatten(varData), stateData }))
    } catch (error) {
      dispatch(updateChartStateFromUrlAction.error(error))
    }
  }

export const TOGGLE_SHOW_LABELS = 'TOGGLE_SHOW_LABELS'
export function toggleShowLabels(showLabels?: boolean): ActionT {
  return {
    type: TOGGLE_SHOW_LABELS,
    payload: {
      showLabels,
    },
  }
}

export const TOGGLE_HIDE_LOW_BASE = 'TOGGLE_HIDE_LOW_BASE'
export function toggleHideLowBase(hideLowBase?: boolean): ActionT {
  return {
    type: TOGGLE_HIDE_LOW_BASE,
    payload: {
      hideLowBase,
    },
  }
}

export const TOGGLE_SHOW_UNWEIGHTED_BASE = 'TOGGLE_SHOW_UNWEIGHTED_BASE'
export const toggleUnweightedBase = showUnweightedBase => ({
  type: TOGGLE_SHOW_UNWEIGHTED_BASE,
  showUnweightedBase,
})

export const setUnweightedBaseFromPerm = () => (dispatch, getState) => {
  const hasUnweightedBasePerm = getHasUnweightedBasePerm(getState())
  dispatch(toggleUnweightedBase(hasUnweightedBasePerm))
}

export const TOGGLE_AUTO_ADJUST_BASE = 'TOGGLE_AUTO_ADJUST_BASE'
export function toggleAutoAdjustBase(autoAdjustBaseEnabled?: boolean): ActionT {
  return {
    type: TOGGLE_AUTO_ADJUST_BASE,
    payload: {
      autoAdjustBaseEnabled,
    },
  }
}

export function toggleAutoAdjustBaseThunk(autoAdjustBaseEnabled?: boolean) {
  return async (dispatch, getState) => {
    const currentEnabled = isAutoAdjustBaseEnabled(getState())
    const toEnable = autoAdjustBaseEnabled ?? !currentEnabled
    await dispatch(toggleAutoAdjustBase(toEnable))
  }
}

export const TOGGLE_NULL_SUPPRESSION = 'TOGGLE_NULL_SUPPRESSION'
export function toggleNullSuppression(nullSuppressionEnabled) {
  return {
    type: TOGGLE_NULL_SUPPRESSION,
    nullSuppressionEnabled,
  }
}

export const SET_STAT_TESTING_CONFIDENCE = 'SET_STAT_TESTING_CONFIDENCE'
/**
 * @param {boolean} showStatTesting - Enables stat testing
 * @param {Array<number>} confidence - Confidence interval for stat testing, i.e. [95] or [90]
 */
export function setStatTestingConfidence(showStatTesting, confidence) {
  return {
    type: SET_STAT_TESTING_CONFIDENCE,
    payload: {
      showStatTesting,
      confidence,
    },
  }
}

export function setStatsThunk(confidence: number) {
  return async (dispatch, getState) => {
    const prevConfidence = getStatTestConfidence(getState())
    if (!confidence) {
      dispatch(setStatTestingConfidence(false, prevConfidence))
    } else {
      if (prevConfidence[0] !== confidence) {
        await dispatch(clearStatTesting())
      }
      await dispatch(setStatTestingConfidence(true, [confidence]))
      dispatch(startPollForStatTesting())
    }
  }
}

type StatTestingDataT = {
  stats_id: string,
  completed_count: number,
  comparison_count: number,
  stats_data: Object,
  purged: boolean,
  time_from_received: string,
}

export const STAT_TESTING_POLL_TICK = 'STAT_TESTING_POLL_TICK'
function statTestingPollTick(data: StatTestingDataT): ActionT {
  return {
    type: STAT_TESTING_POLL_TICK,
    payload: data,
  }
}

export const SET_STAT_TESTING_INTERVAL_ID = 'SET_STAT_TESTING_INTERVAL_ID'
function setStatTestingIntervalId(intervalId: number): ActionT {
  return {
    type: SET_STAT_TESTING_INTERVAL_ID,
    payload: intervalId,
  }
}

export const STOP_POLL_FOR_STAT_TESTING = 'STOP_POLL_FOR_STAT_TESTING'
function stopPollForStatTesting(intervalId: number): ActionT {
  clearInterval(intervalId)
  return { type: STOP_POLL_FOR_STAT_TESTING }
}

export const SET_STAT_TESTING_ERROR = 'SET_STAT_TESTING_ERROR'
export function setStatTestingError(messageKey: string, data: Object = {}): ActionT {
  return {
    type: SET_STAT_TESTING_ERROR,
    payload: {
      messageKey,
      data,
    },
  }
}

export const CLEAR_STAT_TESTING = 'CLEAR_STAT_TESTING'
function clearStatTesting(): ActionT {
  return { type: CLEAR_STAT_TESTING }
}

function clearStatTestingAndStopPoll() {
  return (dispatch, getState) => {
    const intervalId = getStatTestingPollIntervalId(getState())
    dispatch(stopPollForStatTesting(intervalId))
    dispatch(clearStatTesting())
  }
}

export const startPollForStatTesting =
  (triggeredFromAD = false) =>
  (dispatch, getState, { fetch }) => {
    const variables = getVariables(getState())
    if (!variables.length) return

    const { statsId } = getAnalysisDataSelector(getState())
    const showStatTesting = isStatTestingEnabled(getState())

    if (!triggeredFromAD && showStatTesting && !statsId) {
      // stats were not requested initially, we need to re-request AD with stats
      // TODO: cyclical dependency, so we should combine these two thunks
      dispatch(fetchAnalysisData(false))
      return
    }

    const { studyId, datasetNk } = getDataset(getState())
    const lastStatsId = getFetchedStatTestingId(getState())

    if (showStatTesting && statsId && lastStatsId !== statsId) {
      dispatch(statTestingPollTick({ loading: true, statsData: {} }))

      const intervalId = setInterval(async () => {
        try {
          const response = await fetch(
            `${COLLIE_URL}/datasets/${studyId}/${datasetNk}/stats-data/${statsId}/`
          )

          if (!response.ok) {
            dispatch(stopPollForStatTesting(intervalId))
            dispatch(setStatTestingError('generic'))
            return
          }

          const data = await response.json()
          dispatch(statTestingPollTick({ ...data, stats_id: statsId }))

          if (data.purged || (data.stats_data && data.completed_count === data.comparison_count)) {
            dispatch(stopPollForStatTesting(intervalId))
          }
        } catch (error) {
          dispatch(stopPollForStatTesting(intervalId))
          dispatch(setStatTestingError('generic'))
          Sentry.captureException(new Error('Failed to download stat sig data', { cause: error }))
        }
      }, STATS_TESTING_POLL_RATE)

      const previousIntervalId = getStatTestingPollIntervalId(getState())
      if (previousIntervalId) dispatch(stopPollForStatTesting(intervalId))
      dispatch(setStatTestingIntervalId(intervalId))
    }
  }

/**
 * Export current analysis.
 *
 * @param {String} exportType - 'ppt' for Powerpoint, 'xlsx' for Excel
 */
export const exportAnalysis =
  exportType =>
  async (dispatch, getState, { fetch }) => {
    const chartState = getSerializedChartState(getState())
    const handleTrack = getHandleTrack(getState())

    if (exportType === 'ppt') {
      handleTrack('Download PPTX button clicked', chartState)
    } else if (exportType === 'xlsx') {
      handleTrack('Generate Analysis XLSX') // For backwards compatibility
      handleTrack('Download XLSX button clicked', chartState)
    } else {
      throw new Error('Invalid export type')
    }

    const response = await fetch(`${DALMATIAN_URL}/export${exportType}/`, {
      method: 'POST',
      jsonBody: {
        content_type: 'analysis',
        content_id: chartState.state_id,
        state: chartState,
      },
    })

    if (!response.ok) {
      throw response
    }

    return response.blob()
  }

/**
 * Save Analysis state to the backend, keyed and fetchable by the state ID.
 *
 * This is used to pull the Analysis state from query params so links are shareable
 * between users and preserve state on refresh.
 */
export const saveChartState =
  () =>
  async (dispatch, getState, { fetch }) => {
    const { studyId, datasetNk } = getDataset(getState())
    const { state_id: stateId, state } = getSerializedChartState(getState())

    ;[...state.xAxisVariables, ...state.yAxisVariables].forEach(axisVar => {
      const [vStudy, vDataset] = axisVar.variableNk.split('/')
      const errors = []
      if (vStudy !== studyId) {
        errors.push(`Variable study name "${vStudy}" does not match analysis study "${studyId}"`)
      }
      if (vDataset !== datasetNk) {
        errors.push(
          `Variable dataset name "${vDataset}" does not match analysis dataset "${datasetNk}"`
        )
      }
      if (errors.length) {
        const errMessage = errors.join(' AND ')
        Sentry.captureException(
          new Error(`User has created an invalid analysis state - ${errMessage}`, {
            stateId,
            state,
            datasetNk,
            studyId,
          })
        )
      }
    })

    return fetch(encodeURI(`${DALMATIAN_URL}/analysis_states/${studyId}/${datasetNk}/`), {
      method: 'POST',
      jsonBody: {
        state_id: stateId,
        state,
      },
    })
  }

/** Used to redirect A1 URLs to A2. Sets partial A1 state to hydrate into A2 state. */
export const SET_A1_STATE_HUSK = 'SET_A1_STATE_HUSK'
export function setA1StateHusk(chartState: Object | null): ActionT {
  return {
    type: SET_A1_STATE_HUSK,
    payload: {
      chartState,
    },
  }
}

const createStateVar = (question, nets) => {
  const newOptionVariable: VariableT = createOptionVar(question, nets)

  if (question.is_grid && question.grid_breakouts) {
    const newBreakoutVariable: VariableT = createBreakoutVar(question, nets)
    return [newOptionVariable, newBreakoutVariable]
  }
  return [newOptionVariable]
}

/**
 * If A1 state husk is present (set by Customer routing), fetch necessary
 * variables and translate that state into an A2 state.
 */
export function hydrateA1StateHusk() {
  return async (dispatch: Dispatch<*>, getState: Function) => {
    const a1Husk = getA1Husk(getState())
    if (!a1Husk || !a1Husk.mainQuestionID) return false

    dispatch(updateChartStateFromUrlAction.loading())

    const { studyId, datasetNk } = getDataset(getState())
    const filterQueries = a1Husk.filters ? deserializeFilterString(a1Husk.filters) : []
    let filterQuestions = []
    let filterNets = []
    let refQs = {}

    if (filterQueries) {
      filterQuestions = flatten(
        await Promise.all(
          filterQueries.map(({ filter }) => getQuestion(`${studyId}/${datasetNk}/${filter}`))
        )
      )
      filterNets = flatten(
        await Promise.all(
          filterQueries.map(({ filter }) => getQuestionNets(`${studyId}/${datasetNk}/${filter}`))
        )
      ).map(({ results }) => results ?? [])

      refQs = filterQuestions.reduce((acc, filterVar) => {
        acc[filterVar.question_name] = filterVar
        return acc
      }, {})
    }

    const mainQuestionNk = `${studyId}/${datasetNk}/${decodeURIComponent(a1Husk.mainQuestionID)}`
    const mainQuestion = await getQuestion(mainQuestionNk)
    const mainQuestionNets = (await getQuestionNets(mainQuestionNk))?.results ?? []

    let appliedNets = []
    if (Array.isArray(a1Husk.nets)) {
      appliedNets = a1Husk.nets
    } else if (a1Husk.nets ?? false) {
      appliedNets = [a1Husk.nets]
    }

    const filterVars = flatten(filterQuestions.map((q, i) => createStateVar(q, filterNets[i])))

    let xtabs = []
    let xtabNets = []
    if (a1Husk.xtabs) {
      const parsedXtabs = flatten(JSON.parse(a1Husk.xtabs).map(Object.values))
      xtabs = flatten(
        await Promise.all(parsedXtabs.map(name => getQuestion(`${studyId}/${datasetNk}/${name}`)))
      )
      xtabNets = flatten(
        await Promise.all(
          parsedXtabs.map(name => getQuestionNets(`${studyId}/${datasetNk}/${name}`))
        )
      ).map(({ results }) => results ?? [])
    }

    const { state } = convertA1toA2Url(
      a1Husk.chartType,
      studyId,
      datasetNk,
      Number(a1Husk.flipped),
      Number(a1Husk.percent),
      refQs,
      filterQuestions,
      mainQuestion,
      filterQueries,
      xtabs,
      a1Husk.hiddenResponseOptions,
      a1Husk.hiddenFirstXtabResponses,
      a1Husk.hiddenSecondXtabResponses,
      a1Husk.hiddenGridBreakouts,
      appliedNets,
      mainQuestionNets,
      a1Husk.sortXValues,
      a1Husk.labels === '1',
      Number(a1Husk.low_base),
      Number(a1Husk.show_means)
    )

    const xtabVars = flatten(xtabs.map((q, i) => createStateVar(q, xtabNets[i])))

    dispatch(setA1StateHusk({}))
    dispatch(
      updateChartStateFromUrlAction.success({
        varData: flatten([
          ...createStateVar(mainQuestion, mainQuestionNets),
          ...filterVars,
          ...xtabVars,
        ]),
        stateData: { state },
      })
    )
    dispatch(fetchAnalysisData())
  }
}

export const SET_VARIABLE_SEARCH_QUERY = 'SET_VARIABLE_SEARCH_QUERY'
export const setVariableSearchQuery = searchQuery => ({
  type: SET_VARIABLE_SEARCH_QUERY,
  payload: { searchQuery },
})
