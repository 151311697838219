// extracted by mini-css-extract-plugin
export const actionBar = "VariableOptionsSection-module__action-bar--rHU+P";
export const allSuppressed = "VariableOptionsSection-module__all-suppressed--fGaw8";
export const baseTiming = "125ms";
export const baseTimingInt = "125";
export const body = "VariableOptionsSection-module__body--b-cud";
export const borderRadius = "3px";
export const charcoal = "#2d2e2e";
export const cloudGrey = "#c8cdcf";
export const content = "VariableOptionsSection-module__content--KbCJt";
export const controls = "VariableOptionsSection-module__controls--jAV0I";
export const createVariableMenuContainer = "VariableOptionsSection-module__create-variable-menu-container--MFnfj";
export const divider = "VariableOptionsSection-module__divider--Cnw9K";
export const doveGrey = "#f1f3f5";
export const header = "VariableOptionsSection-module__header--+DJm6";
export const khBlue = "#04b1e0";
export const khBlueDark = "#0376ae";
export const khBlueLight = "#7de1f4";
export const lemon = "#fae436";
export const lemonDark = "#e3cd02";
export const lemonLight = "#fff284";
export const lime = "#05c150";
export const limeDark = "#068230";
export const limeLight = "#65ec83";
export const listHeader = "VariableOptionsSection-module__list-header--RT89R";
export const navy = "#052976";
export const navyDark = "#04173f";
export const navyLight = "#82b1f6";
export const noVariables = "VariableOptionsSection-module__no-variables--4CVtO";
export const scarlet = "#e9062b";
export const scarletDark = "#9d050e";
export const scarletLight = "#f5726d";
export const searchContainer = "VariableOptionsSection-module__search-container--GqfHw";
export const selectedVariable = "VariableOptionsSection-module__selected-variable--+kemK";
export const selectedVariableContainer = "VariableOptionsSection-module__selected-variable-container--S28kV";
export const stateOverlay = "VariableOptionsSection-module__state-overlay--ygkuv";
export const stormGrey = "#969b9c";
export const title = "VariableOptionsSection-module__title--MkCGu";
export const topContent = "VariableOptionsSection-module__top-content--F0pgI";
export const variableBreakouts = "VariableOptionsSection-module__variable-breakouts--OrIoK";
export const variableIsGrid = "VariableOptionsSection-module__variable-is-grid--Q1zPb";
export const variableNotGrid = "VariableOptionsSection-module__variable-not-grid--mBBt-";
export const variableNotSelected = "VariableOptionsSection-module__variable-not-selected--fB7D3";
export const variableOptions = "VariableOptionsSection-module__variable-options--2dI5M";
export const variableResults = "VariableOptionsSection-module__variable-results--iSMVU";
export const variableSelected = "VariableOptionsSection-module__variable-selected--xBi-S";
export const violet = "#d401f8";
export const violetDark = "#9003a8";
export const violetLight = "#e77ef9";
export const white = "#fff";