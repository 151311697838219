import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import classnames from 'classnames/bind'
import { FormattedMessage } from 'react-intl'
import { Icon } from '@knowledgehound/laika'

import { getStatTestingRequestProgress } from 'store/DatasetSelectors'
import * as styles from './StatTestingProgress.module.scss'

const cx = classnames.bind(styles)

export default function StatTestingProgress() {
  const { status, messageId, messageData } = useSelector(
    getStatTestingRequestProgress,
    shallowEqual
  )

  return (
    <div className={cx('statTestingProgressBar', { error: status === 'error' })}>
      <div className={cx('icon', status)}>
        <Icon icon={status === 'processing' ? 'sync' : 'stats'} size="small" color="inherit" />
      </div>
      <div>
        <FormattedMessage
          id={messageId}
          values={messageData}
          defaultMessage="Stat testing was unable to run, try again by adding or removing variables."
        />
      </div>
    </div>
  )
}
