// @flow
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, batch } from 'react-redux'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { isEqual, debounce } from 'lodash'
import _throttle from 'lodash/throttle'
import * as Sentry from '@sentry/react'
import { Tooltip, withCookie } from '@knowledgehound/laika'

import {
  stageAddForAxis,
  swapAxisVariablesAction,
  setVariablePosition,
  updateChartTypeThunk,
  toggleAllOptionsThunk,
  toggleOptionThunk,
} from 'store/DatasetActions'
import {
  getChartConfigError,
  getEmptyVariable,
  getChartType,
  getXAxisVariables,
  getYAxisVariables,
  getFilters,
  getIsEmbedded,
  getDataset,
  getVariables,
} from 'store/DatasetSelectors'
import { VIS_CHART_VAR_LIMIT, ROW, COLUMN } from 'store/constants'
import * as filterNetDuck from 'store/FilterNetDuck'
import { getVariable, determineNewVarLayout } from '../Analysis2Utils'
import { isChartStacked, isChartInverted } from '../chart/AnalysisChartUtils'
import AnalysisVariableCard from './AnalysisVariableCard'
import EmptyVariableCard from '../VariableCard/EmptyVariableCard'
import VariableCardDragLayer from './VariableCardDragLayer'
import CreateNets from '../CreateNets/CreateNets'
import AddVariableSlider from './AddVariableSlider'
import ChartTypeSelector from './ChartTypeSelector'
import SidebarHeader from './SidebarHeader'
import SwapTranspose from './SwapTranspose'
import AxisInfoIcon from './AxisInfoIcon'
import { PoweredBy } from './PoweredBy'

/** type imports */
import type { Node } from 'react'
import type { Dispatch } from 'redux'
import type { DatasetQuestionT } from '../types/DatasetsTypes.type'
import type {
  AxisT,
  AxisVariableT,
  VariableT,
  VariableListT,
  OptionKeyT,
  SelectedRequestNumT,
  NetT,
  DatasetT,
  ChartTypeT,
  FilterT,
} from './store/Dataset.type'

import * as styles from './AnalysisSidebar.module.scss'

type PropsT = {
  dispatch: Dispatch<*>,
  isFetching: boolean,
  chartType: ChartTypeT,
  xAxisVariables: Array<AxisVariableT>,
  yAxisVariables: Array<AxisVariableT>,
  variables: VariableListT,
  filters: Array<FilterT>,
  dataset: DatasetT,
  isEmbedded: boolean,
  rdmComplete: boolean,
  email: string,
  baseVariable: { id: string, label: string },
  handleTrack: Function,
  handleRemoveVariable: Function,
  onVariableAdd: Function,
  handleEditFilter?: Function,
  onDeleteNet?: Function,
  fetchAnalysisData: Function,
  setChartLoadingState: Function,
  showStatTesting: boolean,
  autoAdjustBaseEnabled: boolean,
  statsError: boolean,
  meanPopUpVarId: string,
  clearMeanPopUp: Function,
  addFilterModalOpen: boolean,
  cookieValue: string,
  setCookieValue: Function,
}

type StateT = {
  isDismissibleTooltipOpen: boolean,
  isDraggingVariable: boolean,
  draggedVariable: { axis: AxisT, id: string },
  isCreateVariableOpen: boolean,
  createVariableAxis: AxisT,
  cardExpanded: Map<string, boolean>,
  isCreateNetsOpen: boolean,
  variableToReplace: AxisVariableT | null,
  selectedVariables: Array<VariableT>,
  xAxisDroppables: Array<AxisVariableT>,
  yAxisDroppables: Array<AxisVariableT>,
}

const VARIABLE_MAX = 6
const AXIS_VARIABLE_MAX = 5

const CHART_TYPE_MAP = {
  bar: 'Bar',
  column: 'Column',
  line: 'Line',
  spreadsheet: 'Spreadsheet',
  stackedBar: 'Stacked Bar',
  stackedColumn: 'Stacked Column',
}

class AnalysisSidebar extends Component<PropsT, StateT> {
  constructor(props: PropsT) {
    super(props)
    this.state = {
      isDismissibleTooltipOpen: false,
      isDraggingVariable: false,
      draggedVariable: { id: '', axis: '' },
      isCreateVariableOpen: false,
      selectedVariables: [],
      createVariableAxis: ROW,
      cardExpanded: new Map(),
      isCreateNetsOpen: false,
      variableToReplace: null,
      xAxisDroppables: this.props.xAxisVariables,
      yAxisDroppables: this.props.yAxisVariables,
    }
  }

  static contextTypes = {
    sendNotification: PropTypes.func,
  }

  componentDidUpdate(prevProps: PropsT) {
    const { xAxisVariables, yAxisVariables, emptyVar } = this.props
    if (
      prevProps.xAxisVariables !== xAxisVariables ||
      prevProps.yAxisVariables !== yAxisVariables
    ) {
      if (emptyVar) {
        // If a variable is empty, it should expand for correcttion
        const newCardExpanded = new Map(this.state.cardExpanded)
        this.setState({
          xAxisDroppables: [...xAxisVariables],
          yAxisDroppables: [...yAxisVariables],
          cardExpanded: newCardExpanded.set(emptyVar.id, true),
        })
      } else {
        this.setState({
          xAxisDroppables: [...xAxisVariables],
          yAxisDroppables: [...yAxisVariables],
        })
      }
    }
  }

  getAxisText = (axis: AxisT) => {
    const { chartType } = this.props
    return chartType === 'spreadsheet'
      ? axis === ROW
        ? 'row'
        : 'column'
      : axis === ROW
      ? 'category'
      : 'series'
  }

  getAxisLimit = (axis: AxisT, chartType: ChartTypeT) => {
    if (isChartStacked(chartType) && axis === COLUMN) return 1
    if (chartType === 'line' && axis === ROW) return 1
    return AXIS_VARIABLE_MAX
  }

  addToAxisEnabled = (axis: AxisT) => {
    const { isFetching, rdmComplete, chartType, xAxisVariables, yAxisVariables } = this.props
    const axisVars = axis === ROW ? xAxisVariables : yAxisVariables
    const totalRowCol = xAxisVariables.length + yAxisVariables.length

    if (
      isFetching ||
      !rdmComplete ||
      axisVars.length >= this.getAxisLimit(axis, chartType) ||
      totalRowCol >= VARIABLE_MAX
    )
      return false

    return !(chartType !== 'spreadsheet' && totalRowCol >= VIS_CHART_VAR_LIMIT)
  }

  moveToAxisEnabled = (axis: AxisT) => {
    const { isFetching, rdmComplete, chartType, xAxisVariables, yAxisVariables } = this.props
    const { draggedVariable } = this.state
    const axisVars = axis === ROW ? xAxisVariables : yAxisVariables

    return !(
      isFetching ||
      !rdmComplete ||
      (draggedVariable.axis !== axis && axisVars.length >= this.getAxisLimit(axis, chartType)) ||
      (chartType === 'line' && axis === ROW)
    )
  }

  handleReplaceVariable = (variableToReplace: AxisVariableT, axis: AxisT) => {
    const { handleTrack } = this.props

    handleTrack('Replace variable clicked')
    this.props.dispatch(stageAddForAxis(axis))
    this.setState({
      isCreateVariableOpen: true,
      selectedVariables: [],
      isCreateNetsOpen: false,
      createVariableAxis: axis,
      variableToReplace: variableToReplace,
      isDismissibleTooltipOpen: false,
    })
  }

  handleAddVariable = (axis: AxisT) => {
    const { handleTrack } = this.props
    this.props.dispatch(stageAddForAxis(axis))
    this.setState({
      createVariableAxis: axis,
      isCreateVariableOpen: true,
      selectedVariables: [],
      isCreateNetsOpen: false,
      isDismissibleTooltipOpen: false,
    })
    handleTrack('Add variable clicked')
  }

  findBaseVar = () => {
    const { chartType, xAxisVariables, yAxisVariables } = this.props

    const { variableToReplace, createVariableAxis, selectedVariables } = this.state

    let xAxis = [...xAxisVariables]
    let yAxis = [...yAxisVariables]
    let replacingPosition = -1
    if (variableToReplace) {
      const axisVars = createVariableAxis === 'ROW' ? xAxis : yAxis
      const index = axisVars.findIndex(v => v.id === variableToReplace.id)
      replacingPosition = index
      if (createVariableAxis === 'ROW') {
        xAxis = xAxis.filter(v => v.variableNk !== variableToReplace.variableNk)
      } else {
        yAxis = yAxis.filter(v => v.variableNk !== variableToReplace.variableNk)
      }
    }

    const { baseVar } = determineNewVarLayout({
      xAxisVariables: xAxis,
      yAxisVariables: yAxis,
      axis: createVariableAxis,
      newVars: selectedVariables,
      replacingPosition,
      selectedNets: [],
      options: [],
      breakouts: [],
      calculated: [],
      calculatedBreakout: [],
      chartType,
    })

    return baseVar
  }

  onClickVariable = (selectedVariables: Array<VariableT>) => {
    this.setState({ selectedVariables }, () => {
      const baseVar = this.findBaseVar()
      if (
        selectedVariables[0].id === baseVar.id &&
        selectedVariables[0].fundamentalQuestionType.includes('Numeric')
      ) {
        this.onVariableSubmit(selectedVariables[0].variableNk, this.state.createVariableAxis, {
          options: [],
          calculated: ['__mean__'],
          breakouts: [],
          nets: [],
          calculatedBreakout: [],
        })
      }
    })
  }

  onVariableSubmit = (
    variable: DatasetQuestionT,
    axis: AxisT,
    selectedOptions: SelectedRequestNumT
  ) => {
    const variableToReplace = this.state.variableToReplace
    this.props.dispatch(stageAddForAxis(null))
    this.setState({
      isCreateVariableOpen: false,
      selectedVariables: [],
      variableToReplace: null,
    })
    if (variableToReplace) {
      this.props.onVariableAdd(
        variable,
        axis,
        selectedOptions,
        true,
        variableToReplace.variableNk,
        variableToReplace.resourceType
      )
    } else {
      this.props.onVariableAdd(variable, axis, selectedOptions, false)
    }
  }

  handleSwapRowsColumns = async () => {
    batch(() => {
      this.props.dispatch(swapAxisVariablesAction())
      this.props.fetchAnalysisData()
    })
    this.props.handleTrack('Swap rows/columns clicked', {})
    if (this.state.isDismissibleTooltipOpen) {
      this.setState({
        isDismissibleTooltipOpen: false,
      })
    }
  }

  handleDragStart = (id: string, axis: AxisT) => {
    const { xAxisVariables, yAxisVariables } = this.props

    if (!this.state.isDraggingVariable) {
      this.props.handleTrack('Drag and drop start', {
        num_row_variables: xAxisVariables.length,
        num_col_variables: yAxisVariables.length,
      })

      this.setState({
        isDraggingVariable: true,
        draggedVariable: { id, axis },
        isDismissibleTooltipOpen: false,
      })
    }
  }

  handleDragEnd = async () => {
    const { xAxisVariables, yAxisVariables } = this.props
    this.setState({
      xAxisDroppables: [...xAxisVariables],
      yAxisDroppables: [...yAxisVariables],
      isDraggingVariable: false,
      draggedVariable: { id: '', axis: '' },
    })
  }

  switchVariablePositions = async (
    dragId: string,
    dragAxis: AxisT,
    targetId: string,
    targetAxis: AxisT
  ) => {
    const { chartType } = this.props
    const { xAxisDroppables, yAxisDroppables } = this.state
    const { xAxisVariables, yAxisVariables } = this.props
    const droppables = [...xAxisDroppables, ...yAxisDroppables]
    const dragPos = droppables.findIndex(v => v.id === dragId)
    const targetPos = droppables.findIndex(v => v.id === targetId)
    const dragVar = { ...droppables[dragPos], axis: targetAxis }
    const moveToTargetEnabled = this.moveToAxisEnabled(targetAxis)
    const axisLimit = this.getAxisLimit(targetAxis, chartType)

    if (!moveToTargetEnabled) {
      // bump overflow to the opposite axis to prevent too many cars on a single axis
      const indexToBump =
        targetAxis === ROW ? axisLimit - 1 : xAxisDroppables.length - 1 + axisLimit
      droppables[indexToBump] = {
        ...droppables[indexToBump],
        axis: targetAxis === ROW ? COLUMN : ROW,
      }
    }

    droppables.splice(dragPos, 1)
    droppables.splice(targetPos, 0, dragVar)

    const updatedX = []
    const updatedY = []
    droppables.forEach(v => {
      if (v.axis === ROW) {
        updatedX.push(v)
      } else {
        updatedY.push(v)
      }
    })

    // this is to handle the situation where we had previously bumped a
    // variable to a different axis, but the user moved the drag var back
    // to its original axis and we want to send the bumped variable back
    if (
      (dragAxis === ROW && updatedX.length > xAxisVariables.length) ||
      (dragAxis === COLUMN && updatedY.length > yAxisVariables.length)
    ) {
      this.setState({
        xAxisDroppables: xAxisVariables,
        yAxisDroppables: yAxisVariables,
      })
    } else {
      this.setState({
        xAxisDroppables: updatedX,
        yAxisDroppables: updatedY,
      })
    }
  }

  handleHoverVariable = _throttle(
    (dragId: string, dragAxis: AxisT, targetId: string, targetAxis: AxisT) => {
      if (!this.state.isDraggingVariable) return
      this.switchVariablePositions(dragId, dragAxis, targetId, targetAxis)
    },
    200,
    {
      leading: true,
      trailing: false,
    }
  )

  clearHover = () => {
    this.setState({
      xAxisDroppables: this.props.xAxisVariables,
      yAxisDroppables: this.props.yAxisVariables,
    })
  }

  getVarIds = (axisVariables: Array<AxisVariableT>) => {
    return axisVariables.map((av: AxisVariableT) => av.id)
  }

  getDragVariable = (axisVars: Array<AxisVariableT>, id: string) => {
    return axisVars.find(av => av.id === id)
  }

  handleDropVariable = async (emptyAxis: AxisT = '') => {
    const { xAxisVariables, yAxisVariables } = this.props
    const { xAxisDroppables, yAxisDroppables, draggedVariable } = this.state

    let updatedX = [...xAxisDroppables]
    let updatedY = [...yAxisDroppables]

    if (emptyAxis === ROW && draggedVariable && draggedVariable.id) {
      const dragged = this.getDragVariable(yAxisDroppables, draggedVariable.id)
      updatedX = dragged ? [...xAxisDroppables, dragged] : xAxisDroppables
      updatedY = updatedY.filter(d => d.id !== draggedVariable.id)
    } else if (emptyAxis === COLUMN && draggedVariable && draggedVariable.id) {
      const dragged = this.getDragVariable(xAxisDroppables, draggedVariable.id)
      updatedY = dragged ? [...yAxisDroppables, dragged] : yAxisDroppables
      updatedX = updatedX.filter(d => d.id !== draggedVariable.id)
    }

    const xAxisVarIds = this.getVarIds(updatedX)
    const yAxisVarIds = this.getVarIds(updatedY)

    this.setState({
      isDraggingVariable: false,
      draggedVariable: { id: '', axis: '' },
    })

    if (
      isEqual(xAxisVarIds, this.getVarIds(xAxisVariables)) &&
      isEqual(yAxisVarIds, this.getVarIds(yAxisVariables))
    ) {
      return
    }

    await this.props.dispatch(setVariablePosition(xAxisVarIds, yAxisVarIds))
    this.props.fetchAnalysisData()

    this.props.handleTrack('Drag and drop end', {
      num_row_variables: xAxisVariables.length,
      num_col_variables: yAxisVariables.length,
    })
  }

  handleCreateNet = (variable: VariableT) => {
    const { variables, dispatch } = this.props
    if (variable.isGrid) {
      const selectedGrid = variables.filter(v => v.questionName === variable.questionName)
      dispatch(
        filterNetDuck.setSelectedVariableThunk(variable, [], selectedGrid, {
          id: '__total__',
          type: 'calculated',
          label: 'Total',
        })
      )
    } else {
      dispatch(filterNetDuck.setSelectedVariableThunk(variable, []))
    }
    this.setState({
      isCreateNetsOpen: true,
    })
  }

  handleDeleteNet = async (net: NetT, variable: VariableT) => {
    try {
      const { dispatch, onDeleteNet } = this.props
      await dispatch(
        filterNetDuck.deleteNetThunk(variable.variableNk, variable.resourceType, net.id)
      )
      // onDeleteNet used from Analysis Page
      onDeleteNet && onDeleteNet()
      const trackProps = {
        question_id: variable.variableNk,
        question_text: variable.questionName,
        netToDelete: net.id,
      }
      this.props.handleTrack('Question Response Net Deleted', trackProps)
    } catch (e) {
      Sentry.captureException(new Error('Error trying to delete net', { cause: e }))
    }
  }

  onCreateNet = () => {
    this.setState({
      isCreateNetsOpen: false,
    })
  }

  onCancelNetCreate = () => {
    const { dispatch } = this.props
    dispatch(filterNetDuck.resetFilterNetThunk())
    this.setState({
      isCreateNetsOpen: false,
    })
  }

  onCancelVariableAdd = () => {
    this.props.dispatch(stageAddForAxis(null))
    this.setState({
      isCreateVariableOpen: false,
      selectedVariables: [],
      variableToReplace: null,
    })
  }

  handleChangeChartType = async (chartType: ChartTypeT) => {
    const { dispatch, handleTrack, chartType: currChartType, cookieValue } = this.props

    if (chartType === currChartType) return
    if (this.state.isDismissibleTooltipOpen) {
      this.setState({
        isDismissibleTooltipOpen: false,
      })
    }

    const onIntervalVarAdd = addedVar => {
      this.setState({
        isDismissibleTooltipOpen: !cookieValue,
      })
    }
    dispatch(updateChartTypeThunk(chartType, onIntervalVarAdd))
    handleTrack('Chart type changed', { chartType })
  }

  debouncedFetchData = debounce(async (callback?: Function = null, keepBase: boolean = false) => {
    this.props.fetchAnalysisData(callback, keepBase)
  }, 1400)

  handleToggleOption = async (axisVariable, option?: OptionKeyT) => {
    this.props.setChartLoadingState(true)

    if (!option) {
      await this.props.dispatch(toggleAllOptionsThunk(axisVariable.id, axisVariable.axis))
    } else {
      await this.props.dispatch(
        toggleOptionThunk(axisVariable.id, axisVariable.axis, option.id, option.type)
      )
    }

    if (
      (!option && axisVariable.selectedOptions.every(opt => !opt.selected)) ||
      (option &&
        (option.id === '__mean__' ||
          axisVariable.selectedOptions.some(opt => option.id === opt.id && !opt.selected))) ||
      this.props.chartError ||
      (this.props.showStatTesting && this.props.statsError) ||
      this.props.autoAdjustBaseEnabled
    ) {
      // only fetch when adding a selection or if autoAdjust base
      // or stat testing is on and there was an error
      this.debouncedFetchData(() => {
        this.props.setChartLoadingState(false)
      }, true)
    } else {
      this.props.setChartLoadingState(false)
    }
  }

  handleDismissIntervalTooltip = () => {
    this.props.setCookieValue('true')
    this.setState({
      isDismissibleTooltipOpen: false,
    })
  }

  handleExpand = (variable, shouldExpand?: boolean) => {
    const expanded = this.state.cardExpanded.get(variable.id) ?? false
    const newCardExpanded = new Map(this.state.cardExpanded)
    shouldExpand = typeof shouldExpand === 'boolean' ? shouldExpand : !expanded

    this.setState({
      cardExpanded: newCardExpanded.set(variable.id, shouldExpand),
    })

    this.props.handleTrack(`Variable card ${shouldExpand ? 'expanded' : 'unexpanded'}`)
  }

  renderVariableCards(axisVariables: Array<AxisVariableT>): Array<Node> {
    const {
      variables,
      isFetching,
      handleEditFilter,
      handleRemoveVariable,
      handleTrack,
      baseVariable,
      chartType,
      yAxisVariables,
      meanPopUpVarId,
      clearMeanPopUp,
    } = this.props

    const { isDismissibleTooltipOpen } = this.state

    return axisVariables.map(axisVariable => {
      const result = getVariable(variables, axisVariable.variableNk, axisVariable.resourceType)
      if (!result) {
        console.warn('null variable found in axisVariables, ignoring.', {
          axisVariable,
          variables,
        })
        return null
      }
      const expanded = this.state.cardExpanded.get(result.id) ?? false

      const isLineInterval = chartType === 'line' && axisVariable.axis === ROW
      const disableDrag = isLineInterval || (chartType === 'line' && yAxisVariables.length <= 1)
      const isBase = baseVariable && baseVariable.id === axisVariable.id
      const shouldHighlight = isDismissibleTooltipOpen && axisVariable.axis === ROW

      return (
        <AnalysisVariableCard
          key={result.id}
          variableToRender={result}
          axisVariable={axisVariable}
          isDisabled={isFetching}
          isExpanded={expanded}
          shouldHighlight={shouldHighlight}
          handleTrack={handleTrack}
          handleToggleOption={this.handleToggleOption}
          handleExpand={this.handleExpand}
          handleReplaceVariable={this.handleReplaceVariable}
          handleEditFilter={handleEditFilter}
          handleAddNet={this.handleCreateNet}
          onDeleteNet={this.props.onDeleteNet}
          handleRemoveVariable={handleRemoveVariable}
          handleDragStart={this.handleDragStart}
          handleDragEnd={this.handleDragEnd}
          handleHoverVariable={this.handleHoverVariable}
          handleDropVariable={this.handleDropVariable}
          isLineInterval={isLineInterval}
          disableDrag={disableDrag}
          isBase={isBase}
          showMeanPopUp={axisVariable.id === meanPopUpVarId}
          clearMeanPopUp={clearMeanPopUp}
        />
      )
    })
  }

  shouldShowEmpty = (axis: AxisT) => {
    const { isDraggingVariable } = this.state
    return isDraggingVariable ? this.moveToAxisEnabled(axis) : this.addToAxisEnabled(axis)
  }

  renderVariableSection = (axis: AxisT) => {
    const { xAxisDroppables, yAxisDroppables, isDraggingVariable, draggedVariable } = this.state
    const axisText = this.getAxisText(axis)
    const axisDroppables = axis === ROW ? xAxisDroppables : yAxisDroppables
    const hasAxisVar = axisDroppables && axisDroppables.length > 0
    const enableAdd = this.addToAxisEnabled(axis)

    return (
      <div className={styles.rowColContainer}>
        {this.props.chartType && axis && <AxisInfoIcon chart={this.props.chartType} axis={axis} />}
        {hasAxisVar && this.props.variables.length && (
          <div>{this.renderVariableCards(axisDroppables)}</div>
        )}
        {this.shouldShowEmpty(axis) && (
          <EmptyVariableCard
            axisText={axisText}
            enableAdd={enableAdd}
            hide={isDraggingVariable && axisDroppables.length > 0}
            isDraggingVariable={isDraggingVariable}
            draggingAxis={draggedVariable.axis}
            axis={axis}
            handleAddVariable={this.handleAddVariable}
            clearHover={this.clearHover}
            handleDropVariable={this.handleDropVariable}
          />
        )}
      </div>
    )
  }

  render() {
    const {
      email,
      isFetching,
      rdmComplete,
      dataset,
      isEmbedded,
      chartType,
      xAxisVariables,
      yAxisVariables,
      filters,
      selectedVariable,
      addFilterModalOpen,
    } = this.props

    const {
      variableToReplace,
      createVariableAxis,
      isDraggingVariable,
      isCreateVariableOpen,
      selectedVariables,
      isDismissibleTooltipOpen,
      isCreateNetsOpen,
    } = this.state

    if (!dataset) return null

    const hasRows = xAxisVariables && xAxisVariables.length > 0
    const hasColumns = yAxisVariables && yAxisVariables.length > 0
    const totalVariableLimit = chartType !== 'spreadsheet' ? VIS_CHART_VAR_LIMIT : VARIABLE_MAX
    const canSwap =
      !isChartInverted(chartType) ||
      (isChartStacked(chartType) && xAxisVariables.length === 1 && yAxisVariables.length === 1)

    const optionExclusions = []
    if (isCreateVariableOpen && selectedVariables && selectedVariables.length) {
      const baseVar = this.findBaseVar()
      if (selectedVariables[0].id !== baseVar.id) optionExclusions.push('__mean__')
    }

    return (
      <aside className={styles.analysisSidebar}>
        <div className={`${styles.content} ${isCreateNetsOpen ? styles.overlayOpen : ''}`}>
          <SidebarHeader
            dataset={dataset}
            isEmbedded={isEmbedded}
            handleTrack={this.props.handleTrack}
          />
          <ChartTypeSelector
            isFetching={isFetching}
            handleChangeChartType={this.handleChangeChartType}
            currChartType={chartType}
            xAxisVariables={xAxisVariables}
            yAxisVariables={yAxisVariables}
            chartTypeMap={CHART_TYPE_MAP}
            datasetVariables={dataset.variables || []}
          />
          <DndProvider backend={HTML5Backend}>
            <Tooltip
              isOpen={isDismissibleTooltipOpen}
              content="An interval variable was added to make the best looking line chart. Line charts are best when viewing data over time."
              placement="right"
              variant="dismissible"
              onDismiss={this.handleDismissIntervalTooltip}
            >
              {this.renderVariableSection(ROW)}
            </Tooltip>
            {canSwap && (
              <SwapTranspose
                disabled={
                  isFetching || !rdmComplete || (!hasRows && !hasColumns) || isDraggingVariable
                }
                currChartType={chartType}
                handleSwapRowsColumns={this.handleSwapRowsColumns}
              />
            )}
            {this.renderVariableSection(COLUMN)}
            <VariableCardDragLayer />
          </DndProvider>
          {isEmbedded && (
            <div>
              <PoweredBy />
            </div>
          )}
        </div>
        {!addFilterModalOpen && isCreateNetsOpen && selectedVariable && (
          <CreateNets
            isOpen={isCreateNetsOpen}
            email={email}
            filters={filters}
            dispatch={this.props.dispatch}
            onCreateNets={this.onCreateNet}
            clickCancel={this.onCancelNetCreate}
          />
        )}
        {isCreateVariableOpen && (
          <AddVariableSlider
            isOpen={isCreateVariableOpen}
            replacingVariable={variableToReplace}
            axis={createVariableAxis}
            chartType={chartType}
            addedVariables={[...xAxisVariables, ...yAxisVariables]}
            handleCancel={this.onCancelVariableAdd}
            onVariableSubmit={this.onVariableSubmit}
            onClickVariable={this.onClickVariable}
            totalVariableLimit={totalVariableLimit}
            optionExclusions={optionExclusions}
          />
        )}
      </aside>
    )
  }
}

const mapStateToProps = state => ({
  chartError: getChartConfigError(state),
  emptyVar: getEmptyVariable(state),
  selectedVariable: filterNetDuck.getSelectedVariable(state),
  chartType: getChartType(state),
  xAxisVariables: getXAxisVariables(state),
  yAxisVariables: getYAxisVariables(state),
  filters: getFilters(state),
  variables: getVariables(state),
  dataset: getDataset(state),
  isEmbedded: getIsEmbedded(state),
})

export default connect(mapStateToProps)(withCookie(AnalysisSidebar, 'interval-tooltip-seen'))
