import { isEmpty } from 'lodash'
import * as Sentry from '@sentry/react'

import * as laikaColors from '@knowledgehound/laika/scss/_colors.module.scss'

import { generateTableData } from 'tabularizeData'
import { transformAnalysisData } from '../Analysis2Utils'
import { generateAxisVars, getAnalysisData as fetchAnalysisData } from './DatasetRequests'
import { defaultChartConfigState } from './DatasetReducers'
import setupDataFetchRequest from 'util/setupDataFetchRequest'
import { getOrderedSeries } from '../chart/AnalysisChartUtils/AnalysisChartUtils'
import { isOptionSelected } from '../FilterUtils'

export const getAnalysisData = async (
  selectedVariables,
  axisVariables,
  filters,
  studyId,
  datasetNk
) => {
  try {
    const requestBody = setupDataFetchRequest({
      xAxisVariables: axisVariables,
      yAxisVariables: [],
      filters: filters.filter(f => f.questionName !== selectedVariables[0].questionName),
      chartType: 'column',
    })
    if (!requestBody) {
      throw new Error('Unable to generate AD request body')
    }
    const aData = await fetchAnalysisData(requestBody, studyId, datasetNk)
    if (!aData || isEmpty(aData) || isEmpty(aData.data)) {
      throw new Error('AD object empty')
    }
    const mappedVariables = aData.variables.map(v => ({
      id: v.id,
      questionName: v.name,
      resourceType: v.resource_type,
    }))

    const transformedData = transformAnalysisData(mappedVariables, aData.data, aData.groups)

    return {
      data: transformedData.data,
      variables: mappedVariables,
      groups: transformedData.groups,
      base_size: aData.base_size,
    }
  } catch (e) {
    Sentry.captureException(
      new Error('Distribution Chart, error while fetching analysisData', { cause: e })
    )
    throw new Error(e)
  }
}

export const getTabularizedData = async (
  variableList,
  selectedVariables,
  selectedGridBreakout,
  filters,
  studyId,
  datasetNk
) => {
  let axisVariables
  try {
    axisVariables = generateAxisVars(
      selectedVariables,
      'ROW',
      [], // selected nets
      selectedVariables[0].options.map(o => o.id), // selected options
      selectedGridBreakout && selectedGridBreakout.type === 'breakout'
        ? [selectedGridBreakout.id]
        : [], // selected breakouts
      [], // selected calculated
      selectedGridBreakout && selectedGridBreakout.type === 'calculated'
        ? [selectedGridBreakout.id]
        : [] // selected calculatedBreakout, this is for numeric nets on grids
    )
  } catch (e) {
    Sentry.captureException(
      new Error('Distribution Chart, unable to generate axis variables', { cause: e })
    )
    throw new Error(e)
  }

  const analysisData = await getAnalysisData(
    selectedVariables,
    axisVariables,
    filters,
    studyId,
    datasetNk
  )

  try {
    const viewSettings = {
      ...defaultChartConfigState.viewSettings,
      numberType: 'numeric',
      chartType: 'column',
      showLabels: false,
    }
    const { tableData, colHeaders, rowHeaders } = generateTableData(
      variableList,
      axisVariables,
      [],
      analysisData,
      0,
      viewSettings
    )
    if (!tableData || !tableData.length) {
      throw new Error('Error generating tabledata for Distribution chart')
    } else {
      return { tableData, colHeaders, rowHeaders }
    }
  } catch (e) {
    Sentry.captureException(
      new Error('Distribution Chart, unable to generate tableData', { cause: e })
    )
    throw new Error(e)
  }
}

export const getDistributionChartOptions = (
  tableData,
  rowHeaders,
  colHeaders,
  selectedOptions,
  selectedMethod
) => {
  const series = getOrderedSeries(
    'column',
    tableData,
    colHeaders,
    rowHeaders && rowHeaders[0].length,
    'numeric',
    false,
    []
  )
  let minX
  let maxX
  let maxY
  series[0].data = series[0].data.map((valData, i) => {
    const selected = isOptionSelected(
      { id: rowHeaders[i][0].id.optionId, type: rowHeaders[i][0].id.optionType },
      selectedOptions,
      selectedMethod
    )
    const color = selected ? laikaColors.violetLight : laikaColors.cloudGrey
    const x = parseFloat(rowHeaders[i][0].id.optionId)
    const y = valData.y ? valData.y : 0
    if (!i || x > maxX) maxX = x
    if (!i || x < minX) minX = x
    if (!i || y > maxY) maxY = y
    return { ...valData, borderColor: color, color, y, x }
  })

  return {
    chart: {
      type: 'column',
      animation: false,
      backgroundColor: null,
    },
    plotOptions: {
      series: {
        colorByPoint: true,
        turboThreshold: 0,
        pointPadding: 0,
        groupPadding: 0,
        states: {
          hover: {
            enabled: false,
          },
        },
      },
    },
    yAxis: {
      max: maxY,
      alignTicks: false,
      tickInterval: maxY,
      gridLineWidth: 0,
      minorGridLineWidth: 0,
      title: {
        enabled: false,
      },
      labels: {
        enabled: false,
      },
    },
    xAxis: {
      categories: rowHeaders.map(row => parseFloat(row[0].id.optionId)),
      max: maxX + (maxX - minX) * 0.003, // I dunno why but without a little extra it cuts the last item off
      min: minX,
      visible: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    series,
  }
}
