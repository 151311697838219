import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import { captureException } from '@sentry/react'

import { generateTableData, ManagedTabularizeDataError } from 'tabularizeData'
import { roundToPrecision } from 'util/math'
import AnalysisSegment from 'util/segment'
import setupDataFetchRequest from 'util/setupDataFetchRequest'
import { isIntervalOrNumeric, getStateId, resolveDatasetVariable } from '../Analysis2Utils'
import { getUserInfo, getUserPermissions, getIsUserManager } from './configurationDuck'
import { ROW } from './constants'

import type {
  StatTestingConfigReduxT,
  DatasetReducerStateT,
  DatasetReduxT,
  VariableListReduxT,
  ChartConfigReduxT,
} from './DatasetRedux.type'
import type { DatasetQuestionT } from 'data/studyDatasets/DatasetsTypes.type'

type appReducerT = {
  dataset: DatasetReducerStateT,
}

/** selectors */
const datasetState = (state: appReducerT): DatasetReduxT => state.dataset.dataset

export const variablesState = (state: appReducerT): VariableListReduxT => state.dataset.variables

const chartConfigState = (state: appReducerT): ChartConfigReduxT => state.dataset.chartConfig

export const getIsEmbedded = state => Boolean(state.dataset.isEmbedded)

const statTestingState = (state: appReducerT): StatTestingConfigReduxT => state.dataset.statTesting

export const getA1Husk = (state: appReducerT): Object => state.dataset.a1Husk

export const getDataset: Function => DatasetT | null = createSelector(
  datasetState,
  currDatasetState => currDatasetState.data
)

export const getDatasetVariables: Function => Array<DatasetQuestionT> = createSelector(
  datasetState,
  currDatasetState => currDatasetState?.data?.variables ?? []
)

export const getIntervalVariables = createSelector(
  getDatasetVariables,
  variables => variables?.filter(isIntervalOrNumeric) ?? []
)

export const isFetchingDataset: Function => boolean = createSelector(
  datasetState,
  currDatasetState =>
    (currDatasetState.data === null && currDatasetState.lastFetched === null) ||
    currDatasetState.isFetching
)

export const getAccessibleDatasetQuestions = createSelector(
  getDataset,
  getIsUserManager,
  getUserPermissions,
  (dataset, isManager, permissions) => {
    const isDIYAdmin =
      permissions.includes('QUALTRICS') ||
      permissions.includes('SURVEY_MONKEY') ||
      permissions.includes('DECIPHER')

    const annotated = dataset?.annotated
    const selfLoadedDataset = dataset?.previewable
    const diyDataset = Boolean(dataset?.externalType) && dataset?.externalType !== 'Data API'

    if ((isDIYAdmin && diyDataset) || (isManager && selfLoadedDataset) || annotated) {
      return dataset?.variables.filter(
        q => q.main_var_list || q.suggested_xtab || (isDIYAdmin && diyDataset)
      )
    }
    return []
  }
)

export const getUserCanAccessQuestions = createSelector(getAccessibleDatasetQuestions, questions =>
  Boolean(questions && questions.length)
)

export const isDatasetAvailable: Function => boolean = createSelector(
  isFetchingDataset,
  getDataset,
  (isFetching, dataset) => dataset.rdmComplete && !isFetching
)

export const getLowBaseThreshold: Function => boolean = createSelector(
  getDataset,
  dataset => dataset.lowBaseThreshold
)

export const getVariables: Function => VariableListT = createSelector(
  variablesState,
  currVarState => currVarState.data
)

/** If a user is in process of adding a variable to an analysis, this is the target axis. */
export const getStagingAxis = state => state.dataset.chartConfig.staging.axis

export const getChartConfig: Function => ChartConfigT = createSelector(
  chartConfigState,
  currConfigState => currConfigState.data
)

export const getViewSettings = createSelector(
  getChartConfig,
  chartConfig => chartConfig.viewSettings
)

export const getChartNumberType = createSelector(
  getViewSettings,
  viewSettings => viewSettings.numberType
)

export const getChartSortState = createSelector(
  getViewSettings,
  viewSettings => viewSettings.sortState
)

export const getChartConfigError = createSelector(
  chartConfigState,
  currConfigState => currConfigState.error
)

/**
  Sends analytics data to kissmetrics / segment
  Default data sent are as listed below
    - Dataset Natural Key
    - study ID
    - study Name
    - user email
    - client

  @param eventName {string} - name of the event that will be logged
  @param valuesToAdd {Object} - Object which the properties will be spread to
  the data that is sent to the back end
*/
export const getHandleTrack = createSelector(
  getDataset,
  getVariables,
  getUserInfo,
  (dataset, variables, userInfo) =>
    (eventName: string, valuesToAdd: Object = {}) => {
      if (userInfo && dataset && variables) {
        const standardProperties = {
          study_id: dataset.studyId,
          study_name: dataset.studyName,
          user_email: userInfo.email,
          client: window.location.host.split('.')[0],
          dataset_nk: dataset.datasetNk,
          num_variables: variables.length,
        }

        const mergedEventProperties = {
          ...standardProperties,
          ...valuesToAdd,
        }
        AnalysisSegment.track(eventName, mergedEventProperties)
      }
    }
)

export const getAnalysisData: Function => AnalysisDataT = createSelector(
  chartConfigState,
  currChartConfig => currChartConfig.data.dataSettings.data
)

export const getXAxisVariables = createSelector(
  chartConfigState,
  currChartConfig => currChartConfig.data.dataSettings.xAxisVariables
)

export const getYAxisVariables = createSelector(
  chartConfigState,
  currChartConfig => currChartConfig.data.dataSettings.yAxisVariables
)

export const getBaseQuestion = createSelector(
  chartConfigState,
  currChartConfig => currChartConfig.data.dataSettings.data.baseQuestion
)

export const isAutoAdjustBaseEnabled: Function => boolean = createSelector(
  chartConfigState,
  currChartConfig => currChartConfig.data.dataSettings.autoAdjustBaseEnabled
)

export const getFilters = createSelector(
  chartConfigState,
  currChartConfig => currChartConfig.data.dataSettings.filters
)

export const getIsFetchingVariables = createSelector(
  [variablesState],
  variables => variables.isFetching
)

export const getSuppressedVariableOptions = state =>
  state.dataset.variables.nullSuppressedOpts.variables

export const getFetchingSuppressedVariableOptions = state =>
  state.dataset.variables.nullSuppressedOpts.isFetching

export const getSuppressedVariablesWithErrors = state =>
  state.dataset.variables.nullSuppressedOpts.hasError

export const isFetchingData: Function => boolean = createSelector(
  [getIsFetchingVariables, chartConfigState],
  (isFetchingVariables, chartConfig: ChartConfigReduxT) => {
    return Boolean(isFetchingVariables || chartConfig.isFetching)
  }
)

export const getChartType: Function => ChartTypeT = createSelector(
  getViewSettings,
  viewSettings => viewSettings.chartType
)

export const getIsInverted = createSelector(getChartType, chartType =>
  ['stackedBar', 'stackedColumn', 'line'].includes(chartType)
)

export const shouldShowLabels: Function => boolean = createSelector(
  getViewSettings,
  viewSettings => viewSettings.showLabels
)

export const shouldSuppressNulls: Function => boolean = createSelector(
  getViewSettings,
  viewSettings => viewSettings.suppressNullValues
)

export const shouldHideLowBase: Function => boolean = createSelector(
  getViewSettings,
  viewSettings => viewSettings.hideLowBase
)

export const getShowUnweightedBase = createSelector(getViewSettings, viewSettings =>
  Boolean(viewSettings.showUnweightedBase)
)

export const isStatTestingEnabled: Function => boolean = createSelector(
  getViewSettings,
  viewSettings => viewSettings.showStatTesting
)

export const getStatTestConfidence = createSelector(
  getViewSettings,
  viewSettings => viewSettings.statsConfidence || [95]
)

export const getStatTestingPollIntervalId: Function => number = createSelector(
  statTestingState,
  stats => stats.intervalId
)

export const getStatTestingData: Function => Object = createSelector(
  statTestingState,
  stats => stats.statsData
)

export const hasStatTestingError: Function => boolean = createSelector(statTestingState, stats =>
  Boolean(stats.error && stats.error.messageKey)
)

export const getFetchedStatTestingId: Function => string = createSelector(
  statTestingState,
  stats => stats.statsId
)

export const getAvailableWeightsState = createSelector(
  variablesState,
  currVarState => currVarState.availableWeights
)

export const getIsFetchingWeights = createSelector(
  getAvailableWeightsState,
  availableWeights => availableWeights.isFetching
)

export const getWeightsPollIntervalId = createSelector(
  getAvailableWeightsState,
  availableWeights => availableWeights.intervalId
)

export const getStandardWeights = createSelector(
  getAvailableWeightsState,
  availableWeights => availableWeights.standard
)

export const getDefaultWeight = createSelector(
  getAvailableWeightsState,
  availableWeights => availableWeights.defaultWeight
)

export const getCustomWeights = createSelector(
  getAvailableWeightsState,
  availableWeights => availableWeights.custom
)

export const getWeighting = createSelector(getChartConfig, currChartConfig => {
  const weighting = currChartConfig.dataSettings.weighting
  return Array.isArray(weighting) ? weighting[0] : weighting
})

export const getWeightingLabel = createSelector(
  getWeighting,
  getDefaultWeight,
  getCustomWeights,
  (currentWeight, defaultWeight, customWeights) => {
    if (currentWeight === 'unweighted') return null
    if (currentWeight === 'useDefault' || !currentWeight) {
      if (isEmpty(defaultWeight)) return null
      return defaultWeight.label ?? defaultWeight.id
    }

    const customWeight = customWeights.find(weight => weight.id === currentWeight)
    if (customWeight) {
      return customWeight.label
    }

    return currentWeight
  }
)

export const getValidWeightShape = createSelector(getWeighting, weighting => {
  if (!weighting) return undefined
  if (weighting === 'unweighted') return 'unweighted'
  if (Array.isArray(weighting)) return weighting
  if (weighting !== 'useDefault') return [weighting]
})

type StatTestingProgressT = {
  status: 'inactive' | 'processing' | 'error' | 'completed',
  messageId: string,
  messageData: Object,
}

export const getStatTestingRequestProgress: Function => StatTestingProgressT = createSelector(
  statTestingState,
  isStatTestingEnabled,
  getStatTestConfidence,
  (
    { error, comparisonCount = 0, completedCount = 0, purged, statsData, loading },
    statTestingEnabled,
    confidence
  ) => {
    let messageId = 'progress.inactive'
    let status = 'inactive'
    let progress = comparisonCount < 1 ? 0 : Math.round((completedCount / comparisonCount) * 100)

    if (statTestingEnabled) {
      if (purged || error) {
        messageId = `error.${(error || {}).messageKey}`
        status = 'error'
      } else if (comparisonCount < 0) {
        messageId = isEmpty(statsData) ? 'progress.inactive' : 'progress.simpleCompleted'
        progress = 100
      } else if (loading) {
        messageId = 'progress.processing'
        status = 'processing'
      } else if (completedCount === comparisonCount) {
        if (isEmpty(statsData)) messageId = 'progress.noStatSig'
        messageId = 'progress.completed'
        status = 'completed'
      }
    }

    return {
      status,
      messageId: `analysis.statTesting.${messageId}`,
      messageData: {
        confidence: `${confidence[0]}%`,
        comparisons: comparisonCount,
        progress: progress,
        ...(error || {}).data,
      },
    }
  }
)

export const getElevatedKey = createSelector(
  chartConfigState,
  currChartConfig => currChartConfig.data.dataSettings.elevatedKey
)

export const getAnalysisDataFetchRequestBody = createSelector(
  getXAxisVariables,
  getYAxisVariables,
  getFilters,
  isAutoAdjustBaseEnabled,
  getChartType,
  isStatTestingEnabled,
  getStatTestConfidence,
  getElevatedKey,
  getValidWeightShape,
  getShowUnweightedBase,
  (
    xAxisVariables,
    yAxisVariables,
    filters,
    autoAdjustBaseEnabled,
    chartType,
    statTestingRequested,
    statsConfidence,
    elevatedKey,
    weighting,
    showUnweightedBase
  ) =>
    setupDataFetchRequest({
      xAxisVariables,
      yAxisVariables,
      filters,
      autoAdjustBaseEnabled,
      chartType,
      statTestingRequested,
      statsConfidence,
      elevatedKey,
      weighting,
      showUnweightedBase,
    })
)

export const getAnalysisStateId = createSelector(
  getXAxisVariables,
  getYAxisVariables,
  getFilters,
  getViewSettings,
  getValidWeightShape,
  isAutoAdjustBaseEnabled,
  getStateId
)

export const getSerializedChartState = createSelector(
  getDataset,
  getAnalysisStateId,
  getXAxisVariables,
  getYAxisVariables,
  getFilters,
  getViewSettings,
  getValidWeightShape,
  getWeightingLabel,
  isAutoAdjustBaseEnabled,
  getFetchedStatTestingId,
  (
    dataset,
    stateId,
    xAxisVariables,
    yAxisVariables,
    filters,
    viewSettings,
    weighting,
    weightingLabel,
    autoAdjustBaseEnabled,
    statsId
  ) => {
    if (!dataset) return {}

    return {
      study_id: dataset.studyId,
      dataset_name: dataset.datasetNk,
      state_id: stateId,
      stats_id: statsId,
      state: {
        filters,
        xAxisVariables,
        yAxisVariables,
        autoAdjustBaseEnabled,
        viewSettings,
        weighting,
        weightingLabel: weighting === 'useDefault' ? undefined : weightingLabel,
      },
    }
  }
)

const createDeeperSelector = createSelectorCreator(defaultMemoize, isEqual)

export const getAnalysisDataWithStatTesting: Function => AnalysisDataT = createDeeperSelector(
  [getAnalysisData, isStatTestingEnabled, getFetchedStatTestingId, getStatTestingData],
  (analysisData, showStatTesting, fetchedStatsId, statTestingData) => {
    if (!showStatTesting || !statTestingData || analysisData.statsId !== fetchedStatsId)
      return analysisData

    return {
      ...analysisData,
      data: Object.keys(analysisData.data).reduce((acc, key) => {
        acc[key] = {
          ...analysisData.data[key],
          statTesting: statTestingData[key] || [],
        }
        return acc
      }, {}),
    }
  }
)

export const getTableData = createDeeperSelector(
  isFetchingData,
  getUserPermissions,
  getVariables,
  getXAxisVariables,
  getYAxisVariables,
  getAnalysisDataWithStatTesting,
  getLowBaseThreshold,
  getViewSettings,
  (
    isFetching,
    perms,
    variables,
    xAxisVars,
    yAxisVars,
    adWithStats,
    lowBaseThreshold,
    viewSettings
  ) => {
    try {
      if (isFetching) return {}
      return generateTableData(
        variables,
        xAxisVars,
        yAxisVars,
        adWithStats,
        lowBaseThreshold,
        viewSettings
      )
    } catch (error) {
      if (!(error instanceof ManagedTabularizeDataError)) {
        captureException(new Error('Failed to tabularize analysis data', { cause: error }))
        console.error(error)
      }
      return { error }
    }
  }
)

export const isAllTableDataLowBase = createSelector(
  getTableData,
  tableData => (tableData || {}).isEverythingLowBase ?? false
)

const getEmptyXVariable = createSelector(
  getXAxisVariables,
  getVariables,
  (xAxisVariables, variables) => {
    const emptyRowVar = xAxisVariables.find(v => !v.selectedOptions.some(o => o.selected))
    if (emptyRowVar) return resolveDatasetVariable(variables, emptyRowVar)
    return null
  }
)

const getEmptyYVariable = createSelector(
  getYAxisVariables,
  getVariables,
  (yAxisVariables, variables) => {
    const emptyColVar = yAxisVariables.find(v => !v.selectedOptions.some(o => o.selected))
    if (emptyColVar) return resolveDatasetVariable(variables, emptyColVar)
    return null
  }
)

export const getEmptyVariable = createSelector(
  getEmptyXVariable,
  getEmptyYVariable,
  (emptyXVar, emptyYVar) => emptyXVar || emptyYVar || null
)

const getWeightIsUnavailable = createSelector(
  isFetchingData,
  getStandardWeights,
  getCustomWeights,
  getWeighting,
  (fetchingData, standardWeights, customWeights, currentWeight) => {
    if (
      fetchingData ||
      currentWeight === undefined ||
      currentWeight === 'useDefault' ||
      currentWeight === 'unweighted' ||
      (!standardWeights.length && !customWeights.length)
    ) {
      return false
    }
    return !(
      standardWeights.some(weight => weight.id === currentWeight) ||
      customWeights.some(weight => weight.id === currentWeight)
    )
  }
)

export const getChartErrorMessageKey = createSelector(
  getXAxisVariables,
  getYAxisVariables,
  getEmptyVariable,
  isDatasetAvailable,
  getChartConfigError,
  shouldHideLowBase,
  shouldSuppressNulls,
  getTableData,
  getWeightIsUnavailable,
  (
    xVars,
    yVars,
    emptyVariable,
    datasetAvailable,
    error,
    hideLowBase,
    suppressNulls,
    { isEverythingLowBase, isEverythingSuppressed },
    weightIsUnavailable
  ) => {
    if (!datasetAvailable) {
      return {
        messageKey: 'notAvailable',
      }
    }
    if (isString(error) && error.includes('too many permutations')) {
      return {
        messageKey: 'timeOut',
      }
    }
    if (error) {
      return {
        messageKey: 'unknown',
      }
    }
    if (xVars.length === 0 && yVars.length === 0) {
      return {
        messageKey: 'noRowsOrColumns',
      }
    }
    if (emptyVariable) {
      return {
        messageKey: 'noVariableOptionsSelected',
        messageContent: { variableLabel: emptyVariable.label },
      }
    }
    if (weightIsUnavailable) {
      return {
        messageKey: 'weightIsUnavailable',
      }
    }
    if (hideLowBase && isEverythingLowBase) {
      return {
        messageKey: 'everythingLowBase',
      }
    }
    if (suppressNulls && isEverythingSuppressed) {
      return {
        messageKey: 'everythingNullSuppressed',
      }
    }
    return {}
  }
)

const createNetState = (state: appReducerT) => state.dataset.createNet

export const getNewNet = createSelector(createNetState, createNetState => createNetState.data)

export const createNetLoading = createSelector(
  createNetState,
  createNetState => createNetState.isFetching
)

export const createNetError = createSelector(createNetState, createNetState => createNetState.error)

export const isFetchingChartSummary = state => state.dataset.chartSummary.isFetching
export const hasErrorFetchingChartSummary = state => state.dataset.chartSummary.hasError
export const getChartSummary = state => state.dataset.chartSummary.summary ?? ''

// Custom Weights ///////////////////////////////////////////////////////

export const getIsCreatingCustomWeight = state =>
  state.dataset.variables.customWeightCreation.isCreating
export const getErrorCreatingCustomWeight = state => {
  const error = state.dataset.variables.customWeightCreation.hasErrorCreating

  if (!error) return false
  if (error?.name) return error.name
  return 'We could not create your custom weight, please try again later.'
}

export const getIsDeletingCustomWeight = state =>
  state.dataset.variables.customWeightCreation.isDeleting
export const getErrorDeletingCustomWeight = state =>
  state.dataset.variables.customWeightCreation.hasErrorDeleting

export const getCustomWeightName = state => state.dataset.variables.customWeightCreation.name

export const getStagedVariablesForCustomWeights = state =>
  state.dataset.variables.customWeightCreation.variableIds

export const getSelectionsForCustomWeights = state =>
  state.dataset.variables.customWeightCreation.selections

/**
 * Get if user's has staged a valid custom weight, otherwise
 * provide necessary steps to make a valid one.
 */
export const getCustomWeightValidity = createSelector(
  getCustomWeightName,
  getSelectionsForCustomWeights,
  (name, selections) => {
    const errors = []
    const total = roundToPrecision(
      selections.reduce((sum, opt) => {
        const value = Number(opt.value)
        return sum + (Number.isNaN(opt.value) ? 0 : value)
      }, 0),
      1
    )

    if (total !== 100.0) {
      errors.push({
        reason: 'Weights do not sum to 100%',
        total,
      })
    }

    if (!name.length) {
      errors.push({
        reason: 'Must provide a name for the custom weight',
      })
    }

    if (name.length > 100) {
      errors.push({
        reason: 'Custom weight name is too long',
      })
    }

    return {
      isValid: errors.length === 0,
      errors,
    }
  }
)

/** Get what dataset variables can be used in a custom weight variable. */
export const getCustomWeightEligibleVariables = createSelector(
  getDataset,
  getDatasetVariables,
  (dataset, variables) =>
    variables.filter(
      variable =>
        variable.question_type === 'Pick One' && variable.base_sample_size === dataset.sampleSize
    )
)

export const getVariableSearchQuery = state => state.dataset.variables.searchQuery

const getAvailableVariablesForAxis = createSelector(
  getDatasetVariables,
  getStagingAxis,
  getChartType,
  (variables, stagingAxis, chartType) => {
    if (chartType === 'line' && stagingAxis === ROW) {
      return variables.filter(isIntervalOrNumeric)
    }
    return variables
  }
)

export const getVariablesWithSearch = createSelector(
  getAvailableVariablesForAxis,
  getVariableSearchQuery,
  (variables, searchQuery) => {
    const query = searchQuery.toLowerCase()
    if (!searchQuery) return variables

    const searchedResults = variables.flatMap(variable => {
      const responseMatches = variable.categorical_responses.filter(response =>
        response.toLowerCase().includes(query)
      )

      const variableTextMatches = variable.question_text.toLowerCase().includes(query)

      if (!searchQuery.length || variableTextMatches || responseMatches.length) {
        return {
          ...variable,
          innerHits: responseMatches.length - 3,
          matches: [...responseMatches].slice(0, 3),
        }
      }
      return []
    })
    return searchedResults
  }
)
