import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'

import { Button, TextInput, KeyEvents } from '@knowledgehound/laika'

import { filterNet as filterNetDuck } from 'store'

import * as styles from './NetName.module.scss'

export default function NetName({
  handleClickCreate,
  createAnother,
  containerClass,
  headerNumber = '4',
}) {
  const dispatch = useDispatch()
  const newNetName = useSelector(filterNetDuck.getCreateNetName, shallowEqual)
  const loading = useSelector(filterNetDuck.getCreateNetLoading, shallowEqual)
  const netError = useSelector(filterNetDuck.getCreateNetError, shallowEqual)
  const createdCount = useSelector(filterNetDuck.getCreateNetCount, shallowEqual)
  const validResponseOptions = useSelector(filterNetDuck.getResponseOptionsValid, shallowEqual)

  const submitDisabled = Boolean(
    !validResponseOptions || !newNetName || newNetName.length === 0 || loading || netError
  )

  return (
    <div className={`${styles.netActionContainer} ${containerClass || ''}`}>
      <label className={styles.netNameHeader}>{`${headerNumber}. Name this net`}</label>
      <TextInput
        name="net_name"
        value={newNetName}
        maxLength={100}
        placeholder="eg: Top Two Box"
        onChange={event => dispatch(filterNetDuck.setCreateNetName(event.currentTarget.value))}
        onKeyDown={event => {
          if (KeyEvents.onEnterKey(event) && !submitDisabled) {
            event.preventDefault()
            event.stopPropagation()
            handleClickCreate()
          }
        }}
      />
      {netError && (
        <div className={styles.errorText}>
          <FormattedMessage id={`analysis.${netError}`} />
        </div>
      )}
      {!netError && (
        <div className={styles.createdText}>
          {createdCount ? `${createdCount} new net${createdCount > 1 ? 's' : ''} created` : ''}
        </div>
      )}
      <div className={styles.netButtonContainer}>
        <Button disabled={submitDisabled} onClick={createAnother} variant="secondary" size="large">
          Create another
        </Button>
        <Button
          disabled={submitDisabled}
          onClick={handleClickCreate}
          variant="primary"
          size="large"
        >
          Create and Continue
        </Button>
      </div>
    </div>
  )
}
