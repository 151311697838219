import React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import * as Sentry from '@sentry/react'

import { ToggleableSelectionOptions } from '@knowledgehound/laika'

import { config as configDuck, filterNet as filterNetDuck } from 'store'
import { getNetExplanation } from '../Analysis2Utils'

import * as styles from './NetsFilters.module.scss'

function NetsFilters({ isChecked, handleChangeResponseOption, handleTrack }) {
  const dispatch = useDispatch()
  const selectedVariable = useSelector(filterNetDuck.getSelectedVariable, shallowEqual)
  const selectedOptions = useSelector(filterNetDuck.getSelectedOptions, shallowEqual)
  const userInfo = useSelector(configDuck.getUserInfo, shallowEqual)
  const email = (userInfo && userInfo.email) || ''

  if (!selectedVariable) return null

  const onDeleteNet = async net => {
    if (selectedVariable) {
      try {
        await dispatch(
          filterNetDuck.deleteNetThunk(
            selectedVariable.variableNk,
            selectedVariable.resourceType,
            net.pk
          )
        )
        const newOptions = selectedOptions.filter(opt => {
          return !(opt.type === 'net' && opt.id === net.pk)
        })
        if (newOptions.length !== selectedOptions.length) {
          dispatch(filterNetDuck.setSelectedOptions(newOptions))
        }
        if (handleTrack) {
          const trackProps = {
            question_id: selectedVariable.variableNk,
            question_text: selectedVariable.questionName,
            user: email,
            netToDelete: net.id,
          }
          handleTrack('Question Response Net Deleted', trackProps)
        }
      } catch (e) {
        Sentry.captureException(new Error('Error deleting net in A2 filter modal', { cause: e }))
      }
    }
  }

  const filterNets = selectedVariable.nets || []

  const options = filterNets.map(net => {
    const onClickNet = () => handleChangeResponseOption(net.id, 'net')
    const canDelete = net.email === email

    return {
      id: `filter-${net.id}`,
      type: 'net',
      pk: net.id,
      value: net.label,
      text: net.label,
      info: getNetExplanation(net),
      isAvailable: true,
      isSelected: isChecked({ id: net.id, type: 'net' }),
      onClick: onClickNet,
      canDelete,
      onDelete: canDelete ? onDeleteNet : null,
    }
  })

  return (
    <div className={styles.netsFilters}>
      <ToggleableSelectionOptions title="Nets" options={options} />
    </div>
  )
}

export default NetsFilters
