import React, { useEffect, useRef, useState, useCallback, useMemo, memo } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import { useDrag, useDrop } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { DropdownButton, Tooltip, useCookie } from '@knowledgehound/laika'

import { DND_VARIABLE_CARD } from 'store/constants'
import { getIsEmbedded, getIntervalVariables } from 'store/DatasetSelectors'
import { getUserEmail } from 'store/configurationDuck'
import VariableCard from '../VariableCard/VariableCard'

import { baseVariableTag as baseVariableTagClass } from './AnalysisSidebar.module.scss'

type PropsT = {
  variableToRender: VariableT, // DEV NOTE: if changing variable name, change related conditional on `VariableCardDragLayer.jsx`
  axisVariable: AxisVariableT,
  isDisabled: boolean,
  isExpanded: boolean,
  shouldHighlight: boolean,
  handleToggleOption: Function,
  handleExpand: Function,
  handleReplaceVariable: Function,
  handleEditFilter: Function,
  handleAddNet: Function,
  onDeleteNet: Function,
  handleRemoveVariable: Function,
  handleDragStart: Function,
  handleDragEnd: Function,
  handleHoverVariable: Function,
  handleDropVariable: Function,
  handleTrack: Function,
  dragLayerStyle: Object,
  isOver: boolean,
  isLineInterval: boolean,
  disableDrag: boolean,
  isBase: boolean,
  showMeanPopUp: string,
  clearMeanPopUp: Function,
}

function AnalysisVariableCard({
  variableToRender,
  axisVariable,
  isDisabled,
  isExpanded,
  shouldHighlight,
  handleToggleOption,
  handleExpand,
  handleReplaceVariable,
  handleEditFilter,
  handleAddNet,
  onDeleteNet,
  handleTrack,
  handleDragStart,
  handleDragEnd,
  handleRemoveVariable,
  handleHoverVariable,
  handleDropVariable,
  dragLayerStyle,
  disableDrag,
  isLineInterval,
  isBase,
  showMeanPopUp,
  clearMeanPopUp,
}: PropsT) {
  const cardRef = useRef()
  const isEmbedded = useSelector(getIsEmbedded, shallowEqual)
  const email = useSelector(getUserEmail, shallowEqual)
  const intervalVars = useSelector(getIntervalVariables, shallowEqual)
  const [hasMeansCookie, setCookieValue] = useCookie('means-tooltip-seen')
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isMeanTooltipOpen, setIsMeanTooltipOpen] = useState(!hasMeansCookie && !!showMeanPopUp)

  const [{ isOver }, drop] = useDrop({
    accept: DND_VARIABLE_CARD,
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
    canDrop: () => !isDisabled && !isLineInterval,
    hover: item => {
      if (isLineInterval) return

      const { id: overId, axis: overAxis } = axisVariable
      const {
        axisVariable: { id: dragId, axis: dragAxis },
      } = item

      if (dragId === overId) return

      handleHoverVariable(dragId, dragAxis, overId, overAxis)
    },
    drop: () => {
      handleDropVariable()
    },
  })

  const [{ isDragging }, drag, dragPreviewRef] = useDrag({
    item: { type: DND_VARIABLE_CARD },
    canDrag: monitor => {
      return !disableDrag && !monitor.isDragging()
    },
    begin: (item, monitor) => {
      handleDragStart(variableToRender.id, axisVariable.axis)

      return {
        variableToRender,
        axisVariable,
      }
    },
    end: (item, monitor) => {
      if (!monitor.didDrop()) {
        handleDragEnd()
      }
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  // Render empty image when dragging. Rendering of dragged component is handled by VariableCardDragLayer.js
  useEffect(() => {
    dragPreviewRef(getEmptyImage(), { captureDraggingState: true })
    drag(drop(cardRef))
  }, [dragPreviewRef, drag, drop, cardRef])

  const onEditFilter = useCallback(() => {
    if (!handleEditFilter) return
    setIsMenuOpen(false)
    const resourceType =
      variableToRender.isGrid && variableToRender.fundamentalQuestionType.includes('Numeric')
        ? 'breakouts'
        : variableToRender.resourceType
    handleEditFilter(variableToRender.variableNk, resourceType)
  }, [variableToRender, handleEditFilter])

  const onReplaceVariable = useCallback(() => {
    setIsMenuOpen(false)
    handleReplaceVariable(axisVariable, axisVariable.axis)
  }, [axisVariable, handleReplaceVariable])

  const onRemoveVariable = useCallback(() => {
    handleRemoveVariable(variableToRender)
  }, [handleRemoveVariable, variableToRender])

  const onAddNet = useCallback(() => {
    setIsMenuOpen(false)
    handleAddNet(variableToRender)
  }, [variableToRender, handleAddNet])

  const onToggleOption = useCallback(
    option => handleToggleOption(axisVariable, option),
    [handleToggleOption, axisVariable]
  )

  const onExpand = useCallback(
    () => handleExpand(variableToRender, !isExpanded),
    [handleExpand, isExpanded, variableToRender]
  )

  const dropdownMenuItems = useMemo(() => {
    const items = [
      <DropdownButton.MenuItem
        key="filter"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          onEditFilter()
        }}
        disabled={isDisabled || !handleEditFilter}
        icon="filter"
        small
      >
        Filter data
      </DropdownButton.MenuItem>,
      <DropdownButton.MenuItem
        key="create-net"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          onAddNet()
        }}
        disabled={isDisabled}
        icon="combine"
        small
      >
        Create Net
      </DropdownButton.MenuItem>,
    ]
    if (!isLineInterval) {
      items.push(
        <DropdownButton.MenuItem
          key="trash"
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            onRemoveVariable()
          }}
          disabled={isDisabled}
          icon="trash"
          small
        >
          Remove
        </DropdownButton.MenuItem>
      )
    }
    if (!isLineInterval || intervalVars.length > 1) {
      items.unshift(
        <DropdownButton.MenuItem
          key="breakouts"
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            onReplaceVariable()
          }}
          disabled={isDisabled}
          icon="breakouts"
          small
        >
          Change variable
        </DropdownButton.MenuItem>
      )
    }
    return items
  }, [
    isDisabled,
    isLineInterval,
    intervalVars,
    onEditFilter,
    onAddNet,
    onReplaceVariable,
    onRemoveVariable,
    handleEditFilter,
  ])

  const handleDismissMeansTooltip = useCallback(() => {
    setIsMeanTooltipOpen(false)
    clearMeanPopUp()
    if (!hasMeansCookie) {
      setCookieValue('true', isEmbedded)
    }
  }, [clearMeanPopUp, hasMeansCookie, isEmbedded, setCookieValue])

  return (
    <React.Fragment>
      <Tooltip
        isOpen={isMeanTooltipOpen}
        content="Not the data you were looking for? KnowledgeHound automatically displays the mean for questions that support means. If you prefer to view the distribution you can modify the visible options here."
        placement="right"
        variant="dismissible"
        onDismiss={handleDismissMeansTooltip}
      >
        <div ref={cardRef}>
          {isBase && <span className={baseVariableTagClass}>Base variable</span>}
          <VariableCard
            variableToRender={variableToRender}
            axisVariable={axisVariable}
            dropdownMenuItems={dropdownMenuItems}
            isMenuOpen={isMenuOpen}
            setMenuOpen={setIsMenuOpen}
            isBase={isBase}
            isExpanded={isExpanded}
            isDisabled={isDisabled}
            isDragInProgress={isDragging}
            isDragDisabled={disableDrag}
            isDropDisabled={isLineInterval}
            dragLayerStyle={dragLayerStyle}
            isOver={isOver}
            shouldHighlight={shouldHighlight}
            onClickHeader={onExpand}
          >
            {isExpanded && (
              <VariableCard.ExpandedContent
                variableToRender={variableToRender}
                axisVariable={axisVariable}
                isBase={isBase}
                isDisabled={isDisabled}
                isDragInProgress={isDragging}
                handleToggleOption={onToggleOption}
                handleToggleAll={onToggleOption}
                handleTrack={handleTrack}
                onDeleteNet={onDeleteNet}
                email={email}
              />
            )}
          </VariableCard>
        </div>
      </Tooltip>
    </React.Fragment>
  )
}

// TODO: memo can be removed after breaking up the AnalysisSidebar component
// into more discrete components. It's re-rendering to frequently
export default memo(AnalysisVariableCard)
