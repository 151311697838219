// extracted by mini-css-extract-plugin
export const actionBar = "AddVariableSlider-module__action-bar--OcNvw";
export const baseTiming = "125ms";
export const baseTimingInt = "125";
export const body = "AddVariableSlider-module__body--kNfXM";
export const borderRadius = "3px";
export const charcoal = "#2d2e2e";
export const cloudGrey = "#c8cdcf";
export const content = "AddVariableSlider-module__content--ud3u5";
export const controls = "AddVariableSlider-module__controls--SdzA2";
export const createVariableMenuContainer = "AddVariableSlider-module__create-variable-menu-container--0HM7w";
export const doveGrey = "#f1f3f5";
export const header = "AddVariableSlider-module__header--Ifi00";
export const khBlue = "#04b1e0";
export const khBlueDark = "#0376ae";
export const khBlueLight = "#7de1f4";
export const lemon = "#fae436";
export const lemonDark = "#e3cd02";
export const lemonLight = "#fff284";
export const lime = "#05c150";
export const limeDark = "#068230";
export const limeLight = "#65ec83";
export const navy = "#052976";
export const navyDark = "#04173f";
export const navyLight = "#82b1f6";
export const noVariables = "AddVariableSlider-module__no-variables--mXafp";
export const scarlet = "#e9062b";
export const scarletDark = "#9d050e";
export const scarletLight = "#f5726d";
export const searchContainer = "AddVariableSlider-module__search-container--8Q5QJ";
export const stateOverlay = "AddVariableSlider-module__state-overlay--Ef7EM";
export const stormGrey = "#969b9c";
export const title = "AddVariableSlider-module__title--z0c2s";
export const topContent = "AddVariableSlider-module__top-content--KfnQG";
export const variableNotSelected = "AddVariableSlider-module__variable-not-selected--TXrUu";
export const variableResults = "AddVariableSlider-module__variable-results--FWxDz";
export const variableSelected = "AddVariableSlider-module__variable-selected--ftstC";
export const violet = "#d401f8";
export const violetDark = "#9003a8";
export const violetLight = "#e77ef9";
export const white = "#fff";