import { camelCase, differenceWith, isEmpty, isEqual } from 'lodash'

/** local imports */
import * as actions from './DatasetActions'
import * as customWeightActions from './customWeightActions'
import { getNewFilterList, isOptionSelected, updatedAxisVarsForFilters } from '../FilterUtils'
import {
  removeVarsFromFilters,
  removeFiltersFromVars,
  compareVariables,
  transformAnalysisData,
  normalizeStatsIds,
  getUpdatedAxisVarsForChartType,
  determineBaseVariable,
  determineNewVarLayout,
} from '../Analysis2Utils'
import { getQnameFromNk } from '../util/naturalKeyUtils'
import { updateAxisVarOptions } from '../util/variableUtils'
import { COLUMN, ROW } from './constants'

// NOTE: this is referenced in FeaturedAnalysis and AnalysisThumbnail
// be aware of the affect of any changes made
export const defaultChartConfigState: ChartConfigT = {
  error: null,
  dataSettings: {
    elevatedKey: '', // used  by Pug, currently not on the FE
    weighting: 'useDefault',
    data: {
      data: {},
      statsId: '',
      groups: {},
      variables: [],
      base_size: null,
      baseQuestion: {
        id: '',
        label: '',
      },
    },
    xAxisVariables: [],
    yAxisVariables: [],
    filters: [],
    autoAdjustBaseEnabled: false,
  },
  viewSettings: {
    numberType: 'percentage',
    chartType: '',
    showLabels: true,
    showStatTesting: false,
    statsConfidence: [95],
    suppressNullValues: true,
    hideLowBase: false,
    showMeans: false,
    showUnweightedBase: false,
    sortState: {
      COLUMN: {
        sortVariables: [],
        direction: '',
      },
      ROW: {
        sortVariables: [],
        direction: '',
      },
    },
  },
}

export const defaultReducerState: DatasetReducerStateT = {
  isEmbedded: false,
  dataset: {
    isFetching: false,
    error: null,
    lastFetched: null,
    data: null,
  },
  variables: {
    searchQuery: '',
    lastFetched: null,
    error: null,
    isFetching: false,
    data: [],
    nullSuppressedOpts: {
      filters: {},
      isFetching: {},
      hasError: {},
      baseVariableId: {},
      variables: {},
    },
    customWeightCreation: {
      isCreating: false,
      hasErrorCreating: false,
      isDeleting: false,
      hasErrorDeleting: false,
      name: '',
      variableIds: [],
      selections: [],
    },
    availableWeights: {
      isFetching: false,
      hasError: false,
      intervalId: null,
      standard: [],
      custom: [],
      defaultWeight: {},
    },
  },
  chartConfig: {
    isFetching: false,
    lastFetched: null,
    error: null,
    data: defaultChartConfigState,
    staging: {
      axis: null,
      variableToReplace: null,
    },
  },
  statTesting: {
    comparisonCount: -1,
    completedCount: -1,
    statsId: '',
    intervalId: null,
    statsData: {},
    error: null,
    purged: false,
    loading: false,
  },
  chartSummary: {
    isFetching: false,
    hasError: false,
    summary: null,
  },
  a1Husk: {},
}

export const datasetReducer = (
  state: DatasetReducerStateT = defaultReducerState,
  action: ActionT
): DatasetReducerStateT => {
  switch (action.type) {
    case actions.RESET_DATASET_REDUCER_STATE: {
      return defaultReducerState
    }
    case actions.RESET_ANALYSIS_CHART: {
      return {
        ...state,
        chartConfig: {
          ...defaultReducerState.chartConfig,
        },
        statTesting: {
          ...defaultReducerState.statTesting,
        },
      }
    }
    case actions.fetchDatasetAction.loading.type: {
      return {
        ...state,
        dataset: {
          ...state.dataset,
          isFetching: true,
          error: null,
        },
        variables: {
          ...defaultReducerState.variables,
        },
        chartConfig: {
          ...defaultReducerState.chartConfig,
        },
        statTesting: {
          ...defaultReducerState.statTesting,
        },
      }
    }
    case actions.fetchDatasetAction.success.type: {
      if (!action.payload) {
        return state
      }
      const { dataset, defaultFilters } = action.payload
      const lastFetched = Date.now()
      const newDatasetState = !dataset
        ? {}
        : {
            datasetNk: dataset.dataset_name,
            client: dataset.client,
            studyId: dataset.study_id,
            sampleSize: dataset.study_size,
            studySample: dataset.studySample,
            studyName: dataset.studyName,
            studyDate: dataset.studyDate,
            suppliers: dataset.suppliers ? [...dataset.suppliers] : [],
            variables: dataset.questions ? [...dataset.questions] : [],
            defaultWeighting: dataset.default_weighting,
            rdmComplete: !!dataset.rdm_complete,
            permissions: dataset.permissions,
            defaultFilters: defaultFilters,
            defaultXtab: dataset.default_xtab,
            lowBaseThreshold: dataset.lowBaseThreshold,
            externalType: dataset.external_type,
            previewable: dataset.previewable,
            annotated: dataset.annotated,
          }

      return {
        ...state,
        dataset: {
          ...state.dataset,
          lastFetched,
          isFetching: false,
          data: newDatasetState,
          error: null,
        },
      }
    }
    case actions.fetchDatasetAction.error.type: {
      const lastFetched = Date.now()

      return {
        ...state,
        dataset: {
          ...state.dataset,
          lastFetched,
          isFetching: false,
          error: (action.payload && action.payload.message) || action.payload,
        },
      }
    }
    case actions.fetchWeightsAction.loading.type: {
      return {
        ...state,
        variables: {
          ...state.variables,
          availableWeights: {
            ...state.variables.availableWeights,
            hasError: false,
            isFetching: true,
          },
        },
      }
    }
    case actions.fetchWeightsAction.success.type: {
      if (!action.payload) return state
      const { custom, static: standard, default: defaultWeight } = action.payload
      return {
        ...state,
        variables: {
          ...state.variables,
          isFetching: false,
          availableWeights: {
            ...state.variables.availableWeights,
            defaultWeight,
            standard,
            custom: custom.map(weight => ({
              id: weight.id,
              specId: weight.weighting_id,
              isDataReady: weight.is_data_ready,
              isValid: weight.is_valid,
              label: weight.label,
              userIsOwner: weight.owned,
            })),
          },
        },
      }
    }
    case actions.fetchWeightsAction.error.type: {
      return {
        ...state,
        variables: {
          ...state.variables,
          availableWeights: {
            ...state.variables.availableWeights,
            hasError: action.payload,
            isFetching: false,
          },
        },
      }
    }
    case actions.SET_WEIGHT_POLL_INTERVAL_ID: {
      return {
        ...state,
        variables: {
          ...state.variables,
          availableWeights: {
            ...state.variables.availableWeights,
            intervalId: action.intervalId,
          },
        },
      }
    }
    case actions.fetchVariableAction.loading.type: {
      return {
        ...state,
        variables: {
          ...state.variables,
          isFetching: true,
        },
      }
    }
    case actions.SET_FETCH_VARIABLE_LOADING: {
      return {
        ...state,
        variables: {
          ...state.variables,
          isFetching: action.payload.isFetching,
        },
      }
    }
    case actions.fetchVariableAction.success.type: {
      if (!action.payload) {
        console.error('No payload detected in fetchVariableAction success', action)
        return state
      }
      const lastFetched = Date.now()
      // this fixme is because flow doesnt know action payload type, as its generic
      // $FlowFixMe
      const newVariables: VariableT = action.payload
      // Do not want to add copies of the same variable, so check first
      const newVariablesArr = [
        ...differenceWith(state.variables.data, newVariables, compareVariables),
        ...newVariables,
      ]

      return {
        ...state,
        variables: {
          ...state.variables,
          lastFetched,
          isFetching: false,
          data: newVariablesArr,
        },
      }
    }
    case actions.STAGE_ADD_FOR_AXIS: {
      return {
        ...state,
        chartConfig: {
          ...state.chartConfig,
          staging: {
            ...state.chartConfig.staging,
            axis: action.axis,
          },
        },
      }
    }
    case actions.fetchVariableAction.error.type: {
      const lastFetched = Date.now()

      return {
        ...state,
        variables: {
          ...state.variables,
          isFetching: false,
          lastFetched,
          error: action.payload,
        },
      }
    }
    case actions.fetchAnalysisDataAction.loading.type: {
      return {
        ...state,
        chartConfig: {
          ...state.chartConfig,
          isFetching: true,
          error: null,
        },
      }
    }
    case actions.fetchAnalysisDataAction.success.type: {
      if (!action.payload) return state

      const { fetchedData, base } = action.payload
      const {
        dataSettings: { xAxisVariables, yAxisVariables },
        viewSettings: { showMeans },
      } = state.chartConfig.data
      const variables = state.variables.data
      const lastFetched = Date.now()
      let meanMode = false

      const updateAxisVarsForMeans = (axisVars, catOrNumeric, baseId) => {
        if (!baseId) return axisVars

        return axisVars.map(av => {
          if (av.id === baseId && catOrNumeric) {
            meanMode = Boolean(showMeans)
            if (!av.selectedOptions.find(o => o.id === '__mean__')) {
              av.selectedOptions.unshift({
                id: '__mean__',
                type: 'calculated',
                selected: Boolean(showMeans),
              })
            }
            return av
          } else {
            return {
              ...av,
              selectedOptions: av.selectedOptions.map(o => {
                return o.id !== '__mean__'
                  ? o
                  : {
                      ...o,
                      selected: false,
                    }
              }),
            }
          }
        })
      }

      const baseQuestion = state.variables.data.find(v => v.id === `${base[0]}${base[1]}`)

      const mappedVariables = fetchedData.variables.map(v => ({
        id: v.id,
        questionName: v.name,
        resourceType: v.resource_type,
      }))

      const updatedFetchedData = transformAnalysisData(
        mappedVariables,
        fetchedData.data,
        fetchedData.groups,
        fetchedData.unweighted_groups
      )

      let catOrNumeric = false

      const updatedVariables = variables.map(v => {
        if (v.id === baseQuestion.id && v.meansEligible) {
          catOrNumeric = true
          return !v.calculated.find(o => o.id === '__mean__')
            ? {
                ...v,
                calculated: [
                  {
                    id: '__mean__',
                    type: 'calculated',
                    label: 'Mean',
                  },
                  ...v.calculated,
                ],
              }
            : v
        }
        return v
      })

      const updatedX = updateAxisVarsForMeans(
        xAxisVariables,
        catOrNumeric,
        baseQuestion && baseQuestion.id
      )
      const updatedY = updateAxisVarsForMeans(
        yAxisVariables,
        catOrNumeric,
        baseQuestion && baseQuestion.id
      )

      return {
        ...state,
        variables: {
          ...state.variables,
          data: updatedVariables,
        },
        chartConfig: {
          ...state.chartConfig,
          error: (action.payload && action.payload.error) || null,
          isFetching: false,
          lastFetched,
          data: {
            ...state.chartConfig.data,
            dataSettings: {
              ...state.chartConfig.data.dataSettings,
              data: {
                baseQuestion: baseQuestion
                  ? {
                      id: baseQuestion.id,
                      label: baseQuestion.label,
                    }
                  : {
                      id: '',
                      label: '',
                    },
                data: updatedFetchedData.data,
                variables: mappedVariables,
                groups: updatedFetchedData.groups,
                unweighted_groups: updatedFetchedData.unweightedGroups,
                base_size: fetchedData.base_size,
                unweighted_base_size: fetchedData.unweighted_base_size,
                statsId: fetchedData.stats_id,
              },
              xAxisVariables: updatedX,
              yAxisVariables: updatedY,
            },
            viewSettings: {
              ...state.chartConfig.data.viewSettings,
              showMeans: meanMode,
            },
          },
        },
      }
    }
    case actions.fetchAnalysisDataAction.error.type: {
      if (!action.payload) {
        return state
      }
      const lastFetched = Date.now()

      return {
        ...state,
        chartConfig: {
          ...state.chartConfig,
          isFetching: false,
          lastFetched,
          error: (action.payload && action.payload.message) || action.payload,
          data: {
            ...state.chartConfig.data,
            dataSettings: {
              ...state.chartConfig.data.dataSettings,
              data: defaultChartConfigState.dataSettings.data,
            },
            viewSettings: {
              ...state.chartConfig.data.viewSettings,
              sortState: defaultChartConfigState.viewSettings.sortState,
            },
          },
        },
      }
    }
    case actions.CLEAR_ANALYSIS_DATA: {
      const { keepBase } = action.payload
      return {
        ...state,
        chartConfig: {
          ...state.chartConfig,
          isFetching: false,
          error: null,
          data: {
            ...state.chartConfig.data,
            dataSettings: {
              ...state.chartConfig.data.dataSettings,
              data: {
                ...defaultChartConfigState.dataSettings.data,
                baseQuestion: keepBase
                  ? state.chartConfig.data.dataSettings.data.baseQuestion
                  : defaultChartConfigState.dataSettings.data.baseQuestion,
              },
            },
          },
        },
      }
    }

    case actions.ADD_SUPPRESSED_OPTIONS: {
      return {
        ...state,
        variables: {
          ...state.variables,
          nullSuppressedOpts: {
            baseVariableId: {
              ...state.variables.nullSuppressedOpts.baseVariableId,
              [action.variableNk]: action.baseVariableId,
            },
            hasError: {
              ...state.variables.nullSuppressedOpts.hasError,
              [action.variableNk]: false,
            },
            filters: {
              ...state.variables.nullSuppressedOpts.filters,
              [action.variableNk]: action.filters,
            },
            isFetching: {
              ...state.variables.nullSuppressedOpts.isFetching,
              [action.variableNk]: false,
            },
            variables: {
              ...state.variables.nullSuppressedOpts.variables,
              [action.variableNk]: action.options,
            },
          },
        },
      }
    }

    case actions.SET_SUPPRESSED_OPTIONS_FETCHING: {
      return {
        ...state,
        variables: {
          ...state.variables,
          nullSuppressedOpts: {
            ...state.variables.nullSuppressedOpts,
            isFetching: {
              ...state.variables.nullSuppressedOpts.isFetching,
              [action.variableNk]: action.isFetching,
            },
          },
        },
      }
    }

    case actions.SET_SUPPRESSED_OPTIONS_ERROR: {
      return {
        ...state,
        variables: {
          ...state.variables,
          nullSuppressedOpts: {
            ...state.variables.nullSuppressedOpts,
            hasError: {
              ...state.variables.nullSuppressedOpts.hasError,
              [action.variableNk]: action.error,
            },
            isFetching: {
              ...state.variables.nullSuppressedOpts.isFetching,
              [action.variableNk]: false,
            },
          },
        },
      }
    }

    case actions.ADD_NET_TO_VARIABLE: {
      if (!action.payload) {
        return state
      }

      const { response, updatedVariable, fromFilterModal } = action.payload
      const lastFetched = Date.now()
      const variableType = updatedVariable.resourceType
      const newVariables = state.variables.data.map(v =>
        v.variableNk === response.question_nk && v.resourceType === variableType
          ? updatedVariable
          : v
      )

      const newVariablesFromAxis = axisVariables => {
        const netIncludesSelectedOption = opt => {
          if (response.response_rules && !isEmpty(response.response_rules)) {
            const method = Object.keys(response.response_rules)[0]
            return isOptionSelected(
              opt,
              [{ id: response.response_rules[method], type: 'option' }],
              method
            )
          }
          const netResponses = variableType === 'options' ? response.responses : response.breakouts
          return netResponses.includes(opt.label)
        }

        return axisVariables.map(v => {
          if (v.variableNk === response.question_nk && v.resourceType === variableType) {
            const variable = newVariables.find(
              newVar =>
                newVar.variableNk === response.question_nk && newVar.resourceType === variableType
            )
            if (!variable) {
              return v
            }
            const newNets = variable.nets.map(net => {
              const axisNet = v.selectedOptions.find(
                axisO => axisO.id === net.id && axisO.type === 'net'
              )
              return {
                id: `${net.id}`,
                type: 'net',
                selected:
                  (`${response.pk}` === net.id && !fromFilterModal) ||
                  (axisNet && axisNet.selected),
              }
            })
            const newOptions = variable.options.map(o => {
              const axisOpt = v.selectedOptions.find(
                axisO => axisO.id === o.id && axisO.type === o.type
              )
              return {
                id: o.id,
                type: o.type,
                selected:
                  netIncludesSelectedOption(o) && !fromFilterModal
                    ? false
                    : axisOpt.selected || false,
              }
            })
            const newCalc = variable.calculated.map(o => {
              const calcOpt = v.selectedOptions.find(
                calcO => calcO.id === o.id && calcO.type === o.type
              )
              return {
                id: o.id,
                type: o.type,
                selected: (calcOpt && calcOpt.selected) || false,
              }
            })
            return {
              ...v,
              selectedOptions: [...newCalc, ...newNets, ...newOptions],
            }
          }
          return v
        })
      }

      const newXAxisVars = newVariablesFromAxis(state.chartConfig.data.dataSettings.xAxisVariables)
      const newYAxisVars = newVariablesFromAxis(state.chartConfig.data.dataSettings.yAxisVariables)

      return {
        ...state,
        chartConfig: {
          ...state.chartConfig,
          error: null,
          data: {
            ...state.chartConfig.data,
            dataSettings: {
              ...state.chartConfig.data.dataSettings,
              xAxisVariables: newXAxisVars,
              yAxisVariables: newYAxisVars,
            },
          },
          lastFetched,
        },
        variables: {
          ...state.variables,
          data: newVariables,
          lastFetched,
        },
      }
    }
    case actions.REMOVE_NET_FROM_VARIABLE: {
      if (!action.payload) {
        return state
      }

      const { variableNaturalKey, variableType, netId } = action.payload

      const newVariables = state.variables.data.map(v => {
        if (v.variableNk === variableNaturalKey && v.resourceType === variableType) {
          const newNets = v.nets.filter(n => n.id !== netId)
          return {
            ...v,
            nets: newNets,
          }
        }
        return v
      })

      // remove the whole filter if the only responses are the net being removed
      const varQuestionName = getQnameFromNk(variableNaturalKey)
      let newFilters = []
      state.chartConfig.data.dataSettings.filters.forEach(f => {
        if (f.questionName === varQuestionName && f.variableResourceType === variableType) {
          const newFilteredOptions = f.filteredOptions.filter(
            option => !(option.id === netId && option.type === 'net')
          )
          if (newFilteredOptions.length > 0) {
            newFilters.push({
              ...f,
              filteredOptions: newFilteredOptions,
            })
          }
        } else {
          newFilters.push(f)
        }
      })

      const filterSelectedOptions = axisVariables => {
        return axisVariables.map(v => {
          if (v.variableNk === variableNaturalKey && v.resourceType === variableType) {
            const newSelectedOptions = v.selectedOptions.filter(
              o => o.id.toString() !== netId.toString() || o.type !== 'net'
            )
            return {
              ...v,
              selectedOptions: newSelectedOptions,
            }
          }
          return v
        })
      }

      const newXAxisVariables = filterSelectedOptions(
        state.chartConfig.data.dataSettings.xAxisVariables
      )
      const newYAxisVariables = filterSelectedOptions(
        state.chartConfig.data.dataSettings.yAxisVariables
      )

      return {
        ...state,
        chartConfig: {
          ...state.chartConfig,
          error: null,
          data: {
            ...state.chartConfig.data,
            dataSettings: {
              ...state.chartConfig.data.dataSettings,
              filters: newFilters,
              xAxisVariables: newXAxisVariables,
              yAxisVariables: newYAxisVariables,
            },
          },
        },
        variables: {
          ...state.variables,
          data: newVariables,
        },
      }
    }
    case actions.ADD_VARIABLES_TO_AXIS: {
      const {
        newVars,
        axis,
        selected: {
          nets: selectedNets = [],
          options = [],
          breakouts = [],
          calculated = [],
          calculatedBreakout = [],
        },
        replacingPosition,
        defaultMean,
        setMeanPopUp,
        fromPageLoadWithXtab,
      } = action.payload

      const { xAxisVariables, yAxisVariables } = state.chartConfig.data.dataSettings

      const { updatedY, updatedX, meanMode } = determineNewVarLayout({
        xAxisVariables,
        yAxisVariables,
        axis,
        newVars,
        replacingPosition,
        selectedNets,
        options,
        breakouts,
        calculated,
        calculatedBreakout,
        chartType: state.chartConfig.data.viewSettings.chartType,
        defaultMean,
        fromPageLoadWithXtab,
      })

      if (meanMode && setMeanPopUp) setMeanPopUp(newVars[0].id)

      return {
        ...state,
        chartConfig: {
          ...state.chartConfig,
          error: null,
          data: {
            ...state.chartConfig.data,
            dataSettings: {
              ...state.chartConfig.data.dataSettings,
              xAxisVariables: updatedX,
              yAxisVariables: updatedY,
            },
            viewSettings: {
              ...state.chartConfig.data.viewSettings,
              sortState: defaultChartConfigState.viewSettings.sortState,
              showMeans: meanMode,
            },
          },
        },
      }
    }
    case actions.SET_VARIABLE_POSITION: {
      if (!action.payload) return state

      const { newXAxis, newYAxis } = action.payload
      const { xAxisVariables, yAxisVariables } = state.chartConfig.data.dataSettings

      const combinedAxis = [...xAxisVariables, ...yAxisVariables]

      let couldNotFindVariable = false

      const mapAxisFromNk = id => {
        const found = combinedAxis.find(variable => variable.id === id)
        if (!found) {
          couldNotFindVariable = true
          return null
        }

        return {
          ...found,
          selectedOptions: [...found.selectedOptions],
        }
      }
      const mappedXAxis = newXAxis.map(mapAxisFromNk).map(v => {
        return {
          ...v,
          axis: ROW,
        }
      })
      const mappedYAxis = newYAxis.map(mapAxisFromNk).map(v => {
        return {
          ...v,
          axis: COLUMN,
        }
      })

      if (couldNotFindVariable) {
        console.error(
          'Could not find requested variable key and type combination from ',
          action.payload,
          'in',
          combinedAxis
        )
        return state
      }

      return {
        ...state,
        chartConfig: {
          ...state.chartConfig,
          data: {
            ...state.chartConfig.data,
            dataSettings: {
              ...state.chartConfig.data.dataSettings,
              xAxisVariables: mappedXAxis,
              yAxisVariables: mappedYAxis,
            },
            viewSettings: {
              ...state.chartConfig.data.viewSettings,
              sortState: defaultChartConfigState.viewSettings.sortState,
            },
          },
        },
      }
    }
    case actions.SET_AXIS_VARIABLES: {
      if (!action.payload) {
        return state
      }

      const { xAxisVariables, yAxisVariables } = action.payload

      return {
        ...state,
        chartConfig: {
          ...state.chartConfig,
          error: null,
          data: {
            ...state.chartConfig.data,
            dataSettings: {
              ...state.chartConfig.data.dataSettings,
              xAxisVariables,
              yAxisVariables,
            },
          },
        },
      }
    }
    // Remove a variable from both axes regardless of type
    case actions.REMOVE_AXIS_VARIABLE: {
      if (!action.payload) {
        return state
      }

      const { variableNk } = action.payload

      const filteredXAxis = state.chartConfig.data.dataSettings.xAxisVariables.filter(
        v => v.variableNk !== variableNk
      )
      const filteredYAxis = state.chartConfig.data.dataSettings.yAxisVariables.filter(
        v => v.variableNk !== variableNk
      )

      return {
        ...state,
        chartConfig: {
          ...state.chartConfig,
          error: null,
          data: {
            ...state.chartConfig.data,
            dataSettings: {
              ...state.chartConfig.data.dataSettings,
              xAxisVariables: filteredXAxis,
              yAxisVariables: filteredYAxis,
            },
            viewSettings: {
              ...state.chartConfig.data.viewSettings,
              sortState: defaultChartConfigState.viewSettings.sortState,
            },
          },
        },
      }
    }
    case actions.REMOVE_VARIABLE_FROM_VAR_LIST: {
      if (!action.payload) {
        return state
      }

      const { variableNk } = action.payload

      const currentFilters = state.chartConfig.data.dataSettings.filters

      // dont remove variable from variables list if there is a filter applied
      const varQuestionName = getQnameFromNk(variableNk)
      const updatedVariables = currentFilters.find(f => f.questionName === varQuestionName)
        ? state.variables.data
        : state.variables.data.filter(v => v.variableNk !== variableNk)

      return {
        ...state,
        variables: {
          ...state.variables,
          data: updatedVariables,
        },
      }
    }
    case actions.SWAP_AXIS_VARIABLES: {
      const { xAxisVariables, yAxisVariables } = state.chartConfig.data.dataSettings

      const newXAxisVariables = yAxisVariables.map(v => {
        return {
          ...v,
          axis: ROW,
        }
      })

      const newYAxisVariables = xAxisVariables.map(v => {
        return {
          ...v,
          axis: COLUMN,
        }
      })

      return {
        ...state,
        chartConfig: {
          ...state.chartConfig,
          data: {
            ...state.chartConfig.data,
            dataSettings: {
              ...state.chartConfig.data.dataSettings,
              xAxisVariables: newXAxisVariables,
              yAxisVariables: newYAxisVariables,
            },
            viewSettings: {
              ...state.chartConfig.data.viewSettings,
              sortState: defaultChartConfigState.viewSettings.sortState,
            },
          },
        },
      }
    }
    case actions.TOGGLE_OPTION: {
      if (!action.payload) {
        return state
      }
      const { id, axis, optionId, optionType, updatedVars, isSelected } = action.payload

      if (!state.chartConfig.data || !state.chartConfig.data.dataSettings) {
        return state
      }

      const dataSettings = {
        ...state.chartConfig.data.dataSettings,
        ...(axis === ROW
          ? {
              xAxisVariables: updatedVars,
            }
          : {
              yAxisVariables: updatedVars,
            }),
      }

      const sortMatch = (sortVariables, varId, optionId, optionType) => {
        return sortVariables.find(sortedVar => {
          return (
            varId === sortedVar.varId &&
            sortedVar.optionId === optionId &&
            sortedVar.optionType === optionType
          )
        })
      }

      const sortState = state.chartConfig.data.viewSettings.sortState
      const selectedOptIsSorted = !isSelected
        ? sortMatch(sortState[axis].sortVariables, id, optionId, optionType)
        : null

      return {
        ...state,
        chartConfig: {
          ...state.chartConfig,
          data: {
            ...state.chartConfig.data,
            dataSettings,
            viewSettings: {
              ...state.chartConfig.data.viewSettings,
              showMeans:
                optionId === '__mean__'
                  ? isSelected
                  : state.chartConfig.data.viewSettings.showMeans,
              sortState:
                !isSelected && selectedOptIsSorted
                  ? {
                      ...state.chartConfig.data.viewSettings.sortState,
                      [axis]: {
                        sortVariables: [],
                        direction: '',
                      },
                    }
                  : { ...state.chartConfig.data.viewSettings.sortState },
            },
          },
        },
        statTesting: {
          ...state.statTesting,
          completedCount: isSelected ? state.statTesting.completedCount : -1,
          comparisonCount: isSelected ? state.statTesting.comparisonCount : -1,
        },
      }
    }
    case actions.TOGGLE_ALL_OPTIONS: {
      if (!action.payload) {
        return state
      }

      const { id, axis, updatedVars, someSelected } = action.payload

      // if any option is selected, deselect all, including if all options are selected
      // if no options are selected, select all.
      // does not include nets or calculated options
      const dataSettings = {
        ...state.chartConfig.data.dataSettings,
        ...(axis === ROW
          ? {
              xAxisVariables: updatedVars,
            }
          : {
              yAxisVariables: updatedVars,
            }),
      }
      const varWasSorted = someSelected
        ? state.chartConfig.data.viewSettings.sortState[axis].sortVariables.find(
            sortedVar => sortedVar.varId === id
          )
        : null

      return {
        ...state,
        chartConfig: {
          ...state.chartConfig,
          data: {
            ...state.chartConfig.data,
            dataSettings,
            viewSettings:
              someSelected && varWasSorted
                ? {
                    ...state.chartConfig.data.viewSettings,
                    sortState: {
                      ...state.chartConfig.data.viewSettings.sortState,
                      [axis]: {
                        sortVariables: [],
                        direction: '',
                      },
                    },
                  }
                : { ...state.chartConfig.data.viewSettings },
          },
        },
      }
    }
    case actions.ADD_FILTER: {
      if (!action.payload) {
        return state
      }

      const { filterData } = action.payload
      const selectedVariable = filterData.selectedVariable
      const newFilter = {
        questionName: selectedVariable.questionName,
        variableResourceType: selectedVariable.resourceType,
        method: filterData.selectedFilterMethod,
        filteredOptions: filterData.selectedOptions,
      }

      if (filterData.selectedGridBreakout) {
        newFilter.breakoutId = filterData.selectedGridBreakout.id
      }

      const sortMatch = sortVariables =>
        sortVariables.find(
          sortedVar =>
            selectedVariable &&
            selectedVariable.id === sortedVar.varId &&
            !isOptionSelected(
              { id: sortedVar.optionId, type: sortedVar.optionType },
              filterData.selectedOptions.map(fo => fo.id),
              filterData.selectedFilterMethod
            )
        )

      const sortState = state.chartConfig.data.viewSettings.sortState
      const filterMatchesSort =
        sortMatch(sortState[ROW].sortVariables) || sortMatch(sortState[COLUMN].sortVariables)

      const updatedDataSettings = {
        ...state.chartConfig.data.dataSettings,
        filters: getNewFilterList(
          state.chartConfig.data.dataSettings.filters,
          newFilter,
          filterData.filterToReplace
        ),
      }

      // Need to update selected options if NOT autoAdjustBaseEnabled or
      // filtered by null suppression
      if (!state.chartConfig.data.viewSettings.suppressNullValues) {
        const { xAxisVariables, yAxisVariables, autoAdjustBaseEnabled } =
          state.chartConfig.data.dataSettings
        const { updatedY, updatedX } = updatedAxisVarsForFilters(
          autoAdjustBaseEnabled,
          xAxisVariables,
          yAxisVariables,
          filterData,
          state.variables.data
        )
        updatedDataSettings.xAxisVariables = updatedX
        updatedDataSettings.yAxisVariables = updatedY
      }

      return {
        ...state,
        chartConfig: {
          ...state.chartConfig,
          error: null,
          data: {
            ...state.chartConfig.data,
            dataSettings: updatedDataSettings,
            viewSettings: filterMatchesSort
              ? {
                  ...state.chartConfig.data.viewSettings,
                  sortState: defaultChartConfigState.viewSettings.sortState,
                }
              : { ...state.chartConfig.data.viewSettings },
          },
        },
      }
    }
    case actions.REMOVE_FILTERS: {
      if (!action.payload) {
        return state
      }

      const { filtersToRemove, resetFilters, clearFromVars } = action.payload

      const newFilterList = resetFilters
        ? []
        : differenceWith(state.chartConfig.data.dataSettings.filters, filtersToRemove, isEqual)

      // dont want to remove from variables if it is also an axis variable or if that var is used in another filter
      const { xAxisVariables, yAxisVariables } = state.chartConfig.data.dataSettings
      const filtersToRemoveFromVars = clearFromVars
        ? differenceWith(
            removeVarsFromFilters([...xAxisVariables, ...yAxisVariables], filtersToRemove),
            newFilterList,
            (tr, nf) => {
              if (nf.breakoutId) {
                return tr.questionName === nf.questionName
              }
              return (
                tr.questionName === nf.questionName &&
                tr.variableResourceType === nf.variableResourceType
              )
            }
          )
        : []

      return {
        ...state,
        chartConfig: {
          ...state.chartConfig,
          error: null,
          data: {
            ...state.chartConfig.data,
            dataSettings: {
              ...state.chartConfig.data.dataSettings,
              filters: newFilterList,
            },
          },
        },
        variables: {
          ...state.variables,
          data: clearFromVars
            ? removeFiltersFromVars(state.variables.data, filtersToRemoveFromVars)
            : state.variables.data,
        },
      }
    }
    case actions.setDefaultFiltersAction.loading.type: {
      return {
        ...state,
        variables: {
          ...state.variables,
          isFetching: true,
        },
      }
    }
    case actions.setDefaultFiltersAction.success.type: {
      if (!action.payload) {
        return state
      }
      const lastFetched = Date.now()

      return {
        ...state,
        variables: {
          ...state.variables,
          lastFetched,
          isFetching: false,
          data: action.payload
            ? [...state.variables.data, ...action.payload.varData]
            : state.variables.data,
        },
        chartConfig: {
          ...state.chartConfig,
          isFetching: false,
          lastFetched,
          error: null,
          data: {
            ...state.chartConfig.data,
            dataSettings: {
              ...state.chartConfig.data.dataSettings,
              filters: (action.payload && action.payload.defaultFilters) || [],
            },
          },
        },
      }
    }
    case actions.setDefaultFiltersAction.error.type: {
      const lastFetched = Date.now()

      return {
        ...state,
        variables: {
          ...state.variables,
          isFetching: false,
          lastFetched,
          error: action.payload,
        },
      }
    }

    case actions.SET_CHART_DISPLAY_TYPE: {
      if (!action.payload) {
        return state
      }

      const { displayType } = action.payload

      return {
        ...state,
        chartConfig: {
          ...state.chartConfig,
          error: null,
          data: {
            ...state.chartConfig.data,
            viewSettings: {
              ...state.chartConfig.data.viewSettings,
              numberType: displayType,
            },
          },
        },
      }
    }
    case actions.SET_CHART_TYPE: {
      if (!action.payload) {
        return state
      }

      const { chartType, interval } = action.payload

      const { xAxisVariables, yAxisVariables } = state.chartConfig.data.dataSettings
      // if we have never fetched (such as page load) the base Question variable can have empty id
      const baseQuestion = state.chartConfig.data.dataSettings.data.baseQuestion.id
        ? state.chartConfig.data.dataSettings.data.baseQuestion
        : determineBaseVariable(xAxisVariables, yAxisVariables, chartType)

      const { newXAxis, newYAxis } = getUpdatedAxisVarsForChartType(
        xAxisVariables,
        yAxisVariables,
        baseQuestion,
        chartType,
        interval
      )

      return {
        ...state,
        chartConfig: {
          ...state.chartConfig,
          data: {
            ...state.chartConfig.data,
            viewSettings: {
              ...state.chartConfig.data.viewSettings,
              chartType,
            },
            dataSettings: {
              ...state.chartConfig.data.dataSettings,
              xAxisVariables: newXAxis.map(v => ({ ...v, axis: ROW })),
              yAxisVariables: newYAxis.map(v => ({ ...v, axis: COLUMN })),
            },
          },
        },
      }
    }

    case actions.TOGGLE_HIDE_LOW_BASE: {
      const { payload } = action
      if (!payload) return state

      const hideLowBase = payload.hideLowBase ?? !state.chartConfig.data.viewSettings.hideLowBase

      return {
        ...state,
        chartConfig: {
          ...state.chartConfig,
          error: null,
          data: {
            ...state.chartConfig.data,
            viewSettings: {
              ...state.chartConfig.data.viewSettings,
              hideLowBase,
            },
          },
        },
      }
    }

    case actions.TOGGLE_SHOW_UNWEIGHTED_BASE: {
      return {
        ...state,
        chartConfig: {
          ...state.chartConfig,
          error: null,
          data: {
            ...state.chartConfig.data,
            viewSettings: {
              ...state.chartConfig.data.viewSettings,
              showUnweightedBase:
                action.showUnweightedBase ??
                !state.chartConfig.data.viewSettings.showUnweightedBase,
            },
          },
        },
      }
    }

    case actions.TOGGLE_SHOW_LABELS: {
      const { payload } = action
      if (!payload) return state

      const showLabels = payload.showLabels ?? !state.chartConfig.data.viewSettings.showLabels

      return {
        ...state,
        chartConfig: {
          ...state.chartConfig,
          error: null,
          data: {
            ...state.chartConfig.data,
            viewSettings: {
              ...state.chartConfig.data.viewSettings,
              showLabels,
            },
          },
        },
      }
    }

    case actions.TOGGLE_AUTO_ADJUST_BASE: {
      const { payload } = action
      if (!payload) return state

      const autoAdjustBaseEnabled =
        payload.autoAdjustBaseEnabled ?? !state.chartConfig.data.dataSettings.autoAdjustBaseEnabled

      return {
        ...state,
        chartConfig: {
          ...state.chartConfig,
          error: null,
          data: {
            ...state.chartConfig.data,
            dataSettings: {
              ...state.chartConfig.data.dataSettings,
              autoAdjustBaseEnabled,
            },
          },
        },
      }
    }

    case actions.TOGGLE_NULL_SUPPRESSION: {
      const suppressNullValues =
        action.nullSuppressionEnabled ?? !state.chartConfig.data.viewSettings.suppressNullValues

      return {
        ...state,
        chartConfig: {
          ...state.chartConfig,
          data: {
            ...state.chartConfig.data,
            viewSettings: {
              ...state.chartConfig.data.viewSettings,
              suppressNullValues,
            },
          },
        },
      }
    }

    case actions.SET_STAT_TESTING_CONFIDENCE: {
      const { payload } = action
      if (!payload) return state

      return {
        ...state,
        chartConfig: {
          ...state.chartConfig,
          error: null,
          data: {
            ...state.chartConfig.data,
            viewSettings: {
              ...state.chartConfig.data.viewSettings,
              showStatTesting: payload.showStatTesting,
              statsConfidence: payload.confidence,
            },
          },
        },
      }
    }

    case actions.SET_SORT: {
      if (!action.payload || !action.payload.sortState) {
        return state
      }

      const newSortState = action.payload.sortState

      return {
        ...state,
        chartConfig: {
          ...state.chartConfig,
          error: null,
          data: {
            ...state.chartConfig.data,
            viewSettings: {
              ...state.chartConfig.data.viewSettings,
              sortState: newSortState,
            },
          },
        },
      }
    }

    case actions.updateChartStateFromUrlAction.loading.type: {
      return {
        ...state,
        chartConfig: {
          ...state.chartConfig,
          isFetching: true,
        },
      }
    }
    case actions.updateChartStateFromUrlAction.success.type: {
      if (
        !action.payload ||
        !action.payload.stateData ||
        !action.payload.stateData.state ||
        !action.payload.varData
      ) {
        return {
          ...state,
          chartConfig: {
            ...state.chartConfig,
            isFetching: false,
          },
        }
      } else {
        const {
          varData,
          stateData: {
            state: {
              filters,
              viewSettings,
              xAxisVariables,
              yAxisVariables,
              autoAdjustBaseEnabled,
              weighting,
            },
          },
        } = action.payload
        const lastFetched = Date.now()

        if (!viewSettings.chartType) {
          viewSettings.chartType = 'spreadsheet'
        }

        if (!viewSettings.hasOwnProperty('suppressNullValues')) {
          viewSettings.suppressNullValues = false
        }

        // If any variable options or nets have been added/removed, we want to reflect that
        const updatedXAxisVar = updateAxisVarOptions(xAxisVariables, varData)
        const updatedYAxisVar = updateAxisVarOptions(yAxisVariables, varData)
        return {
          ...state,
          chartConfig: {
            ...state.chartConfig,
            isFetching: false,
            lastFetched,
            error: null,
            data: {
              ...state.chartConfig.data,
              dataSettings: {
                ...defaultChartConfigState.dataSettings,
                ...state.chartConfig.data.dataSettings,
                xAxisVariables: updatedXAxisVar || [],
                yAxisVariables: updatedYAxisVar || [],
                filters: filters || [],
                autoAdjustBaseEnabled,
                weighting,
              },
              viewSettings: {
                ...defaultChartConfigState.viewSettings,
                ...viewSettings,
              },
            },
          },
          variables: {
            ...state.variables,
            lastFetched,
            isFetching: false,
            data: varData || [],
          },
        }
      }
    }
    case actions.updateChartStateFromUrlAction.error.type: {
      const lastFetched = Date.now()
      return {
        ...state,
        chartConfig: {
          ...state.chartConfig,
          lastFetched,
          isFetching: false,
          error: action.payload && action.payload.message ? action.payload.message : action.payload,
        },
      }
    }
    case actions.SET_STAT_TESTING_INTERVAL_ID: {
      return {
        ...state,
        statTesting: {
          ...state.statTesting,
          intervalId: action.payload,
        },
      }
    }
    case actions.STAT_TESTING_POLL_TICK: {
      const { variables } = state.chartConfig.data.dataSettings.data
      const loading =
        action.payload.loading || action.payload.completed_count < action.payload.comparison_count

      return {
        ...state,
        statTesting: {
          ...state.statTesting,
          statsId: action.payload.stats_id,
          completedCount: action.payload.completed_count,
          comparisonCount: action.payload.comparison_count,
          statsData: normalizeStatsIds(action.payload.stats_data, variables),
          purged: action.payload.purged,
          loading,
        },
      }
    }
    case actions.STOP_POLL_FOR_STAT_TESTING: {
      return {
        ...state,
        statTesting: {
          ...state.statTesting,
          intervalId: null,
          loading: false,
        },
      }
    }
    case actions.SET_STAT_TESTING_ERROR: {
      const data = Object.keys(action.payload.data).reduce((acc, key) => {
        if (key === 'message' || key === 'message_id') return acc
        acc[camelCase(key)] = action.payload.data[key]
        return acc
      }, {})

      return {
        ...state,
        statTesting: {
          ...state.statTesting,
          error: {
            messageKey: action.payload.messageKey,
            data,
          },
        },
      }
    }
    case actions.CLEAR_STAT_TESTING: {
      return {
        ...state,
        chartConfig: {
          ...state.chartConfig,
          data: {
            ...state.chartConfig.data,
            dataSettings: {
              ...state.chartConfig.data.dataSettings,
              data: {
                ...state.chartConfig.data.dataSettings.data,
                statsId: defaultChartConfigState.dataSettings.data.statsId,
              },
            },
          },
        },
        statTesting: defaultReducerState.statTesting,
      }
    }
    case customWeightActions.RESET_CUSTOM_WEIGHTS: {
      return {
        ...state,
        variables: {
          ...state.variables,
          customWeightCreation: defaultReducerState.variables.customWeightCreation,
        },
      }
    }
    case customWeightActions.SET_CUSTOM_WEIGHT_VARIABLE_NAME: {
      return {
        ...state,
        variables: {
          ...state.variables,
          customWeightCreation: {
            ...state.variables.customWeightCreation,
            name: action.name,
          },
        },
      }
    }
    case customWeightActions.STAGE_VARIABLE_FOR_CUSTOM_WEIGHT: {
      return {
        ...state,
        variables: {
          ...state.variables,
          customWeightCreation: {
            ...state.variables.customWeightCreation,
            variableIds: action.variableIds,
            selections: action.selections,
          },
        },
      }
    }
    case customWeightActions.EDIT_CUSTOM_WEIGHT_SELECTION: {
      return {
        ...state,
        variables: {
          ...state.variables,
          customWeightCreation: {
            ...state.variables.customWeightCreation,
            selections: action.selections,
          },
        },
      }
    }
    case customWeightActions.CREATE_CUSTOM_WEIGHT_FETCHING: {
      return {
        ...state,
        variables: {
          ...state.variables,
          customWeightCreation: {
            ...state.variables.customWeightCreation,
            isCreating: true,
            hasErrorCreating: false,
          },
        },
      }
    }
    case customWeightActions.CREATE_CUSTOM_WEIGHT_SUCCESS: {
      return {
        ...state,
        variables: {
          ...state.variables,
          customWeightCreation: {
            ...state.variables.customWeightCreation,
            isCreating: false,
            hasErrorCreating: false,
          },
        },
      }
    }
    case customWeightActions.CREATE_CUSTOM_WEIGHT_ERROR: {
      return {
        ...state,
        variables: {
          ...state.variables,
          customWeightCreation: {
            ...state.variables.customWeightCreation,
            isCreating: false,
            hasErrorCreating: action.error ?? true,
          },
        },
      }
    }
    case customWeightActions.DELETE_CUSTOM_WEIGHT_FETCHING: {
      return {
        ...state,
        variables: {
          ...state.variables,
          customWeightCreation: {
            ...state.variables.customWeightCreation,
            isDeleting: true,
            hasErrorDeleting: false,
          },
        },
      }
    }
    case customWeightActions.DELETE_CUSTOM_WEIGHT_SUCCESS: {
      return {
        ...state,
        variables: {
          ...state.variables,
          availableWeights: {
            ...state.variables.availableWeights,
            custom: state.variables.availableWeights.custom.filter(
              weight => weight.specId !== action.specId
            ),
          },
          customWeightCreation: {
            ...state.variables.customWeightCreation,
            isDeleting: false,
            hasErrorDeleting: false,
          },
        },
      }
    }
    case customWeightActions.DELETE_CUSTOM_WEIGHT_ERROR: {
      return {
        ...state,
        variables: {
          ...state.variables,
          customWeightCreation: {
            ...state.variables.customWeightCreation,
            isDeleting: false,
            hasErrorDeleting: action.error ?? true,
          },
        },
      }
    }
    case actions.SET_A1_STATE_HUSK: {
      return {
        ...state,
        a1Husk: action.payload.chartState,
      }
    }
    case actions.SET_WEIGHTING: {
      const { weight } = action.payload
      return {
        ...state,
        chartConfig: {
          ...state.chartConfig,
          data: {
            ...state.chartConfig.data,
            dataSettings: {
              ...state.chartConfig.data.dataSettings,
              weighting: weight,
            },
          },
        },
      }
    }
    case actions.GET_ANALYSIS_CHART_SUMMARY_LOADING: {
      return {
        ...state,
        chartSummary: {
          ...state.chartSummary,
          isFetching: true,
          hasError: false,
          summary: null,
        },
      }
    }
    case actions.GET_ANALYSIS_CHART_SUMMARY_SUCCESS: {
      return {
        ...state,
        chartSummary: {
          ...state.chartSummary,
          isFetching: false,
          hasError: false,
          summary: action.summary,
        },
      }
    }
    case actions.GET_ANALYSIS_CHART_SUMMARY_ERROR: {
      return {
        ...state,
        chartSummary: {
          ...state.chartSummary,
          isFetching: false,
          hasError: true,
          summary: null,
        },
      }
    }
    case actions.SET_VARIABLE_SEARCH_QUERY: {
      const { searchQuery } = action.payload
      return {
        ...state,
        variables: {
          ...state.variables,
          searchQuery: searchQuery,
        },
      }
    }
    default:
      return state
  }
}
