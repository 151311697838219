// extracted by mini-css-extract-plugin
export const active = "VariableCard-module__active--h4UDp";
export const baseTiming = "125ms";
export const baseTimingInt = "125";
export const baseVariable = "VariableCard-module__base-variable--rrCv5";
export const borderRadius = "3px";
export const buttonContainer = "VariableCard-module__button-container--awZ3-";
export const cardHeader = "VariableCard-module__card-header--kBTDl";
export const caretButton = "VariableCard-module__caret-button--Z9p-K";
export const caretContainer = "VariableCard-module__caret-container--yNafb";
export const charcoal = "#2d2e2e";
export const cloudGrey = "#c8cdcf";
export const disableDrop = "VariableCard-module__disable-drop--xDF2-";
export const disabled = "VariableCard-module__disabled--KdyRQ";
export const doveGrey = "#f1f3f5";
export const dragInProgress = "VariableCard-module__drag-in-progress--WQC5M";
export const dragLayer = "VariableCard-module__drag-layer--mh+kZ";
export const headerText = "VariableCard-module__header-text--KgnP3";
export const headerTextContainer = "VariableCard-module__header-text-container--PEL+s";
export const highlight = "VariableCard-module__highlight--lmeIh";
export const isDragging = "VariableCard-module__is-dragging--JZJeN";
export const isOver = "VariableCard-module__is-over--ADlj1";
export const khBlue = "#04b1e0";
export const khBlueDark = "#0376ae";
export const khBlueLight = "#7de1f4";
export const lemon = "#fae436";
export const lemonDark = "#e3cd02";
export const lemonLight = "#fff284";
export const lime = "#05c150";
export const limeDark = "#068230";
export const limeLight = "#65ec83";
export const navy = "#052976";
export const navyDark = "#04173f";
export const navyLight = "#82b1f6";
export const scarlet = "#e9062b";
export const scarletDark = "#9d050e";
export const scarletLight = "#f5726d";
export const stormGrey = "#969b9c";
export const variableCard = "VariableCard-module__variable-card--o8jxW";
export const violet = "#d401f8";
export const violetDark = "#9003a8";
export const violetLight = "#e77ef9";
export const warning = "VariableCard-module__warning--RjtX3";
export const warningTooltip = "VariableCard-module__warning-tooltip--lCNi0";
export const white = "#fff";