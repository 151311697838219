// extracted by mini-css-extract-plugin
export const addFilterContainer = "AddFilter-module__add-filter-container--8iVuQ";
export const arrow = "AddFilter-module__arrow--Nefr8";
export const baseTiming = "125ms";
export const baseTimingInt = "125";
export const borderRadius = "3px";
export const charcoal = "#2d2e2e";
export const closeButton = "AddFilter-module__close-button--olrW5";
export const cloudGrey = "#c8cdcf";
export const columnHeader = "AddFilter-module__column-header--FT4BO";
export const createNetContainer = "AddFilter-module__create-net-container--1Ull6";
export const doveGrey = "#f1f3f5";
export const dropdownContainer = "AddFilter-module__dropdown-container--SsUYl";
export const filterColumn = "AddFilter-module__filter-column--oLycd";
export const filterColumns = "AddFilter-module__filter-columns--Gimch";
export const filterMethods = "AddFilter-module__filter-methods--zx+53";
export const filterOptions = "AddFilter-module__filter-options--6grHK";
export const filterText = "AddFilter-module__filter-text--O9s8s";
export const fullHeight = "AddFilter-module__full-height--5EUNp";
export const handle = "AddFilter-module__handle--2yBb9";
export const inputContainer = "AddFilter-module__input-container--3nBS6";
export const khBlue = "#04b1e0";
export const khBlueDark = "#0376ae";
export const khBlueLight = "#7de1f4";
export const lemon = "#fae436";
export const lemonDark = "#e3cd02";
export const lemonLight = "#fff284";
export const lime = "#05c150";
export const limeDark = "#068230";
export const limeLight = "#65ec83";
export const listBorder = "AddFilter-module__list-border--cXDIG";
export const modalActions = "AddFilter-module__modal-actions--ABbo4";
export const navy = "#052976";
export const navyDark = "#04173f";
export const navyLight = "#82b1f6";
export const netNameContainer = "AddFilter-module__net-name-container--U7-sN";
export const netOptions = "AddFilter-module__net-options--+RYYB";
export const numericContainer = "AddFilter-module__numeric-container--JQ6fv";
export const optionsHeading = "AddFilter-module__options-heading--OzNNf";
export const placeholder = "AddFilter-module__placeholder--tdvBM";
export const scarlet = "#e9062b";
export const scarletDark = "#9d050e";
export const scarletLight = "#f5726d";
export const searchInputContainer = "AddFilter-module__search-input-container--EIaQs";
export const selected = "AddFilter-module__selected--Kf9cw";
export const selectedVariable = "AddFilter-module__selected-variable--72BOQ";
export const stormGrey = "#969b9c";
export const toggle = "AddFilter-module__toggle--MGjNB";
export const toggleContainer = "AddFilter-module__toggle-container--pCf8Y";
export const value = "AddFilter-module__value--55TKW";
export const variable = "AddFilter-module__variable--mWcst";
export const variableHeader = "AddFilter-module__variable-header---lSlH";
export const variableItem = "AddFilter-module__variable-item--jaYN2";
export const variableList = "AddFilter-module__variable-list--r8BAU";
export const variables = "AddFilter-module__variables--sNRDa";
export const violet = "#d401f8";
export const violetDark = "#9003a8";
export const violetLight = "#e77ef9";
export const white = "#fff";