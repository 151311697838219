import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import _truncate from 'lodash/truncate'
import { Button, Icon, Tooltip } from '@knowledgehound/laika'

import { generateFilterText } from '../../FilterUtils'

import * as styles from './FilterBadges.module.scss'

const propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      questionName: PropTypes.string.isRequired,
      method: PropTypes.string.isRequired,
    })
  ),
  variables: PropTypes.arrayOf(PropTypes.object),
  canEditFilter: PropTypes.bool,
  onEditFilter: PropTypes.func,
  onRemoveFilter: PropTypes.func,
  isLoading: PropTypes.bool,
  truncateQuestionTo: PropTypes.number,
  truncateResponsesTo: PropTypes.number,
  /** CSS class for the outermost container */
  containerClassName: PropTypes.string,
  hideTooltip: PropTypes.bool,
  /** When true, does not apply "badge" look to filter items. */
  isPlainTextual: PropTypes.bool,
  /** optional ID provided to tooltip in case of possible collisions of multiple exact same filter badges on screen */
  tooltipId: PropTypes.string,
  hideClearAll: PropTypes.bool,
}

/** A simple list component of current filters */
function FilterBadges({
  canEditFilter,
  filters,
  variables,
  containerClassName,
  truncateQuestionTo,
  truncateResponsesTo,
  isLoading,
  onEditFilter,
  onRemoveFilter,
  hideTooltip,
  isPlainTextual,
  tooltipId,
  hideClearAll,
}) {
  if (!filters || filters.length === 0) return null

  const _canEditFilter = typeof canEditFilter !== 'boolean' ? true : canEditFilter

  return (
    <div className={`${containerClassName ?? ''}`}>
      {!hideClearAll && _canEditFilter && onRemoveFilter && (
        <Button variant="secondary" size="small" onClick={e => onRemoveFilter(e, filters, true)}>
          Clear Filters
        </Button>
      )}
      {filters.map((currFilter, i) => {
        const { methodText, responseTextList, questionTextFull, breakoutText } = generateFilterText(
          variables,
          currFilter
        )

        if (!responseTextList) {
          if (!_canEditFilter) return null

          return (
            <div
              key={`${currFilter.questionName}${currFilter.method}-${i}`}
              className={classnames(styles.filter, styles.badge, styles.error)}
            >
              <Tooltip
                placement="top"
                content="This filter is associated with a survey question that does not exist. Delete this filter or reach out to customer success for help."
              >
                {({ hostRef, openTooltip, closeTooltip, attributes }) => (
                  <span
                    ref={hostRef}
                    onMouseEnter={openTooltip}
                    onMouseLeave={closeTooltip}
                    {...attributes}
                  >
                    <Icon icon="error" size="small" color="inherit" /> Broken filter
                  </span>
                )}
              </Tooltip>
              <div className={styles.filterActions}>
                {onRemoveFilter && (
                  <Tooltip placement="top" content="Remove broken filter(s)">
                    {({ hostRef, openTooltip, closeTooltip, attributes }) => (
                      <button
                        ref={hostRef}
                        type="button"
                        onMouseEnter={openTooltip}
                        onMouseLeave={closeTooltip}
                        className={styles.filterAction}
                        onClick={e => onRemoveFilter(e, [currFilter], false)}
                        disabled={isLoading}
                        {...attributes}
                      >
                        <Icon icon="close" size="small" color="inherit" />
                      </button>
                    )}
                  </Tooltip>
                )}
              </div>
            </div>
          )
        }

        const responseText = responseTextList
          .map(
            response =>
              `‘${
                truncateResponsesTo
                  ? _truncate(response, { length: truncateResponsesTo })
                  : response
              }’`
          )
          .join(', ')

        let maxQuestionLength = truncateQuestionTo || 60
        const displayQuestionText = breakoutText
          ? `${_truncate(questionTextFull.split(' - ')[0], {
              length: maxQuestionLength,
            })} ${_truncate(breakoutText, { length: maxQuestionLength })}`
          : _truncate(questionTextFull, { length: maxQuestionLength })

        if (!(displayQuestionText && methodText && responseText)) return null
        const fullText = [questionTextFull, methodText.toLowerCase(), responseTextList].join(' ')
        const displayText = _truncate(
          [displayQuestionText, methodText.toLowerCase(), responseText].join(' '),
          { length: 100 }
        )

        return (
          <div
            key={`${currFilter.questionName}${currFilter.method}-${i}`}
            className={classnames(styles.filter, { [styles.badge]: !isPlainTextual })}
          >
            <Tooltip placement="top" content={fullText}>
              {({ hostRef, openTooltip, closeTooltip, attributes }) => (
                <span
                  ref={hostRef}
                  onMouseEnter={hideTooltip ? undefined : openTooltip}
                  onMouseLeave={closeTooltip}
                  {...attributes}
                >
                  {displayText}
                </span>
              )}
            </Tooltip>
            <div className={styles.filterActions}>
              {_canEditFilter && onEditFilter && (
                <Tooltip placement="top" content="Edit Filter">
                  {({ hostRef, openTooltip, closeTooltip, attributes }) => (
                    <button
                      ref={hostRef}
                      type="button"
                      onMouseEnter={openTooltip}
                      onMouseLeave={closeTooltip}
                      className={styles.filterAction}
                      onClick={evt => onEditFilter(evt, currFilter)}
                      disabled={isLoading}
                      {...attributes}
                    >
                      <Icon icon="edit" size="small" color="inherit" />
                    </button>
                  )}
                </Tooltip>
              )}
              {_canEditFilter && onRemoveFilter && (
                <Tooltip placement="top" content="Remove filter(s)">
                  {({ hostRef, openTooltip, closeTooltip, attributes }) => (
                    <button
                      ref={hostRef}
                      type="button"
                      onMouseEnter={openTooltip}
                      onMouseLeave={closeTooltip}
                      className={styles.filterAction}
                      onClick={e => onRemoveFilter(e, [currFilter], false)}
                      disabled={isLoading}
                      {...attributes}
                    >
                      <Icon icon="close" size="small" color="inherit" />
                    </button>
                  )}
                </Tooltip>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}

FilterBadges.propTypes = propTypes

export default FilterBadges
