import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useIntl } from 'react-intl'
import classnames from 'classnames'

import { Icon, Tooltip } from '@knowledgehound/laika'
import {
  isAutoAdjustBaseEnabled,
  getDataset,
  getAnalysisData,
  getWeightingLabel,
  getLowBaseThreshold,
  getShowUnweightedBase,
} from 'store/DatasetSelectors'
import * as styles from './SampleInfoBar.module.scss'

function SampleInfoBar() {
  const intl = useIntl()
  const dataset = useSelector(getDataset, shallowEqual)
  const analysisData = useSelector(getAnalysisData, shallowEqual)
  const autoAdjustBaseEnabled = useSelector(isAutoAdjustBaseEnabled, shallowEqual)
  const lowBaseThreshold = useSelector(getLowBaseThreshold, shallowEqual)
  const weightingLabel = useSelector(getWeightingLabel, shallowEqual)
  const showUnweightedBase = useSelector(getShowUnweightedBase, shallowEqual)

  const useUnweightedBase = showUnweightedBase && analysisData.unweighted_base_size !== null
  const displayLowBase =
    lowBaseThreshold >=
    (useUnweightedBase ? analysisData.unweighted_base_size : analysisData.base_size)

  return (
    <React.Fragment>
      {dataset && (
        <div className={styles.metadataText}>
          <span className={styles.metadata}>
            Total sample N={dataset.sampleSize.toLocaleString()}
          </span>
          {Boolean(analysisData && analysisData.base_size) && (
            <span className={styles.metadata}>
              {useUnweightedBase || !weightingLabel ? (
                <span>Unweighted base</span>
              ) : (
                <span>Weighted base</span>
              )}
              <Tooltip placement="bottom" content="Auto-update base enabled">
                {({ hostRef, openTooltip, closeTooltip, attributes }) => (
                  <span
                    ref={hostRef}
                    onMouseEnter={autoAdjustBaseEnabled ? openTooltip : undefined}
                    onMouseLeave={closeTooltip}
                    className={classnames(styles.baseSize, {
                      [styles.active]: autoAdjustBaseEnabled,
                    })}
                    {...attributes}
                  >
                    <span className={displayLowBase ? styles.lowBaseScarlet : ''}>
                      N=
                      {Math.round(
                        showUnweightedBase && analysisData.unweighted_base_size !== null
                          ? analysisData.unweighted_base_size
                          : analysisData.base_size
                      ).toLocaleString()}
                    </span>
                    {displayLowBase && (
                      <span className={styles.lowBaseScarlet}>
                        <Icon icon="lowBase" color="inherit" />
                        Low base
                      </span>
                    )}
                    {autoAdjustBaseEnabled && <Icon icon="info" size="small" color="violet" />}
                  </span>
                )}
              </Tooltip>
            </span>
          )}
          <span className={styles.metadata}>
            {intl.formatMessage({ id: `analysis.weightApplied.long` }, { label: weightingLabel })}
          </span>
        </div>
      )}
    </React.Fragment>
  )
}

export default SampleInfoBar
