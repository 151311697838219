import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import { filterNet as filterNetDuck } from 'store'
import { getOptionText } from '../Analysis2Utils'
import { ToggleableSelectionOptions } from '@knowledgehound/laika'

function CategoricalFilters({
  isChecked,
  handleChangeResponseOption,
  handleToggleAllQuestionResponses,
}) {
  const selectedVariable = useSelector(filterNetDuck.getSelectedVariable, shallowEqual)
  const selectedGridBreakout = useSelector(filterNetDuck.getSelectedBreakout, shallowEqual)

  if (!selectedVariable) return null

  const isOption = !selectedGridBreakout || selectedGridBreakout.type === 'breakout'
  const formattedResponses = selectedVariable.options.map(option => ({
    id: option.id,
    value: option.id.toString(),
    type: option.type,
    text: getOptionText(selectedVariable, option),
    isAvailable: true,
    isSelected: isChecked(option),
    onClick: () => handleChangeResponseOption(option.id, option.type),
  }))

  return (
    <ul>
      <ToggleableSelectionOptions
        title={`Question ${!isOption ? 'options' : 'responses'}`}
        options={formattedResponses}
        onToggleAll={handleToggleAllQuestionResponses}
      />
    </ul>
  )
}

export default CategoricalFilters
