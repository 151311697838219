import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector, shallowEqual } from 'react-redux'

import { RadioButtonList } from '@knowledgehound/laika'

import { filterNet as filterNetDuck } from 'store'
import possibleFilterMethods from 'util/filterMethods'

import * as styles from './AddFilter.module.scss'

export default function FilterMethod({
  displayMethod,
  handleChangeFilterMethod,
  excludeList = [],
  sectionStyle = '',
  headerNumber = '2',
}) {
  const variable = useSelector(filterNetDuck.getSelectedVariable, shallowEqual)
  const method = useSelector(filterNetDuck.getSelectedMethod, shallowEqual)

  const options = useMemo(() => {
    return variable
      ? variable.supported_filter_methods
          .reduce((methods, methodPair) => {
            const pairData = possibleFilterMethods[methodPair].map(availMethod => ({
              value: availMethod.value,
              checked: Boolean(availMethod.value === method),
              labelText: availMethod.label,
              onChange: handleChangeFilterMethod,
            }))
            return [...methods, ...pairData]
          }, [])
          .filter(m => !excludeList.includes(m.value))
      : []
  }, [variable, excludeList, method, handleChangeFilterMethod])

  if (!displayMethod || !variable) {
    return (
      <div className={styles.filterColumn}>
        <h4 className={styles.columnHeader}>
          <FormattedMessage id="analysis.selectOperator" values={{ headerNumber }} />
        </h4>
        <div className={styles.placeholder} />
      </div>
    )
  }

  return (
    <div className={styles.filterColumn}>
      <div className={sectionStyle}>
        <h4 className={styles.columnHeader}>
          <FormattedMessage id="analysis.selectOperator" values={{ headerNumber }} />
        </h4>
        <div className={styles.dropdownContainer}>
          <RadioButtonList options={options} onChange={handleChangeFilterMethod} />
        </div>
      </div>
    </div>
  )
}
