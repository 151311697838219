import React from 'react'

import * as styles from './VariableCardSkeleton.module.scss'

export default function VariableCardSkeleton() {
  return (
    <div className={styles.loadingContainer}>
      <div className={styles.bar} />
      <hr className={styles.divider} />
      <div className={styles.bar} />
      <div className={styles.bar} />
      <div className={styles.bar} />
      <div className={styles.bar} />
    </div>
  )
}
