import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FormattedMessage, useIntl } from 'react-intl'
import { uniqueId } from 'lodash'
import { Icon, Tooltip } from '@knowledgehound/laika'

import * as styles from './NullSuppressionAccordion.module.scss'

const propTypes = {
  children: PropTypes.node.isRequired,
  hasError: PropTypes.bool,
  isBreakout: PropTypes.bool,
}

function NullSuppressionAccordion({ children, hasError = false, isBreakout = false }) {
  const intl = useIntl()
  const [open, setOpen] = useState(false)
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)
  const headerId = useRef(uniqueId('null-accordion'))

  if (hasError) {
    return (
      <div className={styles.errorMessage}>
        There was a problem fetching null suppression data, so some or all of the above options
        might not have respondent data.
      </div>
    )
  }

  return (
    <React.Fragment>
      <div
        id={headerId.current}
        onClick={() => setOpen(!open)}
        className={classnames(styles.header, { [styles.open]: open })}
      >
        <FormattedMessage
          id={`analysis.nullSuppression.title.${isBreakout ? 'breakouts' : 'responses'}`}
        />
        <Tooltip
          placement="auto"
          isOpen={isTooltipOpen}
          content={intl.formatMessage({ id: 'analysis.nullSuppression.tooltip' })}
          onDismiss={() => setIsTooltipOpen(false)}
          dismissButtonText={intl.formatMessage({
            id: 'analysis.nullSuppression.tooltipAction',
          })}
          variant="dismissible"
        >
          {({ hostRef, attributes }) => (
            <div
              ref={hostRef}
              className={styles.help}
              onClick={event => {
                event.stopPropagation()
                setIsTooltipOpen(!isTooltipOpen)
              }}
              {...attributes}
            >
              <Icon icon="info" size="small" color="inherit" />
            </div>
          )}
        </Tooltip>
        <div className={styles.arrow}>
          <Icon icon="arrowDown" size="small" />
        </div>
      </div>
      <div
        className={classnames(styles.content, { [styles.open]: open })}
        aria-labelledby={headerId.current}
      >
        {children}
      </div>
    </React.Fragment>
  )
}

NullSuppressionAccordion.propTypes = propTypes

export default NullSuppressionAccordion
