import React from 'react'
import { useDragLayer } from 'react-dnd'

import { VariableCard } from '../VariableCard'

const layerStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  height: '100%',
  width: '268px',
}

const getItemStyles = (initialOffset, currentOffset) => {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    }
  }

  return {
    transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)`,
  }
}

export default function VariableCardDragLayer() {
  const { item, isDragging } = useDragLayer(monitor => {
    const initialOffset = monitor.getInitialSourceClientOffset()
    const currentOffset = monitor.getSourceClientOffset()

    return {
      item: {
        ...monitor.getItem(),
        dragLayerStyle: getItemStyles(initialOffset, currentOffset),
      },
      isDragging: monitor.isDragging(),
    }
  })

  if (!isDragging || !item) {
    return null
  }

  return (
    <div style={layerStyles}>
      <VariableCard {...item} isExpanded={false} onClickHeader={() => {}} dropdownMenuItems={[]} />
    </div>
  )
}
