// extracted by mini-css-extract-plugin
export const baseTiming = "125ms";
export const baseTimingInt = "125";
export const borderRadius = "3px";
export const charcoal = "#2d2e2e";
export const cloudGrey = "#c8cdcf";
export const doveGrey = "#f1f3f5";
export const emptyMessage = "CustomWeightModal-module__empty-message--Q+VGS";
export const errorMessage = "CustomWeightModal-module__error-message--lysQb";
export const inputGroup = "CustomWeightModal-module__input-group--3OV0U";
export const invalid = "CustomWeightModal-module__invalid--kwyM0";
export const khBlue = "#04b1e0";
export const khBlueDark = "#0376ae";
export const khBlueLight = "#7de1f4";
export const layout = "CustomWeightModal-module__layout--1MnOz";
export const lemon = "#fae436";
export const lemonDark = "#e3cd02";
export const lemonLight = "#fff284";
export const lime = "#05c150";
export const limeDark = "#068230";
export const limeLight = "#65ec83";
export const name = "CustomWeightModal-module__name--O-Hgv";
export const navy = "#052976";
export const navyDark = "#04173f";
export const navyLight = "#82b1f6";
export const options = "CustomWeightModal-module__options--zortA";
export const optionsContainer = "CustomWeightModal-module__options-container--6hnV0";
export const scarlet = "#e9062b";
export const scarletDark = "#9d050e";
export const scarletLight = "#f5726d";
export const stormGrey = "#969b9c";
export const total = "CustomWeightModal-module__total--ySJqs";
export const valid = "CustomWeightModal-module__valid--WBoUm";
export const variables = "CustomWeightModal-module__variables--lFjco";
export const variablesSection = "CustomWeightModal-module__variables-section--RFVIr";
export const violet = "#d401f8";
export const violetDark = "#9003a8";
export const violetLight = "#e77ef9";
export const white = "#fff";