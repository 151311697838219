(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@knowledgehound/data/fetchUtils"), require("@knowledgehound/laika"), require("@knowledgehound/laika/scss/_colors.module.scss"), require("@knowledgehound/story-components/addContentItemModal"), require("@knowledgehound/studies/StudyRequests"), require("@progress/kendo-react-common"), require("@progress/kendo-react-grid"), require("@progress/kendo-react-tooltip"), require("@sentry/react"), require("classnames"), require("classnames/bind"), require("downloadjs"), require("fast-cartesian-product"), require("highcharts"), require("highcharts-react-official"), require("highcharts/modules/pattern-fill"), require("html2plaintext"), require("is-html"), require("js-sha256"), require("lodash"), require("lodash/isEmpty"), require("lodash/isEqual"), require("lodash/isString"), require("lodash/throttle"), require("lodash/truncate"), require("prop-types"), require("query-string"), require("react"), require("react-dnd"), require("react-dnd-html5-backend"), require("react-dom/server"), require("react-intl"), require("react-redux"), require("react-router-dom"), require("reselect"), require("simple-text-search"));
	else if(typeof define === 'function' && define.amd)
		define(["@knowledgehound/data/fetchUtils", "@knowledgehound/laika", "@knowledgehound/laika/scss/_colors.module.scss", "@knowledgehound/story-components/addContentItemModal", "@knowledgehound/studies/StudyRequests", "@progress/kendo-react-common", "@progress/kendo-react-grid", "@progress/kendo-react-tooltip", "@sentry/react", "classnames", "classnames/bind", "downloadjs", "fast-cartesian-product", "highcharts", "highcharts-react-official", "highcharts/modules/pattern-fill", "html2plaintext", "is-html", "js-sha256", "lodash", "lodash/isEmpty", "lodash/isEqual", "lodash/isString", "lodash/throttle", "lodash/truncate", "prop-types", "query-string", "react", "react-dnd", "react-dnd-html5-backend", "react-dom/server", "react-intl", "react-redux", "react-router-dom", "reselect", "simple-text-search"], factory);
	else if(typeof exports === 'object')
		exports["analysis"] = factory(require("@knowledgehound/data/fetchUtils"), require("@knowledgehound/laika"), require("@knowledgehound/laika/scss/_colors.module.scss"), require("@knowledgehound/story-components/addContentItemModal"), require("@knowledgehound/studies/StudyRequests"), require("@progress/kendo-react-common"), require("@progress/kendo-react-grid"), require("@progress/kendo-react-tooltip"), require("@sentry/react"), require("classnames"), require("classnames/bind"), require("downloadjs"), require("fast-cartesian-product"), require("highcharts"), require("highcharts-react-official"), require("highcharts/modules/pattern-fill"), require("html2plaintext"), require("is-html"), require("js-sha256"), require("lodash"), require("lodash/isEmpty"), require("lodash/isEqual"), require("lodash/isString"), require("lodash/throttle"), require("lodash/truncate"), require("prop-types"), require("query-string"), require("react"), require("react-dnd"), require("react-dnd-html5-backend"), require("react-dom/server"), require("react-intl"), require("react-redux"), require("react-router-dom"), require("reselect"), require("simple-text-search"));
	else
		root["analysis"] = factory(root["@knowledgehound/data/fetchUtils"], root["@knowledgehound/laika"], root["@knowledgehound/laika/scss/_colors.module.scss"], root["@knowledgehound/story-components/addContentItemModal"], root["@knowledgehound/studies/StudyRequests"], root["@progress/kendo-react-common"], root["@progress/kendo-react-grid"], root["@progress/kendo-react-tooltip"], root["@sentry/react"], root["classnames"], root["classnames/bind"], root["downloadjs"], root["fast-cartesian-product"], root["highcharts"], root["highcharts-react-official"], root["highcharts/modules/pattern-fill"], root["html2plaintext"], root["is-html"], root["js-sha256"], root["lodash"], root["lodash/isEmpty"], root["lodash/isEqual"], root["lodash/isString"], root["lodash/throttle"], root["lodash/truncate"], root["prop-types"], root["query-string"], root["react"], root["react-dnd"], root["react-dnd-html5-backend"], root["react-dom/server"], root["react-intl"], root["react-redux"], root["react-router-dom"], root["reselect"], root["simple-text-search"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__663__, __WEBPACK_EXTERNAL_MODULE__320__, __WEBPACK_EXTERNAL_MODULE__529__, __WEBPACK_EXTERNAL_MODULE__209__, __WEBPACK_EXTERNAL_MODULE__418__, __WEBPACK_EXTERNAL_MODULE__702__, __WEBPACK_EXTERNAL_MODULE__506__, __WEBPACK_EXTERNAL_MODULE__87__, __WEBPACK_EXTERNAL_MODULE__205__, __WEBPACK_EXTERNAL_MODULE__310__, __WEBPACK_EXTERNAL_MODULE__759__, __WEBPACK_EXTERNAL_MODULE__417__, __WEBPACK_EXTERNAL_MODULE__153__, __WEBPACK_EXTERNAL_MODULE__277__, __WEBPACK_EXTERNAL_MODULE__96__, __WEBPACK_EXTERNAL_MODULE__440__, __WEBPACK_EXTERNAL_MODULE__272__, __WEBPACK_EXTERNAL_MODULE__770__, __WEBPACK_EXTERNAL_MODULE__226__, __WEBPACK_EXTERNAL_MODULE__467__, __WEBPACK_EXTERNAL_MODULE__974__, __WEBPACK_EXTERNAL_MODULE__674__, __WEBPACK_EXTERNAL_MODULE__819__, __WEBPACK_EXTERNAL_MODULE__67__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__99__, __WEBPACK_EXTERNAL_MODULE__48__, __WEBPACK_EXTERNAL_MODULE__156__, __WEBPACK_EXTERNAL_MODULE__680__, __WEBPACK_EXTERNAL_MODULE__849__, __WEBPACK_EXTERNAL_MODULE__250__, __WEBPACK_EXTERNAL_MODULE__767__, __WEBPACK_EXTERNAL_MODULE__756__, __WEBPACK_EXTERNAL_MODULE__128__, __WEBPACK_EXTERNAL_MODULE__975__, __WEBPACK_EXTERNAL_MODULE__373__) => {
return 