import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useDrop } from 'react-dnd'
import { FormattedMessage } from 'react-intl'

import { DND_VARIABLE_CARD } from 'store/constants'
import * as styles from './EmptyVariableCard.module.scss'

const propTypes = {
  axis: PropTypes.string,
  axisText: PropTypes.string,
  clearHover: PropTypes.func,
  draggingAxis: PropTypes.string,
  enableAdd: PropTypes.bool,
  handleAddVariable: PropTypes.func,
  handleDropVariable: PropTypes.func,
  hide: PropTypes.bool,
  isDraggingVariable: PropTypes.bool,
}

function EmptyVariableCard({
  axis,
  axisText,
  clearHover,
  draggingAxis,
  enableAdd,
  handleAddVariable,
  handleDropVariable,
  hide,
  isDraggingVariable,
}) {
  const [{ isOver }, drop] = useDrop({
    accept: DND_VARIABLE_CARD,
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
    hover: () => {
      clearHover()
    },
    drop: () => {
      handleDropVariable(axis)
    },
  })

  return (
    <button
      ref={drop}
      type="button"
      disabled={!enableAdd}
      className={classnames(styles.emptyCard, { [styles.isOver]: isOver, [styles.hide]: hide })}
      onClick={e => {
        e && e.stopPropagation()
        if (!isDraggingVariable) {
          handleAddVariable(axis)
        }
      }}
    >
      <FormattedMessage
        id={`analysis.sidebar.emptyVariable${isDraggingVariable ? 'DragTarget' : 'Card'}`}
        values={{ axisText, target: axis === draggingAxis ? 'Return' : 'Add' }}
      />
    </button>
  )
}

EmptyVariableCard.propTypes = propTypes

export default EmptyVariableCard
