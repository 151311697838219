import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect, batch } from 'react-redux'
import classnames from 'classnames'
import * as Sentry from '@sentry/react'
import { isEmpty, isEqual } from 'lodash'
import { parse as queryStringParse } from 'query-string'
import AddContentItemModal from '@knowledgehound/story-components/addContentItemModal'
import { LoadingSpinner } from '@knowledgehound/laika'

import AccessRestricted from './AccessRestricted'
import {
  selectors,
  actions,
  constants,
  config as configDuck,
  filterNet as filterNetDuck,
} from './store'
import AnalysisSegment from './util/segment'
import { cleanParams } from './util/routeParamUtils'
import AddFilter from './AddFilter'
import AnalysisMetadata from './AnalysisMetadata'
import Analysis2Chart from './chart/Analysis2Chart'
import ChartLoading from './chart/ChartLoading'
import AnalysisSidebar from './AnalysisSidebar'
import ChartActions from './ChartActions'
import {
  getOptionText,
  getVariable,
  getA2url,
  hasNoSelectedOpts,
  determineBaseVariable,
} from './Analysis2Utils'
import {
  getDatasetFromNk,
  getStudyNumberFromNk,
  getQnameFromNk,
  constructNkFromParts,
} from './util/naturalKeyUtils'
import { getFilterDefaults } from './FilterUtils'

import type { Match, RouterHistory, Location } from 'react-router-dom'
import type { a2SiteUserT, PermissionsListT } from './store/configurationDuck'
import type { DispatchPromise } from './store/DatasetRedux.type'

import * as styles from './Analysis2.module.scss'

type PropsT = {
  isEmbedded: boolean,
  dispatch: DispatchPromise,
  urlParams: Match,
  history: RouterHistory,
  location: Location,
  dataset: DatasetT,
  isFetchingDataset: boolean,
  variables: VariableListT,
  chartConfig: ChartConfigT,
  isFetchingData: boolean,
  statsError: boolean,
  userInfo: a2SiteUserT,
  userPermissions: PermissionsListT,
  defaultFilterVariable: VariableT | null,
}

type StateT = {
  addFilterModalOpen: boolean,
  chartLoadingOverride: boolean,
  addVarTargetAxis: null | AxisT,
  addToAddToContentItemModalOpen: boolean,
  chartTypeDropdownOpen: boolean,
  meanPopUpVarId: string,
  filterToReplace: FilterT | null,
}

export class Analysis2 extends Component<PropsT, StateT> {
  constructor(props: PropsT) {
    super(props)
    this.state = {
      addFilterModalOpen: false,
      addVarTargetAxis: null,
      filterToReplace: null,
      // FIXME: come back to this, bit of a code smell, from PR:
      // “Overrides” are often a shortcut that bites back. Seems like you are
      // implementing the equivalent of “initialState” here.
      // I agree, the default state is “has no data” and until fetching the
      // dataset and fetching the data is complete, “has no data” is true, but
      // once we have both of those, we have what we need to proceed without
      // errors. So, either rename this to hasNoData or initialState ?
      chartLoadingOverride: true,
      addToAddToContentItemModalOpen: false,
      chartTypeDropdownOpen: false,
      meanPopUpVarId: '',
    }
  }

  static contextTypes = {
    sendNotification: PropTypes.func,
  }

  // flow doesnt handle this very well because awaited React lifecycle must not return a promise or it'll hang
  //$FlowFixMe
  async componentDidMount() {
    const { dispatch, urlParams, location, userInfo, a1Husk } = this.props
    if (isEmpty(userInfo)) {
      // Supplies, User info, permissions and chart colours
      await dispatch(configDuck.fetchUserConfig())
    }

    const params = cleanParams(urlParams)

    if (params.naturalKey || params.studyNumber) {
      let studyNumber = params.studyNumber
      let datasetName = params.datasetName
      let questionName = params.questionName
      let nk = constructNkFromParts(studyNumber, datasetName, questionName)

      // embed will pass in natural key, use instead of deriving study/dataset from url
      if (params.naturalKey) {
        nk = decodeURI(params.naturalKey)
        studyNumber = getStudyNumberFromNk(nk)
        datasetName = getDatasetFromNk(nk)
        questionName = getQnameFromNk(nk)
      }

      const search = queryStringParse(location?.search)

      try {
        await dispatch(actions.fetchDataset(studyNumber))

        // Make sure perm value is reflected in viewSettings
        dispatch(actions.setUnweightedBaseFromPerm())

        await dispatch(actions.fetchWeights())
        dispatch(filterNetDuck.setFilterDataset(datasetName))
        dispatch(filterNetDuck.setFilterStudy(studyNumber))
        if (!isEmpty(a1Husk) && Object.keys(a1Husk).length > 1) {
          await dispatch(actions.hydrateA1StateHusk())
        } else if (!isEmpty(search) && search.stateId) {
          await dispatch(actions.updateChartStateFromUrl(search.stateId))
          dispatch(actions.refetchAllSuppressedOptions())
          this.fetchAnalysisData()
        } else if (this.props.dataset) {
          const {
            dataset: { defaultFilters, defaultXtab },
            chartConfig: {
              dataSettings: { filters },
            },
          } = this.props
          // this array tracks which nks has been fetched to avoid a re-fetch
          const defaultNks = []
          let fetchData = false
          let isDefaultXtabInterval = false

          // Check for default xtabs
          if (defaultXtab && defaultXtab.length) {
            const fetchedDefaultXtabVars = await Promise.all(
              defaultXtab.map(xt => {
                const defaultNk = `${studyNumber}/${datasetName}/${xt}`
                defaultNks.push(defaultNk)
                return dispatch(actions.fetchVariableThunk(defaultNk, true))
              })
            )

            isDefaultXtabInterval = fetchedDefaultXtabVars.some(xt =>
              xt.some(v => v.represents === 'interval')
            )

            fetchedDefaultXtabVars.forEach(vs =>
              dispatch(
                actions.addVariablesToAxisAction({
                  axis: isDefaultXtabInterval ? constants.ROW : constants.COLUMN,
                  newVars: vs,
                  selected: {
                    nets: [],
                    calculated: [],
                    options: vs[0].options.map(o => o.id),
                    breakouts: vs.length > 1 ? vs[1].options.map(o => o.id) : [],
                    calculatedBreakout: [],
                  },
                  setMeanPopUp: this.showMeanPopUp,
                })
              )
            )

            if (isDefaultXtabInterval) {
              await dispatch(actions.setChartTypeAction('line', fetchedDefaultXtabVars[0].id))
            }

            fetchData = true
          }

          // If question was given, and it was not already fetched by default xtab, add to row
          if (
            questionName &&
            questionName.length &&
            (!defaultNks.length || !defaultNks.includes(nk))
          ) {
            const givenVar = await dispatch(actions.fetchVariableThunk(nk, true))
            dispatch(
              actions.addVariablesToAxisAction({
                axis: isDefaultXtabInterval ? constants.COLUMN : constants.ROW,
                newVars: givenVar,
                selected: {
                  nets: [],
                  calculated: [],
                  options: givenVar[0].options.map(o => o.id),
                  breakouts: givenVar.length > 1 ? givenVar[1].options.map(o => o.id) : [],
                  calculatedBreakout: [],
                },
                defaultMean: givenVar[0].fundamentalQuestionType.includes('Numeric'),
                setMeanPopUp: this.showMeanPopUp,
                fromPageLoadWithXtab: Boolean(defaultXtab && defaultXtab.length),
              })
            )

            fetchData = true
          }

          // Check for default filters
          if (!filters.length && !isEmpty(defaultFilters)) {
            await dispatch(actions.setDefaultFilters(defaultFilters, studyNumber, datasetName))
          }
          if (fetchData) {
            dispatch(actions.refetchAllSuppressedOptions())
            this.fetchAnalysisData()
          }
        }
        if (!this.props.chartConfig.viewSettings.chartType) {
          // default chart type set here
          dispatch(actions.setChartTypeAction('column'))
        }
        this.setChartLoadingState(false)
      } catch (e) {
        Sentry.captureException(new Error('Error mounting Analysis2', { cause: e }))
        this.setChartLoadingState(false)
      }
    }

    AnalysisSegment.page('Analysis')
  }

  // flow doesnt handle this very well because awaited React lifecycle must not return a promise or it'll hang
  //$FlowFixMe
  async componentDidUpdate(prevProps: PropsT, prevState: StateT) {
    const {
      isEmbedded,
      history,
      dataset,
      chartConfig: {
        dataSettings: { filters, xAxisVariables, yAxisVariables, autoAdjustBaseEnabled, weighting },
        viewSettings,
      },
      stateId,
      dispatch,
    } = this.props

    const {
      chartConfig: {
        dataSettings: {
          filters: prevFilters,
          xAxisVariables: prevXAxis,
          yAxisVariables: prevYAxis,
          autoAdjustBaseEnabled: prevBaseAdjust,
          weighting: prevWeighting,
        },
        viewSettings: prevViewSettings,
      },
    } = prevProps

    if (dataset) {
      const stateChange =
        !isEqual(filters, prevFilters) ||
        !isEqual(xAxisVariables, prevXAxis) ||
        !isEqual(yAxisVariables, prevYAxis) ||
        !isEqual(viewSettings, prevViewSettings) ||
        !isEqual(weighting, prevWeighting) ||
        autoAdjustBaseEnabled !== prevBaseAdjust
      if (stateChange) {
        const newUrl = getA2url(dataset.studyId, dataset.datasetNk, stateId)
        await dispatch(actions.saveChartState())
        if (!isEmbedded) {
          history.replace(encodeURI(newUrl))
        }
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch(actions.stopFetchWeightPoll())
  }

  showMeanPopUp = meanPopUpVarId => {
    this.setState({ meanPopUpVarId })
  }

  clearMeanPopUp = () => {
    this.setState({ meanPopUpVarId: '' })
  }

  setChartLoadingState = (overrideState: boolean) => {
    // forces a 'loading' state on the chart when true
    this.setState({
      chartLoadingOverride: overrideState,
    })
  }

  /**
    Note that callback should be the handler for all cases, including
    failure to fetch data
  */
  fetchAnalysisData = async (callback?: Function | null, keepBase: boolean = false) => {
    const {
      dispatch,
      dataset,
      chartConfig: {
        dataSettings: { xAxisVariables, yAxisVariables },
      },
    } = this.props

    const allAxisVars = [...xAxisVariables, ...yAxisVariables]
    const hasEmptyVar = allAxisVars.some(axisVar => hasNoSelectedOpts(axisVar))

    if (
      allAxisVars.length < 1 ||
      hasEmptyVar ||
      !dataset ||
      !dataset.datasetNk ||
      !dataset.studyId ||
      !dataset.rdmComplete ||
      !dataset.permissions ||
      !dataset.permissions.view
    ) {
      if (typeof callback === 'function') callback()
      return
    }

    try {
      dispatch(actions.clearAnalysisData(keepBase))
      await dispatch(actions.fetchAnalysisData())
      if (typeof callback === 'function') callback()
    } catch (e) {
      Sentry.captureException(
        new Error('Analysis2: Error when fetching analysis Data', { cause: e })
      )
      if (typeof callback === 'function') callback()
    }
  }

  handleDeleteNet = async () => {
    // If a net is deleted the stateId and URL should be updated
    // to reflect the new axis variables
    const { isEmbedded, history, dataset, stateId, dispatch } = this.props

    const newUrl = getA2url(dataset.studyId, dataset.datasetNk, stateId)
    await dispatch(actions.saveChartState())
    if (!isEmbedded) {
      history.replace(encodeURI(newUrl))
    }
  }

  handleRemoveVariable = (variableToRemove: VariableT) => {
    const { dispatch, handleTrack } = this.props

    batch(async () => {
      dispatch(actions.clearAnalysisData())
      await dispatch(actions.removeVariableThunk(variableToRemove.variableNk))
      handleTrack('Remove variable clicked', { variableToRemove })
      dispatch(actions.refetchAllSuppressedOptions())
      this.fetchAnalysisData()
    })
  }

  handleAddContentItem = () => {
    this.setState({
      addToAddToContentItemModalOpen: !this.state.addToAddToContentItemModalOpen,
    })
  }

  handleOpenFilterModal = () => {
    this.props.handleTrack('Add Filter clicked')
    const { dispatch } = this.props
    dispatch(filterNetDuck.resetFilterNetThunk())
    this.setState({
      addFilterModalOpen: true,
    })
  }

  handleCloseFilterModal = () => {
    const { dispatch } = this.props
    dispatch(filterNetDuck.resetFilterNetThunk())
    this.setState({
      addFilterModalOpen: false,
      filterToReplace: null,
    })
  }

  onAddFilter = async (filterData: VariableFilterDataT) => {
    const { filterToReplace } = this.state
    this.handleCloseFilterModal()

    await this.props.dispatch(actions.addFilterAction({ ...filterData, filterToReplace }))
    this.props.dispatch(actions.refetchAllSuppressedOptions())
    this.fetchAnalysisData(null, true)

    // This is just so we send text instead of ids for
    // tracking data, per product
    const filterOptions = filterData.selectedOptions
      ? filterData.selectedOptions.map(option => getOptionText(filterData.selectedVariable, option))
      : []
    this.props.handleTrack('Filter Applied', {
      selectedVariable: filterData.selectedVariable,
      selectedFilterMethod: filterData.selectedFilterMethod,
      selectedOptions: filterOptions,
      selectedGridBreakout:
        filterData.selectedGridBreakout && filterData.selectedGridBreakout.label
          ? filterData.selectedGridBreakout.label
          : null,
    })
  }

  handleEditFilterFromSidebar = (nk: string, type: string) => {
    const {
      variables,
      handleTrack,
      dispatch,
      chartConfig: {
        dataSettings: { filters },
      },
    } = this.props
    const defaultFilterVariable = getVariable(variables, nk, type)
    handleTrack('Add Filter From Sidebar', { variable: defaultFilterVariable })

    if (defaultFilterVariable) {
      const optionVar = getVariable(variables, nk, 'options')
      const breakoutVar = getVariable(variables, nk, 'breakouts')
      const defaultFilterGrid = optionVar && breakoutVar ? [optionVar, breakoutVar] : null
      dispatch(
        filterNetDuck.setSelectedVariableThunk(defaultFilterVariable, filters, defaultFilterGrid)
      )
    }
    this.setState({
      addFilterModalOpen: true,
    })
  }

  handleEditFilterFromBadge = (evt: SyntheticEvent<*>, filter: Object) => {
    evt.preventDefault()
    const {
      variables,
      handleTrack,
      dispatch,
      chartConfig: {
        dataSettings: { filters },
      },
    } = this.props
    const { defaultFilterVariable, defaultFilterGrid, defaultFilterBreakout } = getFilterDefaults(
      variables,
      filter
    )

    handleTrack('Edit Filter From Badge', { variable: defaultFilterVariable, filter })

    if (defaultFilterVariable && filter.method) {
      dispatch(
        filterNetDuck.setSelectedVariableThunk(
          defaultFilterVariable,
          filters,
          defaultFilterGrid,
          defaultFilterBreakout,
          filter.method
        )
      )
      this.setState({
        filterToReplace: filter,
        addFilterModalOpen: true,
      })
    } else {
      this.setState({
        addFilterModalOpen: true,
      })
    }
  }

  handleRemoveFiltersFromBadge = async (
    evt: SyntheticEvent<*>,
    filters: Array<FilterT>,
    resetFilters: boolean = false
  ) => {
    await this.props.dispatch(actions.removeFiltersAction(filters, resetFilters))
    this.props.handleTrack('Remove Filters From Badge', { filters, clearAll: resetFilters })
    this.props.dispatch(actions.refetchAllSuppressedOptions())
    this.fetchAnalysisData(null, true)
  }

  onVariableAdd = async (
    variableNk: string,
    currTargetAxis: AxisT,
    selected: SelectedRequestNumT,
    isReplacingVariable: boolean,
    replacingVariableNk: string,
    replacingVariableType: string
  ) => {
    const {
      dispatch,
      chartConfig: {
        dataSettings: { xAxisVariables, yAxisVariables },
        variables,
      },
    } = this.props
    // TODO: Handle variable fetching state
    try {
      batch(async () => {
        dispatch(actions.clearAnalysisData())
        const newVars = await dispatch(actions.fetchVariableThunk(variableNk, true))
        const newVarPosition = actions.getVariablePosition(
          currTargetAxis,
          isReplacingVariable,
          replacingVariableNk,
          replacingVariableType,
          xAxisVariables,
          yAxisVariables,
          variables
        )
        if (isReplacingVariable && replacingVariableNk) {
          await dispatch(actions.removeVariableThunk(replacingVariableNk))
        }
        dispatch(
          actions.addVariablesToAxisAction({
            axis: currTargetAxis,
            newVars,
            selected,
            replacingPosition: newVarPosition,
            setMeanPopUp: this.showMeanPopUp,
          })
        )
        this.props.dispatch(actions.refetchAllSuppressedOptions())
        this.fetchAnalysisData()

        this.props.handleTrack(`${isReplacingVariable ? 'Replaced Variable' : 'Added Variable'}`, {
          axis: currTargetAxis,
          addedVariable: newVars,
        })
      })
    } catch (e) {
      Sentry.captureException(new Error('Analysis2: Error when fetching Variable', { cause: e }))
    }
  }

  renderAccessRestricted() {
    return (
      <main>
        <AccessRestricted isEmbedded={this.props.isEmbedded} />
      </main>
    )
  }

  fakeSession() {
    // Only used to prevent changing AddContentItemModal
    const { userInfo, userPermissions } = this.props
    return {
      data: {
        admin: userInfo.admin,
        manager: userInfo.manager,
        email: userInfo.email,
        stories_enabled: userPermissions.includes('STORIES'),
      },
    }
  }

  render() {
    const {
      dispatch,
      dataset,
      isFetchingData,
      isFetchingDataset,
      chartConfig: {
        dataSettings: { xAxisVariables, yAxisVariables, filters, autoAdjustBaseEnabled },
        viewSettings: { chartType, showStatTesting },
      },
      stateId,
      userInfo,
      isEmbedded,
      statsError,
      handleTrack,
      serializedChartState,
      userCanAccessQuestions,
    } = this.props
    const {
      addFilterModalOpen,
      chartLoadingOverride,
      addToAddToContentItemModalOpen,
      meanPopUpVarId,
    } = this.state

    if (isFetchingDataset) {
      return <LoadingSpinner centered text="Fetching data" />
    }

    const accessRestricted = !dataset?.permissions?.view
    if (accessRestricted || !userCanAccessQuestions) {
      return this.renderAccessRestricted()
    }

    const baseVar = determineBaseVariable(xAxisVariables, yAxisVariables, chartType)

    return (
      <section className={classnames(styles.analysisPage, { [styles.inRoute]: !isEmbedded })}>
        <AnalysisSidebar
          dispatch={dispatch}
          isFetching={isFetchingData || isFetchingDataset}
          rdmComplete={dataset && dataset.rdmComplete}
          email={userInfo.email || ''}
          baseVariable={baseVar}
          handleTrack={handleTrack}
          handleRemoveVariable={this.handleRemoveVariable}
          onVariableAdd={this.onVariableAdd}
          handleEditFilter={this.handleEditFilterFromSidebar}
          onDeleteNet={this.handleDeleteNet}
          fetchAnalysisData={this.fetchAnalysisData}
          setChartLoadingState={this.setChartLoadingState}
          showStatTesting={showStatTesting}
          autoAdjustBaseEnabled={autoAdjustBaseEnabled}
          statsError={statsError}
          meanPopUpVarId={meanPopUpVarId}
          clearMeanPopUp={this.clearMeanPopUp}
          addFilterModalOpen={addFilterModalOpen}
        />
        {dataset && (
          <div className={styles.content}>
            <ChartActions
              handleTrack={handleTrack}
              fetchAnalysisData={this.fetchAnalysisData}
              handleOpenFilterModal={this.handleOpenFilterModal}
              handleAddContentItem={isEmbedded ? null : this.handleAddContentItem}
              sendNotification={this.context.sendNotification}
            />
            <AnalysisMetadata
              handleEditFilterFromBadge={this.handleEditFilterFromBadge}
              handleRemoveFiltersFromBadge={this.handleRemoveFiltersFromBadge}
            />
            {chartLoadingOverride && <ChartLoading />}
            {dataset && !chartLoadingOverride && <Analysis2Chart />}
          </div>
        )}
        {dataset && (
          <AddFilter
            isOpen={addFilterModalOpen}
            onClose={this.handleCloseFilterModal}
            onAddFilter={this.onAddFilter}
            datasetVariables={dataset.variables || []}
            datasetNk={dataset.datasetNk}
            studyId={dataset.studyId}
            appliedFilters={filters || []}
            handleTrack={handleTrack}
          />
        )}
        {!isEmbedded && dataset && (
          <AddContentItemModal
            dispatch={dispatch}
            modalIsOpen={addToAddToContentItemModalOpen}
            onClose={this.handleAddContentItem}
            onCreateError={this.handleAddContentItem}
            content_type="linkedanalysis"
            content_object={serializedChartState}
            study={{
              study_name: dataset.studyName,
              study_number: dataset.studyId,
            }}
            id={stateId}
            name={dataset.datasetNk || ''}
            currentSession={this.fakeSession()}
            newStory={{}}
            newCard={{}}
          />
        )}
      </section>
    )
  }
}

const mapStateToProps = state => ({
  userInfo: configDuck.getUserInfo(state),
  userPermissions: configDuck.getUserPermissions(state),
  dataset: selectors.getDataset(state),
  isFetchingDataset: selectors.isFetchingDataset(state),
  variables: selectors.getVariables(state),
  chartConfig: selectors.getChartConfig(state),
  validWeight: selectors.getValidWeightShape(state),
  weightingLabel: selectors.getWeightingLabel(state),
  stateId: selectors.getAnalysisStateId(state),
  serializedChartState: selectors.getSerializedChartState(state),
  isFetchingData: selectors.isFetchingData(state),
  isEmbedded: selectors.getIsEmbedded(state),
  statsError: selectors.hasStatTestingError(state),
  handleTrack: selectors.getHandleTrack(state),
  a1Husk: selectors.getA1Husk(state),
  defaultFilterVariable: filterNetDuck.getSelectedVariable(state),
  userCanAccessQuestions: selectors.getUserCanAccessQuestions(state),
})

export default connect(mapStateToProps)(Analysis2)
