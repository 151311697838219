import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector, shallowEqual } from 'react-redux'

import { getUserPermissions } from 'store/configurationDuck'
import { getFilters, getVariables, isFetchingData, isFetchingDataset } from 'store/DatasetSelectors'
import FilterBadges from './FilterBadges'
import StatTestingProgress from './StatTestingProgress'
import SampleAndWeight from './WeightDropdown/SampleAndWeight'
import ChartSummary from './ChartSummary'

import * as styles from './AnalysisMetadata.module.scss'

const propTypes = {
  handleEditFilterFromBadge: PropTypes.func,
  handleRemoveFiltersFromBadge: PropTypes.func,
}

function AnalysisMetadata({ handleEditFilterFromBadge, handleRemoveFiltersFromBadge }) {
  const userPerms = useSelector(getUserPermissions, shallowEqual)
  const filters = useSelector(getFilters, shallowEqual)
  const variables = useSelector(getVariables, shallowEqual)
  const fetchingDataset = useSelector(isFetchingDataset, shallowEqual)
  const fetchingData = useSelector(isFetchingData, shallowEqual)
  const showSummaries = useMemo(() => userPerms.includes('GPT_ANALYSIS_EXPLANATION'), [userPerms])

  return (
    <React.Fragment>
      {!showSummaries && <StatTestingProgress />}
      {Boolean(filters && filters.length) && (
        <FilterBadges
          filters={filters}
          variables={variables}
          onEditFilter={handleEditFilterFromBadge}
          onRemoveFilter={handleRemoveFiltersFromBadge}
          isLoading={fetchingData || fetchingDataset}
          containerClassName={styles.filterListContainer}
          truncateQuestionTo={20}
          truncateResponsesTo={15}
        />
      )}
      <SampleAndWeight />
      {showSummaries && <ChartSummary />}
    </React.Fragment>
  )
}

AnalysisMetadata.propTypes = propTypes

export default AnalysisMetadata
