const messages = {
  'en-US': {
    accessRestricted: {
      title: 'Access restricted',
      content:
        'Our records show you do not have access to view this content. If you feel you’ve reached this in error please let our customer success team know.',
      chat: 'Chat with customer success',
      homepage: 'Head to the homepage',
    },
    analysis: {
      actionBar: {
        numeric: { tooltip: 'Display data as numeric' },
        percentage: { tooltip: 'Display data as percentages' },
        showDataLabels: {
          button: 'Data Labels',
          tooltip: 'Show data labels in the visual chart',
        },
        addFilter: {
          button: 'Filter Data',
          tooltip: 'Add filter to apply to this view',
        },
        adjustBase: {
          button: 'Auto-update Base',
          tooltip:
            'Automatically update the base size to match your row/column visual selections on the left',
        },
        statTesting: {
          button: 'Stat Testing',
          tooltip: 'Show statistical significance testing',
        },
        hideLowBase: {
          button: 'Hide Low Bases',
          tooltip: 'Hide data with low bases from the chart',
        },
        nullSuppression: {
          button: 'Hide Nulls',
          tooltip: 'Hide data with all null values',
        },
        addTo: {
          button: 'Add To',
          tooltip: 'Add to Story/Study Dashboard',
        },
      },
      analysisDataErrors: {
        notAvailable: {
          title: 'This dataset is still generating raw data',
          body: 'Please try again in a few minutes. If the problem persists contact client success.',
        },
        noRowsOrColumns: {
          title: 'No variables added',
          body: 'Add a row or column variable to visualize data.',
        },
        noMainVar: {
          title: 'No main variables added',
          body: 'Add a main variable to visualize data.',
        },
        timeOut: {
          title: 'We were unable to load your data because there were too many cross tabulations',
          body: 'Please modify variable selections or filters.',
        },
        noVariableOptionsSelected: {
          title: 'The variable "{variableLabel}" does not have any options selected.',
          body: 'Please select at least one option.',
        },
        deletedOption: {
          title:
            'A response, option or net has been deleted from "{variableLabel}" and cannot be visualized.',
          body: 'Please click on "View Original" to the right and recreate the desired chart.',
        },
        weightIsUnavailable: {
          title: 'The selected weight variable is no longer available.',
          body: 'Please select a different weight variable.',
        },
        everythingLowBase: {
          title: 'All series are hidden due to their low base sizes',
          body: "To see the hidden series, click the 'Hide Low Base' button above.",
        },
        everythingNullSuppressed: {
          title: 'All series are hidden because they only contain null data',
          body: "To see the hidden series, turn 'Hide Nulls' off in the toolbar.",
        },
        unknown: {
          title: 'An error has occurred',
          body: 'Please try adding or removing a variable',
        },
      },
      openEndGate: {
        title: {
          embedded: 'Proceed to KnowledgeHound?',
          notEmbedded: 'Proceed to Open-end analysis?',
        },
        body: {
          embedded: 'This open-ended question can be analyzed in KnowledgeHound.',
          notEmbedded: 'This question can be analyzed in our open-end analysis page.',
        },
        cta: 'Yes, continue',
        cancel: 'Go back',
      },
      statTesting: {
        progress: {
          processing: `Calculating stat testing at a {confidence} confidence level. {
              progress,
              plural,
              =0 {}
              other {{progress, number}% of {comparisons, number} comparisons completed.}
              }`,
          completed:
            'Completed stat testing at a {confidence} confidence level. {progress, number}% of {comparisons, number} comparisons completed.',
          simpleCompleted:
            'Completed stat testing at a {confidence} confidence level. {progress, number}% of comparisons completed.',
          inactive: 'Looking for statistical significance testing? Click the button above.',
          noStatSig:
            'Completed stat testing at a {confidence} confidence level. Nothing was found to be significant.',
        },
        error: {
          // Should match message_key from BE
          TooManyComparisons:
            "{comparisons, number} comparisons exceeds this study's maximum of {maxComparisons, number}. Remove options from your analysis to lower comparisons.",
        },
      },
      summaries: {
        awaitingAD: 'Loading chart data...',
        fetching: 'Summarizing data...',
        error: 'Failed to generate a description for the data below. We have been notified.',
      },
      sidebar: {
        emptyVariableCard: '+ Add new {axisText} variable',
        emptyVariableDragTarget: '{target} to {axisText}',
      },
      apply: 'Apply',
      filterQuestions: 'Search questions',
      weightingMessage:
        "Indicates that this data has been weighted to represent a population that is different than the study's original sample.",
      flipAxis: 'Flip Axis',
      weightApplied: {
        long: '{label, select, null {Not weighted} other {Weighted using "{label}"}}',
        short: '{label, select, null {Not weighted} other {"{label}"}}',
      },
      selectQuestion: '1. Select a variable',
      selectOperator: `{headerNumber}. Select an operator`,
      selectedResponseOptions: '{headerNumber}. Select options',
      selectedNumericOptions: `{headerNumber}. Select a range`,
      breakouts: 'Breakouts',
      filterData: 'Add filter',
      createNet: 'Create a Net',
      filterDataTip: "Filter the current data by a question's response option(s)",
      filters: 'Filters',
      createResponseNet: 'Combine response options',
      applyResponseNets: 'Apply response groups',
      duplicateNet: 'A net with that name already exists',
      netInResponse:
        'Net name cannot be a question response option that is not contained in the net',
      invalidNetName: 'The net name provided is not valid',
      unknownNetError: 'There was an error creating this net',
      currentSampleSize: 'Current',
      sampleSizeTitle: 'Sample Sizes',
      study: 'Study',
      download: 'Download',
      question: 'Question',
      differentBaseCell:
        'This cell has a different base size than the group. {frequency, plural, =0 {No respondents} one {1 respondent} other {{frequency} respondents}} selected this option out of {base} total.',
      nullSuppression: {
        title: {
          responses: 'Hidden responses',
          breakouts: 'Hidden options',
        },
        tooltip:
          'These options are hidden because there is no data associated with them, either because the data is not present or the currently applied filters omit it. This behavior can be turned off by clicking the "Hide Nulls" button.',
        tooltipAction: 'Got it',
        allOptionsSuppressed: {
          responses:
            'All of the responses from this question are hidden because "hide nulls" is turned on and there is no relevant data to show',
          breakouts:
            'All of the options from this question are hidden because "hide nulls" is turned on and there is no relevant data to show',
        },
      },
    },
  },
}

export default messages
