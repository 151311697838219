import Highcharts from 'highcharts'
import PatternFill from 'highcharts/modules/pattern-fill'
import HighchartsReact from 'highcharts-react-official'
import React, { useEffect, useMemo, useRef, useState, memo } from 'react'
// WARNING: Don't use dispatches here until FeaturedAnalysis is fully Redux-y
import { useSelector, shallowEqual } from 'react-redux'
import { isEqual } from 'lodash'

import { getChartColors } from 'store/configurationDuck'
import {
  getTableData,
  isStatTestingEnabled,
  isFetchingData,
  shouldShowLabels,
  shouldHideLowBase,
  shouldSuppressNulls,
  getChartType,
  getChartNumberType,
  getBaseQuestion,
} from 'store/DatasetSelectors'
import { generateHighChartOptions } from '../AnalysisChartUtils'
import ChartLoading from '../ChartLoading'
import ChartError from '../ChartError'

import './VisualChart.scss'

PatternFill(Highcharts)

function VisualChart({ fullWidthHeight, legendHeight }) {
  const chartRef = useRef()
  const chartContainerRef = useRef()
  const resizeObserverRef = useRef()
  const [{ height, width }, setDimensions] = useState({ height: 0, width: 0 })
  const { tableData, colHeaders, rowHeaders, error, isEverythingLowBase, isEverythingSuppressed } =
    useSelector(getTableData, isEqual)
  const baseQuestion = useSelector(getBaseQuestion, shallowEqual)
  const loading = useSelector(isFetchingData, shallowEqual)
  const chartColors = useSelector(getChartColors, shallowEqual)
  const statTestingEnabled = useSelector(isStatTestingEnabled, shallowEqual)
  const showLabels = useSelector(shouldShowLabels, shallowEqual)
  const suppressNulls = useSelector(shouldSuppressNulls, shallowEqual)
  const hideLowBase = useSelector(shouldHideLowBase, shallowEqual)
  const chartType = useSelector(getChartType, shallowEqual)
  const numberType = useSelector(getChartNumberType, shallowEqual)
  const rowHeaderCount = rowHeaders?.[0]?.length ?? 0

  useEffect(() => {
    if (Boolean(window.ResizeObserver) && !resizeObserverRef.current && chartContainerRef.current) {
      resizeObserverRef.current = new ResizeObserver(entries =>
        entries.forEach(entry => {
          // 24px offset prevents looping observer due to scrollbar
          setDimensions({
            width: entry.contentRect.width - 24,
            height: entry.contentRect.height - 24,
          })
        })
      )
      resizeObserverRef.current.observe(chartContainerRef.current)
    }

    return () => {
      resizeObserverRef.current?.disconnect()
    }
  }, [])

  // TODO: Should be selector
  const chartOptions = useMemo(
    () =>
      tableData && tableData.length && tableData[0].length && height && !error
        ? generateHighChartOptions(
            chartType,
            chartColors,
            numberType,
            tableData,
            colHeaders,
            rowHeaders,
            rowHeaderCount,
            height,
            width,
            showLabels,
            (baseQuestion && baseQuestion.label) || '',
            fullWidthHeight,
            legendHeight,
            statTestingEnabled,
            hideLowBase
          )
        : null,
    [
      error,
      chartType,
      chartColors,
      numberType,
      tableData,
      colHeaders,
      rowHeaders,
      rowHeaderCount,
      height,
      width,
      showLabels,
      baseQuestion,
      fullWidthHeight,
      legendHeight,
      statTestingEnabled,
      hideLowBase,
    ]
  )
  if (isEverythingLowBase && hideLowBase) {
    return <ChartError messageKey="everythingLowBase" />
  }

  if (isEverythingSuppressed && suppressNulls) {
    return <ChartError messageKey="everythingNullSuppressed" />
  }

  if (error) {
    const acceptedErrorKeys = {
      noVariableOptionsSelected: true,
      deletedOption: true,
    }
    const messageKey = acceptedErrorKeys[error.message] ? error.message : 'unknown'
    const messageContent = error.variableLabel
      ? { variableLabel: error.variableLabel }
      : { variableLabel: 'a Variable' }

    return <ChartError messageKey={messageKey} messageContent={messageContent} />
  }

  if (loading) return <ChartLoading />

  return (
    <section ref={chartContainerRef} className="VisualChart">
      <HighchartsReact
        highcharts={Highcharts}
        options={chartOptions || { title: false, credits: false }}
        immutable
        containerProps={{ className: 'analysis-visual-chart-container' }}
        ref={chartRef}
      />
    </section>
  )
}

export default memo(VisualChart)
