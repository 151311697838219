/** Axis sentinel value */
export const ROW = 'ROW'

/** Axis sentinel value */
export const COLUMN = 'COLUMN'

/** VariableCard ID used for react-dnd */
export const DND_VARIABLE_CARD = 'ANALYSIS_VARIABLE_CARD'

/** Limit for the number of variables that can be in a visual chart */
export const VIS_CHART_VAR_LIMIT = 4

/** Limit for the number of variables that can be used in a custom weighting variable. */
export const CUSTOM_WEIGHT_MAX_STAGED_VARIABLES = 2
