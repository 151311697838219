// extracted by mini-css-extract-plugin
export const baseTiming = "125ms";
export const baseTimingInt = "125";
export const borderRadius = "3px";
export const charcoal = "#2d2e2e";
export const cloudGrey = "#c8cdcf";
export const content = "ChartSummary-module__content--0UQ6o";
export const cta = "ChartSummary-module__cta--1qU+i";
export const doveGrey = "#f1f3f5";
export const fetching = "ChartSummary-module__fetching--e2VFC";
export const footer = "ChartSummary-module__footer--4QVQ0";
export const khBlue = "#04b1e0";
export const khBlueDark = "#0376ae";
export const khBlueLight = "#7de1f4";
export const lemon = "#fae436";
export const lemonDark = "#e3cd02";
export const lemonLight = "#fff284";
export const lime = "#05c150";
export const limeDark = "#068230";
export const limeLight = "#65ec83";
export const navy = "#052976";
export const navyDark = "#04173f";
export const navyLight = "#82b1f6";
export const scarlet = "#e9062b";
export const scarletDark = "#9d050e";
export const scarletLight = "#f5726d";
export const spin = "ChartSummary-module__spin--egXWS";
export const stormGrey = "#969b9c";
export const summary = "ChartSummary-module__summary--+JVCP";
export const summaryBar = "ChartSummary-module__summary-bar--6vl9X";
export const violet = "#d401f8";
export const violetDark = "#9003a8";
export const violetLight = "#e77ef9";
export const white = "#fff";