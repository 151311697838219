import { batch } from 'react-redux'
import { createSelector } from 'reselect'
import * as Sentry from '@sentry/react'

import { getFilterMethodText } from 'util/filterMethods'
import { encodeNaturalKey } from 'util/naturalKeyUtils'
import { getUserInfo } from './configurationDuck'
import {
  fetchVariableThunk,
  addNetToVariable,
  removeNetFromVariable,
  clearAnalysisData,
  fetchAnalysisData,
} from './DatasetActions'
import { getVariables } from './DatasetSelectors'
import generateReduxAction from './GenerateReduxAction'
import { isNumericMethod } from '../FilterUtils'
import { getTabularizedData, getDistributionChartOptions } from './FilterNetUtils'

const COLLIE_URL = process.env.REACT_APP_COLLIE_URL || ''

export const initialState = {
  studyId: '',
  datasetName: '',
  selectedVariable: null,
  selectedGrid: null,
  selectedGridBreakout: null,
  selectedFilterMethod: '',
  selectedOptions: [],
  createNet: {
    isFetching: false,
    error: null,
    data: null,
    netName: '',
    createCount: 0,
  },
  tableData: {
    isFetching: false,
    error: null,
    data: {
      tableData: null,
      colHeader: null,
      rowHeaders: null,
    },
  },
}

// HELPER FUNCTIONS
export const getFilterMethod = (variable: VariableT | null) => {
  const supported = variable && variable.supported_filter_methods
  return supported ? supported[0] : ''
}

export const getResponsesForVarMethod = (
  filters: Array<FilterT>,
  variable: VariableT,
  selectedFilterMethod?: string,
  breakoutId?: string
) => {
  const filterMethod = selectedFilterMethod ? selectedFilterMethod : getFilterMethod(variable)
  if (filters.length > 0 && variable && filterMethod) {
    const foundFilter = filters.find(
      f =>
        f.questionName === variable.questionName &&
        f.variableResourceType === variable.resourceType &&
        f.method === filterMethod &&
        (!f.breakoutId || !breakoutId || f.breakoutId === breakoutId)
    )
    if (foundFilter) return foundFilter.filteredOptions
  }
  return []
}

export const convertNumericSelectedOptions = (
  newMethod,
  oldMethod,
  selectedOptions,
  selectedVariable
) => {
  if (!selectedOptions || !selectedOptions.length) return []
  const isSingle = ['lt', 'lte', 'gt', 'gte', 'notlt', 'notgt', 'notlte', 'notgte'].includes(
    oldMethod
  )
  const toSingle = ['lt', 'lte', 'gt', 'gte', 'notlt', 'notgt', 'notlte', 'notgte'].includes(
    newMethod
  )
  if (isSingle === toSingle) return selectedOptions
  const min = parseFloat(selectedVariable.options[0].id)
  const max = parseFloat(selectedVariable.options[selectedVariable.options.length - 1].id)
  if (['lt', 'lte', 'notgt', 'notgte'].includes(oldMethod)) {
    return [{ id: [min, selectedOptions[0].id], type: 'option' }]
  }
  if (['gt', 'gte', 'notlt', 'notlte'].includes(oldMethod)) {
    return [{ id: [selectedOptions[0].id, max], type: 'option' }]
  }
  if (['lt', 'lte', 'notgt', 'notgte'].includes(newMethod)) {
    return [{ id: selectedOptions[0].id[1], type: 'option' }]
  }
  if (['gt', 'gte', 'notlt', 'notlte'].includes(newMethod)) {
    return [{ id: selectedOptions[0].id[0], type: 'option' }]
  }
  return []
}

export const validateResponseOptions = (selectedOptions, selectedMethod, eqMin = 1) => {
  if (!selectedOptions || !selectedOptions.length) return false
  if (isNumericMethod(selectedMethod)) {
    const vals = selectedOptions[0].id
    if (['between', 'notbetween'].includes(selectedMethod)) {
      return Boolean(Array.isArray(vals) && vals.length && !vals.some(v => isNaN(v)))
    } else {
      return Boolean(!Array.isArray(vals) && typeof vals === 'number')
    }
  } else {
    return selectedMethod.includes('not')
      ? selectedOptions.length > 0
      : selectedOptions.length >= eqMin
  }
}

// ACTIONS
const SET_STUDY_ID = 'SET_STUDY_ID'
const SET_DATASET_NAME = 'SET_DATASET_NAME'
export const SET_SELECTED_VARIABLE = 'SET_SELECTED_VARIABLE'
export const SET_GRID_BREAKOUT = 'SET_GRID_BREAKOUT'
export const SET_SELECTED_METHOD = 'SET_SELECTED_METHOD'
export const SET_SELECTED_OPTIONS = 'SET_SELECTED_OPTIONS'
export const generateTableDataAction = generateReduxAction('GENERATE_TABLE_DATA')
export const SET_TABLE_DATA_ERROR = 'SET_TABLE_DATA_ERROR'
export const createNetAction = generateReduxAction('CREATE_NET')
export const deleteNetAction = generateReduxAction('DELETE_NET')
export const SET_NET_NAME = 'SET_NET_NAME'
export const SET_CREATE_COUNT = 'SET_CREATE_COUNT'

export const setFilterStudy = studyId => ({
  type: SET_STUDY_ID,
  payload: { studyId },
})

export const setFilterDataset = datasetName => ({
  type: SET_DATASET_NAME,
  payload: { datasetName },
})

export const setSelectedVariable = (
  selectedVariable,
  selectedGrid = initialState.selectedGrid
) => ({
  type: SET_SELECTED_VARIABLE,
  payload: { selectedVariable, selectedGrid },
})

export const setGridBreakout = selectedGridBreakout => ({
  type: SET_GRID_BREAKOUT,
  payload: { selectedGridBreakout },
})

export const setFilterMethod = selectedFilterMethod => ({
  type: SET_SELECTED_METHOD,
  payload: { selectedFilterMethod },
})

export const setSelectedOptions = (selectedOptions, selectedFilterMethod, selectedVariable) => ({
  type: SET_SELECTED_OPTIONS,
  payload: { selectedOptions, selectedFilterMethod, selectedVariable },
})

export const setCreateNetName = netName => ({
  type: SET_NET_NAME,
  payload: { netName },
})

export const setCreateCount = createCount => ({
  type: SET_CREATE_COUNT,
  payload: { createCount },
})

export const setTableDataError = error => ({
  type: SET_TABLE_DATA_ERROR,
  payload: error,
})

// THUNKS
export const resetFilterNetThunk =
  (disableDataFetch = false) =>
  (dispatch, getState) => {
    const createCount = getCreateNetCount(getState())
    if (createCount > 0 && !disableDataFetch) {
      dispatch(clearAnalysisData(true))
      dispatch(fetchAnalysisData())
    }
    batch(() => {
      dispatch(setSelectedVariable(initialState.selectedVariable, initialState.selectedGrid))
      dispatch(setGridBreakout(initialState.selectedGridBreakout))
      dispatch(setFilterMethod(initialState.selectedFilterMethod))
      dispatch(
        setSelectedOptions(
          initialState.selectedOptions,
          initialState.selectedFilterMethod,
          initialState.selectedVariable
        )
      )
      dispatch(generateTableDataAction.success(initialState.tableData.data))
      dispatch(createNetAction.success({ newNet: initialState.createNet.data }))
      dispatch(setCreateNetName(initialState.createNet.netName))
      dispatch(setCreateCount(initialState.createNet.createCount))
    })
  }

export const setSelectedVariableThunk =
  (
    selectedVariable,
    appliedFilters = [],
    selectedGrid = initialState.selectedGrid,
    selectedBreakout = initialState.selectedGridBreakout,
    method = initialState.selectedFilterMethod
  ) =>
  (dispatch, getState) => {
    const filterMethod = method ? method : getFilterMethod(selectedVariable)
    const options = getResponsesForVarMethod(
      appliedFilters,
      selectedVariable,
      filterMethod,
      selectedBreakout && selectedBreakout.id
    )
    batch(() => {
      dispatch(generateTableDataAction.success(initialState.tableData.data))
      dispatch(createNetAction.success({ newNet: initialState.createNet.data }))
      dispatch(setSelectedVariable(selectedVariable, selectedGrid))
      dispatch(setGridBreakout(selectedBreakout))
      dispatch(setFilterMethod(filterMethod))
      dispatch(setSelectedOptions(options, filterMethod, selectedVariable))
    })
  }

export const fetchSelectedVariableThunk =
  (variableNk, resourceType, isGrid, appliedFilters = []) =>
  async (dispatch, getState) => {
    const fetchedVars = await dispatch(fetchVariableThunk(variableNk, false))
    const selectedVariable =
      resourceType === 'breakouts' && fetchedVars.length > 1 ? fetchedVars[1] : fetchedVars[0]
    const method = getFilterMethod(selectedVariable)
    const options = isGrid ? [] : getResponsesForVarMethod(appliedFilters, selectedVariable, method)
    batch(() => {
      dispatch(generateTableDataAction.success(initialState.tableData.data))
      dispatch(createNetAction.success({ newNet: initialState.createNet.data }))
      dispatch(setSelectedVariable(selectedVariable, isGrid ? fetchedVars : null))
      dispatch(setGridBreakout(initialState.selectedGridBreakout))
      dispatch(setFilterMethod(method))
      dispatch(setSelectedOptions(options, method, selectedVariable))
    })
    return fetchedVars
  }

export const setBreakoutThunk =
  (selectedBreakout, appliedFilters = [], method = initialState.selectedFilterMethod) =>
  (dispatch, getState) => {
    const selectedVariable = getSelectedVariable(getState())
    const selectedGrid = getSelectedGrid(getState())
    const gridOption = selectedGrid.find(o => o.resourceType === 'options')
    const gridBreakout = selectedGrid.find(o => o.resourceType === 'breakouts')
    const selected = selectedVariable.resourceType === 'options' ? gridBreakout : gridOption
    const filterMethod = method ? method : getFilterMethod(selected)
    const options = getResponsesForVarMethod(
      appliedFilters,
      selectedVariable,
      filterMethod,
      selectedBreakout && selectedBreakout.id
    )
    batch(() => {
      dispatch(setSelectedVariable(selected, selectedGrid))
      dispatch(setGridBreakout(selectedBreakout))
      dispatch(setFilterMethod(filterMethod))
      dispatch(setSelectedOptions(options, filterMethod, selected))
    })
  }

export const setMethodThunk =
  (method, appliedFilters = []) =>
  (dispatch, getState) => {
    const selectedVariable = getSelectedVariable(getState())
    const selectedBreakout = getSelectedBreakout(getState())
    const oldMethod = getSelectedMethod(getState())
    const selectedOptions = getSelectedOptions(getState())
    const numericChange = isNumericMethod(oldMethod) && isNumericMethod(method)
    const filterOptions = getResponsesForVarMethod(
      appliedFilters,
      getSelectedVariable(getState()),
      method,
      selectedBreakout && selectedBreakout.id
    )
    const options =
      filterOptions && filterOptions.length
        ? filterOptions
        : numericChange
        ? convertNumericSelectedOptions(method, oldMethod, selectedOptions, selectedVariable)
        : []
    batch(() => {
      dispatch(setFilterMethod(method))
      dispatch(setSelectedOptions(options, method, selectedVariable))
    })
  }

export const generateTableDataThunk =
  (filters = []) =>
  async (dispatch, getState) => {
    dispatch(generateTableDataAction.loading())
    const studyId = getFilterStudy(getState())
    const datasetNk = getFilterDataset(getState())
    const selectedVariable = getSelectedVariable(getState())
    const selectedBreakout = getSelectedBreakout(getState())
    const selectedGrid = getSelectedGrid(getState())
    const variableList = getVariables(getState())

    const selectedVariables = selectedGrid ? selectedGrid : [selectedVariable]
    try {
      const tabData = await getTabularizedData(
        variableList,
        selectedVariables,
        selectedBreakout,
        filters,
        studyId,
        datasetNk
      )
      dispatch(generateTableDataAction.success(tabData))
    } catch (e) {
      dispatch(generateTableDataAction.error(e))
    }
  }

export const createNetThunk =
  (fromFilterModal, createAnother = false) =>
  async (dispatch, getState, { fetch }) => {
    dispatch(createNetAction.loading())
    const userInfo = getUserInfo(getState())
    const selectedVariable = getSelectedVariable(getState())
    const selectedOptions = getSelectedOptions(getState())
    const selectedMethod = getSelectedMethod(getState())
    const netLabel = getCreateNetName(getState())
    const createCount = getCreateNetCount(getState())

    const isNumeric = isNumericMethod(selectedMethod)
    const body = { email: userInfo.email, name: netLabel }

    if (!isNumeric) {
      if (selectedVariable.resourceType === 'breakouts') {
        body.breakouts = selectedOptions.map(o => o.id)
      } else {
        const methodOptions = selectedOptions
          .map(o => {
            const option = selectedVariable.options.find(opt => opt.id === o.id)
            if (!option) {
              return '' // flow rejects null, even if it is filtered out
            }
            return option.label
          })
          .filter(el => el !== '')
        methodOptions.sort((a, b) => a - b)
        body.response_rules = {
          [selectedMethod.replace(/(contains|eq)/, 'equal').replace('$', '')]: methodOptions,
        }
      }
    } else {
      body.response_rules = {
        [selectedMethod]: selectedOptions[0].id,
      }
    }

    try {
      const response = await fetch(
        `${COLLIE_URL}/v2/questions/${encodeNaturalKey(selectedVariable.variableNk)}/nets/`,
        {
          method: 'POST',
          jsonBody: body,
        }
      )
      if (!response.ok) {
        const error = new Error(response.statusText)
        error.response = response
        throw error
      }
      const newNet = await response.json()

      const responses =
        selectedVariable.resourceType === 'options'
          ? newNet.responses
          : newNet.breakouts
              .map(breakout => {
                const option = selectedVariable.options.find(o => o.id === breakout) // map from question name back to label
                return option ? option.label : ''
              })
              .filter(el => el !== '')
      const updatedVariable = {
        ...selectedVariable,
        nets: [
          ...selectedVariable.nets,
          {
            id: `${newNet.pk}`,
            type: 'net',
            label: newNet.name,
            responses: responses || [],
            email: newNet.email,
            responseRules: newNet.response_rules,
          },
        ],
      }

      const fallbackMethod =
        selectedVariable.supported_filter_methods.find(m => m.includes('$')) ||
        selectedVariable.supported_filter_methods[0]
      batch(() => {
        dispatch(addNetToVariable(newNet, updatedVariable, fromFilterModal))
        dispatch(setSelectedVariable(updatedVariable, getSelectedGrid(getState())))
        !createAnother && dispatch(setFilterMethod(fallbackMethod))
        !createAnother &&
          dispatch(
            setSelectedOptions(
              [{ id: `${newNet.pk}`, type: 'net' }],
              fallbackMethod,
              updatedVariable
            )
          )
        createAnother && dispatch(setCreateCount(createCount + 1))
        dispatch(createNetAction.success({ newNet, createAnother }))
      })
      return newNet
    } catch (error) {
      const errBody = await error.response.json()
      const netErrorInfo =
        errBody &&
        (errBody.non_field_errors
          ? errBody.non_field_errors[0]
          : '' + errBody.name
          ? errBody.name[0]
          : '')
      let errorCode = 'unknownNetError'
      if (netErrorInfo && netErrorInfo.indexOf('unique') !== -1) errorCode = 'duplicateNet'
      if (netErrorInfo && netErrorInfo.indexOf('is a response option') !== -1)
        errorCode = 'netInResponse'
      if (netErrorInfo && netErrorInfo.indexOf('HTML') !== -1) errorCode = 'invalidNetName'

      Sentry.captureException(new Error('Error creating net', { cause: error }))
      dispatch(createNetAction.error(errorCode))
    }
  }

export const deleteNetThunk =
  (variableNaturalKey, variableType, netId) =>
  async (dispatch, getState, { fetch }) => {
    const selectedVariable = getSelectedVariable(getState())
    try {
      const response = await fetch(
        `${COLLIE_URL}/v2/questions/${encodeNaturalKey(
          variableNaturalKey
        )}/nets/${netId.toString()}/`,
        { method: 'DELETE' }
      )
      if (!response.ok) {
        const error = new Error(response.statusText)
        error.response = response
        throw error
      }

      const updatedVariable = selectedVariable
        ? {
            ...selectedVariable,
            nets: selectedVariable.nets.filter(n => n.id !== netId),
          }
        : null
      batch(() => {
        dispatch(removeNetFromVariable(variableNaturalKey, variableType, netId))
        updatedVariable &&
          dispatch(setSelectedVariable(updatedVariable, getSelectedGrid(getState())))
        dispatch(deleteNetAction.success({ netId }))
      })
    } catch (e) {
      if (e.response && e.response.status && ![401, 403, 404].includes(e.response.status)) {
        Sentry.captureException(new Error('Error deleting net', { cause: e }))
      }
    }
  }

// SELECTORS
export const getFilterStudy = state => state.filterNet.studyId

export const getFilterDataset = state => state.filterNet.datasetName

export const getSelectedVariable = state => state.filterNet.selectedVariable

export const getSelectedGrid = state => state.filterNet.selectedGrid

export const getSelectedBreakout = state => state.filterNet.selectedGridBreakout

export const getSelectedMethod = state => state.filterNet.selectedFilterMethod

export const getSelectedOptions = state => state.filterNet.selectedOptions

export const createNetState = state => state.filterNet.createNet

export const tableDataState = state => state.filterNet.tableData

export const getMinOption = createSelector(getSelectedVariable, selectedVar =>
  selectedVar && selectedVar.options ? parseFloat(selectedVar.options[0].id) : 0
)

export const getMaxOption = createSelector(getSelectedVariable, selectedVar =>
  selectedVar && selectedVar.options
    ? parseFloat(selectedVar.options[selectedVar.options.length - 1].id)
    : 0
)

export const getVisualType = createSelector(getSelectedMethod, selectedFilterMethod => {
  const typeMap = {
    lt: 'lt',
    lte: 'lte',
    gt: 'gt',
    gte: 'gte',
    notlt: 'gte',
    notlte: 'gt',
    notgt: 'lte',
    notgte: 'lt',
    between: 'between',
    notbetween: 'notbetween',
  }

  return typeMap[selectedFilterMethod]
})

export const getValues = createSelector(
  getSelectedOptions,
  getSelectedMethod,
  getMinOption,
  getMaxOption,
  getSelectedVariable,
  getVisualType,
  (selectedOptions, selectedFilterMethod, min, max, selectedVariable, visualType) => {
    if (!selectedVariable) return [min, max]

    const isSingle = ['lt', 'lte', 'gt', 'gte', 'notlt', 'notgt', 'notlte', 'notgte'].includes(
      selectedFilterMethod
    )

    if (selectedOptions && selectedOptions.length) {
      return selectedOptions[0].id
    }
    if (isSingle) {
      return ['lt', 'lte'].includes(visualType) ? max : min
    }
    if (selectedFilterMethod === 'between') return [min, max]
    const optionLength = selectedVariable.options.length
    const minIdx = optionLength % 2 === 0 ? optionLength / 2 - 1 : Math.floor(optionLength / 2)
    const maxIdx = optionLength % 2 === 0 ? optionLength / 2 : Math.ceil(optionLength / 2)
    return [
      parseFloat(selectedVariable.options[minIdx].id),
      parseFloat(selectedVariable.options[maxIdx].id),
    ]
  }
)

export const getTableData = createSelector(tableDataState, tableDataState => tableDataState.data)

export const getTableDataLoading = createSelector(
  tableDataState,
  tableDataState => tableDataState.isFetching
)

export const getTableDataError = createSelector(
  tableDataState,
  tableDataState => tableDataState.error
)

export const getChartOptions = createSelector(
  getTableData,
  getSelectedOptions,
  getSelectedMethod,
  (generatedData, selectedOptions, selectedMethod) => {
    const { tableData, rowHeaders, colHeaders } = generatedData
    if (
      !selectedOptions ||
      !selectedOptions.length ||
      !tableData ||
      !tableData.length ||
      !rowHeaders ||
      !rowHeaders.length ||
      !colHeaders ||
      !colHeaders.length
    ) {
      return null
    }
    return getDistributionChartOptions(
      tableData,
      rowHeaders,
      colHeaders,
      selectedOptions,
      selectedMethod
    )
  }
)

export const getCreatedNet = createSelector(createNetState, createNetState => createNetState.data)

export const getCreateNetLoading = createSelector(
  createNetState,
  createNetState => createNetState.isFetching
)

export const getCreateNetError = createSelector(
  createNetState,
  createNetState => createNetState.error
)

export const getCreateNetName = createSelector(
  createNetState,
  createNetState => createNetState.netName
)

export const getCreateNetCount = createSelector(
  createNetState,
  createNetState => createNetState.createCount
)

export const getResponseOptionsValid = createSelector(
  getSelectedOptions,
  getSelectedMethod,
  (selectedOptions, selectedMethod) => {
    return validateResponseOptions(selectedOptions, selectedMethod, 2)
  }
)

// REDUCER
export const filterNetReducer = (state = initialState, action) => {
  const { payload, type } = action
  switch (type) {
    case SET_STUDY_ID: {
      return {
        ...state,
        studyId: payload.studyId,
      }
    }
    case SET_DATASET_NAME: {
      return {
        ...state,
        datasetName: payload.datasetName,
      }
    }
    case SET_SELECTED_VARIABLE: {
      const { selectedVariable, selectedGrid } = payload
      return {
        ...state,
        selectedVariable,
        selectedGrid,
      }
    }
    case SET_GRID_BREAKOUT: {
      const { selectedGridBreakout } = payload

      return {
        ...state,
        selectedGridBreakout,
      }
    }
    case SET_SELECTED_METHOD: {
      const { selectedFilterMethod } = payload

      return {
        ...state,
        selectedFilterMethod,
      }
    }
    case SET_SELECTED_OPTIONS: {
      const {
        selectedOptions,
        selectedFilterMethod = state.selectedFilterMethod,
        selectedVariable = state.selectedVariable,
      } = payload
      const { createNet } = state
      const convertValtoText = options => {
        return Array.isArray(options)
          ? options.map(v => v.toLocaleString()).join(' and ')
          : options.toLocaleString()
      }
      const isValid =
        !isNumericMethod(selectedFilterMethod) ||
        validateResponseOptions(selectedOptions, selectedFilterMethod, 2)
      let netName = initialState.createNet.netName
      if (isValid) {
        netName = isNumericMethod(selectedFilterMethod)
          ? `${getFilterMethodText(selectedFilterMethod)} ${convertValtoText(
              selectedOptions[0].id
            )}`
          : createNet.netName
      }
      const hasDuplicate =
        selectedVariable && selectedVariable.nets.length > 0 && netName.length > 0
          ? selectedVariable.nets.some(net => net.label === netName.trim())
          : false

      return {
        ...state,
        selectedOptions,
        createNet: {
          ...createNet,
          error: hasDuplicate ? 'duplicateNet' : null,
          netName,
        },
      }
    }
    case generateTableDataAction.loading.type: {
      return {
        ...state,
        tableData: {
          ...state.tableData,
          isFetching: true,
          error: null,
        },
      }
    }
    case generateTableDataAction.success.type: {
      return {
        ...state,
        tableData: {
          ...state.tableData,
          isFetching: false,
          error: null,
          data: payload,
        },
      }
    }
    case generateTableDataAction.error.type: {
      return {
        ...state,
        tableData: {
          ...state.tableData,
          isFetching: false,
          error: payload,
        },
      }
    }
    case SET_TABLE_DATA_ERROR: {
      return {
        ...state,
        tableData: {
          ...state.tableData,
          isFetching: false,
          error: payload,
        },
      }
    }
    case createNetAction.loading.type: {
      return {
        ...state,
        createNet: {
          ...state.createNet,
          isFetching: true,
          error: null,
        },
      }
    }
    case createNetAction.success.type: {
      return {
        ...state,
        createNet: {
          ...state.createNet,
          isFetching: false,
          error: null,
          data: payload.newNet,
          netName: initialState.createNet.netName,
        },
      }
    }
    case createNetAction.error.type: {
      return {
        ...state,
        createNet: {
          ...state.createNet,
          isFetching: false,
          error: payload,
        },
      }
    }
    case SET_NET_NAME: {
      const selectedVariable = state.selectedVariable
      const hasDuplicate =
        selectedVariable && selectedVariable.nets.length > 0 && payload.netName.length > 0
          ? selectedVariable.nets.some(net => net.label === payload.netName.trim())
          : false
      return {
        ...state,
        createNet: {
          ...state.createNet,
          error: hasDuplicate ? 'duplicateNet' : null,
          netName: payload.netName,
        },
      }
    }
    case SET_CREATE_COUNT: {
      return {
        ...state,
        createNet: {
          ...state.createNet,
          createCount: payload.createCount,
        },
      }
    }
    default:
      return state
  }
}
