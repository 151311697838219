import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { injectIntl } from 'react-intl'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'

import { SliderInput, NumberInput, KeyEvents } from '@knowledgehound/laika'

import { filterNet as filterNetDuck } from 'store'
import { getFilterMethodText } from '../util/filterMethods'
import DistributionChart from '../DistributionChart'

import * as styles from './AddFilter.module.scss'

function NumericFilters({
  appliedFilters,
  fromFilterModal = true,
  netModalOpen = false,
  chartContainerClass = '',
}) {
  const dispatch = useDispatch()
  const selectedVariable = useSelector(filterNetDuck.getSelectedVariable, shallowEqual)
  const selectedGrid = useSelector(filterNetDuck.getSelectedGrid, shallowEqual)
  const selectedGridBreakout = useSelector(filterNetDuck.getSelectedBreakout, shallowEqual)
  const selectedFilterMethod = useSelector(filterNetDuck.getSelectedMethod, shallowEqual)
  const selectedOptions = useSelector(filterNetDuck.getSelectedOptions, shallowEqual)
  const min = useSelector(filterNetDuck.getMinOption, shallowEqual)
  const max = useSelector(filterNetDuck.getMaxOption, shallowEqual)
  const visualType = useSelector(filterNetDuck.getVisualType, shallowEqual)
  const values = useSelector(filterNetDuck.getValues, shallowEqual)
  const isSingle = useMemo(
    () =>
      ['lt', 'lte', 'gt', 'gte', 'notlt', 'notgt', 'notlte', 'notgte'].includes(
        selectedFilterMethod
      ),
    [selectedFilterMethod]
  )
  const [singleInputVal, setSingleInputVal] = useState(
    isNaN(isSingle ? values : values[0]) ? '' : isSingle ? values : values[0]
  )
  const [doubleInputVal, setDoubleInputVal] = useState(
    isSingle || isNaN(values[1]) ? '' : values[1]
  )

  useEffect(() => {
    if (isSingle) {
      setSingleInputVal(isNaN(values) ? '' : values)
    } else {
      setSingleInputVal(isNaN(values[0]) ? '' : values[0])
      setDoubleInputVal(isNaN(values[1]) ? '' : values[1])
    }
  }, [values, isSingle])

  const createFauxOpt = useCallback(
    val => ({
      id: Array.isArray(val) ? val.sort((a, b) => a - b) : val,
      type: selectedVariable.resourceType.slice(0, -1),
    }),
    [selectedVariable]
  )

  const setSelectedOptions = useCallback(
    val => {
      dispatch(filterNetDuck.setSelectedOptions([createFauxOpt(val)]))
    },
    [dispatch, createFauxOpt]
  )

  const submitInput = useCallback(
    (field, e) => {
      if (isSingle) {
        setSelectedOptions(parseFloat(e.target.value))
      } else {
        const newVals =
          field === 'singleInput'
            ? [parseFloat(e.target.value), values[1]]
            : [values[0], parseFloat(e.target.value)]
        setSelectedOptions(newVals)
      }
    },
    [isSingle, values, setSelectedOptions]
  )

  const handleOnChange = useCallback(
    (field, e) => {
      // This check is a bit goofy, but this is actually how we detect the
      // difference between clicking on an up/down arrow vs a typed in input
      // to get the divergent behavior
      if (e.nativeEvent.inputType) {
        if (field === 'singleInput') {
          setSingleInputVal(isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value))
        } else {
          setDoubleInputVal(isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value))
        }
      } else {
        submitInput(field, e)
      }
    },
    [setSingleInputVal, setDoubleInputVal, submitInput]
  )

  if (!selectedVariable) return null

  if (values && (!selectedOptions || !selectedOptions.length)) {
    setSelectedOptions(values)
  }
  const convertValtoText = () => {
    return Array.isArray(values)
      ? values.map(v => v.toLocaleString()).join(' and ')
      : values.toLocaleString()
  }

  return (selectedVariable && !selectedGrid) || (selectedVariable && selectedGridBreakout) ? (
    <div className={`${chartContainerClass} ${styles.numericContainer}`}>
      <DistributionChart appliedFilters={appliedFilters} />
      <SliderInput
        minimum={min}
        maximum={max}
        type={visualType}
        value={
          Array.isArray(values) ? values.map(v => (isNaN(v) ? 0 : v)) : isNaN(values) ? 0 : values
        }
        handleChange={setSelectedOptions}
      />
      <div className={styles.inputContainer}>
        <NumberInput
          id="singleInput"
          value={singleInputVal}
          placeholder="Enter Value"
          onChange={e => {
            handleOnChange('singleInput', e)
          }}
          onBlur={e => submitInput('singleInput', e)}
          onKeyDown={e => {
            if (KeyEvents.onEnterKey(e)) {
              submitInput('singleInput', e)
            }
          }}
          max={isSingle ? null : values[1]}
        />
        {!isSingle && (
          <NumberInput
            id="doubleInput"
            value={doubleInputVal}
            placeholder={`${values[1]}`}
            onChange={e => {
              handleOnChange('doubleInput', e)
            }}
            onBlur={e => submitInput('doubleInput', e)}
            onKeyDown={e => {
              if (KeyEvents.onEnterKey(e)) {
                submitInput('doubleInput', e)
              }
            }}
            min={values[0]}
          />
        )}
      </div>
      {!netModalOpen &&
        fromFilterModal &&
        !(Array.isArray(values) ? values.some(v => isNaN(v)) : isNaN(values)) && (
          <div className={styles.filterText}>
            <span>{`${selectedVariable.label.slice(0, 175)}${
              selectedVariable.label.length > 175 ? '...' : ''
            } - ${getFilterMethodText(selectedFilterMethod)} ${convertValtoText()}`}</span>
          </div>
        )}
    </div>
  ) : (
    <div className={`${styles.placeholder} ${styles.fullHeight}`} />
  )
}

export default injectIntl(NumericFilters)
