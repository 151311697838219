// extracted by mini-css-extract-plugin
export const baseTiming = "125ms";
export const baseTimingInt = "125";
export const borderRadius = "3px";
export const charcoal = "#2d2e2e";
export const cloudGrey = "#c8cdcf";
export const columnContainer = "NumericNets-module__column-container--4Sjap";
export const columnHeader = "NumericNets-module__column-header--Zo+qc";
export const doveGrey = "#f1f3f5";
export const filterColumns = "NumericNets-module__filter-columns--zIWJh";
export const khBlue = "#04b1e0";
export const khBlueDark = "#0376ae";
export const khBlueLight = "#7de1f4";
export const lemon = "#fae436";
export const lemonDark = "#e3cd02";
export const lemonLight = "#fff284";
export const lime = "#05c150";
export const limeDark = "#068230";
export const limeLight = "#65ec83";
export const navy = "#052976";
export const navyDark = "#04173f";
export const navyLight = "#82b1f6";
export const numericContainer = "NumericNets-module__numeric-container--p17QU";
export const scarlet = "#e9062b";
export const scarletDark = "#9d050e";
export const scarletLight = "#f5726d";
export const stormGrey = "#969b9c";
export const violet = "#d401f8";
export const violetDark = "#9003a8";
export const violetLight = "#e77ef9";
export const white = "#fff";