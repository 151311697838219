// Pug uses this, make sure if you update this file you bump up
// the analysis package version and update Pug
const possibleFilterMethods = {
  $eq: [
    {
      value: '$eq',
      label: 'Equals',
    },
    {
      value: '$noteq',
      label: "Doesn't Equal",
    },
  ],
  $contains: [
    {
      value: '$contains',
      label: 'Contains',
    },
    {
      value: '$notcontains',
      label: "Doesn't Contain",
    },
  ],
  lt: [
    {
      value: 'lt',
      label: 'Is Less Than',
    },
  ],
  lte: [
    {
      value: 'lte',
      label: 'Is Less Than Or Equal To',
    },
  ],
  gt: [
    {
      value: 'gt',
      label: 'Is Greater Than',
    },
  ],
  gte: [
    {
      value: 'gte',
      label: 'Is Greater Than or Equal To',
    },
  ],
  between: [
    {
      value: 'between',
      label: 'Is Between',
    },
    {
      value: 'notbetween',
      label: 'Is Not Between',
    },
  ],
}

const filterMethodTextMap = {
  $eq: 'Equals',
  $noteq: "Doesn't Equal",
  $contains: 'Contains',
  $notcontains: "Doesn't Contain",
  lt: 'Is Less Than',
  lte: 'Is Less Than Or Equal To',
  gt: 'Is Greater Than',
  gte: 'Is Greater Than Or Equal To',
  between: 'Is Between',
  notbetween: 'Is Not Between',
  // used for nets
  equal: 'Equals',
  notequal: "Doesn't Equal",
  // These remain for legacy A1 redirects
  '$eq-id': 'Equals',
  '$noteq-id': "Doesn't Equal",
  '$contains-id': 'Contains',
  '$notcontains-id': "Doesn't Contain",
}

// Pug uses this, make sure if you update this method you bump up
// the analysis package version and update Pug
export function getFilterMethodText(filterMethod: string): string {
  return filterMethodTextMap[filterMethod] || ''
}

export default possibleFilterMethods
