import { getVariable, addVariableId } from '../Analysis2Utils'

// axis variables need to be updated in case nets have been created/deleted or
// additional options have been added/removed
export const updateAxisVarOptions = (axisVariables, variables) => {
  return axisVariables.map(axisVar => {
    const foundVar = getVariable(variables, axisVar.variableNk, axisVar.resourceType)
    if (!foundVar) return addVariableId(axisVar)
    axisVar.id = foundVar.id
    const updatedSelectedOptions = []
    // We want to update for added/deleted options as well as make sure the ordering is correct
    foundVar.calculated.forEach(c => {
      const found = axisVar.selectedOptions.find(
        so => so.id.toString() === c.id.toString() && so.type === 'calculated'
      )
      updatedSelectedOptions.push({
        id: c.id.toString(),
        type: 'calculated',
        selected: found ? found.selected : false,
      })
    })
    foundVar.nets.forEach(n => {
      const found = axisVar.selectedOptions.find(
        so => so.id.toString() === n.id.toString() && so.type === 'net'
      )
      updatedSelectedOptions.push({
        id: n.id.toString(),
        type: 'net',
        selected: found ? found.selected : false,
      })
    })
    foundVar.options.forEach(o => {
      const found = axisVar.selectedOptions.find(
        so => so.id.toString() === o.id.toString() && so.type === o.type
      )
      updatedSelectedOptions.push({
        id: o.id.toString(),
        type: o.type,
        selected: found ? found.selected : true,
      })
    })
    axisVar.selectedOptions = [...updatedSelectedOptions]
    return axisVar
  })
}
