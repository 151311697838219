import React from 'react'

import SampleInfoBar from './SampleInfoBar'
import WeightDropdown from './WeightDropdown'

import * as styles from './SampleAndWeight.module.scss'

export default function SampleAndWeight({ disabled }) {
  return (
    <div className={styles.sampleInfoWeightContainer}>
      <SampleInfoBar />
      <WeightDropdown disabled={disabled} />
    </div>
  )
}
