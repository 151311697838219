import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Icon } from '@knowledgehound/laika'

import * as styles from './ActionBarToggleButton.module.scss'

const propTypes = {
  /** Optional text label next to icon. */
  children: PropTypes.node,
  /** Hide text label next to icon. */
  hideActionText: PropTypes.bool,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  /** Helper attribute for Ghost Inspector to target. */
  testAttribute: PropTypes.string.isRequired,
}

function ActionBarToggleButton({
  children,
  hideActionText = false,
  isActive = false,
  isDisabled = false,
  icon,
  onClick,
  testAttribute,
}) {
  return (
    <button
      type="button"
      className={classnames('btn', 'toggle', 'small', styles.actionBarToggle, { active: isActive })}
      data-test={testAttribute}
      disabled={isDisabled}
      onClick={event => {
        event.preventDefault()
        event.stopPropagation()
        onClick(event)
      }}
    >
      <Icon icon={icon} color="inherit" size="medium" />
      {children && !hideActionText && <span className={styles.text}>{children}</span>}
    </button>
  )
}

ActionBarToggleButton.propTypes = propTypes

export default ActionBarToggleButton
