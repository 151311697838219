// @flow
import { handleError } from '@knowledgehound/data/fetchUtils'
import { fetchConfiguration } from '../util/api'
import { createSelector } from 'reselect'

export type PermissionsListT = Array<string>

export type ChartColorsT = Array<string>

export type a2SiteUserT = {
  // FIXME: Copied from SiteUserT in customer settings UserManagementTab
  profile_id: string,
  first_name: string,
  last_name: string,
  email: string,
  integrations: string,
  active: boolean,
  admin: boolean,
  manager: boolean,
  personalization: {},
}

export type UserConfigT = {
  client: string,
  user: {} | a2SiteUserT,
  permissions: PermissionsListT,
  chartColors: ChartColorsT,
}

export type ConfigT = {
  isFetching: boolean,
  lastFetched: null | number,
  error: null | Error,
  data: UserConfigT,
}

type configReducerT = {
  config: ConfigT,
}

export const getUserConfig = (state: configReducerT): ConfigT =>
  state.config && state.config.userConfig

// To make this easily movable into a node-module, this was
// created with the idea of Ducks
// https://github.com/erikras/ducks-modular-redux
const FETCH_USER_CONFIG_LOADING = 'FETCH_USER_CONFIG_LOADING'
export const FETCH_USER_CONFIG_SUCCESS = 'FETCH_USER_CONFIG_SUCCESS'
const FETCH_USER_CONFIG_ERROR = 'FETCH_USER_CONFIG_ERROR'
const PATCH_CHART_COLORS_LOADING = 'PATCH_CHART_COLORS_LOADING'
const PATCH_CHART_COLORS_SUCCESS = 'PATCH_CHART_COLORS_SUCCESS'
const PATCH_CHART_COLORS_ERROR = 'PATCH_CHART_COLORS_ERROR'
const PATCH_USER_PERSONALIZATION = 'PATCH_USER_PERSONALIZATION'

export const fetchUserConfigLoading = () => ({
  type: FETCH_USER_CONFIG_LOADING,
})

export const fetchUserConfigSuccess = config => ({
  type: FETCH_USER_CONFIG_SUCCESS,
  payload: config,
})

export const fetchUserConfigError = err => ({
  type: FETCH_USER_CONFIG_ERROR,
  payload: err,
  error: true,
})

export const fetchUserConfig = () => (dispatch, getState) => {
  dispatch(fetchUserConfigLoading())

  return new Promise((resolve, reject) => {
    fetchConfiguration()
      .then(data => {
        resolve(data)
        dispatch(fetchUserConfigSuccess(data))
      })
      .catch(handleError(reject, dispatch, fetchUserConfigError))
  })
}

export const getChartColors: Function => ChartColorsT = createSelector(
  getUserConfig,
  currUserConfig => currUserConfig.data.chartColors
)

export const getUserPermissions: Function => PermissionsListT = createSelector(
  getUserConfig,
  currUserConfig => currUserConfig.data.permissions || []
)

export const getUserInfo: Function => a2SiteUserT = createSelector(
  getUserConfig,
  currUserConfig => currUserConfig.data.user
)

export const getUserEmail = createSelector(
  getUserInfo,
  userInfo => (userInfo && userInfo.email) || ''
)

export const getClientName = createSelector(
  getUserConfig,
  currUserConfig => currUserConfig.data.client
)

export const getClientBase = createSelector(
  getUserConfig,
  currUserConfig => currUserConfig.data.clientBase
)

export const getIsUserAdmin = createSelector(getUserInfo, user => Boolean(user?.admin))

export const getIsUserManager = createSelector(getUserInfo, user => Boolean(user?.manager))

export const hasCollieVectorSearch = createSelector(getUserPermissions, permissions =>
  permissions.includes('COLLIE_VECTOR_SEARCH')
)

export const getHasUnweightedBasePerm = createSelector(getUserPermissions, perms =>
  perms.includes('ANALYSIS_UNWEIGHTED_BASE')
)

export const getHasPreferencesBoostPerm = createSelector(getUserPermissions, perms =>
  perms.includes('BOOST_PREFERENCES')
)

export const getDisplayMetadataOnCardsPerm = createSelector(getUserPermissions, perms =>
  perms.includes('DISPLAY_METADATA_ON_CARDS')
)

export const getUserRole = createSelector(getUserInfo, user => {
  if (user?.admin) return 'admin'
  if (user?.manager) return 'manager'
  return 'user'
})

export const getUserProfileId = createSelector(getUserInfo, user => user.profile_id)

export const getUserPersonalization = createSelector(getUserInfo, user => user.personalization)

export const getUserHasNotViewedModal = createSelector(getUserPersonalization, personalization =>
  personalization ? !personalization.hasOwnProperty('viewedModal') : false
)

export const getUserHasNotViewedLocalFilters = createSelector(
  getUserPersonalization,
  personalization =>
    personalization ? !personalization.hasOwnProperty('viewedLocalFilters') : false
)

export const patchUserPersonlization = data => ({
  type: PATCH_USER_PERSONALIZATION,
  data,
})

export const patchChartColorsLoading = () => ({
  type: PATCH_CHART_COLORS_LOADING,
})

export const patchChartColorsSuccess = colors => ({
  type: PATCH_CHART_COLORS_SUCCESS,
  payload: colors,
})

export const patchChartColorsError = err => ({
  type: PATCH_CHART_COLORS_ERROR,
  payload: err,
  error: true,
})

export const patchChartColors = chartColors => (dispatch, getState) => {
  dispatch(patchChartColorsLoading())

  return new Promise((resolve, reject) => {
    fetchConfiguration({
      method: 'PATCH',
      body: JSON.stringify({ chart_colors: chartColors }),
    })
      .then(data => {
        resolve(data.chart_colors)
        dispatch(patchChartColorsSuccess(data.chart_colors))
      })
      .catch(handleError(reject, dispatch, patchChartColorsError))
  })
}

export const initialState = {
  userConfig: {
    lastFetched: null,
    error: null,
    isFetching: false,
    data: {
      client: '',
      clientBase: '',
      user: {},
      permissions: [],
      chartColors: [],
    },
  },
}

export const configReducer = (state = initialState, action) => {
  const { payload, type } = action
  switch (type) {
    case FETCH_USER_CONFIG_LOADING: {
      return {
        ...state,
        userConfig: {
          ...state.userConfig,
          isFetching: true,
          error: null,
        },
      }
    }
    case FETCH_USER_CONFIG_SUCCESS: {
      const lastFetched = Date.now()
      return {
        ...state,
        userConfig: {
          ...state.userConfig,
          isFetching: false,
          error: null,
          data: {
            client: payload.client ?? '',
            clientBase: payload.client_base ?? '',
            user: payload.user ?? {},
            permissions: payload.permissions ?? [],
            chartColors: payload.chart_colors ?? [],
          },
          lastFetched,
        },
      }
    }
    case FETCH_USER_CONFIG_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        userConfig: {
          ...state.userConfig,
          isFetching: false,
          error: payload,
          lastFetched,
        },
      }
    }
    case PATCH_CHART_COLORS_LOADING: {
      return {
        ...state,
        userConfig: {
          ...state.userConfig,
          isFetching: true,
          error: null,
        },
      }
    }
    case PATCH_CHART_COLORS_SUCCESS: {
      const lastFetched = Date.now()
      return {
        ...state,
        userConfig: {
          ...state.userConfig,
          isFetching: false,
          error: null,
          data: {
            ...state.userConfig.data,
            chartColors: payload,
          },
          lastFetched,
        },
      }
    }
    case PATCH_CHART_COLORS_ERROR: {
      const lastFetched = Date.now()
      return {
        ...state,
        userConfig: {
          ...state.userConfig,
          isFetching: false,
          error: payload,
          lastFetched,
        },
      }
    }
    case PATCH_USER_PERSONALIZATION: {
      return {
        ...state,
        userConfig: {
          ...state.userConfig,
          personalization: action.data,
        },
      }
    }

    default:
      return state
  }
}
