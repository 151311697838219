import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import * as Sentry from '@sentry/react'
import { Modal, Button } from '@knowledgehound/laika'

import { filterNet as filterNetDuck, config as configDuck } from 'store'
import NetName from '../CreateNets/NetName'
import { isNumericMethod } from '../FilterUtils'
import FilterMethod from './FilterMethod'
import VariableFilterSelection from './VariableFilterSelection'
import SelectedVariable from './SelectedVariable'
import FilterResponseOptions from './FilterResponseOptions'
import * as styles from './AddFilter.module.scss'
import NumericFilters from './NumericFilters'

type PropsT = {
  dispatch: any,
  intl: any,
  isOpen: boolean,
  selectedGrid: Array<VariableT> | null,
  selectedVariable: VariableT | null,
  selectedGridBreakout: OptionT | null,
  selectedFilterMethod: string,
  selectedOptions: Array<OptionKeyT | FilterOptionT>,
  onClose: Function,
  onAddFilter: Function,
  datasetVariables: Array<DatasetQuestionT>,
  appliedFilters: Array<FilterT>,
  email: string,
  handleTrack?: Function,
  filterToReplace?: FilterT,
  newNetName: string,
}

type StateT = {
  netModalOpen: boolean,
}

class AddFilter extends Component<PropsT, StateT> {
  static contextTypes = {
    sendNotification: PropTypes.func,
  }

  constructor(props: PropsT) {
    super(props)
    this.state = {
      netModalOpen: false,
    }
  }

  resetModal() {
    this.props.dispatch(filterNetDuck.resetFilterNetThunk())
    this.setState({
      netModalOpen: false,
    })
  }

  handleClose = (evt: SyntheticEvent<*>) => {
    this.resetModal()
    this.props.onClose()
  }

  handleClickVariable = async (filterVariable: Object) => {
    const { dispatch, appliedFilters } = this.props
    try {
      await dispatch(
        filterNetDuck.fetchSelectedVariableThunk(
          filterVariable.nk,
          filterVariable.resourceType,
          filterVariable.is_grid,
          appliedFilters
        )
      )
    } catch (e) {
      Sentry.captureException(
        new Error('Analysis2 Filter Modal: Error when clicking Variable', { cause: e })
      )
    }
  }

  handleSelectBreakout = (option, selected) => {
    this.props.dispatch(filterNetDuck.setBreakoutThunk(option, this.props.appliedFilters))
  }

  handleClickCloseQuestion = () => {
    this.resetModal()
  }

  handleClickApply = (evt: SyntheticEvent<*>) => {
    evt.preventDefault()
    const {
      onAddFilter,
      onClose,
      selectedVariable,
      selectedFilterMethod,
      selectedOptions,
      selectedGrid,
      selectedGridBreakout,
    } = this.props

    this.resetModal()

    onAddFilter({
      selectedVariable,
      selectedFilterMethod,
      selectedOptions,
      selectedGrid,
      selectedGridBreakout,
    })
    onClose()
  }

  handleChangeResponseOption = (optionId: string | number, optionType: string) => {
    const { selectedOptions, dispatch } = this.props

    const updatedOptions = this.isChecked({ id: optionId, type: optionType })
      ? selectedOptions.filter(opt => !(opt.id === optionId && opt.type === optionType))
      : [...selectedOptions, { id: optionId, type: optionType }]

    dispatch(filterNetDuck.setSelectedOptions(updatedOptions))
  }

  handleToggleAllQuestionResponses = (isSelectAll: boolean) => {
    const { selectedVariable, selectedOptions, dispatch } = this.props

    const newSelectedOptions = selectedOptions
      ? selectedOptions.filter(opt => opt.type === 'net')
      : []

    if (isSelectAll && selectedVariable) {
      selectedVariable.options.forEach(({ id, type }) => {
        newSelectedOptions.push({
          id,
          type,
        })
      })
    }
    dispatch(filterNetDuck.setSelectedOptions(newSelectedOptions))
  }

  isChecked = response => {
    return Boolean(
      this.props.selectedOptions.find(
        option => option.id === response.id && option.type === response.type
      )
    )
  }

  handleChangeFilterMethod = (event: Event, value: string) => {
    if (!isNumericMethod(value)) {
      this.props.dispatch(filterNetDuck.setCreateNetName(''))
    }
    this.props.dispatch(filterNetDuck.setMethodThunk(value, this.props.appliedFilters))
  }

  toggleCreateNet = () => {
    this.props.dispatch(filterNetDuck.setCreateCount(0))
    return this.setState({ netModalOpen: !this.state.netModalOpen })
  }

  onCreateNets = async (e, createAnother = false) => {
    const {
      selectedOptions,
      selectedFilterMethod,
      dispatch,
      selectedVariable,
      email,
      newNetName,
      handleTrack,
    } = this.props
    dispatch(filterNetDuck.createNetThunk(true, createAnother))

    if (handleTrack) {
      const trackProps = {
        question_id: selectedVariable.variableNk,
        question_text: selectedVariable.questionName,
        user: email,
        netName: newNetName,
        netMethod: selectedFilterMethod,
        nettedResponses: selectedOptions,
      }
      handleTrack('Question Response Net Created', trackProps)
    }
    if (!createAnother) {
      this.setState({
        netModalOpen: false,
      })
    }
  }

  render() {
    const {
      isOpen,
      datasetVariables,
      handleTrack,
      appliedFilters,
      selectedVariable,
      selectedGrid,
      selectedGridBreakout,
      selectedFilterMethod,
      selectedOptions,
    } = this.props

    const { netModalOpen } = this.state

    return (
      <Modal
        isOpen={isOpen}
        onClose={this.handleClose}
        onSubmit={this.handleClickApply}
        submitDisabled={
          netModalOpen ||
          !(
            selectedVariable &&
            selectedFilterMethod &&
            filterNetDuck.validateResponseOptions(selectedOptions, selectedFilterMethod)
          )
        }
        submitText="Apply"
        title="Add Filter"
      >
        <div className={styles.addFilterContainer}>
          <section className={styles.filterColumns}>
            <div className={styles.filterColumn}>
              {selectedVariable || selectedGrid ? (
                <SelectedVariable
                  handleClickCloseQuestion={this.handleClickCloseQuestion}
                  handleSelectBreakout={this.handleSelectBreakout}
                />
              ) : (
                <VariableFilterSelection
                  datasetVariables={datasetVariables}
                  handleClickVariable={this.handleClickVariable}
                />
              )}
            </div>
            <FilterMethod
              displayMethod={
                (selectedVariable && !selectedGrid) || (selectedVariable && selectedGridBreakout)
              }
              handleChangeFilterMethod={this.handleChangeFilterMethod}
              sectionStyle={styles.filterMethods}
            />
            <div
              className={classNames(styles.filterColumn, {
                [styles.netOptions]: netModalOpen && !isNumericMethod(selectedFilterMethod),
                [styles.createNetContainer]: netModalOpen && isNumericMethod(selectedFilterMethod),
              })}
            >
              <h4 className={styles.columnHeader}>
                <FormattedMessage
                  id={
                    isNumericMethod(selectedFilterMethod)
                      ? 'analysis.selectedNumericOptions'
                      : 'analysis.selectedResponseOptions'
                  }
                  values={{ headerNumber: '3' }}
                />
                {selectedFilterMethod &&
                  (netModalOpen ? (
                    <Button onClick={this.toggleCreateNet} variant="text" size="small" icon="close">
                      Close
                    </Button>
                  ) : (
                    <Button
                      onClick={evt => {
                        evt.stopPropagation()
                        this.toggleCreateNet()
                      }}
                      variant="text"
                      size="small"
                    >
                      Create Net
                    </Button>
                  ))}
              </h4>
              {!isNumericMethod(selectedFilterMethod) && (
                <FilterResponseOptions
                  isChecked={this.isChecked}
                  netModalOpen={netModalOpen}
                  handleChangeResponseOption={this.handleChangeResponseOption}
                  handleTrack={handleTrack}
                  handleToggleAllQuestionResponses={this.handleToggleAllQuestionResponses}
                />
              )}
              {selectedVariable &&
                isNumericMethod(selectedFilterMethod) &&
                selectedVariable.options && (
                  <NumericFilters appliedFilters={appliedFilters} netModalOpen={netModalOpen} />
                )}
            </div>
          </section>
          {netModalOpen && (
            <NetName
              containerClass={styles.netNameContainer}
              handleClickCreate={this.onCreateNets}
              createAnother={e => this.onCreateNets(e, true)}
            />
          )}
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  selectedVariable: filterNetDuck.getSelectedVariable(state),
  selectedGrid: filterNetDuck.getSelectedGrid(state),
  selectedGridBreakout: filterNetDuck.getSelectedBreakout(state),
  selectedFilterMethod: filterNetDuck.getSelectedMethod(state),
  selectedOptions: filterNetDuck.getSelectedOptions(state),
  newNetName: filterNetDuck.getCreateNetName(state),
  email: configDuck.getUserEmail(state),
})

export default connect(mapStateToProps)(injectIntl(AddFilter))
