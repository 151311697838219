// @flow
import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'

import ChartError from './ChartError'
import ChartLoading from './ChartLoading'
import KendoChart from './KendoChart'
import VisualChart from './VisualChart'
import { getChartType, isFetchingData, getChartErrorMessageKey } from 'store/DatasetSelectors'

export default function AnalysisChart() {
  const chartType = useSelector(getChartType, shallowEqual)
  const isFetching = useSelector(isFetchingData, shallowEqual)
  const { messageKey, messageContent = {} } = useSelector(getChartErrorMessageKey, shallowEqual)

  if (isFetching) return <ChartLoading />

  if (messageKey) return <ChartError messageKey={messageKey} messageContent={messageContent} />

  // TODO: Without this a test breaks, and Jest isn't saying which one...
  const chartTypeToShow = chartType || 'spreadsheet'

  return chartTypeToShow === 'spreadsheet' ? (
    <div className="kendo-chart-container">
      <KendoChart />
    </div>
  ) : (
    <div className="VisualChart__container">
      <VisualChart />
    </div>
  )
}
