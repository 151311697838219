import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './VariableSection.module.scss'

const propTypes = {
  handleClickVariable: PropTypes.func.isRequired,
  headerText: PropTypes.string.isRequired,
  variableList: PropTypes.arrayOf(
    PropTypes.shape({
      nk: PropTypes.string.isRequired,
      question_text: PropTypes.string.isRequired,
    })
  ).isRequired,
}

function VariableSection({ handleClickVariable, headerText, variableList }) {
  if (!variableList.length) return null
  return (
    <section className={styles.variableSection}>
      <div className={styles.header}>{`${headerText} (${variableList.length})`}</div>
      <ul className={styles.variableList}>
        {variableList.map(variable => (
          <li
            className={styles.item}
            onClick={() => handleClickVariable(variable)}
            key={variable.nk}
          >
            {variable.question_text}
          </li>
        ))}
      </ul>
    </section>
  )
}

VariableSection.propTypes = propTypes

export default VariableSection
