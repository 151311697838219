/**
 * Floor a number to a specific decimal place.
 * @param {number} number - Value to be modified
 * @param {number} precision - Number of decimal places to limit to
 * @returns {number} Limited value
 */
export function floorToPrecision(number, precision) {
  const str = number.toString()
  const idx = str.indexOf('.')
  return idx >= 0 ? Number(str.slice(0, idx + precision + 1)) : number
}

/**
 * Floor a number to a specific decimal place.
 * @param {number} number - Value to be modified
 * @param {number} precision - Number of decimal places to limit to
 * @returns {number} Limited value
 */
export function roundToPrecision(number, precision) {
  return Number(number.toFixed(precision))
}
