// TODO: also used in A1 - figure out what to do with component
import React from 'react'
import isHtml from 'is-html'
import h2p from 'html2plaintext'

export default function WithHtml({ content, className }) {
  const hasHtml = isHtml(content)
  let plainText = null
  if (hasHtml) {
    // see if there's plain text in it
    plainText = cleanText(content)
    if (plainText.length > 0) {
      return <span className={className || ''}>{plainText}</span>
    } else {
      return (
        <div
          className={className || 'HtmlContent'}
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      )
    }
  } else {
    return <span>{content}</span>
  }
}

export const cleanText = unsafeText => {
  return h2p(unsafeText).replace(/(\r\n|\n|\r)/gm, '')
}
