import { createSelector } from 'reselect'
import { sortBy } from 'lodash'

import { getStandardWeights, getWeighting, getCustomWeights } from 'store/DatasetSelectors'

export const getStandardWeightOptions = createSelector(
  getWeighting,
  getStandardWeights,
  (currentWeight, standardWeights = []) => [
    {
      value: 'useDefault',
      checked: 'useDefault' === currentWeight || !currentWeight,
      labelText: 'Use dataset default',
    },
    {
      value: 'unweighted',
      checked: 'unweighted' === currentWeight,
      labelText: 'No weight',
    },
    ...sortBy(
      standardWeights.map(weight => ({
        value: weight.id,
        checked: weight.id === currentWeight,
        labelText: weight.id,
      })),
      'labelText'
    ),
  ]
)

export const getCustomWeightOptions = createSelector(
  getWeighting,
  getCustomWeights,
  (currentWeight, customWeights = []) =>
    customWeights.map(weight => ({
      value: weight.id ?? weight.label,
      checked: weight.id === currentWeight,
      disabled: !weight.id || !weight.isDataReady || !weight.isValid,
      labelText: weight.label,
      id: weight.id,
      userIsOwner: weight.userIsOwner,
      isValid: weight.isValid,
      isDataReady: weight.isDataReady,
      specId: weight.specId,
    }))
)
