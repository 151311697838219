import React from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import PropTypes from 'prop-types'

import { Icon } from '@knowledgehound/laika'
import { ROW, COLUMN } from 'store/constants'
import { getHandleTrack } from 'store/DatasetSelectors'

import * as styles from './AxisInfoIcon.module.scss'

const propTypes = {
  chart: PropTypes.oneOf(['spreadsheet', 'column', 'bar', 'stackedColumn', 'stackedBar', 'line']),
  axis: PropTypes.oneOf([ROW, COLUMN]),
}

const chartAxis = {
  spreadsheet: {
    [ROW]: {
      text: 'Rows',
      article: 7225252,
    },
    [COLUMN]: {
      text: 'Columns',
      article: 7263550,
    },
  },
  column: {
    [COLUMN]: {
      text: 'Series (Legend)',
      article: 7264592,
    },
    [ROW]: {
      text: 'Categories (X-axis)',
      article: 7264642,
    },
  },
  bar: {
    [COLUMN]: {
      text: 'Series (Legend)',
      article: 7264592,
    },
    [ROW]: {
      text: 'Categories (Y-axis)',
      article: 7264642,
    },
  },
  stackedColumn: {
    [ROW]: {
      text: 'Categories (X-axis)',
      article: 7860331,
    },
    [COLUMN]: {
      text: 'Stacking Variable - Series (Legend)',
      article: 7860279,
    },
  },
  stackedBar: {
    [ROW]: {
      text: 'Categories (Y-axis)',
      article: 7860331,
    },
    [COLUMN]: {
      text: 'Stacking Variable - Series (Legend)',
      article: 7860279,
    },
  },
  line: {
    [ROW]: {
      text: 'Categories (X-Axis)',
      article: 7269295,
    },
    [COLUMN]: {
      text: 'Series (Legend)',
      article: 7264592,
    },
  },
}

const AxisInfoIcon = ({ chart, axis }) => {
  const handleTrack = useSelector(getHandleTrack, shallowEqual)

  const clickedIcon = () => {
    window.Intercom('showArticle', chartAxis[`${chart}`][`${axis}`].article)
    handleTrack(`info article clicked`, { chart, axis })
  }

  return (
    <div className={styles.infoContainer}>
      <div>{chartAxis[`${chart}`][`${axis}`].text}</div>
      <button className={styles.infoIcon} onClick={() => clickedIcon()}>
        <Icon icon="info" size="small" color="khBlue" />
      </button>
    </div>
  )
}

AxisInfoIcon.propTypes = propTypes
export default AxisInfoIcon
