// extracted by mini-css-extract-plugin
export const baseTiming = "125ms";
export const baseTimingInt = "125";
export const borderRadius = "3px";
export const charcoal = "#2d2e2e";
export const cloudGrey = "#c8cdcf";
export const controls = "WeightDropdown-module__controls--Uv3vx";
export const customWeights = "WeightDropdown-module__custom-weights--W3S2c";
export const divider = "WeightDropdown-module__divider--1w4mF";
export const doveGrey = "#f1f3f5";
export const header = "WeightDropdown-module__header--ms+fc";
export const khBlue = "#04b1e0";
export const khBlueDark = "#0376ae";
export const khBlueLight = "#7de1f4";
export const layout = "WeightDropdown-module__layout--oV8KD";
export const lemon = "#fae436";
export const lemonDark = "#e3cd02";
export const lemonLight = "#fff284";
export const lime = "#05c150";
export const limeDark = "#068230";
export const limeLight = "#65ec83";
export const navy = "#052976";
export const navyDark = "#04173f";
export const navyLight = "#82b1f6";
export const scarlet = "#e9062b";
export const scarletDark = "#9d050e";
export const scarletLight = "#f5726d";
export const spin = "WeightDropdown-module__spin--UMtAe";
export const spinner = "WeightDropdown-module__spinner--C6fBh";
export const standardWeights = "WeightDropdown-module__standard-weights--1w-vk";
export const stormGrey = "#969b9c";
export const violet = "#d401f8";
export const violetDark = "#9003a8";
export const violetLight = "#e77ef9";
export const weightDropdownContainer = "WeightDropdown-module__weight-dropdown-container--h1WI3";
export const weightInfoLink = "WeightDropdown-module__weight-info-link--z1bzr";
export const white = "#fff";