import { sha256 } from 'js-sha256'
import { differenceWith, isEmpty } from 'lodash'

import { createOptionVar, createBreakoutVar, generateAxisVars } from 'store/DatasetRequests'
import { ROW, COLUMN } from 'store/constants'
import { getQnameFromNk } from './util/naturalKeyUtils'
import { getFilterMethodText } from './util/filterMethods'

type ConvertedA1QuestionT = {
  availableNets: Array<ResponseNetT>,
  breakouts: Array<string>,
  options: Array<string>,
  nets: Array<string>,
  question: AnalysisQuestionT,
}
type GroupedFilterT = {
  filter: string,
  method: string,
  methodText: string,
  options?: Array<number | string>,
  nets?: Array<number>,
}

const isChartStacked = chartType => ['stackedBar', 'stackedColumn'].includes(chartType)

const hashName = s => {
  let hash = 0
  const strlen = s.length
  if (strlen === 0) return hash

  for (let i = 0; i < strlen; i += 1) {
    const c = s.charCodeAt(i)
    hash = (hash << 5) - hash + c
    hash &= hash // Convert to 32bit integer
  }
  return hash + ''
}

export const isIntervalOrNumeric = (variable: DatasetQuestionT | VariableT) => {
  return (
    variable.represents === 'interval' ||
    ['Real Numeric', 'Integer Numeric'].includes(variable.question_type)
  )
}

export const getFirstInterval = (datasetVariables: Array<DatasetQuestionT> | Array<VariableT>) => {
  if (!datasetVariables || !datasetVariables.length) return null
  const trueInterval = datasetVariables.find(v => v.represents === 'interval')
  return trueInterval || datasetVariables.find(v => isIntervalOrNumeric(v))
}

export const getNetExplanation = net => {
  // New net that supports methods
  if (net.responseRules && !isEmpty(net.responseRules)) {
    const [method] = Object.keys(net.responseRules)
    const methodText = getFilterMethodText(method).replace('Is ', '')
    const valText = Array.isArray(net.responseRules[method])
      ? net.responseRules[method].map(v => `'${v.toLocaleString()}'`).join(' or ')
      : net.responseRules[method].toLocaleString()
    return `${methodText} ${valText}`
  }

  // Older nets only support "equals"
  return `Equals ${net.responses.map(v => `'${v.toLocaleString()}'`).join(' or ')}`
}

const switchToMean = (axisVars: Array<AxisVariableT>, varId: string) => {
  return axisVars.map(v => {
    if (v.id !== varId) return v
    return {
      ...v,
      selectedOptions: v.selectedOptions.map(o => {
        if (o.type === 'net') return o
        return {
          ...o,
          selected: o.type === 'calculated' && o.id === '__mean__',
        }
      }),
    }
  })
}

/**
 * Forecast the layout of analysis variables when the argument variable is inserted
 * into the analysis.
 *
 * This handles the logic around how grid variables get placed, which changes
 * depending on the context.
 *
 * @param {Object} args - Function arguments
 * @param {Array<Object>} args.xAxisVariables - List of current x-axis analysis variables
 * @param {Array<Object>} args.yAxisVariables - List of current y-axis analysis variables
 * @param {String} args.chartType - Current visual chart type: spreadsheet, column, etc.
 * @param {String} args.axis - Target axis for which the new variable will be placed
 * @param {Array<Object>} args.newVars - Incoming variables to be analyzed
 * @param {number} [args.replacingPosition=1] - Index of variable to be replaced by incoming variable
 * @param {Array<Object>} [selectedNets=[]] - List of selected nets belonging to incoming variable
 * @param {Array<Object>} [options=[]] - List of selected options belonging to incoming variable
 * @param {Array<Object>} [breakouts=[]] - List of selected breakouts belonging to incoming grid variable
 * @param {Array<Object>} [calculated=[]] - List of selected calculated options belonging to incoming variable
 * @param {Array<Object>} [calculatedBreakout=[]] - List of selected calculated breakouts belonging to incoming grid variable
 * @param {boolean} [defaultMean=false] - Should the incoming variable default to means mode?
 * @param {boolean} [fromPageLoadWithXtab=false] - True if variable is being added while setting up initial analysis state on a study that has a default crosstab variable. Will keep grid variables in same axis.
 * @returns {Object} Updated lists of X and Y axis variables, the base variable, and if means mode is on
 */
export const determineNewVarLayout = ({
  xAxisVariables,
  yAxisVariables,
  chartType,
  axis,
  newVars,
  replacingPosition = -1,
  selectedNets = [],
  options = [],
  breakouts = [],
  calculated = [],
  calculatedBreakout = [],
  defaultMean = false,
  fromPageLoadWithXtab = false,
}) => {
  let updatedX = [...xAxisVariables]
  let updatedY = [...yAxisVariables]

  const [optionVar, breakoutVar] = generateAxisVars(
    newVars,
    axis,
    selectedNets,
    options,
    breakouts,
    calculated,
    calculatedBreakout
  )
  let optVarInX

  if (Boolean(breakoutVar) && xAxisVariables.length + yAxisVariables.length === 0) {
    // If adding multiple variables (grid q) and none currently exist,
    // place one var on each axis
    optVarInX = ROW && !isChartStacked(chartType)
    updatedX =
      axis === ROW && !isChartStacked(chartType)
        ? [{ ...optionVar, axis: ROW }]
        : [{ ...breakoutVar, axis: ROW }]
    updatedY =
      axis === ROW && !isChartStacked(chartType)
        ? [{ ...breakoutVar, axis: COLUMN }]
        : [{ ...optionVar, axis: COLUMN }]
  } else if (fromPageLoadWithXtab && breakoutVar) {
    updatedX = (axis === ROW ? [...updatedX, breakoutVar, optionVar] : updatedX).map(v => ({
      ...v,
      axis: ROW,
    }))
    updatedY = (axis === COLUMN ? [...updatedY, breakoutVar, optionVar] : updatedY).map(v => ({
      ...v,
      axis: COLUMN,
    }))
  } else {
    optVarInX = axis === ROW
    const newIndex =
      replacingPosition > -1
        ? replacingPosition
        : axis === ROW
        ? xAxisVariables.length
        : yAxisVariables.length
    if (axis === ROW) {
      updatedX.splice(newIndex, 0, { ...optionVar, axis: ROW })
      if (breakoutVar) {
        // Split grid question across both axises
        updatedY.push({ ...breakoutVar, axis: COLUMN })
      }
    } else {
      updatedY.splice(newIndex, 0, { ...optionVar, axis: COLUMN })
      if (breakoutVar && chartType === 'line') {
        // Keep breakouts on same axis line charts only make sense with a
        // single numeric/interval row variable
        updatedY.push({ ...breakoutVar, axis: COLUMN })
      } else if (breakoutVar) {
        // Split grid question across both axises
        updatedX.push({ ...breakoutVar, axis: ROW })
      }
    }
  }

  const baseVar = determineBaseVariable(updatedX, updatedY, chartType)
  const meanOpt = optionVar.selectedOptions.find(
    o => o.id === '__mean__' && o.type === 'calculated'
  )
  let meanMode = Boolean(meanOpt && meanOpt.selected)

  if (defaultMean) {
    if (baseVar && optionVar.id === baseVar.id) {
      meanMode = true
      if (optVarInX) {
        updatedX = switchToMean(updatedX, optionVar.id)
      } else {
        updatedY = switchToMean(updatedY, optionVar.id)
      }
    }
  }
  return { updatedX, updatedY, meanMode, baseVar }
}

export const determineBaseVariable: Function => AxisVariableT = (
  xAxisVariables: Array<AxisVariableT>,
  yAxisVariables: Array<AxisVariableT>,
  chartType: ChartTypeT
) => {
  const xBase = xAxisVariables[xAxisVariables.length - 1]
  const isRowBasedAnalysis = ['line', 'stackedBar', 'stackedColumn'].includes(chartType)

  if (isRowBasedAnalysis) {
    // use a different base for these chart types
    return yAxisVariables.length ? yAxisVariables[0] : xBase
  } else {
    return xAxisVariables.length ? xBase : yAxisVariables[yAxisVariables.length - 1]
  }
}

export const getVariable = (
  variables: Array<VariableT> | Array<AxisVariableT>,
  variableNk: string,
  resourceType: string
) => {
  if (!variables) return null

  const found = variables.find(
    currVar => currVar.variableNk === variableNk && currVar.resourceType === resourceType
  )
  return found ? found : null
}

export const getOptionFromVar = (variable: VariableT | null, option: OptionKeyT) => {
  if (!variable) return option.id.toString()

  const findFromVar = (typeOptions: Array<Object>, type: string): Object | null => {
    const found = typeOptions.find(o => o.id.toString() === option.id.toString())
    return found ? { ...found, type } : null
  }
  if (option.type === 'net') {
    return findFromVar(variable.nets, 'net')
  } else if (option.type === 'calculated') {
    return findFromVar(variable.calculated, 'calculated')
  } else {
    return variable.options.find(
      o => o.id.toString() === option.id.toString() && o.type === option.type
    )
  }
}

export const getOptionText = (variable: VariableT, option: OptionKeyT | FilterOptionT) => {
  const found = getOptionFromVar(variable, option)
  return found ? found.label : option.id.toString()
}

export const hasNoSelectedOpts = (axisVariable: AxisVariableT) => {
  if (!axisVariable) return false
  return axisVariable.selectedOptions.filter(o => o.selected).length === 0
}

export const compareVariables = (v1: VariableT, v2: VariableT) => {
  return v1.variableNk === v2.variableNk && v1.resourceType === v2.resourceType
}

export const compareVarToFilter = (v: VariableT | AxisVariableT, f: FilterT) => {
  const vQuestionName = v.hasOwnProperty('questionName')
    ? v.questionName
    : getQnameFromNk(v.variableNk)
  return f.questionName === vQuestionName && f.variableResourceType === v.resourceType
}

export const removeVarsFromFilters = (
  variables: Array<VariableT> | Array<AxisVariableT>,
  filtersToRemove: Array<FilterT>
) => {
  return differenceWith(filtersToRemove, variables, (f, v) => {
    return compareVarToFilter(v, f)
  })
}

export const removeFiltersFromVars = (
  variables: Array<VariableT> | Array<AxisVariableT>,
  filtersToRemove: Array<FilterT>
) => {
  return differenceWith(variables, filtersToRemove, compareVarToFilter)
}

export const buildCellId = (variable: AnalysisDataVariablesT, option: Object) => {
  const optTypeMap = {
    net: 'N',
    calculated: 'C',
    option: 'O',
    breakout: 'O',
  }

  const optTypeKey = optTypeMap[option.type] || 'O'
  return `${variable.id}:${optTypeKey}:${option.id}`
}

export const resolveDatasetVariable = (
  variables: VariableListT,
  axisVariable: AxisVariableT
): VariableT => {
  const { variableNk, resourceType } = axisVariable
  const datasetVar = getVariable(variables, variableNk, resourceType)
  if (!datasetVar) {
    throw new Error(
      `Failed to resolve variable from natural key ${variableNk}, resourceType ${resourceType}`
    )
  }
  return datasetVar
}

const getSelected = (
  optionList: Array<string>,
  hiddenList: Array<string>,
  responsesInNets: Array<string>
) => {
  const allHidden = [...hiddenList, ...responsesInNets.map(r => hashName(r.toString()))]
  return differenceWith(optionList, allHidden, (o, ho) => {
    return hashName(o).toString() === ho
  })
}

const getSelectedBreakouts = (breakoutList: Array<GridBreakoutT>, hiddenList: Array<string>) => {
  //$FlowFixMe flow doesnt understand how differenceWith works...
  return differenceWith(breakoutList, hiddenList, (o, ho) => {
    return hashName(o.breakout_name).toString() === ho
  })
}

const convertA1Selected = (
  question: AnalysisQuestionT,
  hiddenRes: Array<string>,
  nets: Array<string> = [],
  hiddenBreak: Array<string> = [],
  availableNets: Array<ResponseNetT> = []
) => {
  const selectedCalculated = []
  const responsesInNets = nets.reduce((responses, net) => {
    const availNet = availableNets.find(an => an.pk.toString() === net.toString())
    return availNet ? [...responses, ...availNet.responses] : responses
  }, [])
  const selectedResIds = getSelected(question.responses, hiddenRes, responsesInNets)
    .map(res => {
      if (res === '__total__') {
        selectedCalculated.push(res)
        return null
      }
      if (question.response_metadata && question.response_metadata.length > 0) {
        const response = question.response_metadata.find(r => r.response_text === res)
        if (response) {
          return response.response_id.toString()
          //$FlowFixMe
        } else return null
      }
      return res.toString()
    })
    .filter(id => !!id)

  let breakouts = []
  if (question.grid_breakouts && question.grid_breakouts.length > 0) {
    if (hiddenBreak && hiddenBreak.length > 0) {
      breakouts = getSelectedBreakouts(question.grid_breakouts, hiddenBreak).map(br => br.id)
    } else {
      breakouts = question.grid_breakouts.map(b => b.id)
    }
  }

  return {
    question: { ...question, responses: question.responses.filter(r => r !== '__total__') },
    nets: nets ? (Array.isArray(nets) ? nets : [nets]) : [],
    options: selectedResIds,
    calculated: selectedCalculated,
    breakouts,
    availableNets,
  }
}

const convertA1SelectedXtabs = (
  xtabs: Array<AnalysisQuestionT>,
  hiddenResponses: Array<Array<string>>,
  flipped: number | null,
  chartType: ChartTypeT | null
) => {
  if (!xtabs || xtabs.length === 0) return []
  return xtabs.map((xt, i) => {
    // add totals for crosstabs
    const updatedResponses =
      chartType === 'line' && i === 0 ? xt.responses : [...xt.responses, '__total__']
    const xtWithTot = flipped ? { ...xt } : { ...xt, responses: updatedResponses }
    return convertA1Selected(xtWithTot, hiddenResponses[i])
  })
}

export const convertA1Questions = (
  mainQ: AnalysisQuestionT,
  currentXtabs: Array<AnalysisQuestionT>,
  hiddenMain: Array<string>,
  hiddenFirstXtab: Array<string>,
  hiddenSecondXtab: Array<string>,
  hiddenBreakouts: Array<string>,
  nets: Array<string>,
  availableNets: Array<ResponseNetT>,
  flipped: number | null,
  chartType: ChartTypeT | null
) => {
  const main_with_tot = flipped
    ? { ...mainQ, responses: [...mainQ.responses, '__total__'] }
    : { ...mainQ }
  const main = [
    convertA1Selected(
      main_with_tot,
      hiddenMain || [],
      nets || [],
      hiddenBreakouts || [],
      availableNets
    ),
  ]
  const xtabs = convertA1SelectedXtabs(
    currentXtabs,
    [hiddenFirstXtab, hiddenSecondXtab],
    flipped,
    chartType
  )

  return { main, xtabs }
}

const getAxisVarCode = (axisVar: AxisVariableT) => {
  const varName = getQnameFromNk(axisVar.variableNk)
  const varType = axisVar.resourceType
  return `${varName.slice(0, 3)}-${varType[0]}`
}

const getVarHashPrefix = (
  xAxisVariables: Array<AxisVariableT>,
  yAxisVariables: Array<AxisVariableT>
) => {
  const x = xAxisVariables.map(v => getAxisVarCode(v)).join('_')
  const y = yAxisVariables.map(v => getAxisVarCode(v)).join('_')
  return `x-${x}__y-${y}`
}

export const getStateId = (
  xAxisVariables: Array<AxisVariableT>,
  yAxisVariables: Array<AxisVariableT>,
  filters: Array<FilterT>,
  viewSettings: ViewSettingsT,
  weighting: String,
  autoAdjustBaseEnabled: boolean = false
) => {
  const varPrefix = getVarHashPrefix(xAxisVariables, yAxisVariables)
  const stateHash = sha256(
    JSON.stringify({
      x: xAxisVariables,
      y: yAxisVariables,
      f: filters,
      a: autoAdjustBaseEnabled,
      w: weighting,
      v: viewSettings,
    })
  )
  return `${varPrefix}${stateHash}`
}

export const getA2url = (studyId: string, datasetNk: string, hashStateId: string) => {
  return `/dataset/analysis/${encodeURIComponent(studyId)}/${encodeURIComponent(
    datasetNk
  )}/?stateId=${hashStateId}`
}

export const convertA1Filters = (
  groupedFilters: Array<GroupedFilterT>,
  refFilterQs: Array<AnalysisQuestionT>
) => {
  const convertedFilters = groupedFilters.map(f => {
    const selOpts = f.options ? f.options.map(o => ({ id: o.toString(), type: 'option' })) : []
    const selNets = f.nets ? f.nets.map(n => ({ id: n.toString(), type: 'net' })) : []
    const convertedFilter = {
      questionName: f.filter,
      variableResourceType: 'options',
      method: f.method.split('-')[0], // no -id
      filteredOptions: [...selOpts, ...selNets],
    }

    if (f.breakoutName) {
      const breakoutQ =
        refFilterQs && refFilterQs.length
          ? refFilterQs.find(q => q.question_name === f.filter)
          : null
      const breakoutId =
        breakoutQ && breakoutQ.grid_breakouts && breakoutQ.grid_breakouts.length
          ? breakoutQ.grid_breakouts.find(b => b.breakout_name === f.breakoutName).id
          : null
      // this is filtered out
      if (!breakoutId) {
        console.warn(
          'Could not map a breakout name to ID from given question data, ignoring filter.',
          { filters: groupedFilters, ignoredFilter: f, refFilterQs }
        )
        return null
      }
      convertedFilter.breakoutId = breakoutId
    }

    return convertedFilter
  })
  return convertedFilters.filter(f => f !== null)
}

export const getAxisVariables = (
  main: Array<ConvertedA1QuestionT>,
  xtabs: Array<ConvertedA1QuestionT>,
  flipped: number | null,
  chartType: ChartTypeT | null
) => {
  let rowQs = flipped ? xtabs : main
  let colQs = flipped ? main : xtabs
  let useChartType = chartType

  const colVariables = colQs.length
    ? colQs.reduce((colVars, colQ) => {
        const optVar = createOptionVar(colQ.question, colQ.availableNets)
        if (colQ.question && colQ.question.is_grid) {
          const breakoutVar = createBreakoutVar(colQ.question, [])
          return [...colVars, optVar, breakoutVar]
        }
        return [...colVars, optVar]
      }, [])
    : []
  const rowVariables = rowQs.map(rowQ => createOptionVar(rowQ.question, rowQ.availableNets))
  if (rowQs[0] && rowQs[0].question && rowQs[0].question.is_grid) {
    // if the main question is a grid question, the breakout
    // var needs to be added to the col
    const breakoutVar = createBreakoutVar(rowQs[0].question, [])
    colVariables.unshift(breakoutVar)
    // apparently flow is confused about how arrays work
    // $FlowFixMe
    colQs.unshift({
      breakouts: rowQs[0].breakouts,
      options: [],
      nets: [],
      calculated: [],
      question: rowQs[0].question,
    })
  }

  const xSelected = rowQs.reduce(
    (sel, q) => {
      return {
        breakouts: [...sel.breakouts, ...q.breakouts],
        options: [...sel.options, ...q.options],
        nets: [...sel.nets, ...q.nets],
        calculated: [...sel.calculated, ...q.calculated],
      }
    },
    { breakouts: [], options: [], nets: [], calculated: [] }
  )

  let xAxisVariables = generateAxisVars(
    rowVariables,
    ROW,
    xSelected.nets,
    xSelected.options,
    xSelected.breakouts,
    xSelected.calculated,
    []
  )

  let yAxisVariables = colQs.map(
    (q, i) =>
      generateAxisVars(
        [colVariables[i]],
        COLUMN,
        q.nets,
        q.options,
        q.breakouts,
        q.calculated,
        []
      )[0]
  )

  if (isChartStacked(useChartType)) {
    if (xAxisVariables.length + yAxisVariables.length <= 2) {
      return {
        xAxisVariables: yAxisVariables.map(av => ({ ...av, axis: 'ROW' })),
        yAxisVariables: xAxisVariables.map(av => ({ ...av, axis: 'COLUMN' })),
        useChartType,
      }
    } else if (xAxisVariables.length + yAxisVariables.length === 3) {
      return main[0] && main[0].question && main[0].question.is_grid
        ? {
            xAxisVariables: yAxisVariables.slice(-1).map(av => ({ ...av, axis: 'ROW' })),
            yAxisVariables: [yAxisVariables[0], ...xAxisVariables].map(av => ({
              ...av,
              axis: 'COLUMN',
            })),
            useChartType,
          }
        : {
            xAxisVariables: [yAxisVariables[0]].map(av => ({ ...av, axis: 'ROW' })),
            yAxisVariables: [...xAxisVariables, ...yAxisVariables.slice(-1)].map(av => ({
              ...av,
              axis: 'COLUMN',
            })),
            useChartType,
          }
    }
  } else if (useChartType === 'line') {
    // the order is a little goofy because in A1 we do different
    // things for grids than just double breakouts
    const allVars =
      yAxisVariables[0] && yAxisVariables[0].resourceType !== 'breakouts'
        ? [...xAxisVariables, ...yAxisVariables]
        : [...yAxisVariables, ...xAxisVariables]
    const allQs = [...colQs, ...rowQs]
    const interval = allQs.find(q => q.question && isIntervalOrNumeric(q.question))
    if (interval) {
      const intervalVar = allVars.find(v => v.variableNk === interval.question.nk)
      xAxisVariables = [{ ...intervalVar, axis: 'ROW' }]
      yAxisVariables = allVars
        .filter(v => v.id !== intervalVar.id)
        .map(av => ({ ...av, axis: 'COLUMN' }))
    } else {
      // if no interval question, fallback to column chart
      useChartType = 'column'
    }
  }

  return { xAxisVariables, yAxisVariables, useChartType }
}

const getViewSettings = (
  percent: number | null,
  a1Sort: Array<string>,
  chartType: ChartTypeT | null,
  showLabels: boolean,
  hide_low_base: number,
  show_means: number
): ViewSettingsT => {
  const sortState = {
    COLUMN: {
      sortVariables: [],
      direction: '',
    },
    ROW: {
      sortVariables: [],
      direction: '',
    },
  }

  if (a1Sort && a1Sort.length) {
    // A1 only allows col sort on total col (single var)
    sortState[COLUMN].direction = a1Sort[1] === 0 ? 'ascending' : 'descending'
  }

  return {
    showLabels,
    showStatTesting: chartType === 'spreadsheet' || showLabels,
    statsConfidence: [95],
    numberType: percent ? 'percentage' : 'numeric',
    sortState,
    chartType: chartType || 'spreadsheet',
    hideLowBase: hide_low_base === 1,
    showMeans: show_means === 1,
  }
}

const convertLegacyFilterResponse = (filter: FilterQueryT, referencedQs: ReferencedQuestionsT) => {
  const question = referencedQs ? referencedQs[filter.filter] : null
  if (!question || !question.response_metadata.length || filter.method.indexOf('-id') !== -1) {
    return filter.option
  } else {
    const response = question.response_metadata.find(
      response => response.response_text === filter.option
    )
    return response ? response.response_id : filter.option
  }
}

const groupA1FiltersForA2 = (filters: Array<FilterQueryT>, referencedQs: ReferencedQuestionsT) => {
  const groupedFilters = []
  filters.forEach(filter => {
    const foundIdx = groupedFilters.findIndex(grouped => {
      // is there already a grouped filter for this quest and method?
      return Boolean(
        grouped.filter === filter.filter &&
          grouped.method === filter.method &&
          grouped.breakoutName === filter.breakoutName
      )
    })
    if (foundIdx > -1) {
      // if a match was found, add this option to that grouped filter, for net add netPK
      if (filter.nets) {
        groupedFilters[foundIdx].nets = groupedFilters[foundIdx].nets
          ? [...groupedFilters[foundIdx].nets, ...filter.nets]
          : filter.nets
      } else {
        const option = convertLegacyFilterResponse(filter, referencedQs)
        groupedFilters[foundIdx].options = groupedFilters[foundIdx].options
          ? [...groupedFilters[foundIdx].options, option]
          : [option]
      }
    } else {
      // if no match found, add this filter to the grouped filters list, for net add netPK
      if (filter.nets) {
        groupedFilters.push({ ...filter, nets: filter.nets })
      } else {
        groupedFilters.push({
          ...filter,
          options: [convertLegacyFilterResponse(filter, referencedQs)],
        })
      }
    }
  })
  groupedFilters.forEach(f => {
    delete f.option
  })
  return groupedFilters
}

export const convertA1toA2Url = (
  chartType: ChartTypeT | null,
  studyId: string,
  datasetName: string,
  flipped: number | null,
  percent: number | null,
  refQs: ReferencedQuestionsT,
  refFilterQs: Array<AnalysisQuestionT>,
  question: AnalysisQuestionT,
  filters: Array<FilterQueryT> = [],
  xtabsApplied: Array<AnalysisQuestionT> = [],
  hiddenResponseOptions: Array<string> = [],
  hiddenFirstXtabResponses: Array<string> = [],
  hiddenSecondXtabResponses: Array<string> = [],
  hiddenGridBreakouts: Array<string> = [],
  nets: Array<string> = [],
  availableNets: Array<ResponseNetT> = [],
  sort: Array<string> = [],
  showLabels: boolean = true,
  hide_low_base: number = 0,
  show_means: number = 0
) => {
  const notifications = []
  const groupedFilters = filters && !isEmpty(refQs) ? groupA1FiltersForA2(filters, refQs) : []
  const { main, xtabs } = convertA1Questions(
    question,
    xtabsApplied || [],
    hiddenResponseOptions || [],
    hiddenFirstXtabResponses || [],
    hiddenSecondXtabResponses || [],
    hiddenGridBreakouts || [],
    nets ? (Array.isArray(nets) ? nets : [nets]) : [],
    availableNets || [],
    flipped,
    chartType
  )
  const { xAxisVariables, yAxisVariables, useChartType } = getAxisVariables(
    main,
    xtabs,
    flipped,
    chartType
  )

  if (chartType !== useChartType)
    notifications.push(
      `This chart configuration cannot be viewed as a ${chartType} chart in the new analysis page, the chart type has been updated to a ${useChartType} chart for improved analysis`
    )
  const a2Filters =
    groupedFilters && groupedFilters.length > 0 ? convertA1Filters(groupedFilters, refFilterQs) : []

  const viewSettings = getViewSettings(
    percent,
    sort,
    useChartType,
    showLabels,
    hide_low_base,
    show_means
  )
  const hashStateId = getStateId(xAxisVariables, yAxisVariables, a2Filters, viewSettings)
  const state = {
    xAxisVariables,
    yAxisVariables,
    autoAdjustBaseEnabled: false,
    filters: a2Filters,
    viewSettings,
  }
  return {
    url: getA2url(studyId, datasetName, hashStateId),
    stateId: hashStateId,
    state,
    notifications,
  }
}

const getVariableId = (varData: Object) => {
  return varData.varId
    ? varData.varId
    : `${getQnameFromNk(varData.variableNk)}${varData.resourceType}`
}

export const addVariableId = (av: AxisVariableT) => {
  return { ...av, id: getVariableId(av) }
}

export const transformA2ContentObject = (
  data: FeaturedItemT,
  globalFilters: Array<FilterQueryT> = []
) => {
  const state = { ...data.content_object.state }
  let { state_id, analysis_data, stats_data } = data.content_object
  let analysisData = analysis_data
  let statsData = stats_data
  if (analysis_data && !isEmpty(analysisData)) {
    const mappedVariables = analysis_data.variables.map(v => ({
      id: v.id,
      questionName: v.name,
      resourceType: v.resource_type,
    }))

    const updatedCachedData = transformAnalysisData(
      mappedVariables,
      analysis_data.data,
      analysis_data.groups,
      analysis_data.unweighted_groups
    )

    analysisData = {
      data: updatedCachedData.data,
      variables: mappedVariables,
      groups: updatedCachedData.groups,
      base_size: analysis_data.base_size,
      unweighted_base_size: analysis_data.unweighted_base_size,
      unweighted_groups: updatedCachedData.unweightedGroups,
    }

    if (statsData && !isEmpty(statsData)) {
      statsData = normalizeStatsIds(statsData, analysisData.variables)
    }
  }

  if (globalFilters && globalFilters.length) {
    state.filters = globalFilters
    state.filterOverride = true
  } else {
    state.filterOverride = false
  }

  return {
    title: data.title,
    id: data.id,
    position: data.position,
    content_type: data.content_type,
    analysisDataArgs: {
      state,
      stateId: state_id,
      analysisData,
      datasetName: data.content_object.dataset_name,
      studyId: data.content_object.study_id,
      statsData,
      errorData: data.content_object.errorData,
    },
  }
}

const normalizeDecimal = idList => {
  return idList.map(id => id.replace(/\.0$/, ''))
}

/** Convert integer ID-based cell names to easier to consume name-based IDs */
export const normalizeId = (mapId: string, variables: Array<AnalysisDataVariablesT>) => {
  const idList = mapId.split(',')
  return idList
    .map(id => {
      const splitMap = id.split(':')
      const decimalNormalized = normalizeDecimal(splitMap)
      const foundVar = variables.find(v => v.id.toString() === decimalNormalized[0].toString())
      if (foundVar) {
        decimalNormalized.shift()
        decimalNormalized.unshift(`${foundVar.questionName}${foundVar.resourceType}`)
      }
      return decimalNormalized.join(':')
    })
    .sort()
    .join(',')
}

export const normalizeStatsIds = (statsData = {}, variables) => {
  const normalize = key => normalizeId(key, variables)

  return Object.keys(statsData).reduce((acc, key) => {
    acc[normalize(key)] = statsData[key].map(normalize)
    return acc
  }, {})
}

export const getStatsIdMapping = (statsData = {}, variables) => {
  return Object.keys(statsData).reduce((acc, key) => {
    const normalizedKey = normalizeId(key, variables)
    if (!(normalizedKey in acc)) {
      acc[normalizedKey] = key
    }
    for (const v of statsData[key]) {
      const normalizedValue = normalizeId(v, variables)
      if (!(normalizedValue in acc)) {
        acc[normalizedValue] = v
      }
    }
    return acc
  }, {})
}

export const transformAnalysisData = (
  variables: Array<AnalysisDataVariablesT>,
  data: AnalysisDataDataT,
  groups: AnalysisDataGroupT,
  unweightedGroups: AnalysisDataGroupT
) => {
  const updatedData = {}
  const updatedGroups = {}
  const updatedUnweightedGroups = {}

  for (const id in data) {
    const newId = normalizeId(id, variables)
    updatedData[newId] = data[id]
  }

  for (const id in groups) {
    const newId = normalizeId(id, variables)
    updatedGroups[newId] = groups[id]
  }

  for (const id in unweightedGroups ?? {}) {
    const newId = normalizeId(id, variables)
    updatedUnweightedGroups[newId] = unweightedGroups[id]
  }

  return {
    data: updatedData,
    groups: updatedGroups,
    unweightedGroups: unweightedGroups === null ? null : updatedUnweightedGroups,
  }
}

type baseQuestionT = {
  id: string,
  label: string,
}
const getAxisVarsForStdChart = (
  xAxisVariables: Array<AxisVariableT>,
  yAxisVariables: Array<AxisVariableT>,
  baseQuestion: baseQuestionT
) => {
  const orderAxisVars = (base, axisVars) => {
    if (axisVars.length <= 1) return axisVars
    return axisVars.reduce((newVars, av) => {
      if (av.id === base.id) return [...newVars, av]
      return [av, ...newVars]
    }, [])
  }

  const singleAxisChart = xAxisVariables.length === 0 || yAxisVariables.length === 0
  // The base variable must be the last var in the xAxis (unless all vars are
  // in a single axis) which must be fixed if coming from stacked
  const basePos = xAxisVariables.findIndex(v => v.id === baseQuestion.id)
  const yAxis = basePos > -1 || singleAxisChart ? yAxisVariables : xAxisVariables
  const xAxis =
    basePos > -1 || singleAxisChart
      ? orderAxisVars(baseQuestion, xAxisVariables)
      : orderAxisVars(baseQuestion, yAxisVariables)

  return { xAxis, yAxis }
}

export const getUpdatedAxisVarsForChartType = (
  xAxisVariables,
  yAxisVariables,
  baseQuestion,
  chartType,
  interval
) => {
  let newYAxis = []
  let newXAxis = []

  const axisVars = [...xAxisVariables, ...yAxisVariables]
  if (chartType === 'line' && interval) {
    // The xAxisVariable must be an interval question and all
    // other variables must b yAxisVariables
    axisVars.forEach(v => {
      if (interval === v.id) {
        newXAxis.push(v)
      } else {
        if (v.id === baseQuestion.id) {
          newYAxis.unshift(v)
        } else {
          newYAxis.push(v)
        }
      }
    })
  } else if (isChartStacked(chartType)) {
    // The base variable must be the single yAxisVariable and all
    // other variables must be xAxisVariables
    axisVars.forEach(v => {
      if (v.id === baseQuestion.id) {
        newYAxis.push(v)
      } else {
        newXAxis.push(v)
      }
    })
  } else {
    const { xAxis, yAxis } = getAxisVarsForStdChart(xAxisVariables, yAxisVariables, baseQuestion)
    newXAxis = xAxis
    newYAxis = yAxis
  }
  return { newXAxis, newYAxis }
}
