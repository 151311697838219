/**
  generateReduxAction

  returns an object with three functions for each redux state,
  loading/success/error. If given just an action name, generates
  default, commonly used action for each state.

  Developer may choose to override any of the state functions by
  providing one at the correct argument index.

  @param actionName {string}
      name of the action that will define the action type name.
      for example, 'FETCH_DATASET' in 'FETCH_DATASET_LOADING';
      string given should be all-caps.

  @param loadingOverride? {Function}
  @param successOverride? {Function}
  @param errorOverride? {Function}
      Any three of these optional parameters, if set,
      will be used as override for their associated
      redux state function. A developer may do this if the default
      function provided does not match the payload/arguments structure.
      The action MUST have the correct `type` that would be generated.

      For example, if your success action does not have a payload,
      you could call

      const successOverride = () => {return { type: 'SOME_ACTION_SUCCESS' }}
      generateReduxAction('SOME_ACTION', null, successOverride, null)

  @return {object} shaped as below
      {
        loading: loadingOverride || () => ActionT,
        success: successOverride || () => ActionT,
        error: errorOverride || () => ActionT,
      }
*/
const generateReduxAction = (
  actionName: string,
  loadingOverride?: Function,
  successOverride?: Function,
  errorOverride?: Function
) => {
  const loadingType = `${actionName}_LOADING`
  const successType = `${actionName}_SUCCESS`
  const errorType = `${actionName}_ERROR`

  const loadingAction = loadingOverride
    ? loadingOverride
    : (): ActionT => ({
        type: loadingType,
      })

  const successAction = successOverride
    ? successOverride
    : (payload: any): ActionT => ({
        type: successType,
        payload,
      })

  const errorAction = errorOverride
    ? errorOverride
    : (err: ErrorT): ActionT => ({
        type: errorType,
        payload: err,
      })

  const generatedAction = {
    loading: loadingAction,
    success: successAction,
    error: errorAction,
  }

  // This is because, say, `loading.type` is undefined as these props are a function.
  // As doing `actions.fetchSomeAction.success().type` in the reducer is not scalable (flow would cry)
  // we just set the property on the functions here, for ease of use in the reducer
  generatedAction.loading.type = loadingType
  generatedAction.success.type = successType
  generatedAction.error.type = errorType

  return generatedAction
}

export default generateReduxAction
