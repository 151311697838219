import fastCartesianProduct from 'fast-cartesian-product'

import { floorToPrecision, roundToPrecision } from 'util/math'
import { fetchVariableThunk, setWeighting, fetchWeights } from './DatasetActions'
import {
  getDataset,
  getHandleTrack,
  getStagedVariablesForCustomWeights,
  getSelectionsForCustomWeights,
  getIsCreatingCustomWeight,
  getCustomWeightName,
  getIsDeletingCustomWeight,
  getCustomWeights,
  getWeighting,
} from './DatasetSelectors'
import { CUSTOM_WEIGHT_MAX_STAGED_VARIABLES } from './constants'

const COLLIE = process.env.REACT_APP_COLLIE_URL || ''

export const RESET_CUSTOM_WEIGHTS = 'RESET_CUSTOM_WEIGHTS'
export const resetCustomWeights = () => ({
  type: RESET_CUSTOM_WEIGHTS,
})

export const SET_CUSTOM_WEIGHT_VARIABLE_NAME = 'SET_CUSTOM_WEIGHT_VARIABLE_NAME'
/**
 * Change the name of the custom weight being staged for creation.
 *
 * @param {String} name - New name of the custom weight variable
 */
export const setCustomWeightName = name => ({
  type: SET_CUSTOM_WEIGHT_VARIABLE_NAME,
  name,
})

export const STAGE_VARIABLE_FOR_CUSTOM_WEIGHT = 'STAGE_VARIABLE_FOR_CUSTOM_WEIGHT'
const __stageVariableForWeight = (variableIds, selections) => ({
  type: STAGE_VARIABLE_FOR_CUSTOM_WEIGHT,
  variableIds,
  selections,
})

const generateOptionSelections = variables => {
  const optionsProduct = fastCartesianProduct(variables.flat().map(v => v.options))

  // We limit weights to a tenth of a percent, put remainder on first weight to sum to 100
  const initValue = floorToPrecision(100.0 / optionsProduct.length, 1)
  const firstInitValue = roundToPrecision(
    initValue + (100.0 - initValue * optionsProduct.length),
    1
  )

  return optionsProduct.map((opts, index) => ({
    id: opts.map(opt => opt.id).join(':'),
    label: opts.map(opt => opt.label).join(', '),
    value: index === 0 ? firstInitValue : initValue,
  }))
}

/**
 * Add and stage a variable for the custom weight being created. Will fetch the
 * requested variable if it is not already in cache. Will not stage the variable
 * if user is already at the maximum amount of variables.
 *
 * @param {String} variableNk - Natural key of the requested variable
 */
export const fetchVariableForCustomWeight = variableNk => async (dispatch, getState) => {
  const stagedVariableIds = getStagedVariablesForCustomWeights(getState())
  if (stagedVariableIds.length >= CUSTOM_WEIGHT_MAX_STAGED_VARIABLES) {
    return
  }

  const newVariableIds = [...stagedVariableIds, variableNk]
  const newVariables = await Promise.all(
    newVariableIds.map(id => dispatch(fetchVariableThunk(id, false)))
  )

  const newSelections = generateOptionSelections(newVariables)

  dispatch(__stageVariableForWeight(newVariableIds, newSelections))
}

/**
 * Remove and unstage a variable for the custom weight being created.
 *
 * @param {String} variableNk - Natural key of the variable being removed
 */
export const unstageVariable = variableNk => async (dispatch, getState) => {
  const stagedVariableIds = getStagedVariablesForCustomWeights(getState())
  if (stagedVariableIds.length <= 0) return

  const newVariableIds = stagedVariableIds.filter(nk => nk !== variableNk)
  const newVariables = await Promise.all(
    newVariableIds.map(id => dispatch(fetchVariableThunk(id, false)))
  )

  const newSelections = generateOptionSelections(newVariables)

  dispatch(__stageVariableForWeight(newVariableIds, newSelections))
}

export const EDIT_CUSTOM_WEIGHT_SELECTION = 'EDIT_CUSTOM_WEIGHT_SELECTION'
const __editCustomWeightSelection = selections => ({
  type: EDIT_CUSTOM_WEIGHT_SELECTION,
  selections,
})

/**
 * Change the weighting value of an option pair.
 *
 * @param {String} id - Option ID(s), ordered by staged variable
 * @param {Number} value - New weighting value
 */
export const editCustomWeightSelection = (id, value) => (dispatch, getState) => {
  const currentSelections = getSelectionsForCustomWeights(getState())
  const newSelections = currentSelections.map(optPair => ({
    id: optPair.id,
    label: optPair.label,
    value: id === optPair.id ? value : optPair.value,
  }))
  dispatch(__editCustomWeightSelection(newSelections))
}

export const CREATE_CUSTOM_WEIGHT_FETCHING = 'CREATE_CUSTOM_WEIGHT_FETCHING'
const __createCustomWeightFetching = () => ({
  type: CREATE_CUSTOM_WEIGHT_FETCHING,
})

export const CREATE_CUSTOM_WEIGHT_SUCCESS = 'CREATE_CUSTOM_WEIGHT_SUCCESS'
const __createCustomWeightSuccess = () => ({
  type: CREATE_CUSTOM_WEIGHT_SUCCESS,
})

export const CREATE_CUSTOM_WEIGHT_ERROR = 'CREATE_CUSTOM_WEIGHT_ERROR'
const __createCustomWeightError = error => ({
  type: CREATE_CUSTOM_WEIGHT_ERROR,
  error,
})

/** Create a custom weight based on the current staged variables. */
export const createCustomWeight =
  successCallback =>
  async (dispatch, getState, { fetch }) => {
    if (getIsCreatingCustomWeight(getState())) return

    dispatch(__createCustomWeightFetching())

    const { studyId, datasetNk } = getDataset(getState())
    const dataset = encodeURIComponent(datasetNk)
    const name = getCustomWeightName(getState()).trim()
    const variableNks = getStagedVariablesForCustomWeights(getState())
    const variables = (
      await Promise.all(variableNks.map(nk => dispatch(fetchVariableThunk(nk, false))))
    ).flat()

    const selections = getSelectionsForCustomWeights(getState())

    const response = await fetch(`${COLLIE}/datasets/${studyId}/${dataset}/weights/custom/`, {
      method: 'POST',
      jsonBody: {
        name,
        weighting_spec: {
          variables: variables.map(variable => variable.questionName),
          percentages: selections.map(optPair => ({
            responses: optPair.id.split(':'),
            decimal_value: (optPair.value / 100.0).toFixed(3),
          })),
        },
      },
    })

    if (!response.ok) {
      const error = await response.json()
      dispatch(__createCustomWeightError(error ?? response.statusText))
      return
    }

    const weight = await response.json()

    const intervalId = setInterval(async () => {
      const pollResponse = await fetch(
        `${COLLIE}/datasets/${studyId}/${dataset}/weights/custom/${weight.id}/`
      )

      if (!response.ok) {
        clearInterval(intervalId)
        const error = await pollResponse.json()
        dispatch(__createCustomWeightError(error ?? pollResponse.statusText))
        return
      }

      const pollData = await pollResponse.json()
      if (pollData.is_data_ready) {
        dispatch(__createCustomWeightSuccess())
        clearInterval(intervalId)
        successCallback?.()
        dispatch(setWeighting(pollData.weighting_question_name))
        dispatch(fetchWeights())
      }
    }, 1000)

    const handleTrack = getHandleTrack(getState())
    handleTrack('Created Custom Weight', {
      name,
      weightVariables: variables.map(v => v.label),
    })
  }

export const DELETE_CUSTOM_WEIGHT_FETCHING = 'DELETE_CUSTOM_WEIGHT_FETCHING'
const __deleteCustomWeightFetching = () => ({
  type: DELETE_CUSTOM_WEIGHT_FETCHING,
})

export const DELETE_CUSTOM_WEIGHT_SUCCESS = 'DELETE_CUSTOM_WEIGHT_SUCCESS'
const __deleteCustomWeightSuccess = specId => ({
  type: DELETE_CUSTOM_WEIGHT_SUCCESS,
  specId,
})

export const DELETE_CUSTOM_WEIGHT_ERROR = 'DELETE_CUSTOM_WEIGHT_ERROR'
const __deleteCustomWeightError = error => ({
  type: DELETE_CUSTOM_WEIGHT_ERROR,
  error,
})

/**
 * Delete an existing custom weight.
 *
 * @param {number} specId - Primary key of the custom weight spec
 */
export const deleteCustomWeight =
  specId =>
  async (dispatch, getState, { fetch }) => {
    if (getIsDeletingCustomWeight(getState())) return

    dispatch(__deleteCustomWeightFetching())

    const weight = getCustomWeights(getState()).find(weight => weight.specId === specId)
    const { studyId, datasetNk } = getDataset(getState())

    const response = await fetch(
      `${COLLIE}/datasets/${studyId}/${encodeURIComponent(datasetNk)}/weights/custom/${specId}/`,
      { method: 'DELETE' }
    )

    if (!response.ok) {
      dispatch(__deleteCustomWeightError(response.statusText))
      return
    }

    dispatch(__deleteCustomWeightSuccess(specId))

    const handleTrack = getHandleTrack(getState())
    handleTrack('Deleted Custom Weight', {
      name: weight.label,
    })

    if (weight.id === getWeighting(getState())) {
      dispatch(setWeighting('useDefault'))
    }
  }
