import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import * as styles from './SidebarHeader.module.scss'

const propTypes = {
  dataset: PropTypes.shape({
    studyName: PropTypes.string,
    studyId: PropTypes.string,
    studySample: PropTypes.string,
  }),
  isEmbedded: PropTypes.bool,
  handleTrack: PropTypes.func,
}

function SidebarHeader({ dataset, handleTrack, isEmbedded }) {
  if (!dataset) return null

  return (
    <div className={styles.titleContainer}>
      {isEmbedded ? (
        <div>{dataset.studyName}</div>
      ) : (
        <Link
          to={`/studies/${dataset.studyId}`}
          target="_blank"
          className={styles.studyLink}
          onClick={() => handleTrack('Study Link Clicked')}
          rel="noreferrer"
        >
          {dataset.studyName}
        </Link>
      )}
      <span className={styles.sample}>{dataset.studySample}</span>
    </div>
  )
}

SidebarHeader.propTypes = propTypes

export default SidebarHeader
