import React, { useMemo, useRef } from 'react'
import classnames from 'classnames'
import { useSelector, shallowEqual } from 'react-redux'
import { createSelector } from 'reselect'
// TODO: Use useDraggable hook from kendo, or react-dnd maybe?
import { Draggable } from '@progress/kendo-react-common'

import { getAnalysisData, getShowUnweightedBase, getWeightingLabel } from 'store/DatasetSelectors'

// TODO: Port this to tabularizeData when applied weighting is easier to source
const getShouldShowUnweightedBaseLabel = createSelector(
  getShowUnweightedBase,
  getAnalysisData,
  getWeightingLabel,
  (showUnweightedBase, analysisData, weightingLabel) => {
    return (
      (showUnweightedBase && analysisData.unweighted_base_size !== null) || weightingLabel === null
    )
  }
)

export default function KendoRowHeaderCell({
  cell,
  className,
  columnIndex,
  dataItem,
  field,
  gridRef,
  style,
}) {
  const shouldShowUnweightedBaseLabel = useSelector(getShouldShowUnweightedBaseLabel, shallowEqual)
  const draggableRef = useRef()

  const headerToDisplay = useMemo(() => {
    const header = dataItem[field].toString()
    if (header === 'Base') {
      return shouldShowUnweightedBaseLabel ? 'Unweighted base' : 'Weighted base'
    }
    return header
  }, [dataItem, field, shouldShowUnweightedBaseLabel])

  const span = dataItem.span[field]
  if (!(dataItem['row-id'] === 0 || dataItem['row-id'] % span === 0)) return null

  const dragHandler = end => data => {
    gridRef.current?.columnResize.dragHandler(
      data.event,
      gridRef.current.columnResize.columns[columnIndex],
      draggableRef.current.element,
      end
    )
  }
  return (
    <td
      title={dataItem[field].toString()}
      className={classnames(`${className} row-header merged-rows`, {
        'base-header': dataItem.isBaseRow && cell.props.children === 'Base',
      })}
      style={{ ...style }}
      rowSpan={span}
    >
      {headerToDisplay}
      <Draggable onDrag={dragHandler(false)} onRelease={dragHandler(true)} ref={draggableRef}>
        <span className="k-column-resizer" draggable={false} style={{ touchAction: 'none' }} />
      </Draggable>
    </td>
  )
}
