import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import * as Sentry from '@sentry/react'
import download from 'downloadjs'
import { uniqueId, throttle } from 'lodash'
import { DropdownButton, Tooltip } from '@knowledgehound/laika'

import {
  getBaseQuestion,
  getDataset,
  isFetchingDataset as isFetchingDatasetSelector,
  isFetchingData as isFetchingDataSelector,
  isAllTableDataLowBase,
  getChartConfig,
} from 'store/DatasetSelectors'
import {
  setDisplayTypeAction,
  toggleAutoAdjustBaseThunk,
  toggleHideLowBase,
  toggleShowLabels,
  toggleNullSuppression,
  setStatsThunk,
  refetchAllSuppressedOptions,
  exportAnalysis,
} from 'store/DatasetActions'
import ActionBarToggleButton from './ActionBarToggleButton'

import * as styles from './ChartActions.module.scss'

type PropsT = {
  handleTrack: Function,
  fetchAnalysisData: Function,
  handleOpenFilterModal: Function,
  handleAddContentItem: Function | null,
  sendNotification: Function,
  exportState: Object,
}

export default function ChartActions({
  handleTrack,
  fetchAnalysisData,
  handleOpenFilterModal,
  handleAddContentItem,
  sendNotification,
  exportState,
}: PropsT) {
  const dispatch = useDispatch()
  const actionBarRef = useRef()
  const [hideActionText, setHideActionText] = useState(false)
  const [downloadDropdownOpen, setDownloadDropdown] = useState(false)
  const [statsDropdownOpen, setStatsDropdown] = useState(false)
  const [isExporting, setIsExporting] = useState(false)
  const dataset: DatasetT = useSelector(getDataset, shallowEqual)
  const isFetchingDataset: boolean = useSelector(isFetchingDatasetSelector, shallowEqual)
  const isFetchingData: boolean = useSelector(isFetchingDataSelector, shallowEqual)
  const chartConfig: ChartConfigT = useSelector(getChartConfig, shallowEqual)
  const baseQuestion = useSelector(getBaseQuestion, shallowEqual)
  const everythingLowBase = useSelector(isAllTableDataLowBase, shallowEqual)

  useEffect(() => {
    const observer = throttle(() => setHideActionText(actionBarRef.current.clientWidth < 1100), 16)
    observer()
    window.addEventListener('resize', observer)
    return () => window.removeEventListener('resize', observer)
  }, [actionBarRef, setHideActionText])

  const {
    viewSettings: {
      numberType,
      chartType,
      showLabels,
      showStatTesting,
      statsConfidence,
      hideLowBase,
      suppressNullValues,
    },
    dataSettings: { xAxisVariables, yAxisVariables, autoAdjustBaseEnabled },
  } = chartConfig

  const handleSetDisplayType = (displayType: string) => () => {
    if (displayType !== numberType) {
      dispatch(setDisplayTypeAction(displayType))
      handleTrack('Display type changed', { displayType })
    }
  }

  const handleToggleShowLabels = () => {
    dispatch(toggleShowLabels(!showLabels))
    handleTrack('Toggle Show Data Labels', { showLabels: !showLabels })
  }

  const handleHideLowBase = () => {
    dispatch(toggleHideLowBase(!hideLowBase))
    handleTrack('Toggle Hide Low Base', { hideLowBase: !hideLowBase })
  }

  const handleToggleNullSuppression = () => {
    dispatch(toggleNullSuppression())
    dispatch(refetchAllSuppressedOptions(true))
    handleTrack('Toggle Null Suppression', { suppressNullValues: !suppressNullValues })
  }

  const handleAutoAdjustBase = async () => {
    await dispatch(toggleAutoAdjustBaseThunk(!autoAdjustBaseEnabled))
    handleTrack('Toggle Auto Adjust Base', { autoAdjustBaseEnabled: !autoAdjustBaseEnabled })
    // TODO: optimization, dont fetch unless something has
    // changed and refetch is needed
    fetchAnalysisData(null, true)
  }

  const handleChangeStatTesting = async option => {
    setStatsDropdown(false)
    if (option && !showLabels) handleToggleShowLabels()
    await dispatch(setStatsThunk(option))
    handleTrack('Toggle Show Stat Testing', { showStatTesting, option })
  }

  const statsMenuItem = (option: number | null): Node => {
    return (
      <DropdownButton.MenuItem
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          handleChangeStatTesting(option)
        }}
        active={showStatTesting ? statsConfidence.includes(option) : !option}
      >
        {option ? `${option}% Confidence` : 'No Stat Testing'}
      </DropdownButton.MenuItem>
    )
  }

  const handleClickDownloadDropdown = (shouldOpen: boolean) => {
    setDownloadDropdown(shouldOpen)
  }

  const handleDownloadPpt = async function () {
    setIsExporting(true)
    try {
      const results = await dispatch(exportAnalysis('ppt'))
      download(results, `${dataset.studyName}.pptx`)
    } catch (e) {
      sendNotification(uniqueId('pptx-export-error'), 'error', 'Failed to export to PPTX')
      Sentry.captureException(new Error('Failed to export to PPTX', { cause: e }))
    }
    setIsExporting(false)
  }

  const handleDownloadXLSX = async function () {
    setIsExporting(true)
    try {
      const results = await dispatch(exportAnalysis('xlsx'))
      download(results, `${dataset.studyName}.xlsx`)
    } catch (e) {
      sendNotification(uniqueId('xlsx-export-error'), 'error', 'Failed to export to XLSX')
      Sentry.captureException(new Error('Failed to export to XLSX', { cause: e }))
    }
    setIsExporting(false)
  }

  const handleChangeExport = (option: string) => {
    if (option === 'pptx') {
      handleDownloadPpt()
    } else if (option === 'xlsx') {
      handleDownloadXLSX()
    }

    handleTrack('Chart Exported', {
      format: option,
      question_id: baseQuestion.id,
      question_text: baseQuestion.label,
      study_id: dataset.studyId,
      study_name: dataset.studyName,
    })
    handleClickDownloadDropdown(false)
  }

  const downloadMenuItem = (exportType: string): Node => (
    <DropdownButton.MenuItem
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        handleChangeExport(exportType)
      }}
    >
      {exportType.toUpperCase()}
    </DropdownButton.MenuItem>
  )

  const datasetAvailable =
    dataset && dataset.rdmComplete && dataset.permissions && dataset.permissions.view
  const hasDatasetAndVariables =
    datasetAvailable && xAxisVariables.length + yAxisVariables.length > 0
  const isDisabled = !dataset.studyId || isFetchingDataset || isFetchingData || isExporting
  const everythingHiddenLowBase = everythingLowBase && hideLowBase
  const disableAddToDash = !hasDatasetAndVariables || isDisabled || everythingHiddenLowBase

  return (
    <section ref={actionBarRef} className={styles.actionBar}>
      <Tooltip
        placement="bottom"
        content={<FormattedMessage id="analysis.actionBar.percentage.tooltip" />}
      >
        <ActionBarToggleButton
          isActive={numberType === 'percentage'}
          isDisabled={isDisabled}
          icon="percent"
          hideActionText={hideActionText}
          onClick={handleSetDisplayType('percentage')}
          testAttribute="percent-style"
        />
      </Tooltip>
      <Tooltip
        placement="bottom"
        content={<FormattedMessage id="analysis.actionBar.numeric.tooltip" />}
      >
        <ActionBarToggleButton
          isActive={numberType === 'numeric'}
          isDisabled={isDisabled}
          icon="numerical"
          hideActionText={hideActionText}
          onClick={handleSetDisplayType('numeric')}
          testAttribute="numeric-style"
        />
      </Tooltip>
      {chartType !== 'spreadsheet' && (
        <Tooltip
          placement="bottom"
          content={<FormattedMessage id="analysis.actionBar.showDataLabels.tooltip" />}
        >
          <ActionBarToggleButton
            isActive={showLabels}
            isDisabled={isDisabled}
            icon="labels"
            hideActionText={hideActionText}
            onClick={handleToggleShowLabels}
            testAttribute="show-data-labels"
          >
            <FormattedMessage id="analysis.actionBar.showDataLabels.button" />
          </ActionBarToggleButton>
        </Tooltip>
      )}
      <Tooltip
        placement="bottom"
        content={<FormattedMessage id="analysis.actionBar.addFilter.tooltip" />}
      >
        <ActionBarToggleButton
          isDisabled={isDisabled}
          icon="filter"
          hideActionText={hideActionText}
          onClick={handleOpenFilterModal}
          testAttribute="add-filter"
        >
          <FormattedMessage id="analysis.actionBar.addFilter.button" />
        </ActionBarToggleButton>
      </Tooltip>
      <Tooltip
        placement="bottom"
        content={<FormattedMessage id="analysis.actionBar.adjustBase.tooltip" />}
      >
        <ActionBarToggleButton
          isActive={autoAdjustBaseEnabled}
          isDisabled={isDisabled}
          icon="adjust"
          hideActionText={hideActionText}
          onClick={handleAutoAdjustBase}
          testAttribute="auto-adjust-base"
        >
          <FormattedMessage id="analysis.actionBar.adjustBase.button" />
        </ActionBarToggleButton>
      </Tooltip>
      <div data-test="stat-testing">
        <DropdownButton
          icon="stats"
          text={
            hideActionText
              ? ''
              : showStatTesting
              ? `Stat Testing (${statsConfidence}%)`
              : 'Stat Testing'
          }
          type="textualDark"
          disabled={isDisabled}
          isOpen={statsDropdownOpen}
          onOpen={event => {
            event.stopPropagation()
            setStatsDropdown(true)
          }}
          onClose={event => {
            event.stopPropagation()
            setStatsDropdown(false)
          }}
        >
          {statsMenuItem(95)}
          {statsMenuItem(90)}
          {statsMenuItem(null)}
        </DropdownButton>
      </div>
      <Tooltip
        placement="bottom"
        content={<FormattedMessage id="analysis.actionBar.hideLowBase.tooltip" />}
      >
        <ActionBarToggleButton
          isActive={hideLowBase}
          isDisabled={isDisabled}
          icon="lowBase"
          hideActionText={hideActionText}
          onClick={handleHideLowBase}
          testAttribute="hide-low-base"
        >
          <FormattedMessage id="analysis.actionBar.hideLowBase.button" />
        </ActionBarToggleButton>
      </Tooltip>
      <Tooltip
        placement="bottom"
        content={<FormattedMessage id="analysis.actionBar.nullSuppression.tooltip" />}
      >
        <ActionBarToggleButton
          isActive={suppressNullValues}
          isDisabled={isDisabled}
          icon="invisible"
          hideActionText={hideActionText}
          onClick={handleToggleNullSuppression}
          testAttribute="hide-nulls"
        >
          <FormattedMessage id="analysis.actionBar.nullSuppression.button" />
        </ActionBarToggleButton>
      </Tooltip>
      <div data-test="download">
        <DropdownButton
          icon={isExporting ? 'sync' : 'download'}
          text={hideActionText ? '' : 'Download'}
          type="textualDark"
          disabled={isDisabled || everythingHiddenLowBase}
          isOpen={downloadDropdownOpen}
          onOpen={event => {
            event.stopPropagation()
            handleClickDownloadDropdown(true)
          }}
          onClose={event => {
            event.stopPropagation()
            handleClickDownloadDropdown(false)
          }}
        >
          {downloadMenuItem('xlsx')}
          {downloadMenuItem('pptx')}
        </DropdownButton>
      </div>
      {handleAddContentItem && (
        <Tooltip
          placement="bottom"
          content={<FormattedMessage id="analysis.actionBar.addTo.tooltip" />}
        >
          <ActionBarToggleButton
            isDisabled={disableAddToDash}
            icon="addContent"
            hideActionText={hideActionText}
            onClick={handleAddContentItem}
            testAttribute="add-to"
          >
            <FormattedMessage id="analysis.actionBar.addTo.button" />
          </ActionBarToggleButton>
        </Tooltip>
      )}
    </section>
  )
}
