// @flow
import * as actions from './DatasetActions'
import * as selectors from './DatasetSelectors'
import * as reducers from './DatasetReducers'
import * as requests from './DatasetRequests'
import * as constants from './constants'
import * as config from './configurationDuck'
import * as filterNet from './FilterNetDuck'
import generateReduxAction from './GenerateReduxAction'

export { segmentMiddleware } from './middleware/segment'

export { actions, selectors, reducers, requests, constants, config, filterNet, generateReduxAction }

const Analysis2Store = {
  actions,
  selectors,
  reducers,
  requests,
  constants,
  config,
  filterNet,
  generateReduxAction,
}

export default Analysis2Store
