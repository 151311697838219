import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import * as styles from './ChartError.module.scss'

const propTypes = {
  messageKey: PropTypes.string.isRequired,
  messageContent: PropTypes.object,
}

const ChartError = ({ messageKey = 'unknown', messageContent = {} }) => (
  <div className={styles.container}>
    <div className={styles.headline}>
      <FormattedMessage
        id={`analysis.analysisDataErrors.${messageKey}.title`}
        values={messageContent}
      />
    </div>
    <div className={styles.body}>
      <FormattedMessage
        id={`analysis.analysisDataErrors.${messageKey}.body`}
        values={messageContent}
      />
    </div>
  </div>
)

ChartError.propTypes = propTypes

export default ChartError
