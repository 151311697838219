import { createSelector } from 'reselect'
import { isEqual } from 'lodash'

import { getTableData, getChartSortState, getXAxisVariables } from 'store/DatasetSelectors'
import { ROW } from 'store/constants'
import { getRowSpan, getCellSortDirection } from 'tabularizeData'

export const getGridData = createSelector(
  getXAxisVariables,
  getChartSortState,
  getTableData,
  (xAxisVariables, sortState, { tableData, rowHeaders, error }) => {
    if (error) return { error }

    const { sortVariables, direction } = sortState[ROW]

    return tableData.map((row, rIdx) => {
      const rowObj = { 'row-id': rIdx, sortVariables: [], span: {}, sorted: '', isBaseRow: false }
      rowHeaders[rIdx].forEach((cell, colIdx) => {
        rowObj[cell.field] = cell.label
        rowObj.span[cell.field] =
          xAxisVariables.length && colIdx < rowHeaders[rIdx].length - 1
            ? getRowSpan(rowHeaders, colIdx)
            : 1
        if (cell.id) {
          rowObj.sortVariables.push(cell.id)
        }
      })
      row.forEach((cell, colIdx) => {
        rowObj[`column-${colIdx}`] = cell
        rowObj[`base-${colIdx}`] = Array.isArray(cell.base) ? cell.base[0] : cell.base
      })
      if (row[row.length - 1] && row[row.length - 1].baseRow) {
        rowObj.isBaseRow = true
      }
      if (isEqual(sortVariables, rowObj.sortVariables)) {
        rowObj.sorted = getCellSortDirection(direction, sortVariables, rowObj.sortVariables)
      }
      return rowObj
    })
  }
)

export const getGridHeaders = createSelector(getTableData, ({ colHeaders, rowHeaders, error }) => {
  if (error) return { error }
  const rowHeaderCount = rowHeaders?.[0]?.length
  const colHeaderCount = colHeaders.length
  return { colHeaders, rowHeaders, rowHeaderCount, colHeaderCount }
})
