// TODO: also used in A1 - figure out what to do with util
export function cleanParams(params) {
  // cleans the params and returns a new params object
  return Object.keys(params).reduce((newParams, param) => {
    let newParamValue = params[param]
    if (!newParamValue) return newParams

    newParamValue = newParamValue.replace(/%26/gi, '&')
    newParamValue = newParamValue.replace(/%3F/gi, '?')
    newParamValue = newParamValue.replace(/%23/gi, '#')
    newParamValue = newParamValue.replace(/%2B/gi, '+')
    newParamValue = newParamValue.replace(/%3B/gi, ';')
    newParamValue = newParamValue.replace(/%2C/gi, ',')
    newParamValue = newParamValue.replace(/%2F/gi, '/')
    newParamValue = newParamValue.replace(/%3A/gi, ':')
    newParamValue = newParamValue.replace(/%40/gi, '@')
    newParamValue = newParamValue.replace(/%3D/gi, '=')
    newParamValue = newParamValue.replace(/%24/gi, '$')
    newParams[param] = newParamValue
    return newParams
  }, {})
}
