import { getOptionText, compareVariables } from './Analysis2Utils'
import { getQnameFromNk } from './util/naturalKeyUtils'
import { getFilterMethodText } from './util/filterMethods'

// Pug uses this, make sure if you update this method you bump up
// the analysis package version and update Pug
export const getFilterVariable = (variables: Array<VariableT>, filter: FilterT) => {
  return variables.find(
    currVar =>
      currVar.questionName === filter.questionName &&
      currVar.resourceType === filter.variableResourceType
  )
}

// Pug uses this, make sure if you update this method you bump up
// the analysis package version and update Pug
export const getFilterResponseText = (
  filter: FilterT,
  filterVariable: VariableT | null,
  method: string = ''
) => {
  return (
    filterVariable &&
    filter.filteredOptions.map(opt => {
      if (Array.isArray(opt.id)) {
        const methodText = method ? `${getFilterMethodText(method)} ` : ''
        return `${methodText}${opt.id
          .map(id => getOptionText(filterVariable, { id, type: opt.type }))
          .join(' and ')}`
      }
      return getOptionText(filterVariable, opt)
    })
  )
}

export const checkAxisVariables = (
  xAxisVariables: Array<AxisVariableT>,
  yAxisVariables: Array<AxisVariableT>,
  selectedVariable: VariableT
) => {
  const foundXaxis = xAxisVariables.find(v => compareVariables(v, selectedVariable))
  const foundYaxis = yAxisVariables.find(v => compareVariables(v, selectedVariable))
  return { foundXaxis, foundYaxis }
}

export const isOptionSelected = (opt, filterOpts, method) => {
  if (['$noteq', '$notcontains'].includes(method)) {
    // deselect filtered out options from existing axis selected options
    return filterOpts.find(fo => fo.id === opt.id && fo.type === opt.type) ? false : opt.selected
  } else if (['$eq', '$contains'].includes(method)) {
    // if it is an eq or contains filter, select filter options
    // AND deselect all other options
    return !!filterOpts.find(fo => fo.id === opt.id && fo.type === opt.type)
  } else if (['net', 'calculated'].includes(opt.type)) {
    return opt.selected
  } else if (['lt', 'notgte'].includes(method)) {
    return opt.type === filterOpts[0].type && parseFloat(opt.id) < parseFloat(filterOpts[0].id)
  } else if (['gt', 'notlte'].includes(method)) {
    return opt.type === filterOpts[0].type && parseFloat(opt.id) > parseFloat(filterOpts[0].id)
  } else if (['lte', 'notgt'].includes(method)) {
    return opt.type === filterOpts[0].type && parseFloat(opt.id) <= parseFloat(filterOpts[0].id)
  } else if (['gte', 'notlt'].includes(method)) {
    return opt.type === filterOpts[0].type && parseFloat(opt.id) >= parseFloat(filterOpts[0].id)
  } else if (method === 'between') {
    return (
      opt.type === filterOpts[0].type &&
      parseFloat(opt.id) >= parseFloat(filterOpts[0].id[0]) &&
      parseFloat(opt.id) <= parseFloat(filterOpts[0].id[1])
    )
  } else if (method === 'notbetween') {
    return (
      opt.type === filterOpts[0].type &&
      (parseFloat(opt.id) < parseFloat(filterOpts[0].id[0]) ||
        parseFloat(opt.id) > parseFloat(filterOpts[0].id[1]))
    )
  }
  return opt.selected
}

const getSelectedOptions = (filterData: VariableFilterDataT, foundAxisVar: AxisVariableT) => {
  return foundAxisVar.selectedOptions.map(opt => {
    return {
      ...opt,
      selected: isOptionSelected(opt, filterData.selectedOptions, filterData.selectedFilterMethod),
    }
  })
}

export const updateAxisSelectedOptions = (
  foundAxisVar: AxisVariableT,
  axisVars: Array<AxisVariableT>,
  filterData: VariableFilterDataT,
  variables: Array<VariableT>
) => {
  const updatedAxisVar = {
    ...foundAxisVar,
    selectedOptions: getSelectedOptions(filterData, foundAxisVar, variables),
  }
  return axisVars.map(v => {
    return compareVariables(v, filterData.selectedVariable) ? updatedAxisVar : v
  })
}

export const updatedAxisVarsForFilters = (
  autoAdjustBaseEnabled,
  xAxisVariables,
  yAxisVariables,
  filterData,
  variables
) => {
  let updatedX = [...xAxisVariables]
  let updatedY = [...yAxisVariables]
  if (autoAdjustBaseEnabled) return { updatedY, updatedX }

  const { foundXaxis, foundYaxis } = checkAxisVariables(
    xAxisVariables,
    yAxisVariables,
    filterData.selectedVariable
  )
  if (foundXaxis) {
    updatedX = updateAxisSelectedOptions(foundXaxis, xAxisVariables, filterData, variables)
  }
  if (foundYaxis) {
    updatedY = updateAxisSelectedOptions(foundYaxis, yAxisVariables, filterData, variables)
  }
  return { updatedY, updatedX }
}

// If autoUpdateBase is on
export const createBaseFilters = (axisVar: AxisVariableT) => {
  const selected = axisVar.selectedOptions
    .filter(o => o.selected && o.type !== 'calculated')
    .map(so => ({
      id: so.id,
      type: so.type,
    }))
  return {
    questionName: getQnameFromNk(axisVar.variableNk),
    variableResourceType: axisVar.resourceType,
    method: '$eq',
    filteredOptions: selected || [],
  }
}

export const variableFiltersMatch = (f1: FilterT, f2: FilterT) => {
  return (
    f1.questionName === f2.questionName &&
    f1.variableResourceType === f2.variableResourceType &&
    f1.method === f2.method &&
    ((!f1.breakoutId && !f2.breakoutId) || f1.breakoutId === f2.breakoutId)
  )
}

export const getNewFilterList = (filterList, newFilter, filterToReplace) => {
  const newFilterList = filterList.filter(f => {
    const match = variableFiltersMatch(f, newFilter)
    const toReplace = filterToReplace ? variableFiltersMatch(f, filterToReplace) : false
    return !match && !toReplace
  })
  return [...newFilterList, newFilter]
}

export const deepCopyFilter = (filter: FilterT) => {
  return {
    ...filter,
    filteredOptions: [...filter.filteredOptions],
  }
}

export const isNumericMethod = (method: string) => {
  return [
    'lt',
    'lte',
    'notlt',
    'notlte',
    'gt',
    'gte',
    'notgt',
    'notgte',
    'between',
    'notbetween',
  ].includes(method)
}

const getParamType = (opt, method) => {
  if (!isNumericMethod(method) || opt.type === 'breakout') {
    return `${opt.type}s`
  }
  return method.replace('not', '')
}

const transformFilterOptions = (filteredOptions, method) => {
  const serialized = {
    options: [],
    breakouts: [],
    nets: [],
  }
  filteredOptions.forEach(opt => {
    const paramType = getParamType(opt, method)
    // convert number-convertable IDs to number
    const id = opt.type === 'breakout' || isNaN(+opt.id) ? opt.id : +opt.id
    if (isNumericMethod(paramType)) {
      // Numeric filter types should only ever have one value assigned for the response
      // It may have multiple breakout selections, but that does not land in this code path
      serialized[paramType] = id
    } else {
      serialized[paramType].push(id)
    }
  })
  for (const key in serialized) {
    if (serialized[key].length === 0) {
      delete serialized[key]
    }
  }

  return serialized
}

export const serializeFilterParams = (filters: Array<FilterT>) => {
  const serializedFilters = {}
  filters.forEach(filter => {
    const isNot = filter.method.includes('not')
    const isAnd = !!filter.breakoutId

    let targetFilterObj

    if (isNot) {
      if (!serializedFilters.hasOwnProperty('$not')) {
        serializedFilters.$not = {}
      }
      if (isAnd) {
        if (!serializedFilters.$not.hasOwnProperty('$and')) {
          serializedFilters.$not.$and = {}
        }
        targetFilterObj = serializedFilters.$not.$and
      } else {
        targetFilterObj = serializedFilters.$not
      }
    } else if (isAnd) {
      if (!serializedFilters.hasOwnProperty('$and')) {
        serializedFilters.$and = {}
      }
      targetFilterObj = serializedFilters.$and
    } else {
      targetFilterObj = serializedFilters
    }

    const opts = isAnd
      ? [
          ...filter.filteredOptions,
          {
            id: filter.breakoutId,
            type: filter.variableResourceType === 'options' ? 'breakout' : 'option',
          },
        ]
      : [...filter.filteredOptions]
    const serializedObj = transformFilterOptions(opts, filter.method)
    const id = filter.questionName.toString()

    if (targetFilterObj.hasOwnProperty(id)) {
      if (isAnd) {
        if (targetFilterObj[id]) {
          targetFilterObj[id].push(serializedObj)
        } else {
          targetFilterObj[id] = [serializedObj]
        }
      } else {
        for (const key in serializedObj) {
          if (targetFilterObj[id].hasOwnProperty(key)) {
            targetFilterObj[id][key] = [...targetFilterObj[id][key], ...serializedObj[key]]
          } else {
            targetFilterObj[id][key] = serializedObj[key]
          }
        }
      }
    } else {
      targetFilterObj[id] = isAnd ? [serializedObj] : serializedObj
    }
  })
  return serializedFilters
}

export const mergeFilterLists = (baseFilters: Array<FilterT>, filters: Array<FilterT>) => {
  let conflict = false
  if (baseFilters.length === 0) return { comboFilters: filters || [], conflict }
  if (filters.length === 0) return { comboFilters: baseFilters || [], conflict }
  const comboFilters = filters.map(f => deepCopyFilter(f))

  baseFilters.forEach(bf => {
    const idx = comboFilters.findIndex(f => variableFiltersMatch(bf, f))
    if (idx > -1) {
      // If the filters match, replace the applied filter with the
      // auto adjust base selection filter
      comboFilters[idx] = bf
      conflict = true
    } else {
      comboFilters.push(bf)
    }
  })

  return { comboFilters, conflict }
}

// Pug uses this code!
export const generateFilterText = (variables, currFilter) => {
  const filterVariable = getFilterVariable(variables, currFilter)
  const methodText =
    currFilter.breakoutId && currFilter.variableResourceType !== 'options'
      ? getFilterMethodText('$eq')
      : getFilterMethodText(currFilter.method)
  let responseTextList = []
  let questionTextFull = ''
  let breakoutText = ''

  if (currFilter.breakoutId) {
    const filterVarOpt = variables.find(
      v => v.questionName === currFilter.questionName && v.resourceType === 'options'
    )
    const filterVarBreakout = variables.find(
      v => v.questionName === currFilter.questionName && v.resourceType === 'breakouts'
    )

    if (!filterVarOpt || !filterVarBreakout) {
      console.warn('could not find', filterVarOpt, filterVarBreakout, 'in', variables)
      return null
    }
    questionTextFull = filterVariable ? filterVariable.label : currFilter.questionName
    breakoutText =
      currFilter.variableResourceType === 'options'
        ? ` - ${getOptionText(filterVarBreakout, {
            type: 'breakout',
            id: currFilter.breakoutId,
          })}`
        : ` - ${getFilterResponseText(
            { filteredOptions: [{ type: 'option', id: currFilter.breakoutId }] },
            filterVarOpt,
            currFilter.method
          )}`
    questionTextFull += breakoutText

    responseTextList =
      currFilter.variableResourceType === 'options'
        ? getFilterResponseText(currFilter, filterVarOpt)
        : getFilterResponseText(currFilter, filterVarBreakout)
  } else {
    responseTextList = getFilterResponseText(currFilter, filterVariable || null)
    questionTextFull = filterVariable ? filterVariable.label : currFilter.questionName
  }
  return { methodText, responseTextList, questionTextFull, breakoutText }
}

export const getFilterDefaults = (variables: Array<VariableT>, filter: Object) => {
  const getOptionFromId = (variables: Array<VariableT>, id: string) => {
    for (let i = 0; i < variables.length; i++) {
      const found = variables[i].options.find(o => o.id === id)
      if (found) return found
    }
    return null
  }

  const defaultFilterVariable = getFilterVariable(variables, filter)
  const defaultFilterGrid = filter.breakoutId
    ? [
        variables.find(v => v.questionName === filter.questionName && v.resourceType === 'options'),
        variables.find(
          v => v.questionName === filter.questionName && v.resourceType === 'breakouts'
        ),
      ]
    : null
  const defaultFilterBreakout = filter.breakoutId
    ? getOptionFromId(variables, filter.breakoutId)
    : null

  return { defaultFilterVariable, defaultFilterGrid, defaultFilterBreakout }
}
