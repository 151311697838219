import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import * as Sentry from '@sentry/react'
import { ToggleableSelectionOptions } from '@knowledgehound/laika'

import { filterNet as filterNetDuck, selectors } from 'store'
import { getSuppressedVariableOptions, shouldSuppressNulls } from 'store/DatasetSelectors'
import { getNetExplanation } from '../Analysis2Utils'
import NullSuppressionAccordion from '../NullSuppressionAccordion'
import VariableCardSkeleton from './VariableCardSkeleton'

import * as styles from './ExpandedContent.module.scss'

const isSuppressionOptsFetching = variableNk => state =>
  state.dataset.variables.nullSuppressedOpts.isFetching[variableNk]

const OptionT = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['option', 'breakout', 'net', 'calculated']), // calculated will refer to Total, Min, Max, Mean
  label: PropTypes.string.isRequired, // option text such as 'Male', 'Female'
})

const propTypes = {
  variableToRender: PropTypes.shape({
    id: PropTypes.string.isRequired, // name+type
    questionName: PropTypes.string.isRequired, // the question reference id within a dataset, such as ageres
    question_type: PropTypes.string.isRequired,
    variableNk: PropTypes.string.isRequired, // natural key (study number, dataset name, question id)
    label: PropTypes.string, // The variable/question text
    nets: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired, // option text such as 'Male', 'Female'
        responses: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
        response_rules: PropTypes.object,
        email: PropTypes.string.isRequired, // user who created the net
      })
    ), // The nets available for the variable
    options: PropTypes.arrayOf(OptionT),
    calculated: PropTypes.arrayOf(OptionT),
    resourceType: PropTypes.string.isRequired, // breakout or response options
    supported_filter_methods: PropTypes.arrayOf(PropTypes.string).isRequired, // $eq or $contains
    isGrid: PropTypes.bool,
  }).isRequired,
  axisVariable: PropTypes.object.isRequired,
  isBase: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isDragInProgress: PropTypes.bool,
  handleToggleOption: PropTypes.func.isRequired,
  handleToggleAll: PropTypes.func.isRequired,
  handleTrack: PropTypes.func,
  onDeleteNet: PropTypes.func,
  email: PropTypes.string,
}

function ExpandedContent({
  variableToRender,
  axisVariable,
  isBase = false,
  isDisabled = false,
  isDragInProgress = false,
  handleToggleOption,
  handleToggleAll,
  handleTrack,
  onDeleteNet,
  email = '',
}) {
  const dispatch = useDispatch()
  const chartType = useSelector(selectors.getChartType, shallowEqual)
  const suppressNulls = useSelector(shouldSuppressNulls, shallowEqual)
  const suppressedMap = useSelector(getSuppressedVariableOptions, shallowEqual)
  const isFetching = useSelector(
    isSuppressionOptsFetching(variableToRender.variableNk),
    shallowEqual
  )

  const [meanMode, isBreakout] = useMemo(() => {
    const meanOpt = axisVariable.selectedOptions.find(o => o.id === '__mean__')
    const isBreakout = axisVariable.selectedOptions.find(o => o.type === 'breakout')
    return [Boolean(meanOpt && meanOpt.selected && chartType !== 'spreadsheet'), isBreakout]
  }, [axisVariable.selectedOptions, chartType])

  const handleDeleteNet = useCallback(
    async net => {
      try {
        await dispatch(
          filterNetDuck.deleteNetThunk(
            variableToRender.variableNk,
            variableToRender.resourceType,
            net.id
          )
        )
        onDeleteNet && onDeleteNet(net)
        const trackProps = {
          question_id: variableToRender.variableNk,
          question_text: variableToRender.questionName,
          netToDelete: net.id,
        }
        handleTrack('Question Response Net Deleted', trackProps)
      } catch (e) {
        Sentry.captureException(new Error('Error trying to delete net', { cause: e }))
      }
    },
    [variableToRender, onDeleteNet, handleTrack, dispatch]
  )

  const convertOption = useCallback(
    option => {
      const isNet = option.type === 'net'
      const axisOpt = axisVariable.selectedOptions.find(
        o => o.id === option.id && o.type === option.type
      )
      const isAvailable = (option.id !== '__mean__' && !meanMode) || option.id === '__mean__'
      return {
        value: '',
        text: option.label,
        type: option.type,
        id: variableToRender.id + option.id + variableToRender.resourceType,
        isAvailable: isAvailable,
        isSelected: Boolean(axisOpt && axisOpt.selected),
        canDelete: isNet ? email === option.email : false,
        info: isNet ? getNetExplanation(option) : null,
        onClick: opt => {
          handleTrack &&
            handleTrack('Selected Variable Option Checkbox', {
              isSelected: !opt.isSelected,
              option: option.id,
              optionText: option.label,
              isNet: isNet,
            })
          handleToggleOption(option)
        },
        onDelete:
          isNet && handleDeleteNet
            ? () => {
                handleDeleteNet(option)
              }
            : undefined,
      }
    },
    [
      axisVariable.selectedOptions,
      meanMode,
      email,
      handleToggleOption,
      handleTrack,
      handleDeleteNet,
      variableToRender,
    ]
  )

  const netsList = useMemo(
    () => variableToRender.nets.map(convertOption),
    [convertOption, variableToRender.nets]
  )

  const calculatedList = useMemo(
    () =>
      isBase
        ? variableToRender.calculated.map(convertOption)
        : variableToRender.calculated.filter(o => o.id !== '__mean__').map(convertOption),
    [isBase, variableToRender.calculated, convertOption]
  )

  const { optionsList, suppressedList } = useMemo(() => {
    return variableToRender.options.reduce(
      (acc, opt) => {
        if (
          suppressNulls &&
          !isFetching &&
          suppressedMap[variableToRender.variableNk] &&
          suppressedMap[variableToRender.variableNk][opt.id]
        ) {
          return {
            ...acc,
            suppressedList: acc.suppressedList.concat(convertOption(opt)),
          }
        }
        return {
          ...acc,
          optionsList: acc.optionsList.concat(convertOption(opt)),
        }
      },
      { optionsList: [], suppressedList: [] }
    )
  }, [
    convertOption,
    variableToRender.variableNk,
    variableToRender.options,
    suppressNulls,
    suppressedMap,
    isFetching,
  ])

  return (
    <React.Fragment>
      <ToggleableSelectionOptions
        options={calculatedList}
        loading={isDisabled || isDragInProgress}
        compact
      />
      {Boolean(netsList.length) && (
        <ToggleableSelectionOptions
          options={netsList}
          loading={isDisabled || isDragInProgress}
          compact
        />
      )}
      <hr className={styles.divider} />
      {isFetching ? (
        <VariableCardSkeleton />
      ) : (
        <ToggleableSelectionOptions
          options={optionsList}
          loading={isDisabled || isDragInProgress}
          onToggleAll={() => {
            if (!meanMode) {
              handleToggleAll()
              handleTrack &&
                handleTrack('Selected Variable Option Checkbox', {
                  option: 'Select All',
                  optionText: 'Select All',
                  isNet: false,
                })
            }
          }}
          disableSelectAll={meanMode}
          compact
        />
      )}
      {suppressNulls && Boolean(suppressedList.length) && !optionsList.length && (
        <p className={styles.allSuppressedWarning}>
          <FormattedMessage
            id={`analysis.nullSuppression.allOptionsSuppressed.${
              isBreakout ? 'breakouts' : 'responses'
            }`}
          />
        </p>
      )}
      {suppressNulls && Boolean(suppressedList.length) && (
        <NullSuppressionAccordion isBreakout={isBreakout}>
          <ul className={styles.suppressedOptions}>
            {suppressedList.map(({ id, text }) => (
              <li key={id} className={styles.suppressedOption}>
                {text}
              </li>
            ))}
          </ul>
        </NullSuppressionAccordion>
      )}
    </React.Fragment>
  )
}

ExpandedContent.propTypes = propTypes
export default ExpandedContent
