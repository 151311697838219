import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { DropdownButton, Icon, Tooltip } from '@knowledgehound/laika'

import ExpandedContent from './ExpandedContent'

import * as styles from './VariableCard.module.scss'

const OptionT = PropTypes.shape({
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['option', 'breakout', 'net', 'calculated']), // calculated will refer to Total, Min, Max, Mean
  label: PropTypes.string.isRequired, // option text such as 'Male', 'Female'
})

const propTypes = {
  variableToRender: PropTypes.shape({
    id: PropTypes.string, // name+type
    questionName: PropTypes.string.isRequired, // the question reference id within a dataset, such as ageres
    question_type: PropTypes.string,
    variableNk: PropTypes.string.isRequired, // natural key (study number, dataset name, question id)
    label: PropTypes.string.isRequired, // The variable/question text
    nets: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired, // option text such as 'Male', 'Female'
        responses: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
        response_rules: PropTypes.object,
        email: PropTypes.string.isRequired, // user who created the net
      })
    ), // The nets available for the variable
    options: PropTypes.arrayOf(OptionT),
    calculated: PropTypes.arrayOf(OptionT),
    resourceType: PropTypes.string, // breakout or response options
    supported_filter_methods: PropTypes.arrayOf(PropTypes.string), // $eq or $contains
    isGrid: PropTypes.bool,
  }).isRequired,
  axisVariable: PropTypes.object,
  dropdownMenuItems: PropTypes.arrayOf(PropTypes.node),
  isMenuOpen: PropTypes.bool,
  setMenuOpen: PropTypes.func,
  isBase: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isDragInProgress: PropTypes.bool,
  isDropDisabled: PropTypes.bool,
  dragLayerStyle: PropTypes.object,
  isOver: PropTypes.bool,
  shouldHighlight: PropTypes.bool,
  isActive: PropTypes.bool,
  onClickHeader: PropTypes.func.isRequired,
}

function VariableCard({
  variableToRender,
  axisVariable,
  dropdownMenuItems = [],
  isMenuOpen = false,
  setMenuOpen,
  isBase = false,
  isExpanded = false,
  isDisabled = false,
  isDragInProgress = false,
  isDropDisabled = true,
  dragLayerStyle = {},
  isOver = false,
  shouldHighlight = false,
  isActive = false,
  onClickHeader,
  children,
}) {
  const showWarning = useMemo(
    () => axisVariable && !axisVariable.selectedOptions.some(o => o.selected),
    [axisVariable]
  )
  const tooltipContent = useMemo(
    () => (showWarning ? 'No options selected. Select at least one.' : ''),
    [showWarning]
  )

  const variableTypeText = useMemo(
    () =>
      variableToRender.isGrid && variableToRender.resourceType
        ? variableToRender.resourceType === 'options'
          ? 'Responses'
          : 'Options'
        : '',
    [variableToRender]
  )

  const handleDropdownOpen = useCallback(
    event => {
      event.stopPropagation()
      setMenuOpen?.(true)
    },
    [setMenuOpen]
  )

  const handleDropdownClose = useCallback(
    event => {
      event.stopPropagation()
      setMenuOpen?.(false)
    },
    [setMenuOpen]
  )

  return (
    <Tooltip placement="right" isOpen={showWarning} content={tooltipContent}>
      {({ hostRef, attributes }) => (
        <div
          ref={hostRef}
          className={classnames(styles.variableCard, {
            [styles.warning]: showWarning,
            [styles.disableDrop]: isDragInProgress && isDropDisabled,
            [styles.dragInProgress]: isDragInProgress,
            [styles.isOver]: isOver,
            [styles.baseVariable]: isBase,
            [styles.highlight]: shouldHighlight,
            [styles.active]: isActive,
          })}
          style={dragLayerStyle}
          {...attributes}
        >
          <div onClick={onClickHeader} className={styles.cardHeader}>
            <div className={styles.headerTextContainer}>
              <div
                className={classnames(styles.headerText, {
                  [styles.disabled]: isDisabled,
                })}
              >
                {`${variableToRender.label}${
                  variableToRender.isGrid ? ` (${variableTypeText})` : ''
                }`}
              </div>
            </div>
            <div
              className={classnames(styles.buttonContainer, {
                [styles.isDragging]: isDragInProgress,
              })}
            >
              {Boolean(dropdownMenuItems.length) && (
                <DropdownButton
                  icon="more"
                  hideArrow
                  type="textualDark"
                  size="small"
                  leftAlignDropdown
                  alignStart
                  disabled={isDisabled}
                  isOpen={isMenuOpen}
                  onOpen={handleDropdownOpen}
                  onClose={handleDropdownClose}
                >
                  {dropdownMenuItems}
                </DropdownButton>
              )}
              <div className={styles.caretContainer}>
                <button className={styles.caretButton} type="button" onClick={onClickHeader}>
                  <Icon icon={isExpanded ? 'arrowUp' : 'arrowDown'} />
                </button>
              </div>
            </div>
          </div>
          {children}
        </div>
      )}
    </Tooltip>
  )
}

VariableCard.propTypes = propTypes
VariableCard.ExpandedContent = ExpandedContent
export default VariableCard
