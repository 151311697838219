import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Icon, Tooltip } from '@knowledgehound/laika'

import { VIS_CHART_VAR_LIMIT } from 'store/constants'
import { getFirstInterval } from '../Analysis2Utils'
import * as styles from './ChartTypeSelector.module.scss'

const supportedChartTypes = ['spreadsheet', 'column', 'bar', 'stackedColumn', 'stackedBar', 'line']

const propTypes = {
  isFetching: PropTypes.bool,
  currChartType: PropTypes.string,
  xAxisVariables: PropTypes.arrayOf(PropTypes.object),
  yAxisVariables: PropTypes.arrayOf(PropTypes.object),
  datasetVariables: PropTypes.arrayOf(PropTypes.object),
  chartTypeMap: PropTypes.object,
  handleChangeChartType: PropTypes.func,
}

function ChartTypeSelector({
  isFetching,
  currChartType,
  xAxisVariables,
  yAxisVariables,
  datasetVariables,
  chartTypeMap,
  handleChangeChartType,
}) {
  const headerText = currChartType
    ? `Chart type (${chartTypeMap[currChartType]}${
        currChartType === 'spreadsheet' ? '' : ' chart'
      })`
    : 'Chart type'

  const chartTypeDisabledMessage = useCallback(
    chartType => {
      if (isFetching) return 'Loading'
      if (chartType === 'spreadsheet') return ''
      if (
        currChartType === 'line' &&
        xAxisVariables.length + yAxisVariables.length <= VIS_CHART_VAR_LIMIT + 1
      )
        return ''
      if (chartType === 'line') {
        const intervalVar = getFirstInterval(datasetVariables)
        if (!intervalVar)
          return 'Line charts are only supported for studies with at least one interval question.'
      }
      return xAxisVariables.length + yAxisVariables.length > VIS_CHART_VAR_LIMIT
        ? `${chartTypeMap[chartType]} chart only supported up to ${VIS_CHART_VAR_LIMIT} variables`
        : ''
    },
    [xAxisVariables, yAxisVariables, chartTypeMap, currChartType, datasetVariables, isFetching]
  )

  const renderChartTypeButton = useCallback(
    chartType => {
      const chartIconMap = {
        bar: 'chartBar',
        column: 'chartColumn',
        stackedBar: 'chartStackedBar',
        stackedColumn: 'chartStackedColumn',
        line: 'chartLine',
        spreadsheet: 'spreadsheet',
      }
      const active = currChartType === chartType
      const disabledMessage = chartTypeDisabledMessage(chartType)

      return (
        <Tooltip
          key={chartType}
          placement="top"
          content={Boolean(disabledMessage) ? disabledMessage : chartTypeMap[chartType]}
        >
          {({ hostRef, openTooltip, closeTooltip, attributes }) => (
            <div
              key={`chart-type-${chartType}`}
              ref={hostRef}
              onMouseEnter={openTooltip}
              onMouseLeave={closeTooltip}
              disabled={Boolean(disabledMessage)}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                if (!disabledMessage) {
                  handleChangeChartType(chartType)
                }
              }}
              className={classnames(styles.chartTypeIcon, {
                [styles.active]: active,
                [styles.disabled]: Boolean(disabledMessage),
              })}
              data-test={`chart-type-${chartType}`}
              {...attributes}
            >
              <Icon icon={chartIconMap[chartType]} color="inherit" size="medium" />
            </div>
          )}
        </Tooltip>
      )
    },
    [chartTypeDisabledMessage, chartTypeMap, currChartType, handleChangeChartType]
  )

  return (
    <div className={styles.selector}>
      <span className={styles.header}>{headerText}</span>
      <div className={styles.input}>{supportedChartTypes.map(ct => renderChartTypeButton(ct))}</div>
    </div>
  )
}

ChartTypeSelector.propTypes = propTypes

export default ChartTypeSelector
