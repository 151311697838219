import React, { useEffect, useRef } from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import { filterNet as filterNetDuck } from 'store'

import * as styles from './DistributionChart.module.scss'

export default function DistributionChart({ appliedFilters = [] }) {
  const dispatch = useDispatch()
  const chartOptions = useSelector(filterNetDuck.getChartOptions, shallowEqual)
  const isLoading = useSelector(filterNetDuck.getTableDataLoading, shallowEqual)
  const error = useSelector(filterNetDuck.getTableDataError, shallowEqual)
  const chartRef = useRef()
  const chartContainerRef = useRef()

  useEffect(() => {
    if (chartOptions) return
    dispatch(filterNetDuck.generateTableDataThunk(appliedFilters))
  }, [dispatch, appliedFilters, chartOptions])

  return (
    <div className={styles.distributionContainer}>
      {chartOptions && (
        <section ref={chartContainerRef} className={styles.chartContainer}>
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
            immutable={false}
            containerProps={{ className: `${styles.highchartsContainer}` }}
            ref={chartRef}
          />
        </section>
      )}
      {isLoading && (
        <div className={styles.loadingError}>
          <span>Loading distribution chart...</span>
        </div>
      )}
      {Boolean(error) && (
        <div className={styles.loadingError}>
          <span>Error generating distribution chart.</span>
        </div>
      )}
    </div>
  )
}
