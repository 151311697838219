import React, { useCallback, useState } from 'react'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import {
  Button,
  DropdownButton,
  Icon,
  IconButton,
  RadioButton,
  RadioButtonList,
  Tooltip,
} from '@knowledgehound/laika'

import { getHandleTrack } from 'store/DatasetSelectors'
import { setWeighting } from 'store/DatasetActions'
import { deleteCustomWeight, resetCustomWeights } from 'store/customWeightActions'
import { getIsUserManager, getIsUserAdmin } from 'store/configurationDuck'
import { getStandardWeightOptions, getCustomWeightOptions } from './selectors'
import CustomWeightModal from './CustomWeightModal'
import * as styles from './WeightDropdown.module.scss'

const propTypes = {
  disabled: PropTypes.bool,
  dropdownVariant: PropTypes.bool,
}

function WeightDropdown({ dropdownVariant, disabled }) {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [customWeightModalOpen, setCustomWeightModalOpen] = useState(false)
  const dispatch = useDispatch()
  const standardWeightOptions = useSelector(getStandardWeightOptions, shallowEqual)
  const customWeightOptions = useSelector(getCustomWeightOptions, shallowEqual)
  const isAdmin = useSelector(getIsUserAdmin, shallowEqual)
  const isManager = useSelector(getIsUserManager, shallowEqual)
  const handleTrack = useSelector(getHandleTrack, shallowEqual)
  const changeWeight = useCallback(
    (event, weight) => {
      dispatch(setWeighting(weight))
      setDropdownOpen(false)
    },
    [dispatch, setDropdownOpen]
  )

  if (!isAdmin && !isManager) {
    return null
  }

  return (
    <React.Fragment>
      <DropdownButton
        text="Edit weighting"
        size="small"
        disabled={disabled}
        type={dropdownVariant ? 'secondary' : 'textual'}
        isOpen={dropdownOpen}
        onOpen={() => setDropdownOpen(true)}
        onClose={() => setDropdownOpen(false)}
      >
        <div className={styles.weightDropdownContainer}>
          <div className={styles.standardWeights}>
            <div className={styles.weightInfoLink}>
              <span className={styles.header}>Standard Weights</span>
              <Button
                icon="info"
                size="small"
                variant="text"
                type="button"
                onClick={() => window.Intercom('showArticle', 6864178)}
              >
                Help
              </Button>
            </div>
            <RadioButtonList
              name="weight-selection"
              onChange={changeWeight}
              options={standardWeightOptions}
            />
          </div>
          {customWeightOptions.length > 0 && (
            <div className={styles.customWeights}>
              <span className={styles.header}>Custom Weights</span>
              {customWeightOptions.map(weight => (
                <div key={weight.value} className={styles.layout}>
                  <RadioButton
                    name="weight-selection"
                    value={weight.value}
                    checked={weight.checked}
                    labelText={weight.labelText}
                    tooltip={weight.tooltip}
                    disabled={weight.disabled}
                    onChange={changeWeight}
                  />
                  <div className={styles.controls}>
                    {!weight.isValid && (
                      <Tooltip content="This weight variable is unusable because its underlying data has changed.">
                        <Icon icon="error" color="scarlet" size="small" />
                      </Tooltip>
                    )}
                    {weight.isValid && !weight.isDataReady && (
                      <Tooltip content="This weight variable will be available after we finish generating data for it.">
                        <div className={styles.spinner}>
                          <Icon icon="sync" color="charcoal" size="small" />
                        </div>
                      </Tooltip>
                    )}
                    {weight.userIsOwner && (
                      <IconButton
                        icon="trash"
                        size="small"
                        callback={() => dispatch(deleteCustomWeight(weight.specId))}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
          <hr className={styles.divider} />
          <Button
            onClick={() => {
              dispatch(resetCustomWeights())
              handleTrack('Opened Custom Weight Creation Modal')
              window.hj?.('event', 'opened_custom_weight_creation_modal')
              setCustomWeightModalOpen(true)
            }}
          >
            Create a custom weight
          </Button>
        </div>
      </DropdownButton>
      <CustomWeightModal
        isOpen={customWeightModalOpen}
        onClose={() => setCustomWeightModalOpen(false)}
      />
    </React.Fragment>
  )
}

WeightDropdown.propTypes = propTypes
export default WeightDropdown
